import MenuButton from 'components/MenuButton/MenuButton';
import QuickMenu from 'components/QuickMenu/QuickMenu';
import SoundButton from 'components/SoundButton/SoundButton';
import { useListenToEvent } from 'hooks/eventDispatcher';
import useScrollProgress from 'hooks/useScrollProgress';
import React, { useContext, useState } from 'react';
import { VoiceOversContext } from 'store/VoiceOversProvider';
import { isMuted, setMute } from 'utils/sound';
import { DispatchMute, GlobalEventType, OverlayElement } from 'utils/types';

export default function Navigation() {
  const [mutedUI, setMutedUI] = useState(isMuted());
  const [open, setOpen] = useState(false);

  function toggleMute() {
    setMute(!mutedUI);
  }

  function toggleOpen() {
    setOpen(!open);
  }

  useListenToEvent(GlobalEventType.setMute, (event: DispatchMute) => {
    setMutedUI(event.message);
  });

  const { jumpTo } = useScrollProgress();

  const { setActiveVoiceOver } = useContext(VoiceOversContext);
  function onItemSelect(item: OverlayElement) {
    setOpen(false);
    jumpTo(item.jumpTo!);
    setActiveVoiceOver(item.voiceOver);
  }

  return (
    <>
      <div className="z-20 w-full md:w-auto md:h-full fixed flex flex-row-reverse md:flex-col items-center justify-between py-5 md:py-10 px-5 md:px-0 md:right-10 pointer-events-none">
        <MenuButton open={open} onClick={toggleOpen} />
        <SoundButton muted={mutedUI} onClick={toggleMute} />
      </div>
      <QuickMenu open={open} onItemSelect={onItemSelect} />
    </>
  );
}

import GallerySection from 'main/components/GallerySection/GallerySection';
import PageTitle from 'main/components/PageTitle/PageTitle';
import Layout from 'main/layouts/Layout';
import React from 'react';

export default function Gallery() {
  return (
    <Layout>
      <div className="pt-[150px] px-5">
        <PageTitle pageHeading="Artworks showcase" pageTitle="Gallery" />
      </div>

      <GallerySection />
    </Layout>
  );
}

import { useDispatchEvent } from 'hooks/eventDispatcher';
import { APP_MAIN_SHEET } from 'main/common/constants';
import SceneLoader from 'main/components/SceneLoader';
import SceneUI from 'main/components/SceneUI/SceneUI';
import MainCanvas from 'main/rendering/components/MainCanvas';
import MainScene from 'main/rendering/components/MainScene/MainScene';
import StationProvider from 'main/store/StationProvider';
import React, { Suspense } from 'react';
import { GlobalEventType } from 'utils/types';

export default function Home() {
  const dispatchFinishedIntroAnimation = useDispatchEvent(() => ({
    type: GlobalEventType.finishedIntroAnimation,
  }));

  function start() {
    APP_MAIN_SHEET.project.ready.then(() => {
      APP_MAIN_SHEET.sequence
        .play({ iterationCount: 1, range: [0, 4] })
        .then(dispatchFinishedIntroAnimation);
    });
  }

  return (
    <StationProvider>
      <div className="h-screen text-white">
        <SceneLoader onLoaded={start} />
        <MainCanvas>
          <Suspense>
            <MainScene />
          </Suspense>
        </MainCanvas>
        <SceneUI />
      </div>
    </StationProvider>
  );
}

import { useFrame } from '@react-three/fiber';
import { useAnimations } from 'hooks/useAnimations';
import useScrollProgress from 'hooks/useScrollProgress';
import { useEffect, useMemo, useRef } from 'react';
import { AnimationAction, AnimationClip, Group, LoopOnce, LoopRepeat } from 'three';
import { GLTF } from 'three-stdlib';
import { ArrElement, DispatchItemInCarousel, GlobalEventType } from 'utils/types';

import { useListenToEvent } from './eventDispatcher';

interface MainSceneAnimationsProps {
  shotCam: GLTF;
  speedLines: Group;
  mittria: GLTF;
  bird: GLTF;
}

const ANIMATION_SEQUENCE_DURATION = 137;
const ANIMATION_SEQUENCE_FPS = 30;

const MITTRIA_ANIMATIONS = [
  {
    name: '1_Intro',
    start: 0,
    loop: false,
  },
  {
    name: '2_Awakening',
    start: 650,
  },
  {
    name: '3_IdleCarousel',
    start: 2126,
    loop: true,
  },
  {
    name: '6_Flying',
    start: 2755,
  },
  {
    name: '7_IdleTeam',
    start: 3365,
    loop: true,
  },
  {
    name: '8_FinalMagic',
    start: 3623,
  },
  {
    name: '9_idleEnd',
    start: 4011,
    loop: true,
  },
];
const BIRD_ANIMATIONS = MITTRIA_ANIMATIONS.filter((anim) =>
  ['2_Awakening', '6_Flying'].includes(anim.name)
);
const CAMERA_ANIMATIONS = [
  {
    name: '1_FirstPart',
    start: 0,
    loop: false,
  },
  {
    name: '2_SecondPart',
    start: 2126,
  },
];

type AnimationSequenceElement = ArrElement<typeof MITTRIA_ANIMATIONS>;

export default function useMainSceneAnimations(props: MainSceneAnimationsProps) {
  const { shotCam, speedLines, mittria, bird } = props;

  const cameraAnimations = useAnimations(shotCam.animations, shotCam.scene, false);
  const speedLinesAnimations = useAnimations(speedLines.animations, speedLines, false);
  const mittriaAnimations = useAnimations(mittria.animations, mittria.scene, false);
  const birdAnimations = useAnimations(bird.animations, bird.scene, false);

  const playableAnimations = useMemo(() => [speedLinesAnimations], [speedLinesAnimations]);
  useEffect(() => {
    function playAllAnimationClips(animations: Api<AnimationClip>) {
      animations.names.forEach((name) => {
        animations.actions[name]?.play();
      });
      animations.mixer.setTime(0);
    }
    playableAnimations.forEach(playAllAnimationClips);
  }, [playableAnimations]);

  // @ts-ignore
  const rightSwipeAction = mittriaAnimations.actions['5_CarouselRight'] as AnimationAction;
  // @ts-ignore
  const leftSwipeAction = mittriaAnimations.actions['4_CarouselLeft'] as AnimationAction;
  // @ts-ignore
  const idle = mittriaAnimations.actions['3_IdleCarousel'] as AnimationAction;

  const activeMittriaRef = useRef<AnimationSequenceElement | undefined>();
  const activeShotcamRef = useRef<AnimationSequenceElement | undefined>();
  const activeBirdRef = useRef<AnimationSequenceElement | undefined>();
  const mittriaDurationRef = useRef(0);
  const shotCamDurationRef = useRef(0);
  const birdDurationRef = useRef(0);

  const animationsConfig = useMemo(
    () => [
      {
        animationsSequence: MITTRIA_ANIMATIONS,
        animationsHandle: mittriaAnimations,
        activeRef: activeMittriaRef,
        durationRef: mittriaDurationRef,
      },
      {
        animationsSequence: CAMERA_ANIMATIONS,
        animationsHandle: cameraAnimations,
        activeRef: activeShotcamRef,
        durationRef: shotCamDurationRef,
      },
      {
        animationsSequence: BIRD_ANIMATIONS,
        animationsHandle: birdAnimations,
        activeRef: activeBirdRef,
        durationRef: birdDurationRef,
      },
    ],
    [mittriaAnimations, cameraAnimations, birdAnimations]
  );
  type AnimationConfigItem = ArrElement<typeof animationsConfig>;

  function updateActiveAnimation(currentFrame: number, config: AnimationConfigItem) {
    const sequence = config.animationsSequence;
    for (let i = 0; i < sequence.length; i++) {
      if (
        sequence[i].start <= currentFrame &&
        currentFrame < (sequence[i + 1]?.start || Number.POSITIVE_INFINITY)
      ) {
        setActiveAnimation(sequence[i], config);
        return;
      }
    }
  }

  function setActiveAnimation(
    sequenceElement: AnimationSequenceElement,
    config: AnimationConfigItem
  ) {
    if (sequenceElement === config.activeRef.current) return;

    // @ts-ignore
    const currentAction = config.animationsHandle.actions[config.activeRef.current?.name || ''] as
      | AnimationAction
      | undefined;
    // @ts-ignore
    const newAction = config.animationsHandle.actions[sequenceElement.name] as AnimationAction;
    const clip = config.animationsHandle.clips.find((c) => c.name === sequenceElement.name);

    if (activeMittriaRef.current?.name !== '3_IdleCarousel') {
      leftSwipeAction.stop();
      rightSwipeAction.stop();
    }
    
    newAction.loop = sequenceElement.loop ? LoopRepeat : LoopOnce;
    newAction.clampWhenFinished = true;
    newAction.play();
    currentAction?.stop();

    config.activeRef.current = sequenceElement;
    config.durationRef.current = clip?.duration || 0;
  }

  const swipeAnimationInProgressRef = useRef(false);
  useListenToEvent(GlobalEventType.legendaryItemCarousel, (event: DispatchItemInCarousel) => {
    if (swipeAnimationInProgressRef.current) return;

    swipeAnimationInProgressRef.current = true;

    function crossFadeTo(start: AnimationAction, end: AnimationAction, fadeDuration = 1) {
      if (activeMittriaRef.current?.name !== '3_IdleCarousel')
        return new Promise((resolve) => {
          resolve(undefined);
        });
      end.enabled = true;
      end.setEffectiveTimeScale(fadeDuration);
      end.reset().play();

      start.crossFadeTo(end, fadeDuration, true);

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(undefined);
        }, fadeDuration * 1000);
      });
    }
    const swipeAnimation = event.direction === 1 ? leftSwipeAction : rightSwipeAction;
    const fadeDuration = 1;

    crossFadeTo(idle, swipeAnimation, fadeDuration);
    setTimeout(async () => {
      await crossFadeTo(swipeAnimation, idle, fadeDuration);
      swipeAnimationInProgressRef.current = false;
      // @ts-ignore
    }, (swipeAnimation._clip.duration - fadeDuration * 1.2) * 1000);
  });

  useScrollProgress((progress) => {
    const progressInSeconds = progress * ANIMATION_SEQUENCE_DURATION;
    const progressInFrames = progressInSeconds * ANIMATION_SEQUENCE_FPS;

    animationsConfig.forEach((config) => {
      updateActiveAnimation(progressInFrames, config);
      const animationOffset = (config.activeRef.current?.start || 0) / ANIMATION_SEQUENCE_FPS;
      if (!config.activeRef.current?.loop) {
        config.animationsHandle.mixer.time = 0;

        // mixer.setTime has a bug where clampWhenFinishes doesn't work.
        // The code below does the same thing as setTime with added condition if the clip is already paused.
        // @ts-ignore
        config.animationsHandle.mixer._actions.forEach((action: AnimationAction) => {
          if (!action.paused) action.time = 0;
        });
        config.animationsHandle.mixer.update(
          Math.min(config.durationRef.current - 0.01, progressInSeconds - animationOffset)
        );
      }
    });
    playableAnimations.forEach((anim) =>
      anim.mixer.setTime(progress * ANIMATION_SEQUENCE_DURATION)
    );
  });

  useFrame((_, delta) => {
    animationsConfig.forEach((config) => {
      if (!config.activeRef.current?.loop) return;
      config.animationsHandle.mixer.update(delta);
    });
  });
}

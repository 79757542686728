import vertexShader from 'rendering/shaders/standard.vertex.glsl';
import fragmentShader from 'rendering/shaders/texture-atlas.fragment.glsl';
import { ShaderMaterial, ShaderMaterialParameters, Texture, Uniform, Vector2 } from 'three';
import { AtlasUvCoordinates } from 'utils/types';

export default class TextureAtlasBasicMaterial extends ShaderMaterial {
  uvCoordinates: AtlasUvCoordinates;

  map: Texture;

  constructor({
    uvCoordinates,
    map,
    ...superProps
  }: Omit<ShaderMaterialParameters, 'uniforms' | 'vertexShader' | 'fragmentShader' | 'defines'> & {
    uvCoordinates: AtlasUvCoordinates;
    map: Texture;
  }) {
    super({
      ...superProps,
      vertexShader,
      fragmentShader,
      uniforms: {
        uvOrigin: new Uniform(uvCoordinates.origin),
        uvSize: new Uniform(uvCoordinates.size),
        map: new Uniform(map),
        uMapResolution: new Uniform(new Vector2(map.image.width, map.image.height)),
        uPadding: new Uniform(uvCoordinates.padding ?? new Vector2(0, 0)),
      },
    });

    this.uvCoordinates = uvCoordinates;
    this.map = map;
  }
}

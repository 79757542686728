import headerLogo from 'assets/images/UI/header-logo.png';
import React from 'react';

export default function Header() {
  return (
    <div className="fixed top-7 sm:top-10 w-full flex flex-row justify-center z-10">
      <img src={headerLogo} alt="Mittaria Genesis logo" className="w-[166px] md:w-[249px]" />
    </div>
  );
}

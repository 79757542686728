import GaTracking from 'components/GaTracking/GaTracking';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import About from 'main/pages/About';
import Article from 'main/pages/Article';
import CreatorForm from 'main/pages/CreatorForm';
import Gallery from 'main/pages/Gallery';
import Home from 'main/pages/Home';
import Ip from 'main/pages/Ip';
import NewSeasons from 'main/pages/NewSeasons';
import Story from 'main/pages/Story';
import TheGenesis from 'main/pages/TheGenesis';
import WTGCreatorForm from 'main/pages/WTGCreatorForm';
import GenesisHome from 'pages/Home';
import Legals from 'pages/Legals/Legals';
import PrivacyPolicyContent from 'pages/Legals/PrivacyPolicy';
import TermsGenesis from 'pages/Legals/TermsGenesis';
import TermsAndConsNFT from 'pages/Legals/TermsNFT';
import TermsOfUseContent from 'pages/Legals/TermsOfUse';
import WTGLegals from 'pages/Legals/WTGLegals';
import React from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import VoiceOverProvider from 'store/VoiceOversProvider';

gsap.registerPlugin(ScrollTrigger);
gsap.config({ nullTargetWarn: false });

const router = createBrowserRouter([
  {
    path: '/',
    element: <GaTracking />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/genesis',
        element: <GenesisHome />,
      },
      {
        path: '/story',
        element: <Story />,
      },
      {
        path: '/ip',
        element: <Ip />,
      },
      {
        path: '/about',
        element: <About />,
      },
      {
        path: '/new-seasons',
        element: <NewSeasons />,
      },
      {
        path: '/the-genesis',
        element: <TheGenesis />,
      },
      {
        path: '/blogs/:id',
        element: <Article />,
      },
      {
        path: '/gallery',
        element: <Gallery />,
      },
      {
        path: '/terms',
        element: (
          <Legals title="Website Terms Of Use">
            <TermsOfUseContent />
          </Legals>
        ),
      },
      {
        path: '/terms-nft',
        element: (
          <Legals title="Mittaria NFT Terms and Conditions">
            <TermsAndConsNFT />
          </Legals>
        ),
      },
      {
        path: '/terms-genesis',
        element: (
          <Legals title="Genesis Collection Terms of Sale" subtitle="mittaria NFT">
            <TermsGenesis />
          </Legals>
        ),
      },
      {
        path: '/creator-form',
        element: (
          <Legals title="Creator Registration" subtitle="Mittaria NFT">
            <CreatorForm />
          </Legals>

        ),
      },
      {
        path: '/wtg-creator-form',
        element: (
          <WTGLegals title="Creator Registration" subtitle="WayToGods NFT">
            <WTGCreatorForm />
          </WTGLegals>
        ),
      },
      {
        path: '/privacy',
        element: (
          <Legals title="Privacy Notice">
            <PrivacyPolicyContent />
          </Legals>
        ),
      },
      {
        path: '/*',
        element: <Navigate to="/" />,
      },
    ],
  },
]);

function App() {
  return (
    <VoiceOverProvider>
      <div className="h-full w-full">
        <RouterProvider router={router} />
      </div>
    </VoiceOverProvider>
  );
}

export default App;
import React from 'react';

export default function TermsAndConsNFT() {
  return (
    <div className="container">
      <p>
        PLEASE READ THESE MITTARIA NFT TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS WEBSITE AND
        MITTARIA NFTs. WE WILL USE YOUR PERSONAL INFORMATION AS SET OUT IN OUR PRIVACY POLICY HERE:{' '}
        <a target="_blank" href="https://mittaria.io/privacy" rel="noreferrer">
          (https://mittaria.io/privacy).
        </a>{' '}
        FOR DETAILS REGARDING OUR INTELLECTUAL PROPERTY RIGHTS IN MITTARIA NFT WEBSITE, PLEASE SEE
        OUR WEBSITE TERMS OF USE HERE:{' '}
        <a target="_blank" href="https://mittaria.io/terms" rel="noreferrer">
          https://mittaria.io/terms.
        </a>
      </p>
      <p>Last updated: 31 January 2024</p>
      <br />
      <h3>1 About Mittaria NFT Collection and the operator of the Website</h3>
      <p>
        1.1 The Mittaria website (available at{' '}
        <a target="_self" href="https://mittaria.io" rel="noreferrer">
          https://mittaria.io.
        </a>
        ) (“Website”) allows its users to mint Mittaria non-fungible tokens (“Mittaria NFT
        Collection”) as follows:
      </p>
      <p className="ml-5">
        1.1.1 Genesis Collection (“Mittaria Genesis NFT”){' '}
        <a target="_blank" href="https://mittaria.io/terms-genesis" rel="noreferrer">
          (https://mittaria.io/terms-genesis).
        </a>
      </p>
      <p className="ml-5">
        1.1.2 Way to Gods Collection (“Mittaria Way to Gods NFT”){' '}
        <a target="_blank" href="https://waytogods.mittaria.io" rel="noreferrer">
          (https://waytogods.mittaria.io/terms).
        </a>
      </p>

      <p>
        1.2 The Utilities Roadmap (“Roadmap”) of Mittaria NFT Collection is as follows, and it may
        be changed from time to time as announced on the Website and/or in the terms and conditions
        of each Mittaria NFT Collection:
      </p>

      <ol className="ml-5" type="a">
        <li>
          <p>
            (a) <span className="underline">ACCESS</span>
          </p>
          <p>
            Step into our evolving ecosystem to mint all upcoming collections and enroll in all
            future seasons for free.
          </p>
        </li>
        <li>
          <p>
            (b) <span className="underline">REWARDS</span>
          </p>
          <p>
            Harness the power of our thriving ecosystem: From valuable rewards to immense
            opportunities, they’re all here for the taking.
          </p>
        </li>
        <li>
          <p>
            (c) <span className="underline">EXPERIENCE</span>
          </p>
          <p>
            Journey through our captivating metaverses: Translucia, Master of the Metaverse;
            Forestias, the beacon of sustainable, compassionate living; and Idyllias, a harmonious
            fusion of virtual and real worlds.
          </p>
        </li>
        <li>
          <p>
            (d) <span className="underline">IP</span>
          </p>
          <p>
            At T&amp;B, we&#39;ve mastered the art of creating successful Intellectual Properties.
            With our incredible community, we will build Mittaria into a globally recognized brand.
          </p>
        </li>
        <li>
          <p>
            (e) <span className="underline">PARTNERSHIP</span>
          </p>
          <p>
            Indulge in the benefits of our global relationships in both Web2 and Web3 and gain value
            from our prestigious network of partners.
          </p>
        </li>
        <li>
          <p>
            (f) <span className="underline">WEB2</span>
          </p>
          <p>
            Benefit from our Web2 accomplishments past, present, and future. Every season features a
            traditional business model that generates value for our community.
          </p>
        </li>
        <li>
          <p>
            (g) <span className="underline">COMMUNITY</span>
          </p>
          <p>
            Immerse yourself in the captivating world of Mittaria - the home of happiness. Join our
            vibrant community and be a part of redefining Web3.
          </p>
        </li>
      </ol>

      <p>
        You acknowledge the Roadmap may or may not be implemented in the future and that we may
        amend the Roadmap or project plan at our sole discretion and from time to time.
      </p>

      <p>
        1.3 Mittaria NFT Collection is NOT intended to not constitute securities of any form,
        including but not limited to units in a business trust, units in a collective investment
        scheme, capital market products, debentures, or any other form of regulated investment or
        investment product, in any jurisdiction, however called in any jurisdiction.
      </p>

      <p>
        1.4 The Website is operated by Mittaria Origin Limited, a company registered in British
        Virgin Islands, whose registered number is 2100690, and registered address is 1 st Floor,
        Irvine’s Place, 159 Main Street, P.O. Box 2132, Road Town, Tortola, British Virgin Islands.
        (<span className="font-bold">&quot;we/us/our&quot;</span>).
      </p>

      <p>
        1.5 The entirety of intellectual property rights in the Website, artworks and materials
        relating to Mittaria NFT Collection belongs to T&amp;B Media Global (Thailand) Co. Ltd, a
        company incorporated under the laws of Thailand, company registration number 0105559126836,
        with registered address at No. 101 True Digital Park Building, 4 th Floor, Room 408,
        Sukhumvit Road, Bang Chak, Prakanong, Bangkok 10260, or to its partner (collectively
        referred to as the <span className="font-bold">&quot;Licensor&quot;</span>)
      </p>

      <p>
        1.6 We are solely responsible for the issuance of Mittaria NFT Collection. We have acquired
        from the Licensor a non-exclusive, non-transferable license (with no right to sub-license),
        revocable license to intellectual property rights existing in artworks of Mittaria NFT
        Collection for the very limited purpose of creating Mittaria NFT Collection for sale to you.
      </p>

      <h3>2 About these terms</h3>

      <p>
        2.1 Our contract with you. By visiting, accessing, or using the Website, or connecting your
        blockchain wallet such as Metamask account, you confirm that you understand and accept these
        Mittaria NFT Terms and Conditions (the “Terms”) and that you agree to comply with them.
        These Terms constitute a legal agreement between you and us, and Licensor.
      </p>

      <p>
        2.2 As of the “Last Updated” date, no regulatory authority has examined or approved any of
        the information set forth in these Terms or any related documentation or communication by
        the Company.
      </p>

      <p>
        2.3 <span className="font-bold">What do these Terms cover?</span> These are the terms which
        govern the use of the Website, the purchase and engagement with Mittaria NFT Collection by
        you (“<span className="font-bold">you/yours</span>”).
      </p>

      <p>
        2.4 <span className="font-bold">How to contact us.</span> You can contact us by writing to
        us at{' '}
        <a href="mailto:admin@mittaria.io">
          <span className="font-bold underline">Admin@mittaria.io</span>
        </a>
        .
      </p>

      <p>
        2.5 <span className="font-bold">How we may contact you.</span> If we have to contact you, we
        will do so by email using the email address you provided to us optionally, or when you
        contact us.
      </p>

      <p>
        2.6 <span className="font-bold">There may be additional terms applicable.</span>There may be
        additional terms posted on our Website, or when you connect your blockchain wallet to use
        our Website, which also apply to your use of the Website. These additional terms may be
        expressly incorporated into these Terms by being mentioned here, or by being published on
        the Website. For example, all Mittaria NFT Collection, including Mittaria Genesis NFTs, are
        subject to specific terms of sale as set out in Schedule 1 (
        <span className="font-bold">&quot;Mittaria NFT Terms of Sale&quot;</span>). There may also
        be additional terms applicable to any specific Mittaria NFT Collection.
      </p>

      <p>
        2.7 <span className="font-bold">We may update these Terms.</span>We reserve the right to
        amend these Terms, including the Schedule(s) at any time, at our discretion including but
        not limited to complying with applicable laws, rules and regulations. Any changes to these
        Terms will be in effect as of the “Last Updated” date referred to at the top of these terms.
        You acknowledge and agree that the form and nature of the Terms, and any part of it, may
        change from time to time, and that we may add new or remove existing features and change any
        part of the Website. Any changes made to these Terms will be notified to you through our
        communication channels or through our Website. You should, however, check back often on the
        Website to confirm that your copy and understanding of these Terms is current and correct.
      </p>

      <h3>3 Representation and Warranties to Use the Website</h3>
      <p>
        3.1 <span className="font-bold">Using the Website</span>. You must meet the criteria listed
        below to use certain functionalities of the Website. By using the Website and connecting
        your blockchain wallet (such as Metamask), you confirm that:
      </p>

      <ol className="ml-5" type="a">
        <li>
          <p>(a) you are at least 18 years of age;</p>
        </li>
        <li>
          <p>(b) you have the capacity, full power, right and authority to agree to these Terms;</p>
        </li>
        <li>
          <p>
            (c) you have successfully passed our KYC/AML and/or intellectual property checks, if we,
            in our sole discretion, decide to conduct them prior to providing access or
            functionalities of the Website or Mittaria NFT Collection to you;
          </p>
        </li>
        <li>
          <p>
            (d) you are not subject to any financial or other sanctions, embargoes or other
            restrictions, including those imposed by the US Office of Foreign Assets Control (OFAC),
            governments of the British Virgin Islands, the United Kingdom, European Union or United
            Nations;
          </p>
        </li>
        <li>
          <p>
            (e) you are not subject to any laws or regulations in your country of nationality or
            residence which would make it unlawful for you to mint or hold Mittaria NFTs or be a
            party to any transaction involving Mittaria NFT Collection;
          </p>
        </li>
        <li>
          <p>
            (f) you are not known for not respecting, bypassing or breaching NFT creators’ rights,
            including royalty rights;
          </p>
        </li>
        <li>
          <p>(g) you understand the risks associated with owning Mittaria NFT Collection;</p>
        </li>
        <li>
          <p>
            (h) you have sufficient knowledge and understanding of how to transact using digital
            crypto wallets;
          </p>
        </li>
        <li>
          <p>
            (i) You have consulted your legal, financial, tax or other professional advisor(s) when
            in doubt about any of the actions on this Website; and
          </p>
        </li>
        <li>
          <p>
            (j) yall the information provided by you to us is true and accurate and you will keep it
            updated at all times by login into your account and updating your account details.
          </p>
        </li>
        <li>
          <p>
            (k) your participation, purchase, and engagement with the Mittaria NFT Collection is
            solely based on the current utilities, features, and functionalities associated with the
            Mittaria NFT Collection at the time of acquisition.
          </p>
        </li>
      </ol>

      <p>
        3.2 We reserve the right to prohibit any and all purchasing of Mittaria NFT Collection to
        users domiciled or located in a country or territory we deem (in our sole and absolute
        discretion) as prohibited, restricting, or subjecting to regulatory requirements, any
        cryptographic token, virtual asset related activities, including but not limited to Algeria,
        Bangladesh, China, Egypt, Iran, Iraq, Morocco, Nepal, North Korea, Qatar, Singapore,
        Tunisia, the United States of America. Mittaria NFT Collection may not be offered to any
        person resident in the British Virgin Islands for purchase. The Company does not intend to
        conduct any cryptographic token, virtual asset, or related activities which may be
        prohibited or subject to regulatory requirements in those jurisdictions. You represent and
        warrant that you are not domiciled or a resident in any of the aforementioned jurisdictions.
      </p>

      <p>
        3.3 <span className="font-bold">Prohibited uses.</span>You shall not use the Website for or
        in connection with any of the purposes or any of the matters listed below and you
        acknowledge that any such use may result in immediate suspension or termination of your
        account and of your right to use the Website. You shall not use the Website to:
      </p>

      <ol className="ml-5" type="a">
        <li>
          <p>
            (a) collude with other users to manipulate the price of any NFT or in any other way
            affect or influence the value or the price of any NFTs;
          </p>
        </li>
        <li>
          <p>
            (b) conduct any unsolicited or unauthorised advertising or promotional activity or any
            other form of similar solicitation (spam);
          </p>
        </li>
        <li>
          <p>
            (c) conduct or be involved (directly or indirectly) in any activities which are, or may
            result in, a breach of any applicable laws (including local, national and international
            law or regulation), including, but not limited to, laws against money laundering and/or
            terrorist financing,
          </p>
        </li>
        <li>
          <p>(d) engage in speculative investments;</p>
        </li>
        <li>
          <p>
            (e) engage in any activity that may bring us or the Website or any other user into
            disrepute (whether defamatory or not);
          </p>
        </li>
        <li>
          <p>
            (f) conduct or be involved (directly or indirectly) in any activities which are, or may
            result in, a breach of any rights of any third party, including their intellectual
            property rights (for example their copyrights, patents or trademarks);
          </p>
        </li>
        <li>
          <p>
            (g) act in any way that is unlawful, fraudulent, or has any unlawful or fraudulent
            purpose or effect (including providing any false, inaccurate or misleading information
            to us and/or any other user);
          </p>
        </li>
        <li>
          <p>
            (h) bypass or facilitate bypassing of NFT creators’ rights, including royalty rights;
          </p>
        </li>
      </ol>

      <h3>4. Purchase of Mittaria NFT Collection</h3>

      <p>
        4.1 <span className="font-bold">Connecting your digital crypto wallet.</span>To mint or
        purchase Mittaria NFT Collection on the Website, you will need to connect your blockchain
        wallet (“Wallet”) to your account on the Website, which must be supported by the Website
        such as Metamask. We may publish additionally supported Wallets on the Website. Having a
        Wallet is necessary to own a Mittaria NFT Collection, and the Wallet may also be used (in
        addition to any other methods of payment made available by us from time to time) to pay for
        and receive payments in respect of any transactions concluded on the Website. Transfer of an
        NFT to the Wallet is at your own risk, and are not responsible or liable for accidental,
        negligent, or fraudulent transfers to/from an incorrect Wallet.
      </p>

      <p>
        4.2 <span className="font-bold">Purchase.</span>The users will be able to purchase Mittaria
        NFT Collection on the Website. Other details relating to the purchase of Mittaria NFT
        Collection shall be subject to any specific terms applicable to each Mittaria NFT
        Collection. Once you confirm the instruction to mint Mittaria NFT Collection and the
        transaction has been confirmed on the blockchain, the specific terms applicable to each
        Mittaria NFT Collection will come into existence between you and us.
      </p>

      <p>
        4.3 <span className="font-bold">Giveaways.</span> Subject to any specific terms applicable
        to each Mittaria NFT Collection, You might be able to receive certain Mittaria NFT
        Collection during our giveaways via free airdrops. At that point, the specific terms
        applicable to each Mittaria NFT Collection will come into existence between you and us.
      </p>
      <p>
        4.4 <span className="font-bold">Receipt of the Mittaria NFT Collection.</span> The Mittaria
        NFT Collection purchase process will be completed when you receive the Mittaria NFT
        Collection into the Wallet and the transaction is recorded on a blockchain. You are solely
        responsible for ensuring that the Wallet address provided by you is correct and you
        acknowledge and agree that we will not be liable as a result of an incorrect Wallet address
        being provided by you.
      </p>
      <p>
        4.5 <span className="font-bold">No right to cancel.</span> Due to the irreversible nature of
        all blockchain transactions, you will be asked by the Website to confirm that you agree to
        lose your right to withdraw from the purchase of Mittaria NFT Collection as soon as you have
        submitted your purchase instruction.
      </p>
      <p>
        4.6{' '}
        <span className="font-bold">We are not responsible for delays outside our control.</span> If
        the purchase process is delayed by an event outside our control, for example as a result of
        any delays in the blockchain network, we will not be liable for such delays. You acknowledge
        that it will not be possible to cancel or reverse your purchase request even in the
        circumstances of a significant delay.
      </p>
      <p>
        4.7 <span className="font-bold">Payment.</span> You must make a payment in full in the form
        nominated by us, in the amount stated on the Website at the time you submit your request.
        You acknowledge and agree that all purchases of Mittaria NFT Collection on the Website are
        irreversible and that no refunds will be due to you in any circumstances.
      </p>
      <p>
        4.8 <span className="font-bold">Taxes.</span> You are responsible for any taxes that may be
        due in connection with your ownership of any Mittaria NFT Collection. It is your sole
        responsibility to determine whether, and to what extent, any taxes apply to any transactions
        on the Website, and to withhold, collect, report and remit the correct amount of tax to the
        appropriate tax authorities. You will indemnify us in full in the event we are required to
        pay any taxes in respect of your purchasing Mittaria NFT Collection on the Website.
      </p>

      <h3>5. Ownership and Sale of Mittaria NFT Collection</h3>

      <p>
        <span className="font-bold">
          Rights to the Mittaria NFT Collection and its images or art
        </span>
      </p>

      <p>
        5.1 Mittaria NFT Collection do not represent or confer any ownership right or stake, share
        or security or equivalent rights, or any right to receive future revenue, shares, or any
        other form of participation in or relating to Mittaria Origin Limited and its affiliates,
        other than rights, subject to limitations and conditions in these Terms and other applicable
        terms.
      </p>
      <p>
        5.2 When you purchase Mittaria NFT Collection, unless otherwise provided, you acknowledge
        and accept that you only receive ownership of the token or code which is stored on the
        blockchain which points to the location of the underlying images or art (typically a HTML
        hyperlink to the asset stored on another server). For the purposes of this clause, ownership
        is proven through recordation on the blockchain.
      </p>
      <p>
        5.3 Purchasing Mittaria NFT Collection do not transfer, assign the ownership in, or license
        to the intellectual property rights existing in the images or art associated with the
        Mittaria NFT Collection (“Artwork”), whether in its totality or in any individual element
        (such as the features, attributes, properties, or traits. For the avoidance of doubt, the
        buyer and subsequent buyer hereby recognize and agree that the entirety of the intellectual
        property in the Artwork unequivocally belongs to the Licensor. Consequently, any
        undertakings aimed at commercial exploitation, reproduction, replication, adaption,
        communication to the public, exhibition, or the creation of derivative works contingent upon
        the Mittaria NFT Collection, or any fraction thereof, unless otherwise provided in specific
        terms applicable to each Mittaria NFT Collection, may infringe the intellectual property
        rights of the Licensor.
        <br />
        <br />
        In case you desire to obtain an intellectual property license to commercially utilize the
        Artwork, you may independently agree to the specific terms applicable to each Mittaria NFT
        Collection to obtain certain copyrights of the Artwork from the Licensor.
      </p>

      <p>
        <span className="font-bold">5.4 Selling Mittaria NFT Collection/Creator Fees</span>
      </p>

      <ol className="ml-5" type="a">
        <li>
          <p>
            (a) You must ensure that if you decide to sell your Mittaria NFT Collection, you sell it
            on the specific terms applicable to each Mittaria NFT Collection and that the
            third-party marketplace where you sell your Mittaria NFT Collection respects NFT
            creators’ rights, including creator fees. This is necessary to protect the rights of the
            creators and intellectual property owners. We are not a party to any such contract and
            will not be liable or responsible for any matters related to any such onward sale. If
            you fail to sell your Mittaria NFT Collection on the specific terms applicable to each
            Mittaria NFT Collection, the rights in the Mittaria NFT Collection may be affected.
          </p>
        </li>
        <li>
          <p>
            (b) You acknowledge that, we, as the creator of the Mittaria NFT Collection, we will
            earn a creator fee which is a percentage of the resale price, (“Creator Fees”) which
            will be automatically deducted each time when you buy or sell Mittaria NFT Collection on
            a third- party secondary marketplace.
          </p>
        </li>
        <li>
          <p>
            (c) We reserve the rights to restrict the sale of Mittaria NFT Collection on the
            third-party secondary marketplaces that do not respect, bypass, or breach NFT creators’
            or intellectual property owner’s rights, including Creator Fees, without assuming any
            liability to you. You undertake not to transfer your ownership in any of your Mittaria
            NFT Collection in any manner which would purposefully result in you failing to pay the
            Creator Fees in full. For the avoidance of doubt, you are not prohibited from gifting
            Mittaria NFT Collection to another person or making a transfer that is not for the
            purpose of circumventing the payment of Creator Fees.
          </p>
        </li>
      </ol>

      <h3>6 Risks</h3>
      <p>
        6.1 You acknowledge and agree that Mittaria NFT Collection and the Website utilise new
        technologies (e.g., blockchain and smart contracts, consensus algorithms, decentralised
        networks) and as such, the ownership, purchase and sale of Mittaria NFT Collection is
        subject to various risks, some of which are difficult to predict. A significant degree of
        technological knowledge (in particular, in relation to blockchain) is required to safely
        deal in and store NFTs of any kind, including using the Wallet. It is your responsibility to
        research and be aware of these risks. Some of the risks, which you should be aware of and
        accept before you conclude any transaction on the Website, are as follows:
      </p>

      <ol className="ml-5" type="a">
        <li>
          <p>
            (a) the volatile nature of virtual assets, including Mittaria NFT Collection, which
            means that the price can fluctuate rapidly, and the ability to find a purchaser may be
            affected;
          </p>
        </li>
        <li>
          <p>
            (b) changes in laws and regulations may affect your rights to use and transfer NFTs;
          </p>
        </li>
        <li>
          <p>
            (c) the use, ownership and transfer of NFTs is not regulated in most jurisdictions,
            which means that your ownership and transactions in Mittaria NFT Collection will not be
            afforded regulatory protections;
          </p>
        </li>
        <li>
          <p>
            (d) transactions involving NFTs are irreversible, including when they are accidental or
            fraudulent, which means that losses suffered may not be recoverable;
          </p>
        </li>
        <li>
          <p>
            (e) NFTs can lose their value entirely if there is no interest in them from potential
            purchasers;
          </p>
        </li>
        <li>
          <p>
            (f) as digitally-held assets, NFTs may be susceptible to fraud, cyberattacks or
            technological issues which may affect your ability to use or sell your NFTs;
          </p>
        </li>
        <li>
          <p>
            (g) your ownership of NFTs relies on you remaining in control of the private key(s)
            associated with the Wallet; and
          </p>
        </li>
        <li>
          <p>
            (h) Each prospective Mittaria NFT Collection holder should evaluate such potential
            purchase on the basis that the NFTs, we, or any third party’s assessment of the
            prospects of the NFTs may not prove accurate. Our past performance or past performance
            of Mittaria NFT Collection is not predictive of future results.
          </p>
        </li>
        <li>
          <p>
            (i) lack of use or interest could negatively impact the development of the Mittaria NFT
            Collection and therefore the potential utility of Mittaria NFT Collection.
          </p>
        </li>
      </ol>

      <p>
        6.2 By using the Website, you accept the risks involved in any transactions involving NFTs
        per the abovementioned.
      </p>

      <h3>7 Third-party sites, products and services</h3>

      <p>
        The Websites may integrate with third-party marketplaces or services, for example, Wallet or
        payments processing services (Third-Party Products). You acknowledge that all Third-Party
        Products are provided by the relevant third parties, not by us, and are subject to the
        third-party terms and conditions applicable to Third-Party Products. We will not be
        responsible for the availability of any Third-Party Products and will not be liable to you
        for any matters related to the delivery of (or a failure to deliver) Third-Party Products to
        you. We are also not responsible for the functioning of any blockchain network recording the
        ownership of NFTs.
      </p>

      <h3>8 Disclaimers and liability</h3>

      <p>
        8.1{' '}
        <span className="font-bold">
          We are not responsible or liable for the actions of users of the Website.
        </span>{' '}
        All users must ensure that they have all necessary rights, authorisations, and permissions
        to mint and purchase Mittaria NFT Collection on the Website. We will not verify any user’s
        rights, authorisations, and permissions to mint and purchase Mittaria NFT Collection on the
        Website and we will not be liable for the acts or omissions of any user.
      </p>
      <p>
        8.2 <span className="font-bold">We do not provide advice.</span> NFTs, including Mittaria
        NFT Collection, are not intended for speculative use, are not sold or represented to be
        financial products and nothing we publish on the Website should be interpreted or relied on
        as financial advice to you or any other person.
      </p>
      <p>
        8.3 <span className="font-bold">We do not make any warranties
        in respect of the Website.</span>  The Website and Mittaria NFT Collection are provided on an “as
        is” and “as available” basis and we make no representations or warranties (whether express
        or implied) that: (a) the Website will meet your requirements; (b) the results or, outcomes
        obtained from the use of the Website will meet your expectations; (c) NFTs will always be
        available for purchase on the Website or that any specific NFTs will be available; and (d)
        the content on the Website will be accurate, reliable, complete, legal or safe. To the
        fullest extent permitted by law, we disclaim all warranties of any kind, whether express or
        implied, statutory or otherwise, including but not limited to warranties of merchantability,
        fitness for a particular purpose, title and non-infringement as to the Website and any
        content on the Website. This does not affect any warranties that cannot be excluded or
        limited under applicable law.
      </p>
      <p>
        8.4 <span className="font-bold">Limitation of Liability.</span> To the fullest extent
        permitted by law, in no event will we be liable to you or any third party for any: (a) loss
        of profit; (b) loss of sales; (c) loss of data or information; (d) damage to goodwill (e)
        loss of revenue; (f) loss of use; (g) indirect, consequential, exemplary, incidental,
        special or punitive damages, arising in connection with the use of the Website, NFT, or
        these terms, whether in contract, tort (including negligence), breach of statutory duty or
        otherwise, and whether foreseeable or not, and even if we have been advised of the
        possibility of any such losses occurring. Notwithstanding anything to the contrary in these
        terms in no event will our total aggregate liability to you exceed the purchase price of the
        Mittaria NFT Collection purchased with us. The limitations and exclusions of liability in
        this clause or anywhere else in these Terms will not apply to our liability for death or
        personal injury caused by our negligence, fraud or fraudulent misrepresentation, or any
        other liability which cannot be limited or excluded by law.
      </p>
      <p>
        8.5 <span className="font-bold">Your Liability to Us.</span> You agree to indemnify us to
        the fullest extent permitted by the applicable laws, and agree to defend and hold us
        harmless, from and against all liabilities, costs, expenses, damages and losses suffered or
        incurred by us arising out of or in connection with: (a) your use of the Mittaria NFT
        Collection, Website, including any transactions made by you using the Website; and (b)
        infringement by you of the Licensor’s intellectual property rights, and any third-party
        rights (including third-party intellectual property rights).
      </p>

      <h3>9 Other important terms</h3>

      <p>
        9.1 <span className="font-bold">No Partnership or Agency.</span> Nothing in these Terms is
        intended to, or shall be deemed to, establish any partnership or joint venture between you,
        us, and/or the Licensor, constitute any party, the agent of another party, or authorize any
        party to make or enter into any commitments for or on behalf of any other party.
      </p>
      <p>
        9.2 <span className="font-bold">Assignment.</span> We and or the Licensor may assign our
        rights and obligations under these terms (without your prior express consent), provided that
        your rights under these Terms remain unaffected. You must not assign your rights or
        obligations under these Terms.
      </p>
      <p>
        9.3 <span className="font-bold">Events Outside Control.</span> Neither we, you, or the
        Licensor will have any liability under or be deemed to be in breach of these Terms for any
        delays or failures in performance of these terms which result from circumstances beyond its
        reasonable control.
      </p>
      <p>
        9.4 <span className="font-bold">Third-Party Rights.</span> A person or entity who is not a
        party to these Terms has no right to enforce any of these Terms.
      </p>
      <p>
        9.5 <span className="font-bold">Waiver.</span> Any right or remedy granted to us or the
        Licensor for breach of these Terms will be in addition to all other rights and remedies
        available to us or the Licensor. If we or the Licensor fail or delay to exercise any right
        or remedy, or only exercise the same in part, this does not mean that we waive such right or
        remedy in full in the future.
      </p>
      <p>
        9.6 <span className="font-bold">Entire Agreement.</span> These Terms, including any
        applicable terms such as the Website Terms of Use, the specific terms applicable to each
        Mittaria NFT Collection, and any other applicable terms incorporated by reference,
        constitute the entire agreement between the parties and supersede and extinguish any and all
        previous and contemporaneous agreements, promises, assurances, and understandings between
        them, whether written or oral, relating to its subject matter. You acknowledge and agree
        that in entering into these Terms you do not rely on and have no remedies in respect of any
        statement, representation, assurance or warranty (whether made innocently or negligently)
        that is not set out in these Terms.
      </p>
      <p>
        9.7 <span className="font-bold">Severance.</span> If any provision of these Terms shall be
        held void, voidable, invalid or inoperative, no other provision of these Terms shall be
        affected as a result thereof, and accordingly, the remaining provisions of this Terms shall
        remain in full force and effect as though such void, voidable, invalid or inoperative
        provision had not been contained herein.
      </p>
      <p>
        9.8 <span className="font-bold">Governing Law.</span> These Terms shall be governed by and
        construed and enforced in accordance with the laws of the British Virgin Islands, without
        regard to conflict of law rules or principles (whether of the British Virgin Islands or any
        other jurisdiction) that would cause the application of the laws of any other jurisdiction.
        Any dispute between the parties arising out of or relating to these Terms that is not
        subject to arbitration or cannot be heard in small claims court will be resolved and filed
        only in the courts of the British Virgin Islands. In such case, you hereby irrevocably and
        unconditionally consent and submit to the exclusive jurisdiction of such courts over any
        suit, action or proceeding arising out of these Terms.
      </p>

      <p>
        9.9 <span className="font-bold">Dispute Resolution and Arbitration</span>
      </p>

      <ol className="ml-5" type="a">
        <li>
          <p>
            (a) All disputes between the parties shall be resolved by binding arbitration.
            Arbitration is a form of private dispute resolution and replaces the right to go to
            court. In the absence of this arbitration agreement, you may otherwise have a right or
            opportunity to bring claims in a court, before a judge or jury, and/or to participate in
            or be represented in a case filed in court by others (including, but not limited to,
            class actions). Your acceptance of these Terms by connecting your blockchain wallet to
            the Website, entering, or using this Website, or purchasing/minting Mittaria NFT
            Collection constitutes a waiver of your right to litigate claims and all opportunity to
            be heard by a judge or jury. There is no judge or jury in arbitration, and court review
            of an arbitration award is limited. The arbitrator must follow these Terms and can award
            the same damages and relief as a court (including attorney’s fees).
          </p>
        </li>
        <li>
          <p>
            (b) Any dispute, controversy, or claim arising out of, relating to, or in connection
            with these Terms, including any question regarding its existence, validity or
            termination, or any dispute regarding non-contractual obligations arising out of or
            relating to it, shall be referred to and finally resolved by arbitration administered by
            the BVI International Arbitration Centre (
            <span className="font-bold">&quot;BVI IAC&quot;</span>) in accordance with the BVI IAC
            Arbitration Rules. (<span className="font-bold">&quot;BVI IAC Rules&quot;</span>) for
            the time being in force, which rules are deemed to be incorporated by reference in this
            clause. The law of this arbitration clause shall be the laws of the British Virgin
            Islands. The place of the arbitration shall be in Singapore. The number of arbitrators
            shall be three (3) arbitrators. The language to be used in the arbitral proceedings
            shall be English.
          </p>
        </li>
      </ol>

      <p>
        9.10 <span className="font-bold">Class Action Waiver</span>
      </p>

      <p>
        9.11 Except as otherwise provided in this clause, to the fullest extent permitted by
        applicable law, the arbitrators may not consolidate more than one person’s claims and may
        not otherwise preside over any form of a class or representative proceeding or claims (such
        as a class action, consolidated action, representative action, or private attorney general
        action) unless all parties specifically agree to do so in writing following initiation of
        the arbitration. Neither you, nor any other holder of the NFTs can be a class
        representative, class member, or otherwise participate in a class, consolidated, or
        representative proceeding.
      </p>
    </div>
  );
}

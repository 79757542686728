/* eslint-disable react/button-has-type */
import { useWeb3ModalAccount, useWeb3Modal } from '@web3modal/ethers5/react'
import Button from 'components/Button';
import React from 'react'
import { Link } from 'react-router-dom'

const TNB_LOGO = 'https://static.wixstatic.com/media/50a585_cb5cf643be0e43a59e42ecce7769bdf6~mv2.png'


function ConnectButton() {
  // 4. Use modal hook
  const { open } = useWeb3Modal();

  function openConnectModal() {
    open();
  }

  return (
    <div>
      <Button onClick={openConnectModal}>
        <span className="font-monsterOfFantasy font-normal">Connect Wallet</span>
      </Button>
    </div>

  );
}

function AccountButton() {
  return <w3m-account-button />
}

export default function CreatorHeader() {
  const { isConnected } = useWeb3ModalAccount()

  return (
    <nav className='container min-h-[5rem] my-2 md:mt-5 w-full flex justify-between items-center'>
        <div>
          <Link target='_blank' to="https://www.tandbmediaglobal.com/" title="TNB" className="absolute top-0 w-[164px] mt-2">
              <img
                // className="absolute top-0 w-[164px] mt-8"
                src={TNB_LOGO}
                alt="TNB Logo"
                className='h-[4.5rem] w-[4.5rem]'
              />
          </Link>
        </div>
        {!isConnected ? <ConnectButton /> : <AccountButton />}
    </nav>
  )
}

import {
  DEFAULT_INTERACTION_BREAK_ACTIVATION_DELTA,
  INTERACTION_BREAKS,
  NO_AUTOPLAY_WINDOW,
} from 'common/constants';
import BouncyButton from 'components/BouncyButton';
import FlyButton from 'components/FlyButton/FlyButton';
import ScrollIndicator from 'components/ScrollIndicator';
import { gsap } from 'gsap';
import useScrollProgress from 'hooks/useScrollProgress';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { VoiceOversContext } from 'store/VoiceOversProvider';
import { InteractionBreak } from 'utils/types';

const ACTIVATION_DELTA = DEFAULT_INTERACTION_BREAK_ACTIVATION_DELTA; // %
const CONTINUE_WITH_SCROLL_DELAY = 1500; // ms
const MIN_TOUCH_DELTA = 100.0;
const SCROLL_THRESHOLD = 500;

export default function InteractionsManager() {
  const [activeBreak, setActiveBreak] = useState<InteractionBreak | undefined>();
  const disabledBreakRef = useRef<InteractionBreak | undefined>();
  const [showFlyIndicator, setShowFlyIndicator] = useState(false);

  const { setInteractionBreak } = useScrollProgress((progress) => {
    const shouldShowFlyIndicator =
      progress >= NO_AUTOPLAY_WINDOW.start && progress <= NO_AUTOPLAY_WINDOW.end;
    if (shouldShowFlyIndicator !== showFlyIndicator) {
      setShowFlyIndicator(shouldShowFlyIndicator);
    }

    // Unset the disabled break ref when progress is far enough away
    if (
      !activeBreak &&
      Math.abs((disabledBreakRef.current?.start ?? 0) - progress) > ACTIVATION_DELTA * 1.2
    )
      disabledBreakRef.current = undefined;

    // Skip finding new active break in case there is already one active
    if (activeBreak) {
      const end = activeBreak.start + activeBreak.activationDelta;

      if (progress <= end) return;
      disabledBreakRef.current = undefined;
      setActiveBreak(undefined);
      return;
    }

    const newActiveBreak = INTERACTION_BREAKS.find((b) => {
      if (b === disabledBreakRef.current) return false;

      return Math.abs(b.start - progress) <= ACTIVATION_DELTA;
    });
    if (activeBreak !== newActiveBreak) setActiveBreak(newActiveBreak);
  });

  useEffect(() => {
    if (showFlyIndicator)
      gsap.fromTo('#fly-indicator', { opacity: 0.0, display: 'flex' }, { opacity: 1.0 });
    else gsap.fromTo('#fly-indicator', { opacity: 1.0 }, { opacity: 0.0, display: 'none' });
  }, [showFlyIndicator]);

  useEffect(() => {
    // Set the active break as disabled, preventing it from activating again unless progress moves far enough away first
    if (activeBreak) disabledBreakRef.current = activeBreak;
    setInteractionBreak(activeBreak);
  }, [activeBreak, setInteractionBreak]);

  const { setActiveVoiceOver } = useContext(VoiceOversContext);
  const onProceed = useCallback(() => {
    if (activeBreak?.sideEffect) activeBreak.sideEffect();
    setActiveVoiceOver(activeBreak?.voiceOverData);
  }, [activeBreak, setActiveVoiceOver]);

  const deactivateBreak = useCallback(() => {
    setActiveBreak(undefined);
  }, []);

  return (
    <>
      <div className="flex justify-center items-center fixed top-0 left-0 h-full w-full pointer-events-none select-none">
        <div
          id="fly-indicator"
          className="absolute bottom-6 md:bottom-10 flex flex-col items-center"
        >
          <FlyButton />
        </div>
      </div>
      {activeBreak && !activeBreak.isFlying && (
        <InteractionOverlay
          interactionBreak={activeBreak}
          onProceed={onProceed}
          deactivateBreak={deactivateBreak}
        />
      )}
    </>
  );
}

interface InteractionOverlayProps {
  onProceed: () => void;
  deactivateBreak: () => void;
  interactionBreak: InteractionBreak;
}

function InteractionOverlay(props: InteractionOverlayProps) {
  const { onProceed, deactivateBreak, interactionBreak } = props;
  const { vignette, scrollClear, isVoid, pressToText = 'continue' } = interactionBreak;
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    gsap.to(containerRef.current, { opacity: 1, duration: 2 });
  }, []);

  function proceed() {
    onProceed();
    deactivateBreak();
    gsap.to(containerRef.current, { opacity: 0, duration: 0.5 });
  }

  const scrollThresholdRef = useRef(0);
  const touchEndY = useRef(0);
  const touchStartY = useRef(0);
  const [canContinueWithScroll, setCanContinueWithScroll] = useState(false);

  // Enable scroll to continue after a brief delay, preventing the user from overshooting in case of very fast scrolling
  useEffect(() => {
    setTimeout(() => setCanContinueWithScroll(true), CONTINUE_WITH_SCROLL_DELAY);
  }, []);

  useEffect(() => {
    if (!interactionBreak.scrollClear || !canContinueWithScroll) return undefined;

    function handleWheel(event: WheelEvent) {
      scrollThresholdRef.current += event.deltaY;
      if (Math.abs(scrollThresholdRef.current) > SCROLL_THRESHOLD) {
        onProceed();
        deactivateBreak();
      }
    }

    function handleTouchStart(event: TouchEvent) {
      touchStartY.current = event.changedTouches[0].screenY;
      // On iOS the proceed has to be called on the touch start because it is used to trigger
      // voice over playback and iOS safari will prevent audio from being played if the touch end
      // event is too far apart from the touch start.
      // https://forum.playcanvas.com/t/ios-autoplay-sound-ok-with-tap-but-not-with-swipe/15585/5;
      if (isIOS) onProceed();
    }

    function handleTouchEnd(event: TouchEvent) {
      touchEndY.current = event.changedTouches[0].screenY;
      if (touchStartY.current - touchEndY.current > MIN_TOUCH_DELTA)
        scrollThresholdRef.current += Math.max(touchStartY.current - touchEndY.current, 0.0) * 8.0;

      if (Math.abs(scrollThresholdRef.current) > SCROLL_THRESHOLD) {
        if (!isIOS) onProceed();
        deactivateBreak();
      }
    }

    const interval = setInterval(() => {
      scrollThresholdRef.current *= 0.8;
    }, 100);

    window.addEventListener('wheel', handleWheel);
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchend', handleTouchEnd);
    return () => {
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchend', handleTouchEnd);
      clearInterval(interval);
    };
  }, [interactionBreak, onProceed, canContinueWithScroll, deactivateBreak]);

  return (
    <div
      ref={containerRef}
      className={`flex justify-center items-center opacity-0 fixed top-0 left-0 h-full w-full pointer-events-none ${
        vignette && 'vignette'
      }`}
    >
      {!scrollClear && (
        <div className="relative">
          <div className="absolute h-full w-full bg-[#001457] rounded-full opacity-75" />
          <BouncyButton
            className="h-[256px] w-[256px] rounded-full border-white border-2 interaction_circle text-white flex flex-col justify-center items-center uppercase pointer-events-auto"
            onClick={proceed}
            onKeyDown={proceed}
            tabIndex={0}
          >
            <p className="text-[18px]">press to</p>
            <p className="text-[24px] font-monsterOfFantasy">{pressToText}</p>
          </BouncyButton>
        </div>
      )}
      {scrollClear && (
        <div className="absolute bottom-6 md:bottom-10 flex flex-col items-center">
          <div
            className={`uppercase  text-center relative ${
              isVoid ? 'top-[-16px] text-white' : 'text-violet'
            }`}
          >
            {isVoid ? (
              <>
                <p className="text-[18px]">scroll to</p>
                <p className="text-[24px] font-monsterOfFantasy">fall into the void</p>
              </>
            ) : (
              <p className="text-[14px]">scroll to continue</p>
            )}
          </div>
          <ScrollIndicator isSmall={!isVoid} />
        </div>
      )}
    </div>
  );
}

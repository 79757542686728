import Modal from 'components/Modal/Modal';
import WalletSuccessImg from 'main/assets/images/wallet-checker/wallet_success.png';
import React, { useEffect, useMemo, useState } from 'react';
import useSWRMutation from 'swr/mutation';

import WalletCheckerInputForm from './WalletCheckerInputForm';
import WalletCheckerLayout from './WalletCheckerLayout';
import WalletCheckerStatus from './WalletCheckerStatus';

const fetcher = (url: string) => fetch(url).then((res) => res.json());

enum WalletCheckerState {
  Default,
  NotEligible,
  List1,
  List2,
}

interface WalletCheckerProps {
  isOpen?: boolean;
  onClose?: () => void;
}
export default function WalletChecker(props: WalletCheckerProps) {
  const { isOpen, onClose } = props;
  const [walletAddress, setWalletAddress] = useState('');
  const { trigger, data, error, isMutating, reset } = useSWRMutation(
    `https://mittaria-wallet-8xpos.ondigitalocean.app/rest/whitelist/checkWallet?wallet=${walletAddress}`,
    fetcher
  );

  useEffect(() => {
    if (isOpen) return;
    reset();
  }, [isOpen, reset]);

  function onButtonClick() {
    if (state === WalletCheckerState.Default) {
      reset();
      trigger();
      return;
    }
    onClose?.();
  }

  const state = useMemo(() => {
    if (data?.status === 404) return WalletCheckerState.NotEligible;
    if (data?.listType === 1) return WalletCheckerState.List1;
    if (data?.listType === 2) return WalletCheckerState.List2;
    return WalletCheckerState.Default;
  }, [data]);

  const errorMessage = useMemo(() => {
    if (data?.status === 400) return 'The wallet address you entered is invalid.';
    if (error) return 'Something went wrong, please try again later.';
    return ' ';
  }, [data, error]);

  const buttonText = state === WalletCheckerState.Default ? 'CHECK WALLET' : 'CLOSE';

  function renderContent() {
    switch (state) {
      case WalletCheckerState.NotEligible:
        return <WalletNotFound />;
      case WalletCheckerState.List1:
        return <WalletFound1 />;
      case WalletCheckerState.List2:
        return <WalletFound2 />;
      case WalletCheckerState.Default:
      default:
        return (
          <WalletCheckerInputForm
            walletAddress={walletAddress}
            errorMessage={errorMessage}
            onAddressChange={setWalletAddress}
            isLoading={!!isMutating}
          />
        );
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <WalletCheckerLayout
        buttonText={buttonText}
        onButtonClick={onButtonClick}
        disableButton={isMutating}
      >
        {renderContent()}
      </WalletCheckerLayout>
    </Modal>
  );
}

function WalletFound1() {
  return (
    <WalletCheckerStatus imageSrc={WalletSuccessImg}>
      Congratulations! You’re on the allowlist. Your
      <br />
      adventure begins on 14/09/2023
    </WalletCheckerStatus>
  );
}

function WalletFound2() {
  return (
    <WalletCheckerStatus
      imageSrc={WalletSuccessImg}
    >{`Excellent news! You're one of our chosen immortals. Your journey begins on 12/09/23`}</WalletCheckerStatus>
  );
}

function WalletNotFound() {
  return (
    <WalletCheckerStatus imageSrc={WalletSuccessImg}>
      Congratulations! You’re on FCFS List. Your adventure begins on 15/09/2023.
    </WalletCheckerStatus>
  );
}

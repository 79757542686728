import { useTexture } from '@react-three/drei';
import { editable as e } from '@theatre/r3f';
import SKYBOX from 'main/assets/textures/Mittaria_skybox_4k.jpg';
import React from 'react';
import { BackSide } from 'three';

export default function SphereSkybox() {
  const skyboxAtlas1 = useTexture(SKYBOX);

  return (
    <e.mesh theatreKey="skybox">
      <sphereGeometry args={[100, 100, 100]} />
      <meshBasicMaterial map={skyboxAtlas1} side={BackSide} />
    </e.mesh>
  );
}

import {
  PerformanceMonitor,
  PerformanceMonitorApi,
  useGLTF,
  useVideoTexture,
} from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { editable as e, PerspectiveCamera } from '@theatre/r3f';
import { useListenToEvent } from 'hooks/eventDispatcher';
import { useAnimations } from 'hooks/useAnimations';
import FLOWERCITY_MODEL from 'main/assets/models/flowercity.glb';
import FLOWERCITY_MODEL_HIGH_QUALITY from 'main/assets/models/flowercity_high.glb';
import MUSIC_VIDEO from 'main/assets/videos/animmusic.mp4';
import TETRIS_VIDEO from 'main/assets/videos/tetris.mp4';
import { STATIONS } from 'main/common/constants';
import Station from 'main/rendering/components/Station';
import useOrbitControls from 'main/rendering/hooks/useOrbitControls';
import React, { Suspense, memo, useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import SphereSkybox from 'rendering/components/SphereSkybox/SphereSkybox';
import {
  AnimationClip,
  FrontSide,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  Object3D,
  Vector3,
} from 'three';
import { AudioSource, pause, play } from 'utils/sound';
import { DispatchAnimateToStation, GlobalEventType } from 'utils/types';

import Clouds from '../Clouds';

const DEFAULT_DISTANCE = 9.0;
const DEFAULT_STATION_DISTANCE = 2;
const MAX_DISTANCE = 11.0;
const MAX_STATION_DISTANCE = 2.5;

function MainScene() {
  const [isAnimatingCamera, setIsAnimatingCamera] = useState(true);

  const { orbitControls } = useOrbitControls({ enabled: !isAnimatingCamera });
  const setDpr = useThree((s) => s.setDpr);
  const GLTFS = useGLTF(STATIONS.map((station) => station.model));
  const city = useGLTF(isMobile ? FLOWERCITY_MODEL : FLOWERCITY_MODEL_HIGH_QUALITY);
  const meetcareAnimations = useAnimations(GLTFS[0].animations, GLTFS[0].scene, true);
  const parkAnimations = useAnimations(GLTFS[1].animations, GLTFS[1].scene, true);
  const cafeneonAnimations = useAnimations(GLTFS[2].animations, GLTFS[2].scene, true);
  const nftAnimations = useAnimations(GLTFS[3].animations, GLTFS[3].scene, true);
  const fashionAnimations = useAnimations(GLTFS[4].animations, GLTFS[4].scene, true);
  const bankAnimations = useAnimations(GLTFS[5].animations, GLTFS[5].scene, true);
  const classAnimations = useAnimations(GLTFS[6].animations, GLTFS[6].scene, true);
  const resortAnimations = useAnimations(GLTFS[7].animations, GLTFS[7].scene, true);
  const stadiumAnimations = useAnimations(GLTFS[8].animations, GLTFS[8].scene, true);
  const craftAnimations = useAnimations(GLTFS[9].animations, GLTFS[9].scene, true);
  const cityAnimations = useAnimations(city.animations, city.scene, true);
  const tetrisVideo = useVideoTexture(TETRIS_VIDEO, { autoplay: true, muted: true });
  tetrisVideo.flipY = false;
  const musicVideo = useVideoTexture(MUSIC_VIDEO, { autoplay: true, muted: true });
  musicVideo.flipY = false;
  const playableAnimations = useMemo(
    () => [
      meetcareAnimations,
      cafeneonAnimations,
      nftAnimations,
      fashionAnimations,
      bankAnimations,
      classAnimations,
      resortAnimations,
      cityAnimations,
      craftAnimations,
      parkAnimations,
      stadiumAnimations,
    ],
    [
      meetcareAnimations,
      cafeneonAnimations,
      nftAnimations,
      fashionAnimations,
      bankAnimations,
      classAnimations,
      resortAnimations,
      cityAnimations,
      craftAnimations,
      parkAnimations,
      stadiumAnimations,
    ]
  );
  useEffect(() => {
    function playAllAnimationClips(animations: Api<AnimationClip>) {
      animations.names.forEach((name) => {
        animations.actions[name]?.play();
      });
    }
    playableAnimations.forEach(playAllAnimationClips);
  }, [playableAnimations]);

  function onPerformanceChange({ factor }: PerformanceMonitorApi) {
    setDpr(0.5 + 1.5 * factor);
  }

  function switchToMeshBasicMaterial(object: Object3D, alphaTest = 0.35) {
    object.traverse((child) => {
      const currentMaterial = (child as Mesh).material as MeshStandardMaterial;
      if (currentMaterial) {
        const newMaterial = new MeshBasicMaterial();
        newMaterial.side = FrontSide;
        newMaterial.alphaTest = alphaTest;
        newMaterial.map = currentMaterial.map;
        newMaterial.toneMapped = false;
        newMaterial.transparent = true;
        newMaterial.name = currentMaterial.name;
        (child as Mesh).material = newMaterial;
      }
    });
  }

  useEffect(() => {
    GLTFS[0].scenes.forEach((scene) => switchToMeshBasicMaterial(scene, 0.35));
    GLTFS[1].scenes.forEach((scene) => switchToMeshBasicMaterial(scene, 0.3));
    GLTFS[2].scenes.forEach((scene) => switchToMeshBasicMaterial(scene, 0.54));
    GLTFS[3].scenes.forEach((scene) => switchToMeshBasicMaterial(scene, 0.35));
    GLTFS[4].scenes.forEach((scene) => switchToMeshBasicMaterial(scene, 0.3));
    GLTFS[5].scenes.forEach((scene) => switchToMeshBasicMaterial(scene, 0.3));
    GLTFS[6].scenes.forEach((scene) => switchToMeshBasicMaterial(scene, 0.3));
    GLTFS[7].scenes.forEach((scene) => switchToMeshBasicMaterial(scene, 0.3));
    GLTFS[8].scenes.forEach((scene) => switchToMeshBasicMaterial(scene, 0.3));
    GLTFS[9].scenes.forEach((scene) => switchToMeshBasicMaterial(scene, 0.3));
    GLTFS[1].scenes.forEach((scene) =>
      scene.traverse((object) => {
        const material = (object as Mesh).material as MeshStandardMaterial;
        if (!material) return;
        if (material.name === 'Music') material.map = musicVideo;
        if (material.name === 'Tetris') material.map = tetrisVideo;
      })
    );
    city.scenes.forEach((scene) => switchToMeshBasicMaterial(scene, 0.3));
  }, [GLTFS, city, musicVideo, tetrisVideo]);

  useListenToEvent(GlobalEventType.animateToStation, (event: DispatchAnimateToStation) => {
    const { object, defaultRotationOffset = 0 } = event;
    if (!object) {
      orbitControls.setTarget(new Vector3(0, 0, 0));
      orbitControls.animateToObject(0, DEFAULT_DISTANCE, MAX_DISTANCE);
    } else {
      const target = new Vector3();
      object.getWorldPosition(target);
      orbitControls.setTarget(target);
      orbitControls.animateToObject(
        object.rotation.y + defaultRotationOffset,
        DEFAULT_STATION_DISTANCE,
        MAX_STATION_DISTANCE
      );
    }
  });

  useListenToEvent(GlobalEventType.zoomIn, () => {
    orbitControls.zoomIn(2.0);
  });

  useListenToEvent(GlobalEventType.zoomOut, () => {
    orbitControls.zoomOut(2.0);
  });

  useListenToEvent(GlobalEventType.finishedIntroAnimation, () => {
    setIsAnimatingCamera(false);
  });

  const stations = useMemo(
    () => STATIONS.map((station, i) => ({ ...station, loadedModel: GLTFS[i] })),
    [GLTFS]
  );

  const autoplayStartedRef = useRef(false);
  useEffect(() => {
    function onMouseDown() {
      if (autoplayStartedRef.current) return;

      autoplayStartedRef.current = true;
      play(AudioSource.mittariaSong);
    }
    window.addEventListener('mousedown', onMouseDown);

    return () => {
      window.removeEventListener('mousedown', onMouseDown);
      pause(AudioSource.mittariaSong);
    };
  }, []);

  return (
    <>
      {/* @ts-ignore */}
      <PerspectiveCamera theatreKey="camera" makeDefault near={0.1} />
      <e.group theatreKey="stations">
        {stations.map((station, index) => (
          <Station
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            index={index}
            model={station.loadedModel}
            name={station.name}
            bounces={!station.noBounce}
            theaterKey={station.theaterKey}
            defaultRotationOffset={station.defaultRotationOffset}
          />
        ))}
        <e.group theatreKey="city">
          <primitive object={city.scene} />
        </e.group>
      </e.group>
      <PerformanceMonitor onChange={onPerformanceChange} />
      <Suspense>
        <Clouds />
      </Suspense>
      <e.directionalLight theatreKey="mainLight" />
      <Suspense>
        <SphereSkybox />
      </Suspense>
    </>
  );
}
export default memo(MainScene);

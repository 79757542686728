import { gsap } from 'gsap';
import { ReactComponent as TitleDecoration } from 'main/assets/images/page-title/titleDecoration.svg';
import Heading from 'main/components/Heading';
import SplitText from 'main/components/SplitText';
import React, { useEffect, useRef } from 'react';

interface PageIntroductionProps extends React.HTMLAttributes<HTMLDivElement> {
  pageHeading?: string;
  pageTitle: string;
}

export default function PageIntroduction(props: PageIntroductionProps) {
  const { className, pageHeading, pageTitle } = props;
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap
        .timeline({
          delay: 0.5,
          defaults: {
            duration: 1.25,
            ease: 'expo.inOut',
          },
        })
        .set(container.current, { visibility: 'visible' }, 0)
        .from(
          'p',
          {
            yPercent: 50,
            autoAlpha: 0,
            ease: 'power4',
          },
          0
        )
        .from(
          'h1 span span',
          {
            yPercent: 25,
            autoAlpha: 0,
            ease: 'power3',
            stagger: {
              amount: 0.4,
              from: 'center',
              grid: 'auto',
            },
          },
          0
        )
        // Decoration
        .fromTo(
          [
            'svg .svg-elem-1',
            'svg .svg-elem-2',
            'svg .svg-elem-3',
            'svg .svg-elem-4',
            'svg .svg-elem-5',
            'svg .svg-elem-6',
            'svg .svg-elem-7',
            'svg .svg-elem-8',
          ],
          {
            scaleX: 0,
          },
          {
            scaleX: 1,
          },
          0.5
        )
        .fromTo(
          'svg .svg-elem-9',
          {
            strokeDashoffset: 34,
            strokeDasharray: 34,
            fill: 'transparent',
          },
          {
            strokeDashoffset: 0,
            fill: 'rgb(172, 159, 255)',
          },
          0.75
        )
        .fromTo(
          'svg .svg-elem-10',
          {
            strokeDashoffset: 62,
            strokeDasharray: 62,
          },
          {
            strokeDashoffset: 0,
          },
          0.75
        );
    }, container);

    return () => ctx.revert();
  }, [container]);

  return (
    <div
      ref={container}
      className={`flex flex-col justify-center items-center invisible text-center ${
        className || ''
      }`}
    >
      {pageHeading && (
        <Heading headingType="p" className="text-[14px] leading-3">
          {pageHeading}
        </Heading>
      )}
      <h1 className="text-h2 uppercase">
        <SplitText textToSplit={pageTitle} />
      </h1>
      <div className="w-full max-w-[500px]">
        <TitleDecoration />
      </div>
    </div>
  );
}

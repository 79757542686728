import { useGLTF } from '@react-three/drei';
import { editable as e } from '@theatre/r3f';
import GLTF_FLOWER from 'assets/models/glb/Flower_v5.glb';
import useScrollProgress from 'hooks/useScrollProgress';
import React, { useMemo, useState } from 'react';
import FallingPetals from 'rendering/components/FallingPetals/FallingPetals';
import { Mesh, Vector2 } from 'three';

export default function FlowersManager() {
  const { scene } = useGLTF(GLTF_FLOWER);

  const petalsAreaSize = useMemo(
    () => new Vector2(DEFAULT_PETAL_PROPS.areaX, DEFAULT_PETAL_PROPS.areaY),
    []
  );

  const petals = scene.children.filter((c) => c.name.includes('Petal')) as Mesh[];

  const [flowersVisible, setFlowersVisible] = useState(true);

  useScrollProgress((progress) => {
    const areFlowersVisible =
      FLOWERS_VISIBLE_WINDOW[0] < progress && progress < FLOWERS_VISIBLE_WINDOW[1];
    if (areFlowersVisible !== flowersVisible) setFlowersVisible(areFlowersVisible);
  });

  return (
    <e.group theatreKey="falling_petals_spawn" visible={flowersVisible}>
      {petals.map((petal) => (
        <FallingPetals
          key={petal.uuid}
          particleCount={DEFAULT_PETAL_PROPS.particleCount}
          size={DEFAULT_PETAL_PROPS.size}
          fallDistance={DEFAULT_PETAL_PROPS.fallDistance}
          fallSpeed={DEFAULT_PETAL_PROPS.fallSpeed}
          gustFrequency={DEFAULT_PETAL_PROPS.gustFrequency}
          gustScale={DEFAULT_PETAL_PROPS.gustScale}
          gustSpeed={DEFAULT_PETAL_PROPS.gustSpeed}
          noiseFrequency={DEFAULT_PETAL_PROPS.noiseFrequency}
          noiseScale={DEFAULT_PETAL_PROPS.noiseScale}
          opacity={DEFAULT_PETAL_PROPS.opacity}
          areaSize={petalsAreaSize}
          mesh={petal}
        />
      ))}
    </e.group>
  );
}

const DEFAULT_PETAL_PROPS = {
  particleCount: 10,
  size: 600,
  gustScale: 0.1,
  gustFrequency: 0.1,
  gustSpeed: 0.2,
  noiseScale: 0.1,
  noiseFrequency: 0.15,
  fallSpeed: 0.05,
  fallDistance: 50,
  opacity: 1.0,
  areaX: 50,
  areaY: 36,
};

const FLOWERS_VISIBLE_WINDOW = [0.35, 0.78491];

import React, { createContext, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';

interface StationContextState {
  activeStation: number;
  showStationOverlay: boolean;
  setActiveStation: (value: number) => void;
  setShowStationOverlay: (value: boolean) => void;
}
export const StationContext = createContext<StationContextState>({
  activeStation: -1,
  showStationOverlay: false,
  setActiveStation: () => {},
  setShowStationOverlay: () => {},
});

interface StationProviderProps {
  children: JSX.Element | JSX.Element[];
}
export default function StationProvider(props: StationProviderProps) {
  const { children } = props;

  const [activeStation, setActiveStation] = useState<number>(-1);
  const [showStationOverlay, setShowStationOverlay] = useState<boolean>(false);

  useEffect(() => {
    if (activeStation < 0 || isMobile) return;
    setShowStationOverlay(true);
  }, [activeStation]);

  const state = useMemo(
    () => ({
      activeStation,
      setActiveStation,
      showStationOverlay,
      setShowStationOverlay,
    }),
    [activeStation, showStationOverlay]
  );

  return <StationContext.Provider value={state}>{children}</StationContext.Provider>;
}

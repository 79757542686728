import legalsBackground from 'assets/images/UI/chapter2.jpg';
import Footer from 'main/components/Footer';
import WTGCreatorHeader from 'main/components/Header/WTGCreatorHeader';
import React from 'react';

interface LegalProps {
  title: string;
  children: React.ReactNode;
  subtitle?: string;
}

export default function WTGLegals(props: LegalProps) {
  const { title, children, subtitle = 'mittaria legals' } = props;

  return (
    <main
      className="relative w-full min-h-screen overflow-hidden bg-black text-white font-altform font-thin bg-no-repeat bg-cover"
    >
    <div
        className="absolute inset-0 min-h-full"
        style={{
        backgroundImage: `url(${legalsBackground})`,
        backgroundSize: 'cover',
        opacity: 0.4,
        }}
    />
      <div className="relative z-10 flex flex-col items-center pt-0">
          <WTGCreatorHeader />
          <p className="text-[24px] md:text-[36px] font-altform uppercase tracking-widest font-yozakura">{subtitle}</p>
          <h1 className="text-center text-[56px] md:text-[100px] my-2 leading-[76px] font-yozakura ">{title}</h1>
          <div className="container mx-auto text-start px-4">
            <div className="legals z-30">{children}</div>
          </div>
        </div>
        <Footer smaller wtgFooter />
    </main>
  );
}

/* eslint-disable jsx-a11y/label-has-associated-control */
import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useRef } from 'react';
import { useLocation } from 'react-router';

import GenesisTermsAndCons from './GenesisTermsAndCons';
import WTGTermsAndCons from './WTGTermsAndCons';

export default function TermsAndConsModal({
  show,
  setCloseModal,
  handleReadTerm,
  read,
}: {
  show: boolean;
  read: boolean;
  setCloseModal: () => void;
  handleReadTerm: () => void;
}) {
  const cancelButtonRef = useRef(null);
  const route = useLocation().pathname;

  const isGenesisForm = route === '/creator-form';

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog className="relative z-10" initialFocus={cancelButtonRef} onClose={setCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full md:container mx-auto items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden ${
                  !isGenesisForm && 'wtg-scrollbar'
                } rounded-lg h-[70vh] overflow-y-scroll w-full md:w-[80%] bg-[#170E4D] text-left shadow-xl transition-all`}
              >
                <div
                  className={` ${
                    isGenesisForm ? 'bg-[#170E4D]' : 'bg-[#E1DDDC]'
                  } px-4 pb-4 pt-5 sm:p-6 sm:pb-4`}
                >
                  {isGenesisForm ? <GenesisTermsAndCons /> : <WTGTermsAndCons />}
                </div>
                <div
                  className={`${
                    isGenesisForm ? 'bg-[#100A31]' : 'bg-[#5A5858]'
                  } rounded-lg px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6`}
                >
                  <label className="text-white">
                    <input
                      type="checkbox"
                      className="form-checkbox text-red-600 mr-1"
                      onChange={handleReadTerm}
                      checked={read}
                    />
                    I have read and agree to the terms and conditions
                  </label>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
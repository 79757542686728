import { useFrame } from '@react-three/fiber';
import { REVEAL_CLOUD_WINDOW, REVEAL_WINDOW } from 'common/constants';
import useDisposableMemo from 'hooks/useDisposableMemo';
import useScrollProgress from 'hooks/useScrollProgress';
import useUniforms from 'hooks/useUniforms';
import React, { memo, useMemo, useState } from 'react';
import fragmentShader from 'rendering/shaders/character-particles.fragment.glsl';
import vertexShader from 'rendering/shaders/sisters.vertex.glsl';
import {
  AdditiveBlending,
  BufferGeometry,
  Color,
  DoubleSide,
  Float32BufferAttribute,
  MathUtils,
  Mesh,
  Points,
  ShaderMaterial,
  Texture,
  Vector3,
} from 'three';
import { MeshSurfaceSampler } from 'three/examples/jsm/math/MeshSurfaceSampler';
import { smoothstep } from 'three/src/math/MathUtils';

interface SisterMeshProps {
  mesh: Mesh;
  map: Texture;
  rotationSpread?: number;
  rotationFlow?: number;
  rotationSpeed?: number;
  flowSpeed?: number;
  flowHeight?: number;
  alphaSize?: number;
  alphaSpeed?: number;
  particleSize?: number;
  particleCount?: number;
  intensity?: number;
  color?: string;
}

const CLOUD_INITIAL_POSITION = new Vector3(0, 0, -300);
const CLOUD_AREA_SIZE = new Vector3(200, 200, 1000);
CLOUD_INITIAL_POSITION.sub(CLOUD_AREA_SIZE.clone().multiplyScalar(0.5));

function SisterMesh(props: SisterMeshProps) {
  const {
    mesh,
    map,
    rotationSpread = 1.0,
    rotationFlow = 1.0,
    rotationSpeed = 1.0,
    alphaSize = 0.03,
    alphaSpeed = 0.1,
    particleSize = 10.0,
    particleCount = 1000,
    color = '#b9f8ff',
    flowHeight = 0.5,
    flowSpeed = 4,
    intensity = 3.0,
  } = props;

  const particleColor = useMemo(() => new Color(color), [color]);
  const uniforms = useUniforms({
    uTime: 0,
    uRotationSpread: rotationSpread,
    uRotationFlow: rotationFlow,
    uRotationSpeed: rotationSpeed,
    uFlowSpeed: flowSpeed,
    uFlowHeight: flowHeight,
    uAlphaSize: alphaSize,
    uAlphaSpeed: alphaSpeed,
    uParticleSize: particleSize,
    uIntensity: intensity,
    uTransition: 1.0,
    uRevealProgress: 0.0,
    map,
    color: particleColor,
  });

  const material = useDisposableMemo(
    () =>
      new ShaderMaterial({
        vertexShader,
        fragmentShader,
        uniforms,
        transparent: true,
        side: DoubleSide,
        alphaTest: 0.5,
        depthWrite: false,
        toneMapped: false,
        blending: AdditiveBlending,
      }),
    [uniforms]
  );

  const geometry = useDisposableMemo(() => {
    if (!mesh) return new BufferGeometry();
    const geom = new BufferGeometry();
    const cloudPositions = [];
    const vertices = [];
    const normals = [];
    const sizes = [];
    const offset = [];
    const cloudSpeed = [];
    const yPercentages = [];
    const sampler = new MeshSurfaceSampler(mesh).setWeightAttribute('uv').build();
    const v3 = new Vector3();
    const n3 = new Vector3();
    mesh.geometry.computeBoundingBox();
    const bounds = mesh.geometry.boundingBox;
    const yBounds = { min: bounds!.min.y, max: bounds!.max.y };

    for (let i = 0; i < particleCount; i++) {
      sampler.sample(v3, n3);
      const size = MathUtils.randFloat(0.1, 0.5);
      sizes.push(size);
      vertices.push(v3.x, v3.y, v3.z);
      normals.push(n3.x, n3.y, n3.z);
      yPercentages.push((v3.y - yBounds.min) / Math.abs(yBounds.max - yBounds.min));

      offset.push(Math.random());

      cloudSpeed.push(Math.random() * 2.0 + 1.0);
      v3.set(Math.random(), Math.random(), Math.random())
        .multiply(CLOUD_AREA_SIZE)
        .add(CLOUD_INITIAL_POSITION);
      cloudPositions.push(v3.x, v3.y, v3.z);
    }

    geom.setAttribute('cloudSpeed', new Float32BufferAttribute(cloudSpeed, 1));
    geom.setAttribute('cloudPosition', new Float32BufferAttribute(cloudPositions, 3));
    geom.setAttribute('position', new Float32BufferAttribute(vertices, 3));
    geom.setAttribute('normal', new Float32BufferAttribute(normals, 3));
    geom.setAttribute('scale', new Float32BufferAttribute(sizes, 1));
    geom.setAttribute('offset', new Float32BufferAttribute(offset, 1));
    geom.setAttribute('yPercentage', new Float32BufferAttribute(yPercentages, 1));

    return geom;
  }, [mesh, particleCount]);

  const particles = useMemo<Points>(() => {
    const points = new Points(geometry, material);
    points.frustumCulled = false;

    return points;
  }, [geometry, material]);

  const [isVisible, setIsVisible] = useState(true);
  useScrollProgress((progress) => {
    uniforms.uTransition.value = smoothstep(
      progress,
      REVEAL_CLOUD_WINDOW.start,
      REVEAL_CLOUD_WINDOW.end
    );

    uniforms.uRevealProgress.value = smoothstep(progress, REVEAL_WINDOW.start, REVEAL_WINDOW.end);

    const active = progress < REVEAL_WINDOW.end;
    if (active !== isVisible) setIsVisible(active);
  });

  useFrame((state) => {
    uniforms.uTime.value = state.clock.elapsedTime;
  });

  return <primitive visible={isVisible} object={particles} />;
}

export default memo(SisterMesh);

import React, { createContext, useEffect, useMemo, useState } from 'react';
import { pause, play } from 'utils/sound';
import { VoiceOverData } from 'utils/types';

interface VoiceOversContextState {
  activeVoiceOver?: VoiceOverData;
  setActiveVoiceOver: (value: VoiceOverData | undefined) => void;
}
export const VoiceOversContext = createContext<VoiceOversContextState>({
  setActiveVoiceOver: () => {},
});

interface VoiceOverProviderProps {
  children: JSX.Element | JSX.Element[];
}
export default function VoiceOverProvider(props: VoiceOverProviderProps) {
  const { children } = props;

  const [activeVoiceOver, setActiveVoiceOver] = useState<VoiceOverData | undefined>();

  const state = useMemo(() => ({ activeVoiceOver, setActiveVoiceOver }), [activeVoiceOver]);

  useEffect(() => {
    if (!activeVoiceOver) return undefined;

    play(activeVoiceOver.audio);

    return () => {
      pause(activeVoiceOver.audio);
    };
  }, [activeVoiceOver]);

  return <VoiceOversContext.Provider value={state}>{children}</VoiceOversContext.Provider>;
}

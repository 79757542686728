import { gsap } from 'gsap';
import { ReactComponent as ButtonBG } from 'main/assets/images/button/background.svg';
import { ReactComponent as VolumeMutedIcon } from 'main/assets/images/header/audio-mute.svg';
import { ReactComponent as VolumeIcon } from 'main/assets/images/header/volume.svg';
import React, { useEffect, useRef, useState } from 'react';
import { isMuted, setMute } from 'utils/sound';

interface VolumeButtonProps extends React.HTMLAttributes<HTMLButtonElement> {}

export default function VolumeButton(props: VolumeButtonProps) {
  const { className, ...rest } = props;
  const container = useRef<HTMLButtonElement>(null);
  const [muteUI, setMuteUI] = useState(isMuted());

  useEffect(() => {
    setMute(muteUI);
  }, [muteUI]);

  useEffect(() => {
    const ctx = gsap.context(() => {
      const tl = gsap
        .timeline({
          paused: true,
        })
        .to('.button-bg', {
          scale: 1,
          duration: 0.5,
          ease: 'expo',
        });

      const handleMouseEnter = () => {
        tl.play();
      };
      const handleMouseLeave = () => {
        tl.reverse();
      };
      container.current?.addEventListener('mouseenter', handleMouseEnter);
      container.current?.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        tl.revert();
        container.current?.removeEventListener('mouseenter', handleMouseEnter);
        container.current?.removeEventListener('mouseleave', handleMouseLeave);
      };
    }, container);

    return () => ctx.revert();
  }, [container]);

  function onClick() {
    setMuteUI((s) => !s);
  }

  return (
    <button
      ref={container}
      type="button"
      onClick={onClick}
      className={`relative overflow-hidden rounded-full aspect-square h-[32px] w-[32px] flex items-center justify-center ${
        className || ''
      }`}
      {...rest}
    >
      <div className="relative z-10">{muteUI ? <VolumeMutedIcon /> : <VolumeIcon />}</div>
      <div className="button-bg absolute z-0 top-0 left-0 h-full w-full rounded-full bg-purple scale-0" />
      <ButtonBG className="absolute z-10 top-0 left-0 w-full h-full" />
    </button>
  );
}

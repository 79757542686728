import TeamCarousel from 'components/TeamCarousel/TeamCarousel';
import React from 'react';

export default function Team() {
  return (
    <div className="bg-transparent h-full flex flex-col items-center pb-2 justify-center pointer-events-auto overflow-hidden">
      <TeamCarousel />
    </div>
  );
}

import legalsBackground from 'assets/images/UI/legals-background.png';
import { ReactComponent as LegalsDecoration } from 'assets/images/UI/legals-decoration.svg';
import MITTARIA_LOGO from 'main/assets/images/header/logo.png';
import CreatorHeader from 'main/components/Header/CreatorHeader';
import Layout from 'main/layouts/Layout';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface LegalProps {
  title: string;
  children: React.ReactNode;
  subtitle?: string;
}

export default function Legals(props: LegalProps) {
  const { title, children, subtitle = 'mittaria legals' } = props;

  const path = useLocation().pathname;

  const isTermsGenesis = path === '/terms-genesis'
  const isCreatorForm = path === '/creator-form' || path === '/wtg-creator-form'

  return (
    <>
      {isTermsGenesis && (
        <Link to="/genesis" title="Mittaria" className="absolute left-6 top-6 z-20">
          <p className="font-[500] text-[16px] font-monsterOfFantasy text-white">GENESIS</p>
        </Link>
      )}
      <Layout smallerFooter hideHeader={isTermsGenesis || isCreatorForm}>
        <div className={`relative z-10 flex flex-col items-center  ${isCreatorForm ? 'pt-0' : 'pt-[150px]'}`}>
          {isTermsGenesis && (
            <Link to="/" title="Mittaria" className="absolute top-0 w-[164px] mt-8">
              <img
                // className="absolute top-0 w-[164px] mt-8"
                src={MITTARIA_LOGO}
                alt="Mittaria Logo"
              />
            </Link>
          )}
          {isCreatorForm && <CreatorHeader />}
          <p className="text-[14px] font-altform uppercase tracking-widest">{subtitle}</p>
          <h1 className="text-center text-[36px] md:text-[48px] leading-[76px] ">{title}</h1>
          <LegalsDecoration className={`mb-4  ${isCreatorForm ? 'md:mb-5' : 'md:mb-10'} max-w-full`} />
          <div className="container mx-auto text-start px-4">
            <div className="legals z-30">{children}</div>
          </div>
        </div>
        <img
          src={legalsBackground}
          className="absolute top-0 inset-x-0 mx-0 min-w-full"
          alt="Mittaria background"
        />
      </Layout>
    </>
  );
}

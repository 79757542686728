import { ReactComponent as ImgDecoration } from 'main/assets/images/content-section/content-section-img-deco.svg';
import { ReactComponent as TitleDecoration } from 'main/assets/images/content-section/content-section-title-deco.svg';
import ButtonLink from 'main/components/ButtonLink';
import Heading from 'main/components/Heading';
import Title from 'main/components/Title';
import React, { useRef } from 'react';

interface ContentSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  inverted?: boolean;
  sectionTitle: string;
  sectionHeading: string;
  sectionImg: string;
  buttonProps?: {
    buttonText: string;
    buttonLink: string;
  };
  fullSize?: boolean;
}

export default function ContentSection(props: ContentSectionProps) {
  const {
    children,
    className,
    sectionTitle,
    sectionHeading,
    sectionImg,
    inverted,
    buttonProps,
    fullSize,
  } = props;
  const container = useRef<HTMLDivElement>(null);

  return (
    <section
      ref={container}
      className={`flex flex-wrap lg:gap-x-[150px] gap-x-[75px] gap-y-[50px] lg:pt-48 pt-20 ${
        className || ''
      } ${inverted ? 'flex-row-reverse' : ''}`}
    >
      <div
        className={`text-container md:flex-1 flex-auto flex flex-col justify-center gap-y-[50px] ${
          fullSize ? 'h-auto' : 'h-full lg:min-h-[60vh] min-h-[40vh]'
        }`}
      >
        <div className="relative">
          <Title titleType="h3" className="absolute top-0 left-0 text-h2 invisible" noDivided>
            {sectionTitle}
          </Title>
          <Title titleType="p" color="white" className="relative text-h2 font-monsterOfFantasy">
            {sectionTitle}
          </Title>
        </div>
        <div className="text-smp capitals">{children}</div>
        {buttonProps && (
          <ButtonLink href={buttonProps.buttonLink} className="mr-auto lg:mt-[50px] mt-[25px]">
            {buttonProps.buttonText}
          </ButtonLink>
        )}
      </div>
      <div
        className={`img-container md:flex-1 flex-auto flex flex-col items-center ${
          fullSize ? 'h-auto' : 'h-full lg:min-h-[75vh] min-h-[50vh]'
        }`}
      >
        <div className="flex-initial flex items-center gap-x-5 max-w-[90%]">
          <div className="flex-1">
            <TitleDecoration />
          </div>
          <Heading noSplit className="flex-0 text-[14px]">
            {sectionHeading}
          </Heading>
          <div className="flex-1 transform -scale-x-100">
            <TitleDecoration />
          </div>
        </div>
        <div
          className={`flex-1 flex justify-center items-center relative lg:h-full h-auto lg:w-[90%] w-full ${
            fullSize ? 'overflow-hidden content-mask' : ''
          }`}
        >
          <img
            src={sectionImg}
            alt="content img"
            className={`relative z-10 md:w-full md:h-auto max-h-[500px] w-auto object-contain ${
              fullSize ? 'max-h-max max-w-[98%]' : 'mb-10'
            }`}
          />
          <ImgDecoration
            className={`absolute z-0 bottom-0 left-1/2 -translate-x-1/2 h-auto ${
              fullSize ? 'max-w-full' : 'max-w-[90%]'
            }`}
          />
        </div>
      </div>
    </section>
  );
}

import footerBg from 'main/assets/images/footer/footer-bg.png';
import Socials from 'main/components/Socials';
import React from 'react';

interface FooterProps {
  className?: string;
  smaller?: boolean;
  absolute?: boolean;
  wtgFooter?: boolean;
}

export default function Footer(props: FooterProps) {
  const { className, smaller, absolute, wtgFooter } = props;

  return (
    <footer
      className={`flex flex-wrap justify-center items-end w-full p-8 ${
        smaller ? '' : 'md:min-h-[60vh] min-h-[30vh]'
      } ${absolute ? 'absolute left-0 bottom-0' : 'relative'}${className || ''}`}
      style={{
        background: absolute
          ? 'linear-gradient(0deg, #160D4D 0%, rgba(22, 13, 77, 0.00) 100%)'
          : 'none',
      }}
    >
      <nav className="relative z-10 flex-auto flex items-center gap-x-1 text-[10px] uppercase">
      <a href="/terms-nft" title="Go to terms and conditions">
          NFT Terms
        </a>
        <span>-</span>
        <a href="/terms" title="Go to terms of use">
          Terms of Use
        </a>
        <span>-</span>
        <a href="/privacy" title="Go to privacy policy">
          Privacy Policy
        </a>
        <span>-</span>
        <a href="mailto: info@mittaria.io" title="Send a mail">
          info@mittaria.io
        </a>
      </nav>
      <Socials />
      {!absolute || !wtgFooter && (
        <div className="absolute z-0 bottom-0 left-0 h-auto w-full">
          <img
            src={footerBg}
            alt="footer bg"
            className="md:w-full md:h-auto w-auto min-h-[20vh] object-cover"
          />
        </div>
      )}
    </footer>
  );
}

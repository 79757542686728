import RegistrationForm from 'main/components/CreatorForm/RegistrationForm'
import React from 'react'

export default function CreatorForm() {
  return (
    <main className='min-h-[80vh]'>
      <RegistrationForm />
    </main>
  )
}

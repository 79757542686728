import React from 'react';

interface BouncyButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}

export default function BouncyButton(props: BouncyButtonProps) {
  const { children, className, ...rest } = props;

  return (
    <button
      type="button"
      className={`transition-transform relative active:scale-95 hover:scale-105 overflow-visible ${className} pointer-events-auto`}
      {...rest}
    >
      {children}
    </button>
  );
}

import { useFrame } from '@react-three/fiber';
import useDisposableMemo from 'hooks/useDisposableMemo';
import useUniforms from 'hooks/useUniforms';
import React from 'react';
import vertexShader from 'rendering/shaders/falling-petals.vertex.glsl';
import fragmentShader from 'rendering/shaders/trail-particles.fragment.glsl';
import {
  DoubleSide,
  InstancedBufferAttribute,
  InstancedBufferGeometry,
  Mesh,
  MeshStandardMaterial,
  ShaderMaterial,
  Vector2,
  Vector3,
} from 'three';

interface FallingPetalsProps {
  particleCount: number;
  areaSize: Vector2;
  mesh: Mesh;
  gustScale?: number;
  gustFrequency?: number;
  gustSpeed?: number;
  noiseScale?: number;
  noiseFrequency?: number;
  fallSpeed?: number;
  fallDistance?: number;
  opacity?: number;
  size?: number;
}

const DEFAULT_PETAL_PROPS = {
  gustScale: 0.1,
  gustFrequency: 0.1,
  gustSpeed: 0.2,
  noiseScale: 0.1,
  noiseFrequency: 0.15,
  fallSpeed: 0.05,
  fallDistance: 10,
  opacity: 1.0,
  size: 1.0,
};

export default function FallingPetals(props: FallingPetalsProps) {
  const {
    particleCount,
    areaSize,
    mesh,
    gustScale = DEFAULT_PETAL_PROPS.gustScale,
    gustFrequency = DEFAULT_PETAL_PROPS.gustFrequency,
    gustSpeed = DEFAULT_PETAL_PROPS.gustSpeed,
    noiseScale = DEFAULT_PETAL_PROPS.noiseScale,
    noiseFrequency = DEFAULT_PETAL_PROPS.noiseFrequency,
    fallSpeed = DEFAULT_PETAL_PROPS.fallSpeed,
    fallDistance = DEFAULT_PETAL_PROPS.fallDistance,
    opacity = DEFAULT_PETAL_PROPS.opacity,
    size = DEFAULT_PETAL_PROPS.size,
  } = props;

  const uniforms = useUniforms({
    uTime: 0,
    map: (mesh.material as MeshStandardMaterial).map,
    uGustScale: gustScale,
    uGustFrequency: gustFrequency,
    uGustSpeed: gustSpeed,
    uNoiseScale: noiseScale,
    uNoiseFrequency: noiseFrequency,
    uFallSpeed: fallSpeed,
    uFallDistance: fallDistance,
    uSize: size,
    opacity,
  });

  const instancedGeometry = useDisposableMemo(() => {
    const geometry = new InstancedBufferGeometry();

    geometry.index = mesh.geometry.index;
    geometry.attributes = { ...mesh.geometry.attributes };

    const posArray = new Float32Array(particleCount * 2);
    const randValuesArray = new Float32Array(particleCount * 3);
    const offsetArray = new Float32Array(particleCount * 1);
    const rotationAxisArray = new Float32Array(particleCount * 3);

    const rotationAxis = new Vector3(0.0, 0.0, 0.0);
    for (let i = 0; i < particleCount; i += 1) {
      const index3X = i * 3;
      const index3Y = i * 3 + 1;
      const index3Z = i * 3 + 2;

      posArray[i * 2] = areaSize.x * (Math.random() - 0.5);
      posArray[i * 2 + 1] = areaSize.y * (Math.random() - 0.5);

      rotationAxis
        .set(Math.random(), Math.random(), Math.random())
        .subScalar(0.5)
        .multiplyScalar(2.0)
        .normalize();
      rotationAxisArray[index3X] = rotationAxis.x;
      rotationAxisArray[index3Y] = rotationAxis.y;
      rotationAxisArray[index3Z] = rotationAxis.z;

      randValuesArray[index3X] = Math.random();
      randValuesArray[index3Y] = Math.random();
      randValuesArray[index3Z] = Math.random();

      offsetArray[i] = Math.random();
    }

    geometry.setAttribute('pos', new InstancedBufferAttribute(posArray, 2));
    geometry.setAttribute('randValue', new InstancedBufferAttribute(randValuesArray, 3));
    geometry.setAttribute('rotationAxis', new InstancedBufferAttribute(rotationAxisArray, 3));
    geometry.setAttribute('offset', new InstancedBufferAttribute(offsetArray, 1));

    return geometry;
  }, [areaSize.x, areaSize.y, mesh, particleCount]);

  const material = useDisposableMemo(
    () =>
      new ShaderMaterial({
        vertexShader,
        fragmentShader,
        uniforms,
        side: DoubleSide,
      }),
    [uniforms]
  );

  useFrame((state) => {
    uniforms.uTime.value = state.clock.elapsedTime;
  });

  return (
    <mesh frustumCulled={false}>
      <primitive object={material} attach="material" />
      <primitive object={instancedGeometry} attach="geometry" />
    </mesh>
  );
}

import { DependencyList, useEffect, useMemo } from 'react';

export default function useDisposableMemo<T extends { dispose: () => void }, K extends T | T[]>(
  fun: () => K,
  deps: DependencyList = []
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const disposableObject = useMemo(fun, deps);

  useEffect(
    () => () => {
      if (Array.isArray(disposableObject)) disposableObject.forEach((o) => o.dispose());
      else disposableObject.dispose();
    },
    [disposableObject]
  );

  return disposableObject;
}

import { gsap } from 'gsap';
import HeadingAlternative from 'main/components/HeadingAlternative';
import React, { useEffect, useRef } from 'react';

interface GalleryProps extends React.HTMLAttributes<HTMLDivElement> {
  imageId: number;
  imageTitle: string;
  imageUrl: string;
  imageGrid: string;
  setClickedImage: React.ComponentState;
}

export default function GalleryImage(props: GalleryProps) {
  const { imageId, imageUrl, imageTitle, imageGrid, setClickedImage } = props;
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      const handleClick = () => {
        setClickedImage({
          id: imageId,
        });

        gsap
          .timeline({
            defaults: {
              duration: 0.75,
              ease: 'power4.inOut',
              stagger: {
                amount: 0.5,
              },
            },
          })
          .fromTo(
            '#gallery-featured',
            {
              display: 'none',
              autoAlpha: 0,
            },
            {
              display: 'grid',
              autoAlpha: 1,
            },
            0
          )
          .fromTo(
            '#gallery-featured > * > *',
            {
              yPercent: 25,
              autoAlpha: 0,
            },
            {
              yPercent: 0,
              autoAlpha: 1,
            },
            0.25
          );
      };

      const tl = gsap
        .timeline({
          paused: true,
          defaults: {
            duration: 0.75,
            ease: 'expo',
          },
        })
        .from(
          '.heading',
          {
            yPercent: 50,
            autoAlpha: 0,
          },
          0
        )
        .from(
          '.image',
          {
            borderColor: 'transparent',
          },
          0
        );

      const handleMouseEnter = () => {
        tl.play();
      };
      const handleMouseLeave = () => {
        tl.reverse();
      };

      container.current?.addEventListener('click', handleClick);
      container.current?.addEventListener('mouseenter', handleMouseEnter);
      container.current?.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        tl.revert();
        container.current?.removeEventListener('click', handleClick);
        container.current?.removeEventListener('mouseenter', handleMouseEnter);
        container.current?.removeEventListener('mouseleave', handleMouseLeave);
      };
    }, container);

    return () => ctx.revert();
  }, [container, setClickedImage, imageId]);

  useEffect(() => {
    const ctx = gsap.context(() => {
      if (container.current) {
        gsap
          .timeline({
            scrollTrigger: {
              trigger: container.current,
              start: 'top 90%',
              once: true,
            },
          })
          .from(container.current, {
            yPercent: 50,
            autoAlpha: 0,
            ease: 'power4',
          });

        gsap
          .timeline({
            scrollTrigger: {
              trigger: container.current,
              start: 'top bottom',
              end: 'bottom top',
              scrub: true,
            },
          })
          .fromTo('img', { yPercent: 12.5 }, { yPercent: -12.5, ease: 'none' });

        const xTo = gsap.quickTo('.image-container', 'x', {
          duration: 0.6,
          ease: 'power1',
        });
        const yTo = gsap.quickTo('.image-container', 'y', {
          duration: 0.6,
          ease: 'power1',
        });

        const parallaxIt = (e: MouseEvent) => {
          const target = e.target as HTMLDivElement;
          const coords = target.getBoundingClientRect();
          const relX = e.clientX - coords.left;
          const relY = e.clientY - coords.top;

          xTo(((relX - target.offsetWidth / 2) / target.offsetWidth) * 100);
          yTo(((relY - target.offsetHeight / 2) / target.offsetHeight) * 100);
        };

        container.current.addEventListener('mousemove', parallaxIt);
        container.current.addEventListener('mouseleave', () => {
          xTo(0);
          yTo(0);
        });
      }
    }, container);

    return () => ctx.revert();
  }, [container]);

  return (
    <div
      ref={container}
      className="gallery-image flex tems-center justify-center"
      data-id={imageId}
      style={{
        gridArea: imageGrid,
      }}
    >
      <div className="image-container flex flex-col items-center justify-center gap-4 cursor-pointer">
        <div className="image p-2 border border-brigth-purple">
          <div className="relative bg-[#221766] overflow-hidden shadow-2xl shadow-dark-purple">
            <img src={imageUrl} alt="" className="object-contain transform scale-125" />
          </div>
        </div>

        <HeadingAlternative className="heading w-[95%] text-[12px] font-altform ">
          {imageTitle}
        </HeadingAlternative>
      </div>
    </div>
  );
}

import { GA_ID } from 'common/constants';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Outlet, useLocation } from 'react-router-dom';

export default function GaTracking() {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    ReactGA.initialize(GA_ID, { gaOptions: { name: 'defaultTracker' } });
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  }, [initialized, location]);

  return <Outlet />;
}

import { gsap } from 'gsap';
import React, { useLayoutEffect, useMemo } from 'react';
import { VoiceOverTranscription } from 'utils/types';

interface SubtitlesProps {
  subtitleSections?: VoiceOverTranscription[];
}

export default function Subtitles(props: SubtitlesProps) {
  const { subtitleSections = [] } = props;

  const structuredSubtitles = useMemo(() => {
    if (!subtitleSections.length) return '';

    return subtitleSections.map((section, sectionIndex) => (
      <div
        className="absolute opacity-0 w-full"
        id={`subtitles-section-${sectionIndex}`}
        key={section.subtitles}
      >
        {section.subtitles}
      </div>
    ));
  }, [subtitleSections]);

  function setOpacity(
    selector: string,
    opacity: number,
    atTime: number,
    timeline: gsap.core.Timeline
  ) {
    timeline.to(
      selector,
      {
        opacity,
        duration: 0.4,
        overwrite: false,
      },
      atTime
    );
  }

  useLayoutEffect(() => {
    if (!subtitleSections.length) return undefined;

    const timeline = gsap.timeline();
    subtitleSections.forEach((section, sectionIndex) => {
      const { start, end } = section;
      const sectionId = `#subtitles-section-${sectionIndex}`;
      setOpacity(sectionId, 1, start, timeline);
      setOpacity(sectionId, 0, end + 0.1, timeline);
    });

    return () => {
      timeline.kill();
    };
  }, [subtitleSections]);

  return (
    <div className="fixed text-center bottom-0 z-90 inset-x-0 mx-auto max-w-[1200px] pointer-events-none px-10 md:p-4">
      <div className="relative flex flex-col items-center text-[20px] md:text-p text-white min-h-[100px] font-altform">
        {structuredSubtitles}
      </div>
    </div>
  );
}

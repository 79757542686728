import { ReactComponent as ScrollDownSmall } from 'assets/images/UI/scroll-down-small.svg';
import { ReactComponent as ScrollDown } from 'assets/images/UI/scroll-down.svg';
import { gsap } from 'gsap';
import React, { useLayoutEffect } from 'react';

interface ScrollIndicatorProps {
  isSmall?: boolean;
}

export default function ScrollIndicator(props: ScrollIndicatorProps) {
  const { isSmall } = props;
  useLayoutEffect(() => {
    gsap.to('#scroll-down', {
      y: 12,
      ease: 'power1.inOut',
      yoyo: true,
      repeat: -1,
      duration: 1.8,
    });
  }, []);

  const id = 'scroll-down';

  if (isSmall) {
    return <ScrollDownSmall id={id} />;
  }

  return <ScrollDown id={id} className="w-[120px] md:w-auto" />;
}

import { gsap } from 'gsap';
import bgClouds from 'main/assets/images/loader/clouds.jpg';
import useLoadProgress from 'main/rendering/hooks/useHasLoaded';
import React, { useEffect, useRef } from 'react';

interface SceneLoaderProps {
  onLoaded: () => void;
}

export default function SceneLoader(props: SceneLoaderProps) {
  const container = useRef<HTMLDivElement>(null);
  const { onLoaded } = props;
  const { hasLoaded, percentage } = useLoadProgress();

  useEffect(() => {
    if (hasLoaded) {
      onLoaded();
      const ctx = gsap.context(() => {
        gsap.to(container.current, { opacity: 0, display: 'none' });
        gsap.to('#loading-text', { opacity: 0, duration: 0.1 });
      }, container);
      return () => ctx.kill();
    }
    return undefined;
  }, [hasLoaded, onLoaded]);

  return (
    <div
      ref={container}
      className="fixed inset-0 z-20 flex items-center justify-center user-select-none bg-purple"
    >
      <img
        src={bgClouds}
        alt="bg clouds"
        className="absolute w-[4096px] h-[2160px] max-w-none z-0"
      />
      <div className="absolute z-10">
        <div
          id="loading-text"
          style={{
            backgroundSize: '100%',
            backgroundImage: `linear-gradient(-0deg, rgba(255,255,255,1) ${percentage}%, rgba(217,217,217,1) ${percentage}%)`,
          }}
          className="relative z-10 font-monsterOfFantasy leading-none text-[90px] bg-clip-text text-transparent"
        >
          {percentage}%
        </div>
      </div>
    </div>
  );
}

import { gsap } from 'gsap';
import buttonBG from 'main/assets/images/button/background.svg';
import { ReactComponent as ButtonClose } from 'main/assets/images/button/button-close.svg';
import { ReactComponent as ButtonDecoration } from 'main/assets/images/collapse-menu/buttonDecoration.svg';
import React, { useEffect, useRef } from 'react';

interface SliderButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  action: 'next' | 'prev' | 'close';
}

export default function SliderButton(props: SliderButtonProps) {
  const { className, action, ...rest } = props;
  const container = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      const tl = gsap
        .timeline({
          paused: true,
        })
        .to('.button-bg', {
          scale: 1,
          duration: 0.5,
          ease: 'expo',
        });

      const handleMouseEnter = () => {
        tl.play();
      };
      const handleMouseLeave = () => {
        tl.reverse();
      };
      container.current?.addEventListener('mouseenter', handleMouseEnter);
      container.current?.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        tl.revert();
        container.current?.removeEventListener('mouseenter', handleMouseEnter);
        container.current?.removeEventListener('mouseleave', handleMouseLeave);
      };
    }, container);

    return () => ctx.revert();
  }, [container]);

  return (
    <button
      ref={container}
      type="button"
      className={`relative overflow-hidden rounded-full aspect-square flex items-center justify-center ${
        action === 'prev' ? 'button-prev' : 'button-next'
      } ${className || ''}`}
      {...rest}
    >
      {action === 'close' ? (
        <ButtonClose className="relative z-10" />
      ) : (
        <ButtonDecoration
          className={`relative z-10 w-[25%] h-full aspect-square transform rotate-90 ${
            action === 'next' ? 'transform -scale-y-100' : ''
          }`}
        />
      )}
      <div className="button-bg absolute z-0 top-0 left-0 h-full w-full rounded-full bg-purple scale-0" />
      <img
        src={buttonBG}
        alt=""
        className="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full object-contain aspect-square"
      />
      {/* <ButtonBG /> */}
    </button>
  );
}

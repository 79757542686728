import { useProgress } from '@react-three/drei';
import { useState } from 'react';

const TOTAL_ITEMS = 40;

export default function useLoadProgress() {
  const { loaded } = useProgress();
  const [hasLoaded, setHasLoaded] = useState(false);

  const percentage = Math.round((loaded / TOTAL_ITEMS) * 100);

  if (percentage === 100 && !hasLoaded) setHasLoaded(true);

  return { hasLoaded, percentage };
}

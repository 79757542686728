import { ReactComponent as Off } from 'assets/images/UI/ic-sound-off.svg';
import { ReactComponent as On } from 'assets/images/UI/ic-sound-on.svg';
import { ReactComponent as Circle } from 'assets/images/UI/menu-sound-circle.svg';
import BouncyButton from 'components/BouncyButton/BouncyButton';
import React from 'react';

interface SoundButtonProps {
  onClick?: () => void;
  muted: boolean;
}

export default function SoundButton(props: SoundButtonProps) {
  const { muted, onClick } = props;
  return (
    <BouncyButton type="button" onClick={onClick}>
      <Circle className="overflow-visible" />
      {muted ? (
        <Off className="absolute inset-0 m-auto overflow-visible" />
      ) : (
        <On className="absolute inset-0 m-auto overflow-visible" />
      )}
    </BouncyButton>
  );
}

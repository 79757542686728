import useResetScrollPosition from 'hooks/useResetScrollPosition';
import contentImg1 from 'main/assets/images/ip-page/contentImg-1.png';
import contentImg10 from 'main/assets/images/ip-page/contentImg-10.png';
import contentImg11 from 'main/assets/images/ip-page/contentImg-11.png';
import contentImg12 from 'main/assets/images/ip-page/contentImg-12.png';
import contentImg2 from 'main/assets/images/ip-page/contentImg-2.png';
import contentImg3 from 'main/assets/images/ip-page/contentImg-3.png';
import contentImg4 from 'main/assets/images/ip-page/contentImg-4.png';
import contentImg5 from 'main/assets/images/ip-page/contentImg-5.png';
import contentImg7 from 'main/assets/images/ip-page/contentImg-7.jpg';
import contentImg8 from 'main/assets/images/ip-page/contentImg-8.png';
// import contentImg9 from 'main/assets/images/ip-page/contentImg-9.png';
import ContentSection from 'main/components/ContentSection';
import IntroductionText from 'main/components/IntroductionText/IntroductionText';
import PageTitle from 'main/components/PageTitle/PageTitle';
import SkyBg from 'main/components/SkyBg/SkyBg';
// import Slider from 'main/components/Slider/Slider';
import Layout from 'main/layouts/Layout';
import React from 'react';

export default function Ip() {
  useResetScrollPosition();

  return (
    <Layout smallerFooter>
      <div className="pt-[150px] px-5">
        <PageTitle pageTitle="Our IP" />
      </div>
      <div className="relative flex justify-center items-center pt-[80px] min-h-[50vh] pb-[25px]">
        <IntroductionText animationsDelay={0} className="z-10 max-w-4xl px-5" smallText>
          Mittaria provides unprecedented value to holders by integrating T&B Media’s most exciting
          IPs into our ecosystem. There are titles for all generations, from global children’s
          animations to blockbuster feature films and highly-anticipated upcoming releases.
        </IntroductionText>
        <SkyBg />
      </div>
      <div className="2xl:max-w-6xl max-w-5xl m-auto px-5">
        <ContentSection
          sectionTitle="Shelldon"
          sectionHeading="Shelldon"
          sectionImg={contentImg1}
          inverted
        >
          Welcome to Shell Land, an underwater city where shellfish, crabs, and fish live
          harmoniously. Join Shelldon, a funny Yoka Star Snail, Connie, a clever Cowrie Snail and
          Herman, a goofy Hermit Crab on their adventures in the Andaman Sea.
        </ContentSection>
        <ContentSection
          sectionTitle="tasty tales of the food truckers"
          sectionHeading="food truckers"
          sectionImg={contentImg2}
        >
          Meet the Food Truckers: three friends traveling the world in a souped-up food truck,
          creating exotic and delicious meals. They film their journey online while facing
          challenges and plenty of comedy. It&apos;s Indiana Jones meets Master Chef with a dash of
          Jackass!
        </ContentSection>
        <ContentSection
          sectionTitle="friendzspace"
          sectionHeading="friendzspace"
          sectionImg={contentImg3}
          inverted
        >
          Mitria sought help from extraordinary beings in the Mortal Realm to maintain balance in
          her newly created galaxy.
          <br />
          <br />
          EZ, a lively and outgoing robot, serves Emin. DASZ, a spirited 19-year-old, protects Dova.
          Samara, an ancient and wise bird, is the guardian of Sirona, and Oda, an enthusiastic
          15-year-old, guards the planet Oriel.
        </ContentSection>
        <ContentSection
          sectionTitle="out of the nest"
          sectionHeading="out of the nest"
          sectionImg={contentImg4}
          fullSize
        >
          Meet Arthur, a young and ambitious &quot;kid&quot; goat who becomes the foster parent to
          seven rambunctious royal hatchlings. Together, they must defeat an evil wizard and his
          minions to save the kingdom, and in doing so, discover the true meaning of family.
        </ContentSection>
        <ContentSection
          sectionTitle="the meeps"
          sectionHeading="the meeps"
          sectionImg={contentImg5}
          inverted
          fullSize
        >
          Follow five soft toy animals on their journey to become the best rock band in the world.
          Experience the challenges and conflicts of their music business adventures, and see how
          they work together to survive.
        </ContentSection>
        {/* <ContentSection
          sectionTitle="legend of the two heroes"
          sectionHeading="legend of the two heroes"
          sectionImg={contentImg6}
        >
          Chinese and Indian mythological heroes, Son Goku and Hanuman unite against evil forces to
          save heaven and earth. The fate of God and Man worlds hangs in the balance.
        </ContentSection> */}
        <ContentSection
          sectionTitle="looking for gods"
          sectionHeading="looking for gods"
          sectionImg={contentImg7}
          fullSize
        >
          The world falls into chaos as the Xianghuo System alters the hierarchy of gods. A hero
          rises from the chaos to restore balance and order.
        </ContentSection>
        <ContentSection
          sectionTitle="12 Zodiacs"
          sectionHeading="12 Zodiacs"
          sectionImg={contentImg8}
          inverted
        >
          Together, they must defeat an evil wizard and his minions to save the kingdom, and in
          doing so, discover the true meaning of family.
        </ContentSection>
        {/* <ContentSection
          sectionTitle="millennium fables"
          sectionHeading="millennium fables"
          sectionImg={contentImg9}
          inverted
        >
          A mysterious teenager triggers events leading to high school teens gaining special powers.
          Thirty years later, a detective uncovers a conspiracy that spans two billion years.
        </ContentSection> */}
        <ContentSection
          sectionTitle="silk road"
          sectionHeading="silk road"
          sectionImg={contentImg10}
          fullSize
        >
          Xiu&apos;s journey on the Silk Road unveils her roots and the ancient world&apos;s
          secrets. With new friendships and dangers, she holds the key to restoring the balance of
          power.
        </ContentSection>
        <ContentSection
          sectionTitle="wellness"
          sectionHeading="wellness"
          sectionImg={contentImg11}
          inverted
          fullSize
        >
          In a hyper-safe and sterile future, Ember, a young orphan, discovers an abandoned secret
          garden. Re-activation a long-forgotten robot programmed with knowledge of the world before
          sterilization, she learns the joy of friendship and cooking natural foods.
        </ContentSection>
        <ContentSection
          sectionTitle="the great hero"
          sectionHeading="the great hero"
          sectionImg={contentImg12}
        >
          Celestial Guardians battle evil spirits to protect humankind. A mortal man, chosen as the
          champion, wields the power of the four Celestial Guardians against the forces of darkness.
        </ContentSection>
      </div>
    </Layout>
  );
}

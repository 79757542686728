import {
  BLACKNESS_IN_TRANSITION,
  BLACKNESS_OUT_TRANSITION,
  ENTER_TRANSITION,
} from 'common/constants';
import useScrollProgress from 'hooks/useScrollProgress';
import React, { useEffect, useMemo, useState } from 'react';
import SceneTransitionPlane from 'rendering/components/SceneTransitionPlane/SceneTransitionPlane';
import { MathUtils } from 'three';

const DEFAULT_SCENE_TRANISTION_PROPS = {
  progress: 0,
  transitionWidth: 0.08,
  dissolveBlur: 0.82,
  opacityBlur: 1.0,
  dissolveSize: 2.5,
  transitionNoiseFactor: 18,
  dissolveNoiseFactor: 4.9,
  dissolveColor: '#4fe4f7',
  centerTransition: false,
};

export default function SceneTransitionsManager() {
  const [scrollProgress, setScrollProgress] = useState(0);
  useScrollProgress(setScrollProgress);

  const transitionProgress = useMemo(() => {
    const transition =
      Math.abs(ENTER_TRANSITION.start - scrollProgress) <
      Math.abs(BLACKNESS_OUT_TRANSITION.start - scrollProgress)
        ? ENTER_TRANSITION
        : BLACKNESS_OUT_TRANSITION;
    const { start, end, reverse } = transition;
    const progress = 1 - MathUtils.clamp(MathUtils.inverseLerp(start, end, scrollProgress), 0, 1.1);
    if (reverse) return 0.9 - progress;
    return progress;
  }, [scrollProgress]);

  const transitionOpacity = useMemo(() => {
    const { start, end } = BLACKNESS_IN_TRANSITION;

    if (scrollProgress < ENTER_TRANSITION.end) return 1;
    if (scrollProgress < start) return 0;
    return MathUtils.clamp(MathUtils.inverseLerp(start, end, scrollProgress), 0, 1);
  }, [scrollProgress]);

  const isCenterTransition = scrollProgress < BLACKNESS_IN_TRANSITION.start / 2;

  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    let visible = false;
    if (scrollProgress <= ENTER_TRANSITION.end) visible = true;
    if (
      scrollProgress >= BLACKNESS_IN_TRANSITION.start &&
      scrollProgress <= BLACKNESS_OUT_TRANSITION.end * 1.1
    )
      visible = true;

    if (isVisible !== visible) setIsVisible(visible);
  }, [isVisible, scrollProgress]);

  return (
    <SceneTransitionPlane
      visible={isVisible}
      progress={transitionProgress}
      opacity={transitionOpacity}
      transitionWidth={DEFAULT_SCENE_TRANISTION_PROPS.transitionWidth}
      dissolveBlur={DEFAULT_SCENE_TRANISTION_PROPS.dissolveBlur}
      opacityBlur={DEFAULT_SCENE_TRANISTION_PROPS.opacityBlur}
      dissolveSize={DEFAULT_SCENE_TRANISTION_PROPS.dissolveSize}
      dissolveNoiseFactor={DEFAULT_SCENE_TRANISTION_PROPS.dissolveNoiseFactor}
      transitionNoiseFactor={DEFAULT_SCENE_TRANISTION_PROPS.transitionNoiseFactor}
      dissolveColor={DEFAULT_SCENE_TRANISTION_PROPS.dissolveColor}
      centerTransition={isCenterTransition}
    />
  );
}

import { gsap } from 'gsap';
import { ReactComponent as LeftDecoration } from 'main/assets/images/button/button-decoration-left.svg';
import { ReactComponent as RightDecoration } from 'main/assets/images/button/button-decoration-right.svg';
import React, { useEffect, useRef } from 'react';

interface ButtonLinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
  href: string;
  title?: string;
}

export default function Button(props: ButtonLinkProps) {
  const { children, className, ...rest } = props;
  const container = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      const tl = gsap
        .timeline({
          paused: true,
          defaults: {
            duration: 0.75,
            ease: 'power4.inOut',
          },
        })
        .fromTo(
          '.right-decoration .svg-button-1',
          {
            scaleX: 0,
          },
          {
            scaleX: 1,
          },
          0
        )
        .fromTo(
          '.right-decoration .svg-button-2',
          {
            scale: 0,
            transformOrigin: 'center',
          },
          {
            scale: 0.8,
            duration: 0.5,
            ease: 'back',
          },
          0
        )
        .fromTo(
          '.right-decoration .svg-button-3',
          {
            fill: 'transparent',
          },
          {
            fill: 'white',
          },
          0
        );

      const handleMouseEnter = () => {
        tl.play();
      };

      const handleMouseLeave = () => {
        tl.reverse();
      };

      container.current?.addEventListener('mouseenter', handleMouseEnter);
      container.current?.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        container.current?.removeEventListener('mouseenter', handleMouseEnter);
        container.current?.removeEventListener('mouseleave', handleMouseLeave);
        tl.revert();
      };
    }, container);

    return () => ctx.revert();
  }, [container]);

  return (
    <a ref={container} className={`flex items-center gap-x-[5px] ${className || ''}`} {...rest}>
      <LeftDecoration className="flex-auto" />
      <span className="flex-initial font-monsterOfFantasy text-[14px] uppercase">{children}</span>
      <RightDecoration className="flex-auto right-decoration" />
    </a>
  );
}

import map from 'main/assets/images/scene-ui/map.png';
import { STATIONS } from 'main/common/constants';
import MapPoint from 'main/components/MapPoint';
import React from 'react';

interface SceneMapProps extends React.HTMLAttributes<HTMLDivElement> {}

const MAP_POSITIONS = [
  'top-[35%] right-[17%]',
  'top-[57%] right-[7%]',
  'top-[65%] right-[29%]',
  'top-[85%] right-[45%]',
  'top-[64%] left-[31%]',
  'top-[56%] left-[10%]',
  'top-[36%] left-[23%]',
  'top-[14%] left-[21%]',
  'top-[20%] left-[45%]',
  'top-[14%] left-[67%]',
];

export default function SceneMap(props: SceneMapProps) {
  const { className } = props;

  return (
    <div className={`relative max-w-[200px] aspect-square ${className || ''}`}>
      <img src={map} alt="Map" />
      {STATIONS.map((station, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <MapPoint key={index} className={MAP_POSITIONS[index]} station={station} index={index} />
      ))}
    </div>
  );
}

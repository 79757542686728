import useResetScrollPosition from 'hooks/useResetScrollPosition';
import STATIONS from 'main/assets/images/info-page/content-img-2.png';
import CREATION from 'main/assets/images/info-page/creation.png';
import FLOWER_CITY from 'main/assets/images/info-page/FlowerCity.png';
import FOUR_PLANETS from 'main/assets/images/info-page/four-planets.png';
import GUARDIANS from 'main/assets/images/info-page/guardians.png';
import VIDEO_BG from 'main/assets/images/info-page/Sirona.png';
import ContentSection from 'main/components/ContentSection';
import Divisor from 'main/components/Divisor/Divisor';
import IntroductionText from 'main/components/IntroductionText/IntroductionText';
import PageTitle from 'main/components/PageTitle/PageTitle';
import SkyBg from 'main/components/SkyBg/SkyBg';
import Title from 'main/components/Title/Title';
// import Video from 'main/components/Video';
import Layout from 'main/layouts/Layout';
import React from 'react';

export default function Story() {
  useResetScrollPosition();

  return (
    <Layout smallerFooter>
      <div className="pt-[150px] px-5 bg-dark-purple">
        <PageTitle pageTitle="Story" />
      </div>
      <div className="relative flex justify-center items-center min-h-[50vh] pt-[50px] pb-[25px] bg-gradient-to-b from-dark-purple to-[#160D4D]">
        <div className="relative z-[3] h-full lg:py-32 py-20 w-full overflow-hidden flex items-center justify-center bg-dark-purple bg-opacity-80">
          <div className="absolute top-0 left-0 w-full h-full z-[4] bg-gradient-to-b from-dark-purple" />
          <IntroductionText animationsDelay={0} className="z-10 max-w-3xl px-5">
            Welcome to Mittaria, where our characters, stories, and IP come to life in the
            metaverse.
          </IntroductionText>
          <img
            src={VIDEO_BG}
            className="absolute top-0 left-0 w-full h-full object-cover z-[1] opacity-75"
            alt="Metaverse 1"
          />
        </div>
        <SkyBg />
      </div>
      <div className="2xl:max-w-6xl max-w-5xl m-auto px-5">
        <ContentSection
          sectionTitle="CREATION"
          sectionHeading="CREATION"
          sectionImg={CREATION}
          inverted
        >
          After completing her trials and adventure in the mortal realm, Mitria regained her
          immortal powers and found that she was stronger than ever.
          <br /> <br />
          Using her newfound abilities, she created a galaxy to reflect her loving and luminous
          personality. This unique star system would become known as Mittaria.
        </ContentSection>
      </div>
      <div className="2xl:max-w-6xl max-w-5xl m-auto px-5">
        <ContentSection
          sectionTitle="FOUR PLANETS"
          sectionHeading="FOUR PLANETS"
          sectionImg={FOUR_PLANETS}
        >
          The four worlds that make up the Mittaria Galaxy are each unique in their terrains,
          ecosystems and civilizations.
          <br />
          <br />
          Emin is devoted to entertainment, Sirona to education and the pursuit of knowledge, Dova
          is the planet of adventure, and Oriel is known as a world of friendship and diplomacy.
        </ContentSection>
      </div>
      <div className="2xl:max-w-6xl max-w-5xl m-auto px-5">
        <ContentSection
          sectionTitle="FOUR GUARDIANS"
          sectionHeading="FOUR GUARDIANS"
          sectionImg={GUARDIANS}
          inverted
        >
          Mitria sought help from extraordinary beings in the Mortal Realm to maintain balance in
          her newly created galaxy.
          <br /> <br />
          EZ, a lively and outgoing robot, serves Emin. DASZ, a spirited 19-year-old, protects Dova.
          Samara, an ancient and wise bird, is the guardian of Sirona, and Oda, an enthusiastic
          15-year-old, guards the planet Oriel.
        </ContentSection>
      </div>
      <div className="2xl:max-w-6xl max-w-5xl m-auto px-5">
        <ContentSection
          sectionTitle="MITTARIA CENTER"
          sectionHeading="MITTARIA CENTER"
          sectionImg={FLOWER_CITY}
        >
          Experience everything the galaxy has to offer from its core, The Mittaria Center. The
          beating heart of Mittaria is home to the ten stations and serves as a nerve center
          connecting the entire star system.
        </ContentSection>
      </div>
      <div className="2xl:max-w-6xl max-w-5xl m-auto px-5">
        <ContentSection
          sectionTitle="THE STATIONS"
          sectionHeading="THE STATIONS"
          sectionImg={STATIONS}
          inverted
        >
          Visit the heart of Mittaria through our ten specialized stations, with abundant
          entertainment and effortless interplanetary commuting.
          <br /> <br />
          Enjoy adrenaline-pumping events at the Arena, find peace in Serenity Park, shop the
          galaxy’s latest fashion trends at the Fabric of Space, and discover out-of-this-world
          recipes in The Eatery.
        </ContentSection>
      </div>
      <div className="pt-[180px] px-5 text-center mx-auto pb-[120px]">
        <Divisor className="max-w-md mx-auto" />
        <Title titleType="h3" color="white" className="pt-6">
          Experience the magic of discovery
        </Title>
      </div>
    </Layout>
  );
}

import { gsap } from 'gsap';
import GalleryFeatured from 'main/components/GalleryFeatured';
import GalleryImage from 'main/components/GalleryImage';
import React, { useEffect, useMemo, useRef, useState } from 'react';

export default function GallerySection() {
  const [galleryImages, setGalleryImages] = useState<GalleryImage[]>();
  const [clickedImage, setClickedImage] = useState<{ id: number }>();
  const container = useRef<HTMLDivElement>(null);
  const localImage = useRef(0);

  useEffect(() => {
    fetch('https://admin.mittaria.io/api/gallery-images', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setGalleryImages(result.galleryImages);
      });
  }, [setGalleryImages]);

  const gallerySections = useMemo(() => {
    if (galleryImages) {
      const gallerySectionsLocal: GalleryImage[][] = [];

      let index = 0;
      for (let i = 0; i < galleryImages.length; i++) {
        if (i % 4 === 0) {
          gallerySectionsLocal[index] = galleryImages.slice(i, i + 4);
          index += 1;
        }
      }

      return gallerySectionsLocal;
    }

    return [];
  }, [galleryImages]);

  const sectionGridsStyle = useMemo<string[][]>(() => {
    const array: string[][] = [];

    array[0] = ['1 / 1 / 3 / 2', '2 / 3 / 4 / 5', '4 / 1 / 6 / 3', '3 / 5 / 5 / 6'];
    array[1] = ['1 / 2 / 3 / 4', '2 / 5 / 4 / 6', '4 / 1 / 5 / 3', '4 / 4 / 6 / 5'];
    array[2] = ['2 / 4 / 4 / 6', '1 / 1 / 3 / 4', '4 / 2 / 6 / 3', '5 / 4 / 6 / 6'];
    array[3] = ['1 / 1 / 3 / 2', '2 / 3 / 4 / 5', '4 / 1 / 6 / 3', '3 / 5 / 5 / 6'];

    return array;
  }, []);

  useEffect(() => {
    if (clickedImage && localImage && galleryImages) {
      localImage.current = galleryImages.length - clickedImage.id;
    }
  }, [clickedImage, localImage, galleryImages]);

  useEffect(() => {
    const ctx = gsap.context(() => {
      const closeButton = document.querySelector('#close-gallery');
      const nextButton = document.querySelector('#next-image');
      const prevButton = document.querySelector('#prev-image');

      function changeActiveImage(value: number) {
        if (galleryImages) {
          if (localImage.current + value > galleryImages.length - 1) {
            localImage.current = 0;
          } else if (localImage.current + value < 0) {
            localImage.current = galleryImages.length - 1;
          } else localImage.current += value;
          setClickedImage(galleryImages[localImage.current]);
        }
      }

      const handleClickPrev = () => {
        gsap.to('#current-image', {
          autoAlpha: 0,
          duration: 0.65,
          ease: 'power1.inOut',
          onComplete: () => {
            changeActiveImage(-1);
            gsap.to('#current-image', {
              autoAlpha: 1,
              duration: 0.65,
              stagger: { amount: 0.5 },
              ease: 'power1.inOut',
            });
          },
        });
      };
      const handleClickNext = () => {
        gsap.to('#current-image', {
          autoAlpha: 0,
          duration: 0.65,
          ease: 'power1.inOut',
          onComplete: () => {
            changeActiveImage(1);
            gsap.to('#current-image', {
              autoAlpha: 1,
              duration: 0.65,
              stagger: { amount: 0.5 },
              ease: 'power1.inOut',
            });
          },
        });
      };
      const handleClickClose = () => {
        gsap
          .timeline({
            defaults: {
              duration: 0.75,
              ease: 'power4.inOut',
              stagger: {
                amount: 0.5,
              },
            },
          })
          .to(
            '#gallery-featured > * > *',
            {
              yPercent: 25,
              autoAlpha: 0,
            },
            0
          )
          .to(
            '#gallery-featured',
            {
              autoAlpha: 0,
            },
            0.25
          )
          .call(() => {
            gsap.set('#gallery-featured', { display: 'none' });
          });
      };

      nextButton?.addEventListener('click', handleClickNext);
      prevButton?.addEventListener('click', handleClickPrev);
      closeButton?.addEventListener('click', handleClickClose);

      return () => {
        nextButton?.removeEventListener('click', handleClickNext);
        prevButton?.removeEventListener('click', handleClickPrev);
        closeButton?.removeEventListener('click', handleClickClose);
      };
    });

    return () => ctx.revert();
  }, [container, galleryImages, clickedImage, localImage]);

  return (
    <div ref={container} className="gallery relative py-20 px-8 flex flex-col gap-y-8">
      {gallerySections.map((section, j) => (
        <div className="min-h-[50vh] w-full md:grid flex flex-col grid-cols-[repeat(5,auto)] grid-rows-[repeat(5,auto)] md:gap-8 gap-[100px]">
          {section.map((image, i) => (
            <GalleryImage
              key={image.imageUrl}
              imageId={image.id}
              imageUrl={image.imageUrl}
              imageTitle={image.title}
              imageGrid={sectionGridsStyle[j > 3 ? j - 3 : j][i]}
              setClickedImage={setClickedImage}
            />
          ))}
        </div>
      ))}
      {galleryImages && <GalleryFeatured imageState={clickedImage} galleryImages={galleryImages} />}
    </div>
  );
}

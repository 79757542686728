import { GaTrackingAction, GaTrackingCategory, IS_DEVELOPMENT, OVERLAYS } from 'common/constants';
import Header from 'components/Header';
import Navigation from 'components/Navigation/Navigation';
import OverlaysManager from 'components/OverlaysManager/OverlaysManager';
import Subtitles from 'components/Subtitles/Subtitles';
import useScrollProgress from 'hooks/useScrollProgress';
import InteractionsManager from 'overlays/InteractionsManager/InteractionsManager';
import LoadingOverlay from 'overlays/LoadingOverlay';
import Welcome from 'overlays/Welcome';
import React, { Suspense, useContext, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import MainCanvas from 'rendering/components/MainCanvas';
import MainScene from 'rendering/MainScene';
import { VoiceOversContext } from 'store/VoiceOversProvider';
import { AudioSource, play, setMute } from 'utils/sound';

import { SOUND_DATA } from './Home.constants';

export default function Home() {
  const [autoplayCompleted, setAutoplayCompleted] = useState(false);
  const [journeyStarted, setJourneyStarted] = useState(false);
  const { setActiveVoiceOver, activeVoiceOver: { subtitleSections = undefined } = {} } =
    useContext(VoiceOversContext);

  function startJourney() {
    AudioSource.backgroundMusic.loop = true;
    play(AudioSource.backgroundMusic);

    setActiveVoiceOver(SOUND_DATA.theBeginning);
    startAutoplay();
    setJourneyStarted(true);
  }

  function startJourneyWithoutSound() {
    setMute(true);
    startJourney();
  }

  const { startAutoplay } = useScrollProgress(undefined, (autoplayActive) => {
    if (autoplayActive === autoplayCompleted) setAutoplayCompleted(!autoplayActive);
  });

  const reachedTheEndRef = useRef(false);
  useScrollProgress((progress) => {
    if (progress < OVERLAYS[OVERLAYS.length - 1].start || reachedTheEndRef.current) return;

    reachedTheEndRef.current = true;
    ReactGA.event({ category: GaTrackingCategory.default, action: GaTrackingAction.endReached });
  });

  const containerOverflow = autoplayCompleted ? 'unset' : 'hidden';
  return (
    <div className="h-screen" style={{ overflowY: containerOverflow }}>
      <div>
        <Suspense>
          <MainCanvas>
            <MainScene />
          </MainCanvas>
        </Suspense>
        <OverlaysManager />
        <InteractionsManager />
        <Welcome onStart={startJourney} onStartWithoutSound={startJourneyWithoutSound} />
        <LoadingOverlay />
        {journeyStarted && <Header />}
        {journeyStarted && <Navigation />}
        <Subtitles subtitleSections={subtitleSections} />
        {IS_DEVELOPMENT && (
          <div
            id="test_progress_ovarlay"
            className="fixed bottom-2 right-[100px] text-h3 text-white"
          >
            0
          </div>
        )}
      </div>
    </div>
  );
}

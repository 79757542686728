import { ReactComponent as Circle } from 'assets/images/UI/menu-sound-circle.svg';
import BouncyButton from 'components/BouncyButton/BouncyButton';
import React from 'react';

interface MenuButtonProps {
  onClick?: () => void;
  open: boolean;
}

export default function MenuButton(props: MenuButtonProps) {
  const { open, onClick } = props;

  const dotClass = 'h-1 w-1 bg-light-purple rounded-full';
  return (
    <BouncyButton type="button" onClick={onClick}>
      <Circle className="object-contain" />
      <div className="absolute inset-0 m-auto flex flex-col gap-1 z-10 items-center justify-center">
        <div
          className={`transition-transform duration-75 ease-in-out flex flex-row gap-3 ${
            open ? 'translate-y-0' : 'translate-y-2'
          }`}
        >
          <div className={dotClass} />
          <div className={dotClass} />
        </div>
        <div className={dotClass} />
        <div
          className={`transition-transform duration-75 ease-in-out flex flex-row gap-3 ${
            open ? 'translate-y-0' : '-translate-y-2'
          }`}
        >
          <div className={dotClass} />
          <div className={dotClass} />
        </div>
      </div>
    </BouncyButton>
  );
}

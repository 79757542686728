import { getProject } from '@theatre/core';
import BANK_PIC from 'main/assets/images/stations/Bank.png';
import CAFE_PIC from 'main/assets/images/stations/Cafe.png';
import CLASS_PIC from 'main/assets/images/stations/ClassClear.png';
import CRAFT_PIC from 'main/assets/images/stations/Craft.png';
import FASHION_PIC from 'main/assets/images/stations/Fashion.png';
import MEETCARE_PIC from 'main/assets/images/stations/MeetCare.png';
import NFT_PIC from 'main/assets/images/stations/NFT.png';
import PARK_PIC from 'main/assets/images/stations/Park.png';
import RESORT_PIC from 'main/assets/images/stations/Resort.png';
import STADIUM_PIC from 'main/assets/images/stations/Stadium.png';
import BANK_MODEL from 'main/assets/models/bank.glb';
import CAFENEON_MODEL from 'main/assets/models/cafeneon.glb';
import CLASS_MODEL from 'main/assets/models/class.glb';
import CRAFT_MODEL from 'main/assets/models/craft.glb';
import FASHION_MODEL from 'main/assets/models/fashion.glb';
import MEETCARE_MODEL from 'main/assets/models/meetcare.glb';
import NFT_MODEL from 'main/assets/models/nft.glb';
import PARK_MODEL from 'main/assets/models/park.glb';
import RESORT_MODEL from 'main/assets/models/resort.glb';
import STADIUM_MODEL from 'main/assets/models/stadium.glb';
import MittariaMain from 'main/assets/theater/MittariaMain.theatre-project-state.json';

export const APP_MAIN_SHEET = getProject('MittariaMain', { state: MittariaMain }).sheet(
  'appMainSheet'
);

export const SIDEVIEW_BREAKPOINT = 800;

export interface StationInformation {
  name: string;
  model: string;
  description: string;
  theaterKey: string;
  img: string;
  noBounce?: boolean;
  defaultRotationOffset?: number;
}

export const STATIONS: StationInformation[] = [
  {
    model: FASHION_MODEL,
    name: 'The Boutique',
    theaterKey: 'fashion',
    img: FASHION_PIC,
    description: `The Boutique is where you can truly display your individuality and creativity. Here you can customize avatars and vehicles and design your costume to stand out and express your style. Sample a range of styles and brands from around the Galaxy.
    
Join our Galacy’s style icon, Arthur, straight from Out of The Nest. Unleash your creativity and design avatars and costumes that capture your unique personality. Draw inspiration from Arthur's bold and innovative fashion choices, and let your imagination soar as you express your individuality through fashion in the metaverse.`,
  },
  {
    model: STADIUM_MODEL,
    name: 'The Arena',
    theaterKey: 'arena',
    img: STADIUM_PIC,
    description: `The Arena is where Mittarii to catch their favorite live events! Whether it's a concert or a comedy show, you'll find it all here. Join your fellow Mittarii and get ready to be blown away by exciting live performances featuring top-tier talent. The Arena always provides an unforgettable experience.
        
Get ready to rock at the Arena with the legendary Meeps. Join your fellow Mittarii to experience electrifying concerts where The Meeps' catchy tunes and high-energy performances will leave you dancing and singing along. Join the excitement, feel the music pulsate through the metaverse, and create unforgettable memories with The Meeps and their fans.`,
  },
  {
    model: MEETCARE_MODEL,
    name: 'Mittaria Care',
    theaterKey: 'meetcare',
    img: MEETCARE_PIC,
    description: `This is the place to go when you need support for your physical or mental health. Whether you're dealing with a chronic condition or need advice on staying healthy and happy, our team of qualified doctors and caregivers is here to help. With virtual consultations and other remote services, you can get the care you need from the comfort of your own home. Come and discover the many ways that Mittaria Care can help you live your best life. 

Enjoy the benefits of bespoke advanced healthcare provided by the Wellness team. Your well-being is the primary concern of Ember and AI-01, who are here to teach you the benefits of well-balanced, organic nutrition using their knowledge from the Secret Garden. If you’re under the weather or just looking for a change in diet, make your way to Mittaria Care and discover the secrets to wellness with your favorites. `,
  },
  {
    model: NFT_MODEL,
    name: 'Pixel Plaza',
    theaterKey: 'nft',
    img: NFT_PIC,
    description: `Welcome to the home of Mittaria's NFT marketplace! Here, you can explore the latest collections from up-and-coming artists and creators, buy and sell rare digital assets, and connect with other NFT enthusiasts from around the world. Our easy-to-use platform is designed to make buying and selling NFTs simple and secure, so you can focus on discovering the next big thing in digital art. 
    
Discover exclusive NFT collections featuring stunning artwork and iconic scenes from the Great Hero universe. Immerse yourself in the thrilling narrative and trade digital assets that capture the essence of this beloved hero within the vibrant NFT ecosystem of Mittaria. `,
  },
  {
    model: CLASS_MODEL,
    name: 'Mittaria Class',
    theaterKey: 'class',
    img: CLASS_PIC,
    description: `Mittaria Class is the perfect place to expand your knowledge and learn new skills. Whether you're interested in science, nature, or any other topic, our interactive classroom is ideal for exploring new ideas and collaborating with fellow learners. You can even workshop your ideas and projects with expert guidance and support from our experienced instructors. Join Mittaria Class and unlock your full potential! 
    
Step into the new Ocean Learning Center, where Shelldon takes center stage as the animated instructor. Learn alongside this knowledgeable and friendly character as he imparts wisdom on various subjects. Whether exploring the wonders of science or diving into the mysteries of nature, Shelldon's interactive lessons and engaging workshops make learning in Mittaria an unforgettable experience. `,
  },
  {
    model: CAFENEON_MODEL,
    name: 'The Cafe',
    theaterKey: 'cafeneon',
    img: CAFE_PIC,
    description: `Calling all foodies! The Mittaria Cafe is your go-to destination for all things culinary. Our expert chefs have curated a diverse selection of recipes from around the globe, so you can experience new flavors and dishes without ever leaving the comfort of the metaverse. But it's not just about great food - our dietary experts are on hand to offer advice on healthy eating habits, so you can improve your mood and overall well-being. Come and explore the delicious world of the Mittaria Cafe! 
    
The Cafe welcomes Food Truckers, renowned for their culinary adventures and exquisite taste. Indulge your senses with Food Trucker's expertly crafted recipes from across the universe. From mouthwatering street food to delectable gourmet delights, let Food Truckers guide you on a journey of flavors and introduce you to new culinary horizons within the vibrant ambiance of Mittaria’s Eatery.`,
  },
  {
    model: RESORT_MODEL,
    name: 'The Resort',
    theaterKey: 'resort',
    img: RESORT_PIC,
    description: `Purpose-built for explorers, this is the ultimate travel hub within Mittaria. Here, you can plan your next adventure virtually with discounted tickets, car rentals, and direct access to hotel bookings. Unsure if you want to visit a particular city? The Resort will allow you to take a virtual tour, walk through the city and visit virtual local tourist attractions. 
      
Embark on a virtual travel adventure at the Silk Road resort in Mittaria. Let Siu take you on a journey through exotic destinations, ancient cities, and breathtaking landscapes. Experience the charm of different cultures, discover hidden gems, and plan your next real-world adventure with Siu.`,
  },
  {
    model: PARK_MODEL,
    name: 'Serenity Park',
    theaterKey: 'park',
    img: PARK_PIC,
    description: `Serenity Park is perfect for exploring the great outdoors and discovering new hobbies. Whether you're into hiking, camping, or just taking a relaxing stroll, you'll find plenty to do in this virtual wilderness. Meet fellow Mittarii and hang out in the campground, or venture out independently and discover all the hidden nooks and crannies that Serenity Park has to offer. With beautiful scenery and endless opportunities for adventure, Serenity Park is a must-visit destination.
    
Join Alice, Kim, and Leo in Serenity Park. Explore virtual campgrounds, engage in thrilling outdoor activities, and embark on adventures with your animated buddies. From treasure hunts to group challenges, the FriendZ Space crew will make your park experiences in Mittaria full of laughter, friendship, and endless fun.`,
  },
  {
    model: CRAFT_MODEL,
    name: 'M-Lab',
    theaterKey: 'craft',
    img: CRAFT_PIC,
    noBounce: true,
    defaultRotationOffset: 0.53,
    description: `Do you love making things with your hands? Then M-Lab is the perfect place for you! Share your DIY ideas and discover new projects to work on, from arts and crafts to unique recipes you just have to share with the world. Connect with others that share your passion for creativity, and explore new ways to express yourself through your projects. Come and get inspired at M-Lab! 
   
Join Shelldon, Connie, and Herman in the Workshop. Get inspired by their innovative projects and share your own creative ideas with fellow visitors. Discover new crafts, from upcycling household items to designing unique decorations, as you embark on exciting DIY journeys with Shelldon and his loyal buddies as your guide. `,
  },
  {
    model: BANK_MODEL,
    name: 'The Vault',
    theaterKey: 'bank',
    img: BANK_PIC,
    description: `The financial hub of Mittaria, The Vault, offers a range of tools and services to help you manage your finances. Whether you need to exchange currencies, store your digital assets, or access loans and other financial products, the Vault has you covered. Our secure platform is designed to make financial transactions straightforward so you can focus on achieving your financial goals. 
     
Uncover the secrets of financial success with the animated duo from the Legend of 2 Heroes at the Mittaria Bank. Let their wisdom guide you through the world of finance, offering valuable insights and advice. From managing investments to making informed financial decisions, embark on a legendary journey toward financial empowerment with the heroes as your trusted allies. `,
  },
];

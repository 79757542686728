import portraitMan1 from 'assets/images/UI/portrait-man-1.jpg';
import portraitMan2 from 'assets/images/UI/portrait-man-2.jpg';
import portraitMan3 from 'assets/images/UI/portrait-man-3.jpg';
import portraitMan4 from 'assets/images/UI/portrait-man-4.jpg';
import portraitMan5 from 'assets/images/UI/portrait-man-5.jpg';
import portraitMan6 from 'assets/images/UI/portrait-man-6.jpg';
import portraitMan7 from 'assets/images/UI/portrait-man-7.jpg';
import portraitWoman1 from 'assets/images/UI/portrait-woman-1.jpg';
import portraitWoman2 from 'assets/images/UI/portrait-woman-2.jpg';
import portraitWoman3 from 'assets/images/UI/portrait-woman-3.jpg';
import portraitWoman4 from 'assets/images/UI/portrait-woman-4.jpg';

export const TEAM: {
  id: number;
  name: string;
  surname: string;
  role: string;
  linkedin?: string;
  twitter?: string;
  portrait: string;
}[] = [
  {
    id: 1,
    name: 'Bhak',
    surname: 'Tanta-Nanta',
    role: 'CEO',
    linkedin: 'https://www.linkedin.com/in/bhaktantananta/',
    portrait: portraitMan1,
  },
  {
    id: 2,
    name: 'Xiao',
    surname: 'Su',
    role: 'Production Director',
    linkedin: 'https://www.linkedin.com/in/kenji-x-a98223b9/',
    portrait: portraitMan2,
  },
  {
    id: 3,
    name: 'Peerapol',
    surname: 'Treelertkul',
    role: 'CFO',
    linkedin: 'https://www.linkedin.com/in/peerapol-treelertkul-35804b65/',
    portrait: portraitMan3,
  },
  {
    id: 4,
    name: 'Watcharavit',
    surname: 'Rungsimavisrut',
    role: 'COO',
    linkedin: 'https://www.linkedin.com/in/watcharavit-r-79194213/',
    portrait: portraitMan4,
  },
  {
    id: 5,
    name: 'Roy',
    surname: 'Hui',
    role: 'CTO',
    linkedin: 'https://www.linkedin.com/in/royhui/',
    portrait: portraitMan5,
  },
  {
    id: 6,
    name: 'Nattaya',
    surname: 'Rujiradamrongchai',
    role: 'Business Development',
    linkedin: 'https://www.linkedin.com/in/nattaya-rujiradamrongchai/',
    portrait: portraitWoman1,
  },
  {
    id: 7,
    name: 'Tasanee',
    surname: 'Ua-aksorn',
    role: 'CMO',
    linkedin: 'https://www.linkedin.com/in/utasanee/',
    portrait: portraitWoman2,
  },
  {
    id: 8,
    name: 'Pornviroon',
    surname: 'Kaewthong',
    role: 'Project Director',
    linkedin: 'https://www.linkedin.com/in/pornviroon-kaewthong-b1596243/',
    portrait: portraitWoman3,
  },
  {
    id: 9,
    name: 'Kantapong',
    surname: 'Tapanyo',
    role: 'Art Director',
    linkedin: 'https://www.linkedin.com/in/kantapong-tapanyo-84110b240',
    portrait: portraitMan6,
  },
  {
    id: 10,
    name: 'Kamontip',
    surname: 'Kamonprapa',
    role: 'Project manager',
    linkedin: 'https://www.linkedin.com/in/kamontip-kamonprapa-80937317a',
    portrait: portraitWoman4,
  },
  {
    id: 11,
    name: 'Nirawit',
    surname: 'Pandey',
    role: 'Community Lead',
    linkedin: 'https://www.linkedin.com/in/nirawitpandey',
    portrait: portraitMan7,
  },
  {
    id: 12,
    name: 'Prommintr',
    surname: 'Prachitromrun',
    role: 'Product Developer',
    linkedin: 'https://www.linkedin.com/in/prommintr-prachitromrun-969169159',
    portrait: portraitMan2,
  },
];

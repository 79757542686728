import SkyBg from 'main/components/SkyBg/SkyBg';
import Slider from 'main/components/Slider/Slider';
import Title from 'main/components/Title/Title';
import Video from 'main/components/Video/Video';
import Layout from 'main/layouts/Layout';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function Article() {
  const { id } = useParams();
  const [article, setArticle] = useState<Article>();
  const [date, setDate] = useState<string>();

  useEffect(() => {
    fetch(`https://admin.mittaria.io/api/blogs`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          result.blogs.forEach((blog: Article, i: number) => {
            if (blog.slug === id) {
              setArticle(result.blogs[i]);
            }
          });
        }
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        // (error) => {}
      );
  }, [setArticle, id]);

  useEffect(() => {
    if (article) {
      const localDate = new Date(article.publishedAt).toString().split(' ');
      const finalDate = `${localDate[0]} ${localDate[2]}`;
      setDate(finalDate);
    }
  }, [article]);

  return (
    <Layout>
      <div className="pt-[150px] px-5 max-w-2xl uppercase text-center mx-auto mb-[75px]">
        <p className="text-brigth-purple font-monsterOfFantasy text-smp mb-8">{date}</p>
        <h1 className="text-h2">{article?.title}</h1>
      </div>
      <div className="relative flex justify-center items-center h-[65vh] mb-[150px]">
        <div className="relative z-10 w-full h-full max-w-6xl px-5">
          <div className=" w-full h-full p-2 border border-purple">
            <img
              src={article?.thumbnailUrl}
              alt="Article img"
              className="h-full w-full object-cover"
            />
          </div>
        </div>
        <SkyBg />
      </div>
      <div className="relative max-w-5xl m-auto mb-[150px] px-5 text-smp">{article?.text}</div>
      <div className="relative flex justify-center items-center h-[65vh] mb-[150px]">
        <div className="relative z-10 w-full h-full max-w-6xl px-5">
          <div className=" w-full h-full p-2 border border-purple">
            <img src={article?.imageUrl} alt="Article img" className="h-full w-full object-cover" />
          </div>
        </div>
      </div>
      {article?.videoUrl && (
        <div className="max-w-6xl m-auto px-5 mb-[150px]">
          <Video
            videoUrl={article?.videoUrl || 'https://www.youtube.com/watch?v=dQw4w9WgXcQ'}
            videoBg={article.thumbnailUrl}
          />
        </div>
      )}

      <div>
        <Title titleType="h2" className="text-center">
          Others articles
        </Title>
        <Slider />
      </div>
    </Layout>
  );
}

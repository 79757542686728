import { AudioSource } from 'utils/sound';

const { narration1, narration2, narration4, narration5, narration7 } = AudioSource;

export const SOUND_DATA = {
  theBeginning: {
    audio: narration1,
    start: 1,
    name: 'audio 1',
    subtitleSections: [
      {
        start: 1.8,
        subtitles: 'Since the beginning of time...',
        end: 3.491,
      },
      {
        start: 4.056,
        subtitles: 'there have always been two realms.',
        end: 5.938,
      },
      {
        start: 6.907,
        subtitles: 'The Mortal Realm, where you and I live,',
        end: 9.706,
      },
      {
        start: 10.49,
        subtitles: 'and the Upper Realm, home to the Seven Sisters.',
        end: 13.875,
      },
      {
        start: 14.813,
        subtitles: 'Half-human and Half-god.',
        end: 16.738,
      },
      {
        start: 17.381,
        subtitles: 'These Seven Sisters are the energy and spirit',
        end: 20.156,
      },
      {
        start: 20.536,
        subtitles: 'that flows through all life in the Mortal Realm.',
        end: 23.314,
      },
    ],
  },
  mittriaReveal: {
    audio: narration2,
    start: 30.5,
    name: 'audio 2',
    subtitleSections: [
      {
        start: 0.5,
        subtitles: 'Mitria...',
        end: 1.347,
      },
      {
        start: 2.1,
        subtitles: 'The youngest of the Sisters, embodies light, and happiness.',
        end: 5.832,
      },
      {
        start: 6.257,
        subtitles: 'Across the realms, her warmth, kindness, and loyalty',
        end: 11.158,
      },
      {
        start: 11.5,
        subtitles: 'are felt by every living being.',
        end: 13.648,
      },
      {
        start: 18.061,
        subtitles: 'But where there is light...',
        end: 19.602,
      },
      {
        start: 20.429,
        subtitles: 'Shadows loom.',
        end: 21.521,
      },
      {
        start: 22.893,
        subtitles: 'Malvia.',
        end: 23.495,
      },
      {
        start: 24.266,
        subtitles: 'The Sister of Darkness, craved power and disorder.',
        end: 28.332,
      },
      {
        start: 29.577,
        subtitles: 'So...',
        end: 30.041,
      },
      {
        start: 30.985,
        subtitles: 'She tricked Mitria into entering the void of the Mortal Realm.',
        end: 34.909,
      },
      {
        start: 35.623,
        subtitles: 'Anxious and alone...',
        end: 37.031,
      },
      {
        start: 37.744,
        subtitles: 'Mitria fell for what felt like an eternity.',
        end: 40.662,
      },
    ],
  },
  theVoid: {
    audio: narration4,
    start: 71.1,
    name: 'audio 4',
    subtitleSections: [
      {
        start: 0.298,
        subtitles: 'Falling and falling... ',
        end: 1.825,
      },
      {
        start: 4.446,
        subtitles: 'Until...',
        end: 5.206,
      },
      {
        start: 6.087,
        subtitles: 'She stopped.',
        end: 6.818,
      },
    ],
  },
  legendaryCharms: {
    audio: narration5,
    start: 85.2,
    name: 'audio 5',
    subtitleSections: [
      {
        start: 0.2,
        subtitles: 'But there was still hope...',
        end: 2.65,
      },
      {
        start: 3.408,
        subtitles: 'Mitria must enter the Mortal Realm',
        end: 5.691,
      },
      {
        start: 6.001,
        subtitles: 'and collect the Ten Legendary Charms.',
        end: 8.334,
      },
      {
        start: 8.882,
        subtitles: 'To regain her powers and return home.',
        end: 11.144,
      },
    ],
  },
  jointTheQuest: {
    audio: narration7,
    start: 180,
    name: 'audio 7',
    subtitleSections: [
      {
        start: 3.0,
        subtitles: 'The fate of the Realms rests on Mitria’s shoulders.',
        end: 6.968,
      },
      {
        start: 7.162,
        subtitles: 'Join her quest to restore balance and harmony to the universe.',
        end: 11.702,
      },
    ],
  },
};

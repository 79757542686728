import { gsap } from 'gsap';
import SliderSeasons from 'main/components/Slider/SliderSeasons';
import SliderButton from 'main/components/SliderButton';
import React, { useEffect, useRef } from 'react';

export default function Slider(props: { seasons?: Season[], className?: string }) {
  const { className, seasons, ...rest } = props;
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      let currSlide = 0;
      const counter = container.current?.querySelector('.text-counter');
      const buttonLeft = container.current?.querySelector('.button-prev');
      const buttonRight = container.current?.querySelector('.button-next');
      const data = seasons;

      if (data)
        gsap.timeline().from('.slide', {
          yPercent: 100,
          autoAlpha: 0,
          duration: 1.25,
          stagger: {
            amount: 0.15,
          },
          ease: 'power4',
        });

      if (seasons) {
        gsap.utils.toArray<HTMLDivElement>('.slide').forEach((slide) => {
          const tl = gsap
            .timeline({
              paused: true,
            })
            .to(slide.querySelector('img'), {
              scale: 1.15,
              duration: 0.75,
              ease: 'power1.inOut',
            });

          slide.addEventListener('mouseenter', () => {
            tl.play();
          });
          slide.addEventListener('mouseleave', () => tl.reverse());
        });
      }

      function slideRight() {
        if (data) {
          if (currSlide >= data.length - 1) {
            currSlide = -1;
          }
          currSlide += 1;
          gsap.to('.slide', {
            xPercent: currSlide * -100,
            duration: 0.75,
            ease: 'power3.inOut',
          });
          /* 
              useState stop GSAP animations
            */
          if (counter) counter.innerHTML = `0${currSlide + 1}`.slice(-2);
        }
      }
      function slideLeft() {
        if (data) {
          if (currSlide <= 0) {
            currSlide = data.length;
          }
          currSlide -= 1;
          gsap.to('.slide', {
            xPercent: currSlide * -100,
            duration: 0.75,
            ease: 'power3.inOut',
          });
          if (counter) counter.innerHTML = `0${currSlide + 1}`.slice(-2);
        }
      }

      buttonLeft?.addEventListener('click', slideLeft);
      buttonRight?.addEventListener('click', slideRight);

      return () => {
        buttonLeft?.removeEventListener('click', slideLeft);
        buttonRight?.removeEventListener('click', slideRight);
      };
    }, container);

    return () => ctx.revert();
  }, [container, seasons]);

  return (
    <div
      ref={container}
      className={`w-full ${'lg:h-[80vh]'} flex lg:flex-nowrap gap-y-10 flex-wrap-reverse justify-center items-center ${
        className || ''
      }`}
      {...rest}
    >
      <div className="lg:flex-0 flex lg:flex-col flex-row-reverse items-center justify-center min-w-[25%] lg:h-full lg:w-auto w-full lg:px-5 px-8">
        <div className="flex-auto flex lg:flex-col items-center lg:justify-start justify-end gap-y-5 lg:gap-x-0 gap-x-5">
          <SliderButton action="prev" className="md:min-h-[65px] min-h-[50px]" />
          <SliderButton action="next" className="md:min-h-[65px] min-h-[50px]" />
        </div>
        <div className="lg:flex-0 flex-auto font-monsterOfFantasy flex lg:items-end">
          <span className="text-counter">01</span>
          <span className="text-brigth-purple">/{`0${seasons?.length}`.slice(-2)}</span>
        </div>
      </div>
      <div className="flex-auto flex  h-full overflow-hidden">
        <SliderSeasons seasons={seasons} />
      </div>
    </div>
  );
}

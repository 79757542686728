import { useThree } from '@react-three/fiber';
import useDisposableMemo from 'hooks/useDisposableMemo';
import useUniforms from 'hooks/useUniforms';
import { useEffect, useMemo } from 'react';
import SceneTransitionFragmentShader from 'rendering/shaders/scene-transition.fragment.glsl';
import StandardVertexShader from 'rendering/shaders/standard.vertex.glsl';
import {
  Color,
  MathUtils,
  Matrix3,
  Mesh,
  PerspectiveCamera,
  PlaneGeometry,
  ShaderMaterial,
} from 'three';

const DISTANCE_FROM_CAMERA = 0.05;

interface SceneTransitionPlaneProps {
  progress: number;
  visible?: boolean;
  transitionWidth?: number;
  opacityBlur?: number;
  dissolveBlur?: number;
  dissolveSize?: number;
  transitionNoiseFactor?: number;
  dissolveNoiseFactor?: number;
  dissolveColor?: Color | string;
  color?: Color | string;
  centerTransition?: boolean;
  opacity?: number;
}

const DEFAULT_COLOR = new Color('#000000');
const DEFAULT_DISSOLVE_COLOR = new Color('#ffffff');

function useConstructColor(color: Color | string) {
  return useMemo(() => {
    if (typeof color === 'object') return color;
    return new Color(color);
  }, [color]);
}

export default function SceneTransitionPlane(props: SceneTransitionPlaneProps) {
  const {
    progress,
    visible = true,
    transitionWidth = 0.125,
    opacityBlur = 0.9,
    dissolveBlur = 0.9,
    dissolveSize = 1,
    transitionNoiseFactor = 5,
    dissolveNoiseFactor = 20,
    dissolveColor: rawDissolveColor = DEFAULT_DISSOLVE_COLOR,
    color: rawColor = DEFAULT_COLOR,
    centerTransition = false,
    opacity = 1,
  } = props;
  const camera = useThree((s) => s.camera) as PerspectiveCamera;
  const canvasSize = useThree((s) => s.size);

  const color = useConstructColor(rawColor);
  const dissolveColor = useConstructColor(rawDissolveColor);

  const uniforms = useUniforms({
    uProgress: progress,
    uvTransform: () => new Matrix3(),
    uTransitionWidth: transitionWidth,
    uOpacityBlur: opacityBlur,
    uDissolveBlur: dissolveBlur,
    uDissolveSize: dissolveSize,
    uTransitionNoiseFactor: transitionNoiseFactor,
    uDissolveNoiseFactor: dissolveNoiseFactor,
    uDissolveColor: dissolveColor,
    uColor: color,
    uOpacity: opacity,
  });

  const material = useDisposableMemo(
    () =>
      new ShaderMaterial({
        transparent: true,
        uniforms,
        vertexShader: StandardVertexShader,
        fragmentShader: SceneTransitionFragmentShader,
        defines: {
          USE_UV: true,
          CENTER_TRANSITION: centerTransition,
        },
      }),
    [uniforms, centerTransition]
  );
  useEffect(() => {
    material.defines.CENTER_TRANSITION = centerTransition;
  }, [centerTransition, material]);

  const planeGeometry = useDisposableMemo(() => new PlaneGeometry(1, 1, 1), []);
  const plane = useMemo(() => {
    const mesh = new Mesh(planeGeometry, material);
    mesh.position.set(0, 0, -DISTANCE_FROM_CAMERA);

    return mesh;
  }, [material, planeGeometry]);

  useEffect(() => {
    plane.visible = visible;
  }, [plane, visible]);

  // Automatically attach the plane to the camera
  useEffect(() => {
    camera.add(plane);

    return () => {
      camera.remove(plane);
    };
  }, [camera, plane]);

  // Scale the plane to cover the whole view port of the camera
  useEffect(() => {
    const height = 2 * DISTANCE_FROM_CAMERA * Math.tan(MathUtils.degToRad(camera.fov / 2));
    const width = (height * canvasSize.width) / canvasSize.height;
    plane.scale.set(width, height, 1);
  }, [plane, camera.fov, canvasSize]);

  return null;
}

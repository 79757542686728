import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import App from 'App';
import { gsap } from 'gsap';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { mainnet, projectId, ethersConfig, modalTheme } from "utils/web3-modal";

import reportWebVitals from './reportWebVitals';

// SETUP WEB3MODAL: Web3Modal must be imported before other Web3Modal components
createWeb3Modal({
  ethersConfig,
  chains: [
    mainnet
  ],
  projectId: projectId!,
  themeMode: 'dark',
  themeVariables: modalTheme,
})

gsap.defaults({
  ease: 'power2',
  duration: 2.6,
  // CAUTION!
  // When using gsap timelines, pass in overwrite: false as a config!
  overwrite: true,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

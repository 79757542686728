import WallerStatusRingImg from 'main/assets/images/wallet-checker/wallet_status_ring.png';
import React from 'react';

interface WalletCheckerStatusProps {
  imageSrc: string;
  children: React.ReactNode;
}
export default function WalletCheckerStatus(props: WalletCheckerStatusProps) {
  const { imageSrc, children } = props;
  return (
    <div className="flex flex-col items-center">
      <div className="relative w-[154px] h-[166px]">
        <div className="flex justify-center items-center absolute h-full w-full top-[8px] left-0">
          <img src={WallerStatusRingImg} alt="status indicator" />
        </div>
        <img className="absolute h-full w-full left-0" src={imageSrc} alt="status indicator" />
      </div>
      <p className="font-monsterOfFantasy text-[18px] lg:text-[24px] text-center">{children}</p>
    </div>
  );
}

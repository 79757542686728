import { gsap } from 'gsap';
import { ReactComponent as ButtonDecoration } from 'main/assets/images/collapse-menu/buttonDecoration.svg';
import { ReactComponent as LinkDecoration } from 'main/assets/images/header/link-decoration.svg';
import Socials from 'main/components/Socials';
import SplitText from 'main/components/SplitText';
import React, { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface CollapseMenuProps extends React.HTMLAttributes<HTMLDivElement> {
  menuName: string;
  menuLinks: Array<{ name: string; to: string; external?: boolean }>;
  collapseClassName?: string;
  socialTab?: boolean;
  menuChildren?: React.ReactNode;
}

export default function CollapseMenu(props: CollapseMenuProps) {
  const route = useLocation().pathname;
  const { className, collapseClassName, menuName, menuLinks, socialTab, children, menuChildren } =
    props;
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      const moreTl = gsap
        .timeline({
          paused: true,
        })
        .from('button > span > span.absolute:first-child', {
          scale: 0,
          duration: 0.75,
          ease: 'expo',
        });
      let flag = true;
      const moreCollapseTl = gsap
        .timeline({ paused: true })
        .to(
          'button svg',
          {
            rotate: 180,
            duration: 0.95,
            ease: 'back.inOut',
          },
          0
        )
        .to(
          '.button-text > span.absolute > span',
          {
            yPercent: -100,
            duration: 0.5,
            stagger: {
              amount: 0.1,
            },
            ease: 'power4.in',
          },
          0
        )
        .set('.button-text > span.relative', { visibility: 'visible' }, 0)
        .from(
          '.mini-menu',
          {
            scale: 0,
            duration: 0.75,
            ease: 'power4.inOut',
          },
          0
        )
        .from(
          '.mini-menu a, .mini-menu div',
          {
            autoAlpha: 0,
            yPercent: 50,
            duration: 0.65,
            stagger: 0.1,
            ease: 'power4.inOut',
          },
          0.25
        )
        .from(
          '.button-text > span.relative > span',
          {
            yPercent: 100,
            duration: 0.5,
            stagger: {
              amount: 0.15,
            },
            ease: 'power4',
          },
          0.5
        );

      const handleMouseEnter = () => {
        moreTl.play();
      };
      const handleMouseLeave = () => {
        moreTl.reverse();
      };
      const handleClick = () => {
        if (flag) moreCollapseTl.play();
        else moreCollapseTl.reverse();
        flag = !flag;
      };
      const moreButton = container.current?.querySelector<HTMLButtonElement>('button');
      moreButton?.addEventListener('mouseenter', handleMouseEnter);
      moreButton?.addEventListener('mouseleave', handleMouseLeave);
      moreButton?.addEventListener('click', handleClick);

      container.current?.querySelectorAll('.mini-menu > .link-page').forEach((link, i) => {
        const img = link.querySelector('svg');
        const linkText = link.querySelector('span');

        if (route === `/${menuLinks[i].to}`) return;

        const linkTl = gsap
          .timeline({
            paused: true,
            defaults: {
              duration: 0.75,
              ease: 'power4.inOut',
            },
          })
          .from(
            img,
            {
              scale: 0,
              xPercent: -50,
            },
            0
          )
          .to(
            linkText,
            {
              x: 30,
            },
            0
          );

        const handleMouseEnterLink = () => {
          linkTl.play();
        };
        const handleMouseLeaveLink = () => {
          linkTl.reverse();
        };
        link.addEventListener('mouseenter', handleMouseEnterLink);
        link.addEventListener('mouseleave', handleMouseLeaveLink);
      });

      return () => {
        moreTl.revert();
        moreCollapseTl.revert();
        moreButton?.removeEventListener('mouseenter', handleMouseEnter);
        moreButton?.removeEventListener('mouseleave', handleMouseLeave);
        moreButton?.removeEventListener('click', handleClick);
      };
    }, container);

    return () => ctx.revert();
  }, [container, menuLinks, route]);

  return (
    <div ref={container} className={`relative text-[14px] ${className || ''}`}>
      <button className="rounded-full border border-[#80808050] p-1" type="button">
        <span className="relative flex items-center justify-center gap-x-2 uppercase rounded-full border border-white py-1 px-3 overflow-hidden">
          <span className="absolute z-0 top-[50%] left-0 transform -translate-y-1/2 w-full h-[200%] rounded-full bg-purple" />
          <div className="button-text relative">
            <span className="absolute top-[50%] left-0 transform -translate-y-1/2  z-10">
              <SplitText onlyChars textToSplit={menuName} />
            </span>
            <span className="relative z-10 invisible">
              <SplitText onlyChars textToSplit="Close" />
            </span>
          </div>
          <ButtonDecoration />
        </span>
      </button>
      <div
        className={`mini-menu absolute top-[50px] left-0 rounded bg-[#241F5A] flex flex-col gap-y-4 px-4 py-7 min-w-[165px] origin-top ${collapseClassName}`}
      >
        {menuLinks.map((link) => (
          <Link
            key={link.name + link.to + Math.random()}
            to={link.external ? link.to : `/${link.to}`}
            target={link.external ? '_blank' : '_self'}
            rel={link.external ? 'noreferrer' : ''}
            title={`Go to Mittaria ${link.to}`}
            className="relative link-page uppercase"
          >
            <LinkDecoration className="absolute top-[50%] left-0 transform -translate-y-1/2" />
            <span
              className={`inline-block ${route === `/${link.to}` ? 'relative left-[30px]' : ''}`}
            >
              {link.name}
            </span>
          </Link>
        ))}
        {menuChildren}
        {socialTab && <Socials noRoute className="w-fit" />}
      </div>
      {children}
    </div>
  );
}

import { gsap } from 'gsap';
import { useDispatchEvent } from 'hooks/eventDispatcher';
import { ReactComponent as TitleDecoration } from 'main/assets/images/loader/decoration.svg';
import { STATIONS } from 'main/common/constants';
import SliderButton from 'main/components/SliderButton';
import StationCard from 'main/components/StationCard';
import { StationContext } from 'main/store/StationProvider';
import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { MathUtils } from 'three';
import { GlobalEventType } from 'utils/types';

export default function StationSection(props: React.HTMLAttributes<HTMLDivElement>) {
  const { className } = props;
  const container = useRef<HTMLDivElement>(null);

  const { activeStation, showStationOverlay, setActiveStation, setShowStationOverlay } =
    useContext(StationContext);

  const changeActiveStation = useCallback(
    (value: number) => {
      const newStation = MathUtils.euclideanModulo(activeStation! + value, STATIONS.length);
      setActiveStation(newStation);
    },
    [activeStation, setActiveStation]
  );

  const dispatchAnimateToStation = useDispatchEvent(() => ({
    type: GlobalEventType.animateToStation,
    object: undefined,
  }));

  function openOverlay() {
    setShowStationOverlay(true);
  }

  function closeStationView() {
    dispatchAnimateToStation();
    const timeline = gsap.timeline();
    timeline.to(
      '#discover-station',
      {
        opacity: 0.0,
        duration: 0.8,
      },
      '<'
    );
    timeline.fromTo(
      '#gradient',
      { width: '60%', height: '50%' },
      {
        width: '0%',
        height: '0%',
        opacity: 0.0,
        duration: 0.8,
      },
      '<'
    );
    timeline.fromTo(
      '#title-decoration',
      { width: '100%' },
      {
        width: '0%',
        opacity: 0.0,
        duration: 0.8,
        onComplete: () => {},
      },
      '<'
    );
    timeline.then(() => {
      setShowStationOverlay(false);
      setActiveStation(-1);
    });
  }

  useEffect(() => {
    gsap.to('#discover-station', { opacity: showStationOverlay ? 0.0 : 1.0, duration: 0.5 });
  }, [showStationOverlay]);

  function handleClickPrev() {
    changeActiveStation(-1);
    gsap.to('#station-info > *:not(#station-close)', {
      autoAlpha: 0,
      duration: 0.65,
      stagger: { amount: 0.5 },
      ease: 'power1.inOut',
      onComplete: () => {
        gsap.to('#station-info > *:not(#station-close)', {
          autoAlpha: 1,
          duration: 0.65,
          stagger: { amount: 0.5 },
          ease: 'power1.inOut',
          overwrite: true,
        });
      },
    });
  }

  function handleClickNext() {
    changeActiveStation(1);
    gsap.to('#station-info > *:not(#station-close)', {
      autoAlpha: 0,
      duration: 0.65,
      stagger: { amount: 0.5 },
      ease: 'power1.inOut',
      overwrite: true,
      onComplete: () => {
        gsap.to('#station-info > *:not(#station-close)', {
          autoAlpha: 1,
          duration: 0.65,
          stagger: { amount: 0.5 },
          ease: 'power1.inOut',
        });
      },
    });
  }

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        '#gradient',
        { width: '0%', height: '0%', overwrite: true },
        {
          width: '60%',
          height: '50%',
          duration: 1.5,
        }
      );
      gsap.fromTo(
        '#title-decoration',
        { width: '0%', overwrite: true },
        {
          width: '100%',
          duration: 1.5,
        }
      );
    }, container);
    return () => ctx.revert();
  }, []);

  return (
    <div
      ref={container}
      className={`relative flex flex-col justify-end items-end md:px-8 px-2 md:flex-initial flex-auto md:h-auto ${
        className || ''
      }`}
    >
      <div
        id="discover-station"
        className="flex flex-col items-center justify-center pointer-events-auto md:w-fit w-full gap-3 pb-5"
      >
        <SliderButton onClick={closeStationView} action="close" className="min-h-[40px]" />
        <div className="flex items-center justify-center w-full gap-x-5">
          <div id="mobile-prev-station">
            <SliderButton onClick={handleClickPrev} action="prev" className="min-w-[35px]" />
          </div>
          <h2 className="flex-initial uppercase text-[28px] text-center">
            {STATIONS[activeStation].name}
          </h2>
          <div id="mobile-next-station">
            <SliderButton onClick={handleClickNext} action="next" className="min-w-[35px]" />
          </div>
        </div>
        <div
          id="title-decoration"
          className="text-white flex items-center justify-center gap-x-5 overflow-hidden"
        >
          <TitleDecoration className="hidden lg:block" />
          <button
            id="discover-button"
            type="button"
            onClick={openOverlay}
            className="font-monsterOfFantasy border-b-2 border-white text-[16px] whitespace-nowrap"
          >
            Discover station
          </button>
          <TitleDecoration className="hidden lg:block transform -scale-x-100" />
        </div>
        <div
          id="gradient"
          className="absolute bottom-0 w-[60%] h-[50%] bg-purple -z-10 bg-opacity-[0.3] rounded-full shadow-[0px_0px_70px_70px_rgba(73,63,130,0.3)]"
        />
      </div>
      <StationCard id="station-info" />
    </div>
  );
}

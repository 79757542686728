import { gsap } from 'gsap';
import Heading from 'main/components/Heading';
import SplitText from 'main/components/SplitText';
import React, { useEffect, useRef } from 'react';

interface IntroductionTextProps extends React.HTMLAttributes<HTMLDivElement> {
  introductionHeading?: string;
  scrollTrigger?: boolean;
  animationsDelay?: number;
  notOnlyChars?: boolean;
  children: React.ReactNode;
  smallText?: boolean;
  animate?: boolean;
}

const animationDuration = 0.3;

export default function IntroductionText(props: IntroductionTextProps) {
  const {
    children,
    className,
    introductionHeading,
    notOnlyChars,
    animationsDelay,
    smallText,
    scrollTrigger,
    animate = true,
  } = props;
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!animate) return undefined;

    const ctx = gsap.context(() => {
      if (scrollTrigger)
        gsap
          .timeline({
            defaults: {
              duration: animationDuration,
              ease: 'power4',
            },
            scrollTrigger: {
              trigger: container.current,
              start: 'top 75%',
              once: true,
            },
          })
          .set(container.current, { visibility: 'visible' }, 0)
          .from(
            'h3',
            {
              autoAlpha: 0,
              yPercent: 50,
              stagger: {
                amount: 0.1,
                from: 'center',
                grid: 'auto',
              },
            },
            0
          )
          .from(
            'p > span',
            {
              autoAlpha: 0,
              yPercent: 50,
              stagger: {
                amount: 0.5,
                from: 'center',
                grid: 'auto',
              },
            },
            0.5
          );
      else
        gsap
          .timeline({
            delay: animationsDelay || 0,
            defaults: {
              duration: animationDuration,
              ease: 'power4',
            },
          })
          .set(container.current, { visibility: 'visible' }, 0)
          .from(
            'h3',
            {
              autoAlpha: 0,
              yPercent: 50,
              stagger: {
                amount: 0.1,
                from: 'center',
                grid: 'auto',
              },
            },
            0.5
          )
          .from(
            'p > span',
            {
              autoAlpha: 0,
              yPercent: 50,
              stagger: {
                amount: 0.5,
                from: 'center',
                grid: 'auto',
              },
            },
            1
          );
    }, container);

    return () => ctx.revert();
  }, [container, animationsDelay, scrollTrigger, animate]);

  return (
    <div
      ref={container}
      className={`relative flex justify-center items-center flex-col invisble ${className || ''}`}
    >
      {introductionHeading && (
        <Heading onlyChars={!notOnlyChars} headingType="h3" className="text-[14px] overflow-hidden">
          {introductionHeading}
        </Heading>
      )}
      <p
        className={`${
          smallText ? 'text-h3' : 'lg:text-h3 text-h3'
        } text-center font-monsterOfFantasy`}
      >
        <SplitText noChars>{children}</SplitText>
      </p>
    </div>
  );
}

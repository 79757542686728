import Footer from 'main/components/Footer/Footer';
import Header from 'main/components/Header/Header';
import React from 'react';

interface LayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  smallerFooter?: boolean;
  absoluteFooter?: boolean;
  hideFooter?: boolean;
  hideHeader?: boolean;
}

export default function Layout(props: LayoutProps) {
  const {
    smallerFooter,
    hideFooter,
    hideHeader = false,
    absoluteFooter,
    children,
    className,
  } = props;

  return (
    <main
      className={`relative w-full min-h-screen overflow-hidden bg-[#160D4D] text-white font-altform font-thin ${
        className || ''
      }`}
    >
      {!hideHeader && <Header />}
      {children}
      {!hideFooter && <Footer smaller={smallerFooter} absolute={absoluteFooter} />}
    </main>
  );
}

import { ReactComponent as LeftDecoration } from 'main/assets/images/button/button-decoration-left.svg';
import iconSrc from 'main/assets/images/seasons-page/icon-2.png';
import Title from 'main/components/Title';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

interface SliderProps extends React.HTMLAttributes<HTMLDivElement> {
  seasons?: Season[];
}

export default function SliderSeasons(props: SliderProps) {
  const { className, seasons, ...rest } = props;
  const container = useRef<HTMLDivElement>(null);

  return (
    <div
      className={`slider h-full w-full relative flex items-center overflow-hidden lg:pl-0 ${
        className || ''
      }`}
      {...rest}
      ref={container}
    >
      {seasons?.map((season) => (
        <Link
          key={season.title + Math.random()}
          to={season.link}
          className={`slide flex items-center justify-center lg:h-full sm:h-[50vh] h-[55vh]  w-full min-w-[375px] relative shrink-0  ${
            season.isDisabled ? 'pointer-events-none' : 'pointer-events-auto'
          }`}
        >
          <div className="relative h-full w-full flex flex-col items-center justify-center overflow-hidden">
            {season.isDisabled && (
              <div className="absolute z-20 inset-0 bg-opacity-40 bg-dark-purple" />
            )}
            <div className="absolute z-0 inset-0 bg-opacity-40 bg-purple" />
            {(season.isDisabled && (
              <div className="h-[40px] aspect-square flex items-center justify-center rounded-full p-1 border border-brigth-purple mx-auto">
                <div className="h-full aspect-square flex items-center justify-center rounded-full p-2 bg-dark-purple">
                  <img src={iconSrc} alt="Icon" className="h-full w-full object-contain" />
                </div>
              </div>
            )) || <div className="h-[40px]" />}
            <img
              src={season.imageUrl}
              alt=""
              className="absolute inset-0 h-full w-full object-cover -z-10"
            />
            <h2 className="relative z-10 md:text-h1 text-h2 text-center">{season.title}</h2>
            <h3 className="relative z-10 md:text-h3 text-h5 text-center">{season.subTitle}</h3>
            <div className="absolute z-10 bottom-3 right-3 flex items-center uppercase gap-6">
              <LeftDecoration className="w-[50px] h-auto rotate-180" />
              <Title className="md:text-h2 text-h5">{`Season ${season.id} `}</Title>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}

import { Canvas } from '@react-three/fiber';
import { getProject } from '@theatre/core';
import { SheetProvider } from '@theatre/r3f';
import ThreeMittriaMainState from 'assets/theater/MittariaMain.json';
import React, { memo } from 'react';

const appMainSheet = getProject('Mittaria', { state: ThreeMittriaMainState }).sheet('appMainSheet');

interface MainCanvasProps {
  children?: React.ReactNode;
}

function MainCanvas(props: MainCanvasProps) {
  return (
    <div className="fixed h-screen w-screen top-0 left-0">
      <Canvas
        gl={{
          preserveDrawingBuffer: true,
          powerPreference: 'high-performance',
        }}
        camera={{
          position: [5, 5, -5],
          fov: 75,
        }}
      >
        <SheetProvider sheet={appMainSheet}>{props.children}</SheetProvider>
      </Canvas>
    </div>
  );
}

export default memo(MainCanvas);

import React, { memo } from 'react';
import { Mesh, Texture } from 'three';

import SisterMesh from '../SisterMesh';

const COUNT = 6;

interface SisterProps {
  mesh: Mesh;
  map: Texture;
}

interface SistersProps {
  radius: number;
  mesh: Mesh;
  map: Texture;
}

function Sister(props: SisterProps) {
  const { map, mesh } = props;

  return (
    <SisterMesh
      particleSize={0.03}
      flowHeight={15.0}
      intensity={1.5}
      flowSpeed={0.2}
      particleCount={2500}
      map={map}
      mesh={mesh}
    />
  );
}

function Sisters(props: SistersProps) {
  const { radius, mesh, map } = props;

  return (
    <group>
      {Array.from({ length: COUNT }, (_, index) => (
        <group
          position={[
            Math.sin((Math.PI * 2.0 * index) / COUNT) * radius,
            0.0,
            Math.cos((Math.PI * 2.0 * index) / COUNT) * radius,
          ]}
          rotation={[0.0, (Math.PI * 2.0 * index) / COUNT - Math.PI, 0.0]}
          scale={[0.1, 0.1, 0.1]}
          key={index}
        >
          <Sister map={map} mesh={mesh} />
        </group>
      ))}
    </group>
  );
}

export default memo(Sisters);

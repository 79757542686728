import React from 'react';

export default function PrivacyPolicyContent() {
  return (
    <>
      <p>
        This notice tells you how we look after your personal data when you visit our website at{' '}
        <a href="https://mittaria.io">https://mittaria.io</a> (Website) and use our non-fungible
        token (NFT) on our Website. It sets out what information we collect about you, what we use
        it for, and whom we share it with. It explains your rights and what to do if you have any
        concerns about your personal data. We may sometimes need to update this notice to reflect
        any changes to the way our Website or Marketplace are provided or to comply with new legal
        requirements. The latest version of this notice will be reflected in the &quot;Last
        Updated&quot; date below.
      </p>
      <p>
        We may add additional features to our Website such as minting NFTs, interaction with Web3
        wallets, staking, gaming, and marketplace (Marketplace) in the future, which may impact how
        we collect and process your personal data.
      </p>
      <p>Last updated: 18 May 2023</p>
      <h3>1. Who we are and other important information</h3>
      <p>
        We are Mittaria Origin Limited, a company registered in the British Virgin Islands, whose
        registered number is 2100690, and registered address is 1st Floor, Irvine&apos;s Place, 159
        Main Street, P.O. Box 2132, Road Town, Tortola, British Virgin Islands (we/us/our).
      </p>
      <p>
        For all visitors and users of our Website, we are the controller of your information (which
        means we decide what information we collect and how it is used).
      </p>
      <p>
        If you have any questions about this privacy notice or the way that we use information,
        please contact us at Admin@mittaria.io.
      </p>
      <h3>2. The information we collect about you</h3>
      <p>
        <b>Personal data</b> means any information which does (or could be used to) identify a
        living person. We have grouped together the types of personal data that we may collect from
        you below:
      </p>
      <ul>
        <li>
          <strong>Identity Data</strong> – first name and last name;
        </li>
        <li>
          <strong>Contact Data</strong> – email address, home address;
        </li>
        <li>
          <strong>Communication Data</strong> – any correspondence between you and us;
        </li>
        <li>
          <strong>Social Media Data</strong> – Facebook, Twitter, Instagram, LinkedIn, Discord, and
          any other social media usernames and passwords;
        </li>
        <li>
          <strong>Financial Data</strong> – crypto wallet addresses, transaction records, or other
          payment method used on our Website including public information observed from blockchains
          such as activity that is publicly visible and/or accessible on blockchains. This may
          include blockchain addresses and information regarding purchases, sales, or transfers of
          digital tokens;
        </li>
        <li>
          <strong>Technical Data</strong> – internet protocol (IP) address, browser type and
          version, time zone setting and generic location, browser plug-in types and versions,
          operating system and platform on the devices you use to access our Website;
        </li>
        <li>
          <strong>Usage Data</strong> – information about your visit to our Website including the
          clickstream to, through and from our site, information you viewed or searched on Website
          or Marketplace, page response times, download errors, length of visits, page interaction;
        </li>
        <li>
          <strong>Feedback</strong> – information and responses you provide about our Website; and
        </li>
        <li>
          <strong>Marketing Data</strong> – your marketing preferences data, for example, if you
          wish to unsubscribe from receiving emails from us.
        </li>
      </ul>
      <h3>How we use your information</h3>
      <p>
        We are required to identify a legal justification (also known as a <b>lawful basis</b>) for
        collecting and using your personal data. There are six legal justifications which
        organizations can rely on. The most relevant of these to us are where we use your personal
        data to:
      </p>
      <ul>
        <li>
          fulfill our <b>contract</b> with you;
        </li>
        <li>
          pursue our <b>legitimate interests</b> (our justifiable business aims);
        </li>
        <li>
          comply with a <b>legal obligation</b> that we have; and
        </li>
        <li>
          do something for which you have given your <b>consent.</b>
        </li>
      </ul>
      <p>
        The list below sets out the lawful basis we rely on when we use your personal data. If we
        intend to use your personal data for a new reason that is not listed in the table, we will
        update our privacy notice.
      </p>
      <ul>
        <li>
          Purpose: Providing our Website to you such as provide information, and connecting Web3
          wallet to the Website; Justification: Contract (when you accept our Terms of Use)
        </li>
        <li>
          Purpose: Keeping you informed about NFT drops; Justification: Contract (when you access
          our Website)
        </li>
        <li>
          Purpose: Verifying you have enough funds available in your crypto wallet to mint and
          purchase NFTs; Justification: Contract (necessary to enable you to mint and purchase NFTs
          on our Website)
        </li>
        <li>
          Purpose: Asking you to provide feedback about our Website; Justification: Consent (the
          feedback is always optional)
        </li>
        <li>
          Purpose: Providing insight on how our Website are being used such as to conduct research,
          analysis and development activities, your behaviours using products and services to create
          a database and to provide services (including, but not limited to, data analytics,
          surveys, technology development and/or profiling), and to offer you with better products
          or services and/or our benefits; Justification: Legitimate interest (necessary to improve
          and optimise our Website), Consent
        </li>
        <li>
          Purpose: Administering and protecting our Website; Justification: Legitimate interests
          (necessary to provide our Marketplace and Website, monitor and improve Website and
          Marketplace security)
        </li>
        <li>
          Purpose: Handling requests for technical support and other queries; Justification:
          Legitimate interests (necessary to ensure proper functioning of our Website)
        </li>
        <li>
          Purpose: Defending against legal claims; Justification: Legitimate interests (to protect
          our business and defend ourselves against legal claims)
        </li>
        <li>
          Purpose: Notifying you about changes to our privacy notice; Justification: Legal
          obligation (necessary to comply with our obligations under the data protection law)
        </li>
        <li>
          Purpose: Providing our Marketplace to you (as set out in our NFT Terms and Conditions)
          when it is launched; Justification: Contract (when you access our Marketplace)
        </li>
        <li>
          Purpose: Verifying your identity, conducting Know-Your Customer, and Customer Due
          Diligence Procedures; Justification: Legal obligation (necessary to comply with our
          Obligations AML/CTF Laws) when Marketplace function is launched.
        </li>
      </ul>
      <p>
        We may <b>anonymise</b> the personal data we collect (so it can no longer identify you) and
        then combine it with other anonymous information so it becomes aggregated data. Data
        protection law does not govern the use of <b>aggregated data</b>, and the various rights
        described below do not apply to it.
      </p>
      <h3>3. Who we share your information with</h3>
      <p>We share (or may share) your personal data with:</p>
      <ul>
        <li>
          <b>Our personnel:</b> our employees (or other types of workers) who have contracts
          containing confidentiality and data protection obligations;
        </li>
        <li>
          <b>Our supply chain:</b> other organizations that help us provide our Website. We ensure
          these organizations only have access to the information required to provide the support we
          use them for and have a contract with them that contains confidentiality and data
          protection obligations;
        </li>
        <li>
          <b>Regulatory authorities:</b> such as the British Virgin Islands Financial Services
          Commission, and or the Office of the Information Commissioner (when established)
        </li>
        <li>
          <b>Strategic & Business Partners:</b> such as platform, server, cloud, and web service
          providers, and IT and marketing service providers;
        </li>
        <li>
          <b>Affiliated Entities:</b> such as our parent and subsidiaries and including but not
          limited to T&B Media Global (Thailand) Company Limited
        </li>
        <li>
          <b>Our professional advisers:</b> such as our accountants or legal advisors where we
          require specialist advice to help us conduct our business; and
        </li>
        <li>
          <b>Any actual or potential buyer of our business.</b>
        </li>
      </ul>
      <p>
        If we were asked to provide personal data in response to a court order or legal request
        (e.g., from the police), we would seek legal advice before disclosing any information and
        carefully consider the impact on your rights when providing a response.
      </p>
      <h3>4. Where your information is located or transferred to</h3>
      <p>
        We will only transfer information outside of the British Virgin Islands where we have a
        valid legal mechanism in place (to make sure your personal data is guaranteed a level of
        protection, regardless of where in the world it is located).
      </p>
      <p>
        If you access our Website whilst abroad, then your personal data may be stored on servers
        located in the same country as you are.
      </p>
      <h3>5. How we keep your information safe</h3>
      <p>
        We have implemented security measures to prevent your personal data from being accidentally
        or illegally lost, used, or accessed by those who do not have permission. These measures
        include:
      </p>
      <ul>
        <li>Access controls and user authentication;</li>
        <li>Internal IT and network security;</li>
        <li>Regular testing and review of our security measures;</li>
        <li>Staff policies and training;</li>
        <li>Incident and breach reporting processes; and</li>
        <li>Business continuity and disaster recovery processes.</li>
      </ul>
      <p>
        If there is an incident that has affected your personal data and we are the controller, we
        will notify the regulator and keep you informed (where required under data protection law).
      </p>
      <p>
        Where we act as the <b>processor</b> (which means we only collect and process information on
        the instructions of a controller) for the affected personal data, we notify the controller
        and support them with investigating and responding to the incident.{' '}
      </p>
      <p>
        If you notice any unusual activity on the Website, please contact us at Admin@mittaria.io.
      </p>
      <h3>6. How long we keep your information</h3>
      <p>
        Where we act as the <b>controller</b>, we will only retain your personal data for as long as
        necessary to fulfill the purposes we collected it for.{' '}
      </p>
      <p>
        To decide how long to keep personal data (also known as its <b>retention period</b>), we
        consider the volume, nature, and sensitivity of the personal data, the potential risk of
        harm to you if an incident were to happen, whether we require the personal data to achieve
        the purposes we have identified or whether we can achieve those purposes through other means
        (e.g., by using aggregated data instead), and any applicable legal requirements.
      </p>
      <p>
        We may keep Identity Data, Contact Data, and certain Communications Data for up to six years
        after the end of our contractual relationship with you. If you browse our Website, we keep
        personal data collected through our analytics tools for only as long as necessary to fulfill
        the purposes we collected it for. If you have asked for information from us or you have
        subscribed to our mailing list, we keep your details until you ask us to stop contacting
        you.
      </p>
      <h3>7. Your legal rights</h3>
      <p>
        You have specific legal rights in relation to your personal data. It is usually free for you
        to exercise your rights, and we aim to respond within one month (although we may ask you if
        we can extend this deadline up to a maximum of two months if your request is particularly
        complex or we receive multiple requests at once). We can decide not to take any action in
        relation to a request where we have been unable to confirm your identity (this is one of our
        security processes to make sure we keep information safe) or if we feel the request is
        unfounded or excessive. We may charge a fee where we decide to proceed with a request that
        we believe is unfounded or excessive. If this happens, we will always inform you in writing.
        We do not respond directly to requests that relate to personal data for which we act as the
        processor. In this situation, we forward your request to the relevant controller and await
        their instruction before we take any action.
      </p>
      <p>Your legal rights in respect of your personal data include:</p>
      <ul>
        <li>
          <b>Access:</b> You must be told if your personal data is being used, and you can ask for a
          copy of your personal data as well as information about how we are using it;
        </li>
        <li>
          <b>Correction:</b> You can ask us to correct your personal data if it is inaccurate or
          incomplete;
        </li>
        <li>
          <b>Restriction:</b> You can ask us to restrict how we use your personal data;
        </li>
        <li>
          <b>Objection:</b> You can object to us using your personal data. If we think there is a
          good reason for us to keep using the information, we will let you know and explain our
          decision;
        </li>
        <li>
          <b>Deletion:</b> You can ask us to delete or remove your personal data if there is no good
          reason for us to continue holding it or if you have asked us to stop using it. If we think
          there is a good reason to keep the information you have asked us to delete, we will let
          you know and explain our decision;
        </li>
        <li>
          <b>Withdrawal:</b> You can notify us to withdraw your previously given consent for data
          processing;
        </li>
        <li>
          <b>Portability:</b> You can ask us to send you an electronic copy of your personal data;
          and
        </li>
        <li>
          <b>Complaints:</b> If you are unhappy with the way we collect and use your personal data,
          you can complain to the Office of the Information Commissioner or any other relevant
          supervisory body.
        </li>
      </ul>
      <p>
        If you wish to make any of the right requests listed above, you can reach us at
        Admin@mittaria.io.
      </p>
      <h3>8. Our cookie policy</h3>
      <p>
        We may use cookies and similar technologies on our Website. <b>Cookies</b> are small text
        files that are downloaded to your device. Cookies contain uniquely generated references that
        are used to distinguish you from other users. They allow information gathered on one webpage
        to be stored until it is needed for use on another, allowing a website to provide you with a
        personalized experience and provide website owners with statistics about how you interact
        with their websites.
      </p>
      <p>
        Cookies are not harmful to your devices (unlike a virus or malicious code), but some
        individuals prefer not to share their information (for example, to avoid targeted
        advertising).
      </p>
      <p>
        <strong>What do we use cookies for?</strong>
      </p>
      <p>We may use cookies to:</p>
      <ul>
        <li>Implement or change the Website in real life;</li>
        <li>Track how visitors use our Website;</li>
        <li>Record whether you have seen specific messages we display on our Website;</li>
        <li>Keep you signed into our Website; and</li>
        <li>Capture and analyze information such as the number of your views and shares.</li>
      </ul>
      <p>
        We can only use cookies if we choose to implement cookies with your permission (you will be
        prompted by a message when you first visit our Website, also known as a cookie banner, where
        you can choose to accept or decline our cookies). You can update your settings on our
        Website.
      </p>
      <p>
        You can choose to decline cookies, but if you turn off necessary cookies, some pages and
        functions on our Website may not work properly. You can also manage cookies through your
        browser settings or device settings (your user manual should contain additional
        information). You can also delete cookies directly with the relevant third parties (for
        example, you can disable Google Analytics on their website).
      </p>
    </>
  );
}

import OPEN_SEA_ICON from 'main/assets/images/footer/open-sea-icon.svg';
import { ReactComponent as Discord } from 'main/assets/images/footer/social-2.svg';
import { ReactComponent as Twitter } from 'main/assets/images/footer/social-3.svg';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface SocialsLinkProps {
  className?: string;
  noRoute?: boolean;
}

export default function Socials(props: SocialsLinkProps) {
  const { className, noRoute } = props;
  const route = useLocation().pathname;

  return (
    <nav
      className={`relative z-10 flex-0 flex justify-center items-center gap-x-4 ${className || ''}`}
    >
      {!noRoute && (
        <>
          <a
            href="/terms-nft"
            className={`uppercase text-[12px] ${route !== '/' ? 'hidden' : ''}`}
            title="Go to Mittaria NFT Terms & Conditions"
          >
            NFT Terms
          </a>
          <a
            href="/terms"
            className={`uppercase text-[12px] ${route !== '/' ? 'hidden' : ''}`}
            title="Go to Website Terms of Use"
          >
            Terms of Use
          </a>
          <a
            href="/privacy"
            className={`uppercase text-[12px] ${route !== '/' ? 'hidden' : ''}`}
            title="Go to privacy policy"
          >
            Privacy Policy
          </a>
        </>
      )}
      <a
        href="https://opensea.io/collection/mittaria-genesis"
        target="_blank"
        rel="noreferrer"
        title="Go to OpenSea"
        className=" w-[24px] h-[24px]"
      >
        <img src={OPEN_SEA_ICON} alt="opensea" />
      </a>
      <a
        href="https://twitter.com/Mittaria_Origin"
        target="_blank"
        rel="noreferrer"
        title="Go to Twitter"
      >
        <Twitter />
      </a>
      <a href="https://discord.gg/Mittaria" target="_blank" rel="noreferrer" title="Go to Discord">
        <Discord />
      </a>
    </nav>
  );
}

import React from 'react';

export default function TermsOfUseContent() {
  return (
    <div className="container">
      <p>
        These Website Terms of Use (Terms of Use) and all policies posted on our website instruct
        you on how to access and use our website at{' '}
        <a href="https://mittaria.io/" target="_self" rel="noopener noreferrer">
          https://mittaria.io/
        </a>
        , including{' '}
        <a href="https://mittamatrix.mittaria.io/" target="_blank" rel="noopener noreferrer">
          https://mittamatrix.mittaria.io/
        </a>{' '}
        (collectively referred to as the “Website”).
      </p>
      <p>
        <strong>PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE ACCESSING OUR WEBSITE.</strong>
      </p>
      <p>Last updated: 31 January 2024</p>
      <h3>1. Who we are and how you accept these Terms of Use</h3>
      <p>
        1.1 We, Mittaria Origin Limited, as the operator of Website, a company registered in British
        Virgin Islands, whose registered number is 2100690, and registered address is 1st Floor,
        Irvine’s Place, 159 Main Street, P.O. Box 2132, Road Town, Tortola, British Virgin Islands (
        <span className="font-bold">we/us/our</span>).
      </p>
      <p>
        1.2 The intellectual property, intellectual property rights in certain materials displayed
        on the Website, including content, information, logos marks, materials, relating to the
        project belongs to T&B Media Global (Thailand) Co. Ltd, a company incorporated under the
        laws of Thailand, company registration number 0105559126836, with registered address at No.
        101 True Digital Park Building, 4th Floor, Room 408, Sukhumvit Road, Bang Chak, Prakanong,
        Bangkok 10260, or to its partner (collectively referred to as the “
        <span className="font-bold">Licensor</span>”)
      </p>
      <p>
        1.3 By using our Website, you confirm that you accept these Terms of Use and that you agree
        to comply with them. If you do not agree to these Terms of Use, you must not access or use
        our Website.
      </p>
      <p>
        1.4 We and the Licensor may amend these Terms of Use from time to time. Every time you wish
        to use our Website, please check these Terms of Use to ensure you understand the terms that
        apply at that time.
      </p>
      <h3>2. Use of our Website</h3>
      <p>2.1. You must be at least eighteen (18) years old to use the Website.</p>
      <p>
        2.2. Subject to your compliance with these Terms of Use and applicable laws, we as the
        operator, and the Licensor are granting you certain rights including but not limited to the
        right to access the Website in accordance with the terms of this Terms of Use.
      </p>
      <p>
        2.3. You must not use the Website if any applicable laws, including but not limited to
        anti-money laundering laws, counter-terrorist financing laws, anti-corruption laws or
        economic sanctions laws, would be breached or if such applicable laws prohibit, penalize,
        sanction, or expose Mittaria, the Licensor, or the Website to liability.
      </p>
      <p>
        2.4 You may not use the Website if you are located in, or a citizen or resident of any
        state, country, territory or other jurisdiction where your use of the Website or would be
        illegal or otherwise violate any applicable law (including any countries sanctioned by the
        UN, US, OFAC, the UK or the EU). You represent and warrant that you are not a citizen or
        resident of any such jurisdiction and that you will not use the Website.
      </p>
      <p>
        2.5. If you are in any doubt as to whether the above restrictions apply to you, you should
        consult your legal, financial, tax or other professional advisor(s). Unfortunately, if you
        breach any of these Terms, the above license will terminate. The decision to terminate or
        suspend any licence and is in our sole discretion.
      </p>
      <p>
        2.6. Our Website is made available free of charge, however certain elements might only be
        accessible to users which connect their blockchain wallets (such as Metamask) to our
        Website.
      </p>
      <p>
        2.7. We do not guarantee that our Website will always be available or be uninterrupted. We
        may suspend or restrict the availability of all or any part of our Website for business and
        operational reasons.
      </p>
      <p>
        You acknowledge that the Website is made available via the internet and as a result, the
        availability of the Website may be subject to limitations, delays and other problems
        inherent in the use of such communication facilities. Consequently we: (a) do not warrant
        that the use of the Website will be uninterrupted or error-free; and (b) are not responsible
        for any delays, delivery failures, or any other loss or damage resulting from the transfer
        of data over communications networks and facilities, including the internet and blockchain
        networks.
      </p>
      <p>
        2.8. We want to ensure that our Website is as accessible and available at all times, so if
        you have any difficulties using our Website, please let us know at{' '}
        <a className="font-bold" href="mailto:Admin@mittaria.io">
          Admin@mittaria.io
        </a>
        .
      </p>
      <p>
        2.9. We have the right to make any changes to the functionality of the Website at any time
        without notice, and for any reason, including without limitation: (a) to address users’
        needs; (b) to comply with any applicable laws; or (c) to add new functionalities or improve
        existing functionalities (including integrating the Website with third-party services).
      </p>
      <p>
        2.10{' '}
        <strong className="font-bold">
          We are not responsible for viruses and you must not introduce them.
        </strong>{' '}
        We do not guarantee that the Website will be secure or free from bugs or viruses. You are
        responsible for configuring your information technology, computer programmes and the
        Website. You should use your own virus protection software. You must not misuse the Website
        by knowingly introducing viruses, trojans, worms, logic bombs or other material that is
        malicious or technologically harmful. You must not attempt to gain unauthorised access to
        the Website, the server on which the Website is stored or any server, computer or database
        connected to the Website. You must not attack the Website via a denial-of-service attack or
        a distributed denial-of-service attack. By breaching this provision, you may commit a
        criminal offence. We may report any such breach to the relevant law enforcement authorities
        and we will co-operate with those authorities by disclosing your identity to them. In the
        event of such a breach, your right to use the Website will cease immediately.
      </p>
      <h3>3. Intellectual property and copyright</h3>
      <p>
        <strong className="font-bold">
          3.1. Intellectual property rights in the material posted on our Website and your right to
          use our Website.{' '}
        </strong>
        We and the Licensor collectively are the owner or the licensee (including any subsequent
        sub-licensee) of all intellectual property, intellectual property rights including
        copyrights, trademarks, patents, and trade secrets, know-how, in our Website and all
        material published on the Website. Those works are protected by intellectual property laws
        and treaties around the world. This means that you are not permitted to copy or use any such
        content other than in accordance with this clause. All such rights are reserved. You may
        print off one copy and may download extracts of any page(s) from our Website for your
        personal use only. You must not modify the paper or digital copies of any materials you have
        printed off or downloaded in any way, and you must not use any illustrations, photographs,
        video or audio sequences or any graphics separately from any accompanying text. Our status
        (and that of any identified contributors) as the authors of content on our Website must
        always be acknowledged.
      </p>
      <p>
        <strong className="font-bold">3.2. Content. </strong>
        User Content refers to all information, materials, video, audio and any other items uploaded
        by the users onto the Website (&quot;User Content&quot;).
      </p>
      <p>
        <strong className="font-bold">3.3. User Content standards.</strong> All User Content
        submitted to the Website:
      </p>
      <ul style={{ listStyleType: 'none' }}>
        <li>(a) must be accurate;</li>
        <li>
          (b) must comply with all applicable law or regulation, including in any country from which
          it is posted;
        </li>
        <li>(c) must be appropriate and not offensive;</li>
        <li>
          (d) must not infringe any third-party rights (including intellectual property rights or
          licence terms); and must pass all necessary intellectual property checks (if we decide to
          conduct them). We are not required to monitor User Content, but we reserve the right to
          remove any User Content from the Website if it does not, in our opinion, meet these User
          Content standards. All users are responsible for complying with the User Content standards
          for any User Content they submit. We accept no liability to you or any other user or third
          party in relation to the content, accuracy or completeness of any User Content. You will
          indemnify us for any loss or damage we suffer as a result of any breach by you of the User
          Content standards.
        </li>
      </ul>
      <p>
        <strong className="font-bold">3.4. Ownership of User Content. </strong>
        For the purpose of these Terms, we assume that you own your User Content and by uploading it
        on the Website, you do not transfer any ownership to us. By uploading any User Content, you:
        (a) represent and warrant that you have the right to upload and publish the User Content,
        whether as an owner or a licensee; and (b) grant us a non-exclusive, worldwide,
        royalty-free, fully paid up, transferrable, sublicensable, perpetual and irrevocable licence
        to copy, display, upload, distribute, publish, store, and otherwise use your User Content to
        the extent and in such a manner as is necessary to operate the Website.
      </p>
      <p>
        <strong className="font-bold">3.5. No commercial use of our content. </strong>
        You must not use any part of the content from our Website for commercial purposes without
        obtaining a licence to do so from us.
      </p>
      <p>
        <strong className="font-bold">3.6. No data mining or web scraping.</strong>
        You shall not conduct, facilitate, authorise, or permit any text or data mining or web
        scraping in relation to our Website. This includes using (or permitting, authorising or
        attempting the use of): (a) any &quot;robot&quot;, &quot;bot&quot;, &quot;spider&quot;,
        &quot;scraper&quot; or other automated device, program, tool, algorithm, code, process or
        methodology to access, obtain, copy, monitor or republish any portion of the site or any
        data, content, information or services accessed via the same; and (b) any automated
        analytical technique aimed at analysing text and data in digital form to generate
        information which includes but is not limited to patterns, trends and correlations.
      </p>
      <p>
        <strong className="font-bold">
          3.7. Content is provided for general information only.{' '}
        </strong>
        The content on our is provided for general information only. It is not intended to amount to
        advice on which you should rely. You must obtain professional or specialist advice before
        taking, or refraining from, any action based on the content on our Website. Although we make
        reasonable efforts to update the information on our Website, we make no representations,
        warranties or guarantees, whether express or implied, that the content on our Website is
        accurate, complete, or up to date.
      </p>
      <p>
        <strong className="font-bold">3.8. Linking to our Website. </strong>
        You may link to our home page only, provided you do so in a way that is fair and legal and
        does not damage our reputation or take advantage of it. You must not establish a link in
        such a way as to suggest any form of association, approval or endorsement on our part where
        none exists. You must not establish a link to our Website on any website that is not owned
        by you. Our Website must not be framed on any other site, nor may you create a link to any
        part of our Website other than the home page. We reserve the right to withdraw linking
        permission without notice.
      </p>
      <p>
        <strong className="font-bold">3.9. Third-party sites, products and services. </strong>
        Where our Website contains links to other sites and resources provided by third parties,
        these links are provided for your information only. Such links should not be interpreted as
        approval by us of those linked websites or information you may obtain from them. We have no
        control over the contents of those sites or resources. Any interaction (or dispute) you may
        subsequently have with those third parties is subject to those third parties’ terms only.
      </p>
      <h3>4. Do not misuse our Website</h3>
      <p>
        4.1. You must not misuse our Website by knowingly introducing viruses, trojans, worms, logic
        bombs or other material that is malicious or technologically harmful.
      </p>
      <p>
        4.2. You must not attempt to gain unauthorised access to our Website, the server on which
        our Website is stored, or any server, computer or database connected to our Website.
      </p>
      <p>
        4.3. We will have the right to refuse the provide certain services or functionalities on the
        Website at our sole discretion, in particular, if we believe that you do not meet the user
        eligibility criteria to use our Website, We may suspend, terminate, prohibit a user from
        using the Website immediately and without notice if we believe that such user is in breach
        of any of these Terms or if we are required to do so under any applicable law or regulations
        or asked to do so by a regulator or any governmental body. We may also suspend, terminate,
        or prohibit a user from using the Website account immediately and without notice if a user
        fails to comply with the Genesis NFT Terms of Sale, or becomes known for not respecting,
        breaching, bypassing or facilitating bypassing of NFT creators’ rights, including royalty
        rights.
      </p>
      <p>
        4.4. You shall: (a) maintain the confidentiality and security of your access credentials and
        not disclose them to anyone; and (b) update your access credentials and notify us as soon as
        you become aware or suspect that your credentials have been compromised, or in case of any
        actual or suspected unauthorised use of your access credentials. We will not be liable to
        you for any loss or damage you incur as a result of your breach of this clause.
      </p>
      <p>
        4.5. We, as the operator of the Website, grant to you a limited, non-exclusive,
        non-transferable, revocable right to access and use the Website, subject to your compliance
        with these Terms. The Licensor grants to you and us a limited, non-exclusive,
        non-transferable, revocable right to the intellectual property rights for the functioning of
        the Website (such as display of images, videos on the Website), subject to your, and our
        compliance with these Terms. If you breach the license under this clause or in violation of
        this Terms, you shall be deemed in violation of our rights as the Website operator and the
        intellectual property rights of the Licensor (as the case may be).
      </p>
      <p>
        4.6. <strong className="font-bold">Prohibited uses. </strong>
        You shall not use the Website for or in connection with any of the purposes or any of the
        matters listed below (and you acknowledge that any such use may result in immediate
        suspension or termination of your account and of your right to use the Website. You shall
        not use the Website to:
      </p>
      <ol className="ml-4">
        <li>
          <p>
            4.6.1. collude with other users to manipulate the price of any NFT or in any other way
            affect or influence the value or the price of any NFTs;
          </p>
        </li>
        <li>
          <p>
            4.6.2. conduct any unsolicited or unauthorised advertising or promotional activity or
            any other form of similar solicitation (spam);
          </p>
        </li>
        <li>
          <p>
            4.6.3. conduct or be involved (directly or indirectly) in any activities which are, or
            may result in, a breach of any applicable laws (including local, national and
            international law or regulation), including, but not limited to, laws against money
            laundering and/or terrorist financing,
          </p>
        </li>
        <li>
          <p>4.6.4. engage in speculative investments;</p>
        </li>
        <li>
          <p>
            4.6.5. engage in any activity that may bring us or the Website or any other user into
            disrepute (whether defamatory or not);
          </p>
        </li>
        <li>
          <p>
            4.6.6. conduct or be involved (directly or indirectly) in any activities which are, or
            may result in, a breach of any rights of any third party, including their intellectual
            property rights (for example their copyrights, patents or trademarks);
          </p>
        </li>
        <li>
          <p>
            4.6.7. act in any way that is unlawful, fraudulent, or has any unlawful or fraudulent
            purpose or effect (including providing any false, inaccurate or misleading information
            to us and/or any other user);
          </p>
        </li>
        <li>
          <p>
            4.6.8. bypass or facilitate bypassing of NFT creators’ rights, including royalty rights;
          </p>
        </li>
        <li>
          <p>
            4.6.9. disseminate any content that contains viruses, or is illegal, immoral or in any
            other way inappropriate or harmful;
          </p>
        </li>
        <li>
          <p>
            4.6.10. interfere in any way with the Website such as the use of any data mining, robot,
            spider, crawler, scraper, script, browser extension, offline reader, or other automated
            means or interface not authorized by us to access the website or functionalities,
            extract data, or otherwise interfere in any way with or modify the rendering of websites
            pages or functionalities in any software in the website or incorporate it into any other
            program or application;
          </p>
        </li>
        <li>
          <p>
            4.6.11. disassemble, decompile, reverse-engineer or otherwise attempt to obtain access
            to the source code, object code, algorithms, components and/or any concepts or ideas
            incorporated into the Website;{' '}
          </p>
        </li>
        <li>
          <p>
            4.6.12. access any part of the Website to build a product or service which competes with
            the Website or any functionality provided by it;
          </p>
        </li>
        <li>
          <p>
            4.6.13. upload any content that contains viruses, or is illegal, immoral such as content
            that may involve or in any other way inappropriate or harmful; or
          </p>
        </li>
        <li>
          <p>
            4.6.14. use the Website to buy, sell, or transfer stolen items, fraudulently obtained
            items, items taken without authorization, and/or any other illegally obtained items;
          </p>
        </li>
      </ol>
      <h3>5. Limitation of Liability </h3>
      <p>
        5.1. The Website is provided on an “AS IS” and “AS AVAILABLE” basis. To the maximum extent
        permitted by law, we exclude all implied conditions, warranties, representations as well as
        our liability for direct, indirect or consequential loss that may result from the use of our
        Website or any content on it.
      </p>
      <p>
        5.2. While we try to keep our Website safe and protected, we do not guarantee that our Website
        will be secure or free from bugs or viruses.
      </p>
      <p>
        5.3. The Website is not intended to provide legal, tax or financial advice. Always do your own
        research, we cannot be held responsible for any kind of loss arising from use of the
        Website. Accordingly, before making any final decisions or implementing any financial
        strategy, you should consider obtaining additional information and advice from your
        accountant or other financial advisers who are fully aware of your individual circumstances.
      </p>
      <h3>6. Changes to this Terms of Use</h3>
      <p>
        We reserve the right to update this Terms of Use for any reason, including but not limited
        to complying with relevant laws of the British Virgin Islands or other applicable laws,
        government policy, regulatory and other obligations of a similar nature. Any updates will
        appear on our Website. Any changes to this Terms of Use will become effective upon
        publishing the Terms of Use or such other date as we may specify.
      </p>
      <h3>7. Entire Agreement</h3>
      <p>
        These Terms of Use constitute the entire agreement between the parties and supersede and
        extinguish any and all previous and contemporaneous agreements, promises, assurances, and
        understandings between them, whether written or oral, relating to its subject matter. You
        acknowledge and agree that in entering into these terms you do not rely on and have no
        remedies in respect of any statement, representation, assurance or warranty (whether made
        innocently or negligently) that is not set out in these Terms of Use.
      </p>
      <h3>8. Governing law</h3>
      <p>
        These Terms of Use shall be construed in accordance with the laws of the British Virgin
        Islands, without reference to its conflict of law provisions, and the obligations, rights
        and remedies of the parties hereunder shall be determined in accordance with such laws.
      </p>
      <h3>9. Dispute Resolution – Arbitration and Class Action Waiver</h3>
      <p>
        9.1. Please read this Dispute Resolution- Arbitration and Class Action Waiver clause
        carefully. It affects your rights.
      </p>
      <p>
        9.2. It provides that all disputes between you and us shall be resolved by binding
        arbitration. Arbitration is a form of private dispute resolution and replaces the right to
        go to court. In the absence of this arbitration agreement, you may otherwise have a right or
        opportunity to bring claims in a court, before a judge or jury, and/or to participate in or
        be represented in a case filed in court by others (including, but not limited to, class
        actions). Your acceptance of these Terms constitutes a waiver of your right to litigate
        claims and all opportunity to be heard by a judge or jury. There is no judge or jury in
        arbitration, and court review of an arbitration award is limited. The arbitrator must follow
        these Terms and can award the same damages and relief as a court. (including attorney’s
        fees).
      </p>
      <p>
        <strong className="font-bold">Arbitration</strong>
      </p>
      <p>
        9.3. Any dispute, controversy, or claim arising out of, relating to, or in connection with
        these Terms of Use, including any question regarding its existence, validity or termination,
        or any dispute regarding non-contractual obligations arising out of or relating to it, shall
        be referred and finally resolved by arbitration administered by the BVI International
        Arbitration Centre (<span className="font-bold">“BVI IAC”</span>) under the BVI IAC
        Arbitration Rules. (<span className="font-bold">&quot;BVI IAC Rules&quot;</span>) for the
        time being in force, which rules are deemed to be incorporated by reference in this clause
        10.3. The law of this arbitration clause shall be the laws of the British Virgin Islands.
        The place of arbitration shall be Singapore. The number of arbitrators shall be 3 (three)
        arbitrators. The language to be used in the arbitral proceedings shall be English.
      </p>
      <p>
        9.4. Any dispute between the Parties arising out of or relating to these Terms of Use that
        is not subject to arbitration or cannot be heard in small claims court will be resolved and
        filed only in the courts of the British Virgin Islands. In such case You hereby irrevocably
        and unconditionally consent and submit to the exclusive jurisdiction of such courts over any
        suit, action or proceeding arising out of these Terms of Use.
      </p>
      <p>
        <strong className="font-bold">Class Action Waiver</strong>
      </p>
      <p>
        9.5. Except as otherwise provided in these Terms of Use, to the fullest extent permitted by
        applicable law, the arbitrators may not consolidate more than one person’s claims, and may
        not otherwise preside over any form of a class or representative proceeding or claims (such
        as a class action, consolidated action, representative action, or private attorney general
        action) unless both you and us specifically agree to do so in writing following initiation
        of the arbitration. Neither you, nor any other user of the Website can be a class
        representative, class member, or otherwise participate in a class, consolidated, or
        representative proceeding.
      </p>
      <h3>10. Severability</h3>
      <p>
        If any term, clause, or provision of these Terms is held to be illegal, invalid, void, or
        unenforceable (in whole or in part), then such term, clause, or provision shall be severable
        from these terms without affecting the validity or enforceability of any remaining part of
        that term, clause, or provision, or any other term, clause, or provision in these Terms,
        which will remain in full force and effect. Any invalid or unenforceable provisions will be
        interpreted to affect the intent of the original provisions. If such construction is not
        possible, the invalid or unenforceable provision will be severed from these Terms, but the
        rest of these Terms will remain in full force and effect.
      </p>
    </div>
  );
}

import { useFrame } from '@react-three/fiber';
import { MITTARIA_PARTICLES_WINDOW } from 'common/constants';
import useDisposableMemo from 'hooks/useDisposableMemo';
import useScrollProgress from 'hooks/useScrollProgress';
import useUniforms from 'hooks/useUniforms';
import React, { useMemo, useState } from 'react';
import fragmentShader from 'rendering/shaders/sparks.fragment.glsl';
import vertexShader from 'rendering/shaders/sparks.vertex.glsl';
import {
  AdditiveBlending,
  BufferGeometry,
  Color,
  DoubleSide,
  Float32BufferAttribute,
  Group,
  MathUtils,
  Points,
  ShaderMaterial,
  Texture,
  Vector3,
} from 'three';
import { getOpacityForActiveWindow, randomPointInSphere } from 'utils/three.utils';

interface SparksProps {
  particleCount?: number;
  rotationFrequency?: number;
  rotationSpeed?: number;

  rotationNoiseFrequency?: number;
  rotationNoiseSpeed?: number;
  rotationNoiseStrength?: number;

  flowHeight?: number;
  flowSpeed?: number;

  alphaFrequency?: number;
  alphaSpeed?: number;

  map: Texture;
  particleSize?: number;
  radius?: number;

  intensity?: number;
  color?: string;
}

const MITTARIA_PARTICLES_REVEAL_WINDOW = { start: 0.165, end: 0.19, transitionDuration: 0.01 };

export default function SparkParticles(props: SparksProps) {
  const [groupRef, setGroupRef] = useState<Group | null>(null);

  const {
    map,
    particleCount = 4000,
    rotationFrequency = 0.66,
    rotationSpeed = 0.18,

    rotationNoiseFrequency = 0.1,
    rotationNoiseSpeed = 0.4,
    rotationNoiseStrength = 0.4,

    flowHeight = 10.0,
    flowSpeed = 1.0,

    alphaFrequency = 0.1,
    alphaSpeed = 0.4,

    particleSize = 4.0,
    intensity = 3.0,
    color = '#4fe4f7',
    radius = 8.0,
  } = props;
  const particleColor = useMemo(() => new Color(color), [color]);

  const uniforms = useUniforms({
    uTime: 0,
    uRotationFrequency: rotationFrequency,
    uRotationSpeed: rotationSpeed,

    uRotationNoiseFrequency: rotationNoiseFrequency,
    uRotationNoiseSpeed: rotationNoiseSpeed,
    uRotationNoiseStrength: rotationNoiseStrength,

    uFlowHeight: flowHeight,
    uFlowSpeed: flowSpeed,

    uAlphaFrequency: alphaFrequency,
    uAlphaSpeed: alphaSpeed,

    uParticleSize: particleSize,
    uIntensity: intensity,
    uVisiblePercentage: 1.0,
    map,
    color: particleColor,
  });

  const material = useDisposableMemo(
    () =>
      new ShaderMaterial({
        vertexShader,
        fragmentShader,
        uniforms,
        transparent: true,
        side: DoubleSide,
        alphaTest: 0.5,
        depthWrite: false,
        toneMapped: false,
        blending: AdditiveBlending,
      }),
    [uniforms]
  );

  const geometry = useDisposableMemo(() => {
    const geom = new BufferGeometry();
    const vertices = [];
    const normals = [];
    const sizes = [];
    const offset = [];
    const visibility = [];

    const v3 = new Vector3();

    for (let i = 0; i < particleCount; i++) {
      randomPointInSphere(radius, 0.5, v3);
      vertices.push(v3.x, v3.y, v3.z);
      v3.normalize();
      normals.push(v3.x, v3.y, v3.z);
      const size = MathUtils.randFloat(0.1, 0.5);
      sizes.push(size);
      offset.push(Math.random());
      visibility.push(Math.random());
    }

    geom.setAttribute('position', new Float32BufferAttribute(vertices, 3));
    geom.setAttribute('normal', new Float32BufferAttribute(normals, 3));
    geom.setAttribute('scale', new Float32BufferAttribute(sizes, 1));
    geom.setAttribute('offset', new Float32BufferAttribute(offset, 1));
    geom.setAttribute('visibility', new Float32BufferAttribute(visibility, 1));

    return geom;
  }, [particleCount, radius]);

  const particles = useMemo<Points>(() => {
    const points = new Points(geometry, material);
    points.frustumCulled = false;

    return points;
  }, [geometry, material]);

  useScrollProgress((progress) => {
    const { start, end, startTransitionDuration, endTransitionDuration } =
      MITTARIA_PARTICLES_WINDOW;
    if (!groupRef) return;

    if (progress > end + endTransitionDuration) return;
    const value = getOpacityForActiveWindow(
      progress,
      start - startTransitionDuration,
      start,
      end,
      end + endTransitionDuration
    );

    const reveal = getOpacityForActiveWindow(
      progress,
      MITTARIA_PARTICLES_REVEAL_WINDOW.start - MITTARIA_PARTICLES_REVEAL_WINDOW.transitionDuration,
      MITTARIA_PARTICLES_REVEAL_WINDOW.start,
      MITTARIA_PARTICLES_REVEAL_WINDOW.end,
      MITTARIA_PARTICLES_REVEAL_WINDOW.end + MITTARIA_PARTICLES_REVEAL_WINDOW.transitionDuration
    );
    const scale = value * 0.5 + 0.5;

    uniforms.uVisiblePercentage.value = (value * 0.8 + 0.2) * 0.33 + reveal * 0.66;
    uniforms.uParticleSize.value = particleSize * (0.5 + reveal * 0.5);
    groupRef.scale.set(scale * 0.9, scale * 0.9, scale * 0.75);
  });

  useFrame((state) => {
    uniforms.uTime.value = state.clock.elapsedTime;
  });

  return (
    <group ref={setGroupRef}>
      <primitive object={particles} />
    </group>
  );
}

import branches from 'assets/images/UI/quick-menu-branches.png';
import { ReactComponent as QuickMenuButton } from 'assets/images/UI/quick-menu-button.svg';
import { ReactComponent as CircleOne } from 'assets/images/UI/quick-menu-circle-one.svg';
import { ReactComponent as CircleTwo } from 'assets/images/UI/quick-menu-circle-two.svg';
import { ReactComponent as CircleOneLeft } from 'assets/images/UI/quick-menu-handle-left.svg';
import { ReactComponent as CircleOneRight } from 'assets/images/UI/quick-menu-handle-right.svg';
import { ReactComponent as CircleSteelWing } from 'assets/images/UI/quick-menu-steel-wing.svg';
import { ReactComponent as CircleWingLeft } from 'assets/images/UI/quick-menu-wing-left.svg';
import { ReactComponent as CircleWingRight } from 'assets/images/UI/quick-menu-wing-right.svg';
import React from 'react';

interface QuickMenuItemProps {
  title: string;
  onClick?: () => void;
  children?: React.ReactNode;
  isFocused: boolean;
  circleVariant: number;
}
function QuickMenuCarouselItem(props: QuickMenuItemProps) {
  const { title, circleVariant, children, onClick, isFocused } = props;

  let focusedAnimationConfig = 'transition-all ease-in-out duration-500';
  if ([3, 0].includes(circleVariant)) {
    focusedAnimationConfig += isFocused ? ' opacity-100 delay-150' : ' opacity-0 delay-0';
  } else {
    focusedAnimationConfig += isFocused
      ? ' opacity-100 translate-y-0 delay-150'
      : ' opacity-0 translate-y-4 delay-0';
  }

  function renderCircle() {
    switch (circleVariant) {
      case 3:
      case 0:
        return (
          <>
            <CircleOne className="relative z-10" />
            <CircleOneLeft
              className={`absolute z-20 left-[-26.5px] top-[19.5px] ${focusedAnimationConfig} ${
                isFocused ? 'translate-x-0' : 'translate-x-4'
              }`}
            />
            <CircleOneRight
              className={`absolute z-20 right-[-28px] top-[19.5px] ${focusedAnimationConfig} ${
                isFocused ? 'translate-x-0' : '-translate-x-4'
              } `}
            />
          </>
        );
      case 1:
        return (
          <>
            <img
              src={branches}
              alt="Branches"
              className={`${focusedAnimationConfig} absolute z-20 -bottom-10`}
            />
            <CircleTwo className="relative z-10" />
          </>
        );
      case 2:
        return (
          <>
            <CircleWingLeft className="absolute z-20 -left-[46px] top-[-40px] inset-y-0 my-auto" />
            <CircleWingRight className="absolute z-20 right-[-46px] top-[-40px] inset-y-0 my-auto" />
            <CircleSteelWing
              className={`absolute z-20 -bottom-[-4px] -left-[76px] inset-x-0 mx-auto ${focusedAnimationConfig}`}
            />
            <CircleOne className="relative z-10" />
          </>
        );
      default:
        return null;
    }
  }

  return (
    <button
      type="button"
      className="relative overflow-visible flex flex-col justify-end items-center w-[82vw] sm:w-[90vw] max-w-[340px] md:max-w-[820px] translate-x-[-50%]"
      onClick={onClick}
    >
      <div className="relative z-10 scale-[60%] md:scale-90 2xl:scale-100">
        {renderCircle()}
        <div
          className={`absolute -bottom-2 z-20 left-[50%] -translate-x-[50%] scale-125 md:scale-100 ${focusedAnimationConfig}`}
        >
          <span className="text-[18px] text-lily absolute m-auto inset-0 flex flex-row items-center justify-center">
            ENTER
          </span>
          <QuickMenuButton />
        </div>
        {children}
      </div>
      <h3 className="mt-[80px] md:mt-[160px] 2xl:mt-[180px] text-center text-lily uppercase text-h4 md:text-h3 leading-[50px] min-h-[100px]">
        {title}
      </h3>
    </button>
  );
}

export default React.memo(QuickMenuCarouselItem);

import { ReactComponent as GoldLoader } from 'assets/images/UI/ic-gold-loader.svg';
import React from 'react';

interface LoadingIndicatorProps {
  progress: number;
}

export default function LoadingIndicator(props: LoadingIndicatorProps) {
  const { progress } = props;

  return (
    <div className="relative w-full flex flex-row items-center">
      <div
        style={{ width: `${progress}%` }}
        id="progress"
        className="h-[1px] w-0 bg-[#BFC8E0] z-10 transition-all duration-1000"
      />
      <GoldLoader className="z-10" />
      <div className="absolute h-[1px] w-full bg-[#857AC8]" />
    </div>
  );
}

import { LEGENDARY_ITEMS } from 'common/constants';
import CarouselButton from 'components/CarouselButton';
import { gsap } from 'gsap';
import { useDispatchEvent } from 'hooks/eventDispatcher';
import React, { useState } from 'react';
import { MathUtils } from 'three';
import { GlobalEventType } from 'utils/types';

export default function LegendaryItemCarouselOverlay() {
  const [currentItem, setCurrentItem] = useState(0);

  const dispatchCurrentLegendaryItem = useDispatchEvent(
    (args: { currentItem: number; direction: number }) => ({
      type: GlobalEventType.legendaryItemCarousel,
      currentItem: args.currentItem,
      direction: args.direction,
    })
  );

  function onClick(direction: number) {
    return () => {
      const newItem = MathUtils.euclideanModulo(currentItem + direction, LEGENDARY_ITEMS.length);
      setCurrentItem(newItem);
      dispatchCurrentLegendaryItem({ currentItem: newItem, direction });
      gsap.fromTo('#legendary-item-name', { opacity: 0 }, { opacity: 1, duration: 1.0 });
    };
  }

  const { name, description, link } = LEGENDARY_ITEMS[currentItem];
  function onLinkClick() {
    if (!link) return;
    window.open(link, '_blank');
  }

  return (
    <div className="w-screen h-full relative flex flex-col justify-center items-center px-6">
      <div className="flex flex-row w-full justify-between items-center md:max-w-[1424px] z-30">
        <div className="transform ease-in-out duration-1000 scale-50 origin-center -translate-x-1/4 md:translate-x-0 md:scale-100">
          <CarouselButton onClick={onClick(-1)} />
        </div>
        <div className="transform ease-in-out duration-1000 scale-50 origin-center translate-x-1/4 md:translate-x-0 md:scale-100">
          <CarouselButton onClick={onClick(1)} direction="right" />
        </div>
      </div>
      <div />
      <div className="absolute bottom-[50px] md:bottom-[110px] duration-200 pointer-events-auto">
        <div
          id="legendary-item-name"
          style={{ cursor: link ? 'pointer' : 'default' }}
          onClick={onLinkClick}
          onKeyDown={onLinkClick}
          role="button"
          tabIndex={0}
        >
          <p
            className="text-[36px] md:text-[50px] font-monsterOfFantasy
      shadow-inner-[0px_2px_4px_0px_#493F82] 
      bg-gradient-to-b from-violet to-light-blue text-[transparent]
      bg-clip-text text-center
      z-30
      "
          >
            {name}
          </p>
          <p className="text-[20px] leading-[24px] md:text-[24px] text-violet text-center max-w-[705px] z-30 mb-12">
            {description}
          </p>
        </div>
        <div className="absolute w-[90%] left-[10%] h-[70%] md:h-[80%] bottom-[10%] bg-white -z-10 bg-opacity-[0.45] rounded-full shadow-[0px_0px_55px_55px_rgba(255,255,255,0.45)]" />
      </div>
    </div>
  );
}

import HeadingAlternative from 'main/components/HeadingAlternative';
import SliderButton from 'main/components/SliderButton';
import React from 'react';

interface GalleryFeaturedProps {
  galleryImages: GalleryImage[];
  imageState?: {
    id: number;
  };
}

export default function GalleryFeatured(props: GalleryFeaturedProps) {
  const { imageState, galleryImages } = props;

  return (
    <div
      id="gallery-featured"
      className="fixed z-20 inset-0 w-full h-full p-8 bg-dark-purple bg-opacity-90 hidden sm:grid-cols-[min-content_1fr_min-content] sm:grid-rows-[auto_1fr_auto] grid-cols-[repeat(2,1fr)] grid-rows-[auto_1fr_min-content_auto] gap-10 grid-flow-row"
    >
      <div className="cell-a flex-0 flex items-center justify-center justify-self-center self-center">
        <SliderButton id="close-gallery" action="close" className="min-h-[50px]" />
      </div>
      <div className="cell-b flex-0 justify-self-center self-center">
        <SliderButton id="prev-image" action="prev" className="min-h-[50px] " />
      </div>
      <div id="current-image" className="cell-c flex-auto flex items-center justify-center">
        <img
          src={galleryImages[galleryImages.length - (imageState?.id || 1)].imageUrl}
          alt="Clicked img"
          className="sm:h-full h-auto w-auto object-contain"
        />
      </div>
      <div className="cell-d flex-0 justify-self-center self-center">
        <SliderButton id="next-image" action="next" className="min-h-[50px]" />
      </div>
      <div className="cell-e flex-0 flex items-center justify-center justify-self-center self-center">
        <HeadingAlternative>
          {galleryImages[galleryImages.length - (imageState?.id || 1)].title}
        </HeadingAlternative>
      </div>
    </div>
  );
}

import { useTexture } from '@react-three/drei';
import { editable as e } from '@theatre/r3f';
import ATLAS_SKYBOX_1 from 'assets/images/skybox/atlas_skybox_1.jpg';
import ATLAS_SKYBOX_2 from 'assets/images/skybox/atlas_skybox_2.jpg';
import ATLAS_SKYBOX_3 from 'assets/images/skybox/atlas_skybox_3.jpg';
import React, { useMemo } from 'react';
import TextureAtlasBasicMaterial from 'rendering/materials/TextureAtlasBasicMaterial';
import { DoubleSide, Texture, Vector2 } from 'three';

type AtlasConfig = [Vector2, Texture];
interface SkyboxProps {
  type?: 'galaxy' | 'dreamy';
}
export default function Skybox(props: SkyboxProps) {
  const { type = 'galaxy' } = props;

  const skyboxAtlas1 = useTexture(ATLAS_SKYBOX_1);
  const skyboxAtlas2 = useTexture(ATLAS_SKYBOX_2);
  const skyboxAtlas3 = useTexture(ATLAS_SKYBOX_3);

  const [skybox1Materials, skybox2Materials] = useMemo(() => {
    const skyboxConfig1: AtlasConfig[] = [
      [new Vector2(0, 0), skyboxAtlas1],
      [new Vector2(0.5, 0), skyboxAtlas1],
      [new Vector2(0, 0.5), skyboxAtlas1],
      [new Vector2(0.5, 0.5), skyboxAtlas1],
      [new Vector2(0, 0), skyboxAtlas2],
      [new Vector2(0.5, 0), skyboxAtlas2],
    ];
    const skyboxConfig2: AtlasConfig[] = [
      [new Vector2(0, 0), skyboxAtlas3],
      [new Vector2(0.5, 0), skyboxAtlas3],
      [new Vector2(0, 0.5), skyboxAtlas3],
      [new Vector2(0.5, 0.5), skyboxAtlas3],
      [new Vector2(0, 0.5), skyboxAtlas2],
      [new Vector2(0.5, 0.5), skyboxAtlas2],
    ];

    function createMaterials(config: AtlasConfig[]) {
      return config.map(
        ([origin, map]) =>
          new TextureAtlasBasicMaterial({
            map,
            side: DoubleSide,
            uvCoordinates: { origin, size: new Vector2(0.5, 0.5), padding: new Vector2(2, 2) },
          })
      );
    }

    return [createMaterials(skyboxConfig1), createMaterials(skyboxConfig2)];
  }, [skyboxAtlas1, skyboxAtlas2, skyboxAtlas3]);

  const materials = useMemo(() => {
    if (type === 'galaxy') return skybox1Materials;
    return skybox2Materials;
  }, [type, skybox1Materials, skybox2Materials]);

  return (
      <e.mesh theatreKey="skybox_cube">
        <boxGeometry args={[100, 100, 100]} />
        <primitive attach="material" object={materials} />
      </e.mesh>
  );
}

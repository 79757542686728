import { useProgress } from '@react-three/drei';
import { ReactComponent as Headphones } from 'assets/images/UI/ic-headphones.svg';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { gsap, Power2 } from 'gsap';
import React, { useEffect, useRef } from 'react';

// Make sure this number is up to date!
const TOTAL_ITEMS_TO_LOAD = 24;

export default function LoadingOverlay() {
  const loaded = useProgress((i) => i.loaded);
  const progress = (loaded / TOTAL_ITEMS_TO_LOAD) * 100;
  const containerRef = useRef<HTMLDivElement>(null);
  const isDone = progress === 100;
  const pointerEvents = isDone ? 'none' : 'auto';

  useEffect(() => {
    if (!isDone) return undefined;

    const g = gsap.to(containerRef.current, {
      opacity: 0,
      duration: 2,
      delay: 2,
      ease: Power2.easeInOut,
    });

    return () => {
      g.kill();
    };
  }, [isDone]);

  return (
    <div
      ref={containerRef}
      style={{ pointerEvents }}
      className="fixed top-0 left-0 h-full w-screen flex flex-col z-30 bg-[black] items-center justify-center pointer-events-none"
    >
      <p className="capitalize font-monsterOfFantasy text-lily text-[20px] md:text-[24px] mb-1">
        upper realm loading...
      </p>
      <LoadingIndicator progress={progress} />
      <div className="absolute bottom-10 flex flex-col items-center gap-4">
        <Headphones />
        <p className="uppercase text-lily text-[12px]">use headphones for a better experience</p>
      </div>
    </div>
  );
}

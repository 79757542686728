import { Canvas } from '@react-three/fiber';
import { SheetProvider } from '@theatre/r3f';
import { APP_MAIN_SHEET } from 'main/common/constants';
import React, { memo } from 'react';

interface MainCanvasProps {
  children?: React.ReactNode;
}

function MainCanvas(props: MainCanvasProps) {
  return (
    <div className="fixed h-screen w-screen top-0 left-0">
      <Canvas
        gl={{
          preserveDrawingBuffer: true,
          powerPreference: 'high-performance',
        }}
        camera={{
          position: [5, 5, -5],
          fov: 75,
        }}
      >
        {/* <Stats /> */}
        <SheetProvider sheet={APP_MAIN_SHEET}>{props.children}</SheetProvider>
      </Canvas>
    </div>
  );
}

export default memo(MainCanvas);

import { ReactComponent as PaginationContainer } from 'assets/images/UI/pagination-container.svg';
import { OVERLAYS } from 'common/constants';
import { gsap } from 'gsap';
import useSwipe from 'hooks/useSwipe';
import React, { useEffect, useRef, useState } from 'react';
import { OverlayElement } from 'utils/types';

import QuickMenuCarouselItem from './QuickMenuCarouselItem';

interface QuickMenuCarouselInterface {
  onItemSelect: (item: OverlayElement) => void;
}

export default function QuickMenuCarousel(props: QuickMenuCarouselInterface) {
  const { onItemSelect } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const QuickMenuItems = OVERLAYS.filter((o) => !!o.quickMenuImage);
  const swipeHandlers = useSwipe({
    onSwipedLeft: () => {
      if (activeIndex === QuickMenuItems.length - 1) return;
      setActiveIndex(activeIndex + 1);
    },
    onSwipedRight: () => {
      if (activeIndex === 0) return;
      setActiveIndex(activeIndex - 1);
    },
  });

  function onItemClicked(index: number, item: OverlayElement) {
    return () => {
      if (index === activeIndex) {
        onItemSelect(item);
      } else {
        setActiveIndex(index);
      }
    };
  }

  useEffect(() => {
    const g = gsap.to(containerRef.current, {
      translateX: `${-25 * activeIndex}%`,
      duration: 0.5,
      ease: 'inOut',
    });

    return () => {
      g.kill();
    };
  }, [activeIndex]);

  return (
    <div className="w-screen h-full flex flex-row justify-end" {...swipeHandlers}>
      <div className="relative flex flex-row justify-start overflow-hidden">
        <div className="relative flex flex-row justify-start ml-[50vw]" ref={containerRef}>
          {QuickMenuItems.map((item, index) => (
            <QuickMenuCarouselItem
              key={item.title || ''}
              title={item.title || ''}
              isFocused={index === activeIndex}
              circleVariant={index}
              onClick={onItemClicked(index, item)}
            >
              <img
                src={item.quickMenuImage}
                alt="Quick menu item"
                className="absolute rounded-full h-full top-0 z-0 inset-x-0 mx-auto"
              />
            </QuickMenuCarouselItem>
          ))}
        </div>
        <div className="absolute bottom-[124px] -translate-x-[50%] pl-[100%]">
          <div className="relative">
            <PaginationContainer className="scale-90 md:scale-100" />
            <p className="absolute inset-0 m-auto text-center text-lily text-footer md:text-ch flex flex-row justify-center items-center">
              <span className="text-white">{activeIndex + 1}</span>/{QuickMenuItems.length}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

import blueLight from 'assets/images/UI/blue-light.png';
import { ReactComponent as Indicator } from 'assets/images/UI/indicator.svg';
import QuickMenuCarousel from 'components/QuickMenuCarousel/QuickMenuCarousel';
import React from 'react';
import { OverlayElement } from 'utils/types';

interface QuickMenuProps {
  open: boolean;
  onItemSelect: (item: OverlayElement) => void;
}

export default function QuickMenu(props: QuickMenuProps) {
  const { open, onItemSelect } = props;

  return (
    <div
      className={`fixed transition-opacity duration-300 h-full bg-[black] flex flex-col justify-end items-center z-10 ${
        open ? 'opacity-1' : 'opacity-0 pointer-events-none duration-[2000ms]'
      }`}
    >
      <QuickMenuCarousel onItemSelect={onItemSelect} />
      <img
        src={blueLight}
        alt="Blue light background"
        className="absolute top-0 pointer-events-none rotate-180 scale-50 -translate-y-[25%] min-w-[700px] -z-10"
      />
      <img src={blueLight} alt="" className="absolute bottom-0 pointer-events-none min-w-[700px]" />
      <Indicator className="mt-[20px] md:mt-[60px] 2xl:mt-[80px]" />
    </div>
  );
}

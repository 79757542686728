import { Html } from '@react-three/drei';
import { editable as e } from '@theatre/r3f';
import { gsap } from 'gsap';
import { useDispatchEvent } from 'hooks/eventDispatcher';
import { ReactComponent as Hotspot } from 'main/assets/images/ui/hotpoint.svg';
import { StationContext } from 'main/store/StationProvider';
import React, { Suspense, memo, useContext, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Object3D, Vector2 } from 'three';
import { GlobalEventType } from 'utils/types';

interface StationProps {
  model: { scene: Object3D };
  name: string;
  theaterKey: string;
  index: number;
  bounces?: boolean;
  defaultRotationOffset?: number;
}

const bounceDuration = 3.5;
const bounceOffset = new Vector2(-0.05, 0.15);

function Station(props: StationProps) {
  const { model, theaterKey, name, index, bounces, defaultRotationOffset } = props;
  const [showLabel, setShowLabel] = useState(isMobile);
  const [containerGroup, setContainerGroup] = useState<Object3D | null>(null);
  const [groupRef, setGroupRef] = useState<Object3D | null>(null);
  const label = useRef(null);

  useEffect(() => {
    if (!containerGroup || !bounces) return undefined;

    const gHandle = gsap.fromTo(
      containerGroup.position,
      { y: bounceOffset.x },
      {
        y: bounceOffset.y,
        duration: bounceDuration,
        ease: 'sine.inOut',
        yoyo: true,
        repeat: -1,
        delay: Math.random() * bounceDuration,
      }
    );

    return () => {
      gHandle.kill();
    };
  }, [containerGroup, bounces]);

  const { activeStation, setActiveStation } = useContext(StationContext);

  const dispatchAnimateToStation = useDispatchEvent(() => ({
    type: GlobalEventType.animateToStation,
    object: groupRef!,
    defaultRotationOffset,
  }));

  useEffect(() => {
    if (activeStation === index) {
      dispatchAnimateToStation();
    }
  }, [activeStation, dispatchAnimateToStation, groupRef, index, defaultRotationOffset]);

  function onHover() {
    if (isMobile) return;
    setShowLabel(true);
  }

  function onLeave() {
    if (isMobile) return;
    setShowLabel(false);
  }

  function onClick() {
    setActiveStation(index);
  }

  useEffect(() => {
    if (!label.current) return undefined;
    const ctx = gsap.context(() => {
      gsap.to(label.current, { opacity: showLabel ? 1.0 : 0.0, duration: 0.3 });
    });
    return () => ctx.kill();
  }, [showLabel]);

  return (
    <Suspense>
      <group ref={setContainerGroup}>
        <e.group ref={setGroupRef} theatreKey={theaterKey}>
          <primitive object={model.scene} />
          <e.group theatreKey={`${theaterKey}-hotspot`}>
            <Html center>
              <div className="select-none flex flex-row items-center">
                <Hotspot
                  onMouseOver={onHover}
                  onClick={onClick}
                  onMouseLeave={onLeave}
                  className="opacity-50 hover:opacity-100 cursor-pointer w-[40px] h-[40px] active:scale-90"
                />
                <div
                  ref={label}
                  className={`${
                    !isMobile ? 'opacity-0' : 'opacity-100'
                  } bg-white font-altform text-blue rounded-[60px] px-[7px] py-[5px] whitespace-nowrap uppercase text-[9px] leading-[11px]`}
                >
                  {name}
                </div>
              </div>
            </Html>
          </e.group>
        </e.group>
      </group>
    </Suspense>
  );
}

export default memo(Station);

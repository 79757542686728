import BACKGROUND_MUSIC from 'assets/audio/genesis-OST.mp3';
import NARRATION_1 from 'assets/audio/narration/VO-1-sisters.mp3';
import NARRATION_2 from 'assets/audio/narration/VO-2-mitria.mp3';
import NARRATION_3 from 'assets/audio/narration/VO-3-shadows_loom.mp3';
import NARRATION_4 from 'assets/audio/narration/VO-4-falling.mp3';
import NARRATION_5 from 'assets/audio/narration/VO-5-phoenix.mp3';
import NARRATION_6 from 'assets/audio/narration/VO-6-legendary-items.mp3';
import NARRATION_7 from 'assets/audio/narration/VO-7-final.mp3';
import SFX_CAROUSEL from 'assets/audio/sfx/sfx-carousel.mp3';
import SFX_MITRIA_REVEAL from 'assets/audio/sfx/sfx-mitria-reveal.mp3';
import { dispatchEvent } from 'hooks/eventDispatcher';
import MITTARIA_SONG from 'main/assets/audio/mittaria-song1.mp3';

import { GlobalEventType } from './types';

export const MUTE_KEY = 'mute_key';

const AudioSource = {
  narration1: new Audio(NARRATION_1),
  narration2: new Audio(NARRATION_2),
  narration3: new Audio(NARRATION_3),
  narration4: new Audio(NARRATION_4),
  narration5: new Audio(NARRATION_5),
  narration6: new Audio(NARRATION_6),
  narration7: new Audio(NARRATION_7),
  backgroundMusic: new Audio(BACKGROUND_MUSIC),
  sfxCarousel: new Audio(SFX_CAROUSEL),
  sfxMitriaReveal: new Audio(SFX_MITRIA_REVEAL),
  mittariaSong: new Audio(MITTARIA_SONG),
};

AudioSource.backgroundMusic.volume = 0.3;
AudioSource.mittariaSong.loop = true;

function setMute(mute: boolean) {
  internalIsMuted = mute;
  localStorage.setItem(MUTE_KEY, String(mute));
  dispatchEvent({ type: GlobalEventType.setMute, message: mute });

  Object.values(AudioSource).forEach((audio) => {
    audio.muted = internalIsMuted;
  });
}

function play(audio: HTMLAudioElement, resume = false) {
  if (!resume) audio.currentTime = 0;
  audio.play();
}

function pause(audio: HTMLAudioElement) {
  audio.pause();
}

let internalIsMuted = false;
function isMuted() {
  return internalIsMuted;
}

setMute(isMuted());

export { AudioSource, setMute, play, pause, isMuted };

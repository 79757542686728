import CarouselButton from 'components/CarouselButton/CarouselButton';
import { TEAM } from 'components/TeamCarousel/TeamCarousel.constants';
import { gsap } from 'gsap';
import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import TeamCarouselItem from './TeamCarouselItem';

const ITEM_WIDTH = 451;
const CONTAINER_PADDING = 60;

export default function TeamCarousel() {
  const [offsetCount, setOffsetCount] = useState(0);
  const innerContainerRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const containerWidthWithoutPadding = containerWidth - CONTAINER_PADDING;
  const elementsVisible = useMemo(() => Math.round(containerWidth / ITEM_WIDTH), [containerWidth]);

  useLayoutEffect(() => {
    const duration = elementsVisible * 0.5;
    gsap.to(innerContainerRef.current, {
      translateX: -containerWidthWithoutPadding * offsetCount,
      duration,
      ease: 'inOut',
    });
  }, [containerWidthWithoutPadding, elementsVisible, offsetCount]);

  const onArrowClick = useCallback(
    (direction: 'left' | 'right') => () => {
      setOffsetCount((prevIndex) => prevIndex + (direction === 'left' ? -1 : 1));
    },
    []
  );

  function setContainerRef(ref: HTMLDivElement) {
    if (!ref || ref.offsetWidth === containerWidth) return;

    setContainerWidth(ref.offsetWidth);
  }

  function setInnerContainerRef(ref: HTMLDivElement) {
    innerContainerRef.current = ref;
  }

  let leftButtonExtraClass = 'opacity-100';
  let leftButtonDisabled = false;
  if (offsetCount === 0) {
    leftButtonExtraClass = 'opacity-0 translate-y-8';
    leftButtonDisabled = true;
  }
  let rightButtonExtraClass = 'opacity-100';
  let rightButtonDisabled = false;

  const elementsOffset = offsetCount * elementsVisible;
  if (elementsOffset + elementsVisible >= TEAM.length) {
    rightButtonExtraClass = 'opacity-0 translate-y-8 none';
    rightButtonDisabled = true;
  }

  return (
    <div
      // number of items * 331px + (number of items + 1) * gap
      className="relative w-[451px] xl:w-[1233px] 2xl:w-[1624px]"
      ref={setContainerRef}
    >
      <div
        className={`absolute transform-all ease-in-out duration-1000 left-[29px] md:-left-[42px] top-[323px] md:top-[290px] z-10 scale-50 md:scale-100 ${leftButtonExtraClass}`}
      >
        <CarouselButton onClick={onArrowClick('left')} disabled={leftButtonDisabled} />
      </div>
      <div className="w-full h-full py-[180px] masked-overflow">
        <div
          className="flex flex-row w-full h-full"
          style={{
            paddingLeft: CONTAINER_PADDING,
            paddingRight: CONTAINER_PADDING,
            gap: CONTAINER_PADDING,
          }}
          ref={setInnerContainerRef}
        >
          {TEAM.map((item) => (
            <TeamCarouselItem key={item.id} {...item} />
          ))}
        </div>
      </div>
      <div
        className={`absolute transform-all ease-in-out duration-1000 right-[29px] md:-right-[42px] top-[323px] md:top-[290px] z-10 scale-50 md:scale-100 ${rightButtonExtraClass}`}
      >
        <CarouselButton
          onClick={onArrowClick('right')}
          direction="right"
          disabled={rightButtonDisabled}
        />
      </div>
    </div>
  );
}

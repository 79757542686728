/* eslint-disable jsx-a11y/label-has-associated-control */
import { zodResolver } from '@hookform/resolvers/zod';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';
import { countries } from 'common/countries';
import { providers } from 'ethers';
import TermsAndConsModal from 'pages/Legals/TermsAndConsModal';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Controller, useForm } from 'react-hook-form';
import { Toaster, toast } from 'sonner';
import { z } from 'zod';

export const WTG_FORM_ROUTE_API = process.env.REACT_APP_WTG_BACKEND_URL as string;

const formSchema = z.object({
  fullName: z.string().min(1, {
    message: 'Your full name is required',
  }),
  address1: z.string().min(2, {
    message: 'Your address is required',
  }),
  address2: z.string().min(2, {
    message: 'Your address 2 is required',
  }),
  city: z.string().min(2, {
    message: 'Your City is required',
  }),
  region: z.string().min(2, {
    message: 'Your Region/State/Province is required',
  }),
  postalCode: z.string().min(2, {
    message: 'Your Postal Code is required',
  }),
  country: z.string(),
  commercial: z.string().min(2, {
    message: 'Your commercial intentions are required',
  }),
  support: z.string(),
  txHash: z
    .string()
    .min(2, {
      message: 'Your transaction hash is required',
    })
    .refine((value) => /^0x/.test(value), {
      message: 'Transaction hash must start with 0x',
    }),
});

export default function WTGRegistrationForm() {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [termsRead, setTermsRead] = React.useState(false);

  function handleModalOpen() {
    setIsModalOpen(true);
  }

  function handleModalClose() {
    setIsModalOpen(false);
  }

  function handleReadTerm() {
    setTermsRead(prevState => !prevState);
  }

  async function onSignMessage() {
    if (isConnected && walletProvider !== undefined) {
      const provider = new providers.Web3Provider(walletProvider);
      const signer = await provider.getSigner();
      if (signer) {
        const message = JSON.stringify({
          address,
          chainId,
          date: new Date().toISOString(),
        });
        const signature = await signer.signMessage(message);
        return { signature, message };
      }
    }
    return null;
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      address1: '',
      address2: '',
      city: '',
      region: '',
      postalCode: '',
      country: 'Thailand',
      commercial: '',
      support: '',
      txHash: '',
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {

    if (!termsRead) {
      setIsModalOpen(true);
      return;
    }

    try {
      const { signature, message } = (await onSignMessage()) || { signature: '', message: '' };

      if (signature) {
        const response = await fetch(WTG_FORM_ROUTE_API, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          cache: 'no-store',
          body: JSON.stringify({
            fullName: values.fullName,
            address1: values.address1,
            address2: values.address2,
            city: values.city,
            region: values.region,
            postalCode: values.postalCode,
            country: values.country,
            commercial: values.commercial,
            support: values.support,
            txHash: values.txHash,
            signature,
            address,
            message,
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error);
        }

        toast.success('Form submitted successfully!');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      toast.error(
        `An error occurred while submitting the form: ${(error as Error).message}` as string
      ); // display the server error message in the toast
    }
  }

  return (
    <>
      <Toaster
        position={isMobile ? 'top-center' : 'bottom-right'}
        toastOptions={{
          classNames: {
            error: 'bg-[#121211] mb-8 text-white border-2 border-[#CC4E4A]/70',
            success: 'bg-[#121211] mb-8 text-white border-2 border-[#CC4E4A]/70',
            warning: 'bg-[#121211] mb-8 text-white border-2 border-[#CC4E4A]/70',
            info: 'bg-[#121211] mb-8 text-white border-2 border-[#CC4E4A]/70',
          },
        }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex text-xs flex-col gap-3 md:gap-0 max-w-screen-md mx-auto pl-5">
          <div className="grid md:grid-cols-4 grid-cols-1 my-5 md:mb-2 h-[2.5rem]">
            <label className="text-center md:text-end mr-5" htmlFor="fullName">
              Full Name*
            </label>
            <Controller
              name="fullName"
              control={control}
              defaultValue=""
              rules={{ required: 'Full Name is required' }}
              render={function ({ field }) {
                return (
                  <input
                    {...field}
                    className=" col-span-3 px-4 focus:outline-white min-h-[2rem] text-[#030303] backdrop-blur-lg rounded-lg backdrop-opacity-10 bg-[#E1DDDC]/80"
                    type="text"
                    id="fullName"
                  />
                );
              }}
            />
            {errors.fullName && (
              <span className="col-span-3 text-center md:col-start-2 md:col-end-4 text-red-300 md:text-start">
                {errors.fullName.message}
              </span>
            )}
          </div>
          <div className="grid md:grid-cols-4 grid-cols-1 my-5 md:mb-2 h-[2.5rem]">
            <label className="text-center md:text-end mr-5" htmlFor="address1">
              Address 1*
            </label>
            <Controller
              name="address1"
              control={control}
              defaultValue=""
              rules={{ required: 'Your Address is required' }}
              render={function ({ field }) {
                return (
                  <input
                    className=" col-span-3 px-4 focus:outline-white min-h-[2rem] text-[#030303] backdrop-blur-lg rounded-lg backdrop-opacity-10 bg-[#E1DDDC]/80"
                    type="text"
                    id="address1"
                    {...register('address1')}
                  />
                );
              }}
            />
            {errors.address1 && (
              <span className="col-span-3 text-center md:col-start-2 md:col-end-4 text-red-300 md:text-start">
                {errors.address1.message}
              </span>
            )}
          </div>
          <div className="grid md:grid-cols-4 grid-cols-1 my-5 md:mb-2 h-[2.5rem]">
            <label className="text-center md:text-end mr-5" htmlFor="address2">
              Address 2*
            </label>
            <Controller
              name="address2"
              control={control}
              defaultValue=""
              rules={{ required: 'Your address 2 is required' }}
              render={function ({ field }) {
                return (
                  <input
                    {...field}
                    className=" col-span-3 px-4 focus:outline-white min-h-[2rem] text-[#030303] backdrop-blur-lg rounded-lg backdrop-opacity-10 bg-[#E1DDDC]/80"
                    type="text"
                    id="address2"
                    {...register('address2')}
                  />
                );
              }}
            />
            {errors.address2 && (
              <span className="col-span-3 text-center md:col-start-2 md:col-end-4 text-red-300 md:text-start">
                {errors.address2.message}
              </span>
            )}
          </div>
          <div className="grid md:grid-cols-4 grid-cols-1 my-5 md:mb-2 h-[2.5rem]">
            <label className="text-center md:text-end mr-5" htmlFor="city">
              City*
            </label>
            <Controller
              name="city"
              control={control}
              defaultValue=""
              rules={{ required: 'Your city is required' }}
              render={function ({ field }) {
                return (
                  <input
                    {...field}
                    className=" col-span-3 px-4 focus:outline-white min-h-[2rem] text-[#030303] backdrop-blur-lg rounded-lg backdrop-opacity-10 bg-[#E1DDDC]/80"
                    type="text"
                    id="city"
                    {...register('city')}
                  />
                );
              }}
            />
            {errors.city && (
              <span className="col-span-3 text-center md:col-start-2 md:col-end-4 text-red-300 md:text-start">
                {errors.city.message}
              </span>
            )}
          </div>
          <div className="grid md:grid-cols-4 grid-cols-1 my-5 md:mb-2 h-[2.5rem]">
            <label className="text-center md:text-end mr-5" htmlFor="region">
              Region*
            </label>
            <Controller
              name="region"
              control={control}
              defaultValue=""
              rules={{ required: 'Your Region is required' }}
              render={function ({ field }) {
                return (
                  <input
                    {...field}
                    className=" col-span-3 px-4 focus:outline-white min-h-[2rem] text-[#030303] backdrop-blur-lg rounded-lg backdrop-opacity-10 bg-[#E1DDDC]/80"
                    type="text"
                    id="region"
                    {...register('region')}
                  />
                );
              }}
            />
            {errors.region && (
              <span className="col-span-3 text-center md:col-start-2 md:col-end-4 text-red-300 md:text-start">
                {errors.region.message}
              </span>
            )}
          </div>
          <div className="grid md:grid-cols-4 grid-cols-1 my-5 md:mb-2 h-[2.5rem]">
            <label className="text-center md:text-end mr-5" htmlFor="postalCode">
              Postal Code*
            </label>
            <Controller
              name="postalCode"
              control={control}
              defaultValue=""
              rules={{ required: 'Your Postal Code is required' }}
              render={function ({ field }) {
                return (
                  <input
                    {...field}
                    className=" col-span-3 px-4 focus:outline-white min-h-[2rem] text-[#030303] backdrop-blur-lg rounded-lg backdrop-opacity-10 bg-[#E1DDDC]/80"
                    type="text"
                    id="postalCode"
                    {...register('postalCode')}
                  />
                );
              }}
            />
            {errors.postalCode && (
              <span className="col-span-3 text-center md:col-start-2 md:col-end-4 text-red-300 md:text-start">
                {errors.postalCode.message}
              </span>
            )}
          </div>
          <div className="grid md:grid-cols-4 grid-cols-1 my-5 md:mb-2 h-[2.5rem]">
            <label className="text-center md:text-end mr-5" htmlFor="country">
              Country*
            </label>
            <Controller
              name="country"
              control={control}
              defaultValue="Thailand" // Set the default value here
              rules={{ required: 'Your Country is required' }}
              render={function ({ field }) {
                return (
                  <select
                    {...field}
                    className="col-span-3 px-4 focus:outline-white min-h-[2rem] text-[#030303] backdrop-blur-lg rounded-lg backdrop-opacity-10 bg-[#E1DDDC]/80"
                  >
                    {countries.map((country) => (
                      <option
                        selected={country.name === 'Thailand'}
                        key={country.id}
                        value={country.name}
                      >
                        {country.emoji} {country.name}
                      </option>
                    ))}
                  </select>
                );
              }}
            />
            {errors.country && (
              <span className="col-span-3 text-center md:col-start-2 md:col-end-4 text-red-300 md:text-start">
                {errors.country.message}
              </span>
            )}
          </div>
          <div className="grid md:grid-cols-4 grid-cols-1 my-5 md:mb-2 h-[2.5rem]">
            <label className="text-center md:text-end mr-5" htmlFor="commercial">
              Commercial Intentions*
            </label>
            <Controller
              name="commercial"
              control={control}
              defaultValue=""
              rules={{ required: 'Your Commercial Intentions is required' }}
              render={function ({ field }) {
                return (
                  <input
                    {...field}
                    className=" col-span-3 px-4 focus:outline-white min-h-[2rem] text-[#030303] backdrop-blur-lg rounded-lg backdrop-opacity-10 bg-[#E1DDDC]/80"
                    type="text"
                    id="commercial"
                    {...register('commercial')}
                  />
                );
              }}
            />
            {errors.commercial && (
              <span className="col-span-3 text-center md:col-start-2 md:col-end-4 text-red-300 md:text-start">
                {errors.commercial.message}
              </span>
            )}
          </div>
          <div className="grid md:grid-cols-4 grid-cols-1 my-5 md:mb-2 h-[2.5rem]">
            <label className="text-center md:text-end mr-5" htmlFor="support">
              Support Needed From Mittaria
            </label>
            <Controller
              name="support"
              control={control}
              defaultValue=""
              rules={{ required: 'Your Support Intentions is required' }}
              render={function ({ field }) {
                return (
                  <input
                    {...field}
                    className=" col-span-3 px-4 focus:outline-white min-h-[2rem] text-[#030303] font-se backdrop-blur-lg rounded-lg backdrop-opacity-10 bg-[#E1DDDC]/80"
                    type="text"
                    id="support"
                    {...register('support')}
                  />
                );
              }}
            />
            {errors.support && (
              <span className="col-span-3 text-center md:col-start-2 md:col-end-4 text-red-300 md:text-start">
                {errors.support.message}
              </span>
            )}
          </div>
          <div className="grid md:grid-cols-4 grid-cols-1 my-5 md:mb-2 h-[2.5rem]">
            <label className="text-center md:text-end mr-5" htmlFor="txHash">
              Licensing Fee Transfer 0.001 ETH*
            </label>
            <Controller
              name="txHash"
              control={control}
              defaultValue=""
              rules={{ required: 'Your Transaction Hash is required' }}
              render={function ({ field }) {
                return (
                  <input
                    className=" col-span-3 px-4 focus:outline-white min-h-[2rem] placeholder-black/30 text-[#030303] font-altform backdrop-blur-lg rounded-lg backdrop-opacity-10 bg-[#E1DDDC]/80"
                    type="text"
                    id="txHash"
                    placeholder="0x12345..."
                    {...register('txHash')}
                  />
                );
              }}
            />
            {errors.txHash && (
              <span className="col-span-3 text-center md:col-start-2 md:col-end-4 text-red-300 md:text-start">
                {errors.txHash.message}
              </span>
            )}
          </div>
          {isConnected && (
              <>
                {!isModalOpen && (
                  <button className={`text-white col-span-4 ${errors ? "mt-5" : "mt-3"} underline`} type="button" onClick={handleModalOpen}>
                    Please read our Terms and Conditions before submission
                  </button>
                )}

                {isModalOpen && (
                  <TermsAndConsModal read={termsRead} handleReadTerm={handleReadTerm} show={isModalOpen} setCloseModal={handleModalClose} />
                )}
              </>
            )}
        </div>
        {!isConnected && (
          <h2 className="flex place-content-center text-center">
            Please Connect your wallet first to submit the form
          </h2>
        )}
        <div className={`flex place-content-center ${!isConnected ? 'mt-5' : 'mt-6'} w-full`}>
          <button
            disabled={!isConnected}
            className={`border ${
              !isConnected && 'opacity-40 cursor-not-allowed mr-5 text-[#636161'
            } border-white text-white backdrop-blur-lg rounded-lg backdrop-opacity-10 bg-[#E1DDDC]/20 px-36 rounded-md py-2 hover:bg-blue-500 hover:text-white transition duration-200 ease-in-out`}
            type="submit"
          >
            <span className="font-monsterOfFantasy">Submit</span>
          </button>
        </div>
      </form>
    </>
  );
}
import { useGLTF } from '@react-three/drei';
import { editable as e } from '@theatre/r3f';
import MODEL_TEXT from 'assets/models/glb/Text_v3.glb';
import useScrollProgress from 'hooks/useScrollProgress';
import React, { useMemo } from 'react';
import { AdditiveBlending, Color, MeshStandardMaterial } from 'three';
import { getOpacityForActiveWindow } from 'utils/three.utils';

const TEXT_VISIBLE_WINDOW = {
  start: 0.72,
  end: 0.95,
  fade: 0.04,
};

export default function Text3D() {
  const text = useGLTF(MODEL_TEXT);

  const materials = useMemo(
    () =>
      // @ts-ignore
      Object.values(text.materials).map((mat: MeshStandardMaterial) => {
        mat.transparent = true;
        mat.opacity = 0;
        mat.color = new Color('#ffffff');
        mat.needsUpdate = true;
        mat.blending = AdditiveBlending;
        mat.metalness = 0.3;

        return mat;
      }),
    // @ts-ignore
    [text.materials]
  );

  useScrollProgress((progress) => {
    const { start, end, fade } = TEXT_VISIBLE_WINDOW;
    const opacity = getOpacityForActiveWindow(progress, start - fade, start, end, end + fade);
    materials.forEach((mat: MeshStandardMaterial) => {
      mat.opacity = opacity;
    });
  });

  return (
    <>
      <e.directionalLight theatreKey="textLight" />
      <primitive object={text.scene} scale={[100, 100, 100]} />
    </>
  );
}

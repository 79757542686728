import { ANIMATION_SKIP, NO_AUTOPLAY_WINDOW } from 'common/constants';
import { Linear, gsap } from 'gsap';
import { useCallback, useLayoutEffect } from 'react';
import { InteractionBreak } from 'utils/types';

import useLatest from './useLatest';

const EASING_DURATION = 5.0; // sec
const AUTOPLAY_DURATION = 150;

const initialBreak: InteractionBreak | undefined = undefined;

function createScrollHandler() {
  const progressCallbacksMap = new Map<Function, Function>();
  const autoplayStatusCallbacksMap = new Map<Function, Function>();
  const state = {
    isReady: false,
    animation: 0,
    _isInAutoplay: true,
    get isInAutoplay() {
      return this._isInAutoplay;
    },
    set isInAutoplay(value: boolean) {
      this._isInAutoplay = value;
      autoplayStatusCallbacksMap.forEach((callback) => callback(value));
    },

    _interactionBreak: initialBreak,
    get interactionBreak() {
      return this._interactionBreak;
    },
    set interactionBreak(value: InteractionBreak | undefined) {
      if (value === this._interactionBreak) return;

      this._interactionBreak = value;
      if (value) pause();
      else resume();
    },
  };
  let autoplayAnimation: GSAPTween | undefined;

  const testDiffDiv = document.getElementById('test_progress_ovarlay');

  function getTargetAnimationValue(delta: number) {
    const targetValue = state.animation + delta;
    if (targetValue < ANIMATION_SKIP.start || targetValue > ANIMATION_SKIP.end) return targetValue;

    return delta < 0 ? ANIMATION_SKIP.start : ANIMATION_SKIP.end;
  }

  function moveScroll() {
    if (state.isInAutoplay) return;

    const scrollProgress = getTargetAnimationValue(1 / 120.0);
    if (scrollProgress > NO_AUTOPLAY_WINDOW.end) {
      state.isInAutoplay = true;
      autoplay(state.animation);
    } else
      gsap.to(state, {
        animation: scrollProgress,
        duration: EASING_DURATION / 2.0,
        onUpdate,
      });
  }

  function onUpdate() {
    progressCallbacksMap.forEach((_, callback) => callback(state.animation));
    if (testDiffDiv) {
      testDiffDiv.innerText = String(state.animation.toFixed(3));
    }
  }

  function addProgressCallback(callback: Function) {
    progressCallbacksMap.set(callback, callback);
  }

  function removeProgressCallback(callback: Function) {
    progressCallbacksMap.delete(callback);
  }

  function addAutoplayStatusCallback(callback: Function) {
    autoplayStatusCallbacksMap.set(callback, callback);
  }

  function removeAutoplayStatusCallback(callback: Function) {
    autoplayStatusCallbacksMap.delete(callback);
  }

  function autoplay(startProgress = 0.0, force?: boolean) {
    if (force) state.isReady = true;
    if (!state.isReady) return;

    if (startProgress >= ANIMATION_SKIP.start && startProgress <= ANIMATION_SKIP.end)
      startProgress = ANIMATION_SKIP.end;
    state.animation = startProgress;
    const duration = AUTOPLAY_DURATION * ((1.0 - startProgress) / 1.0);

    autoplayAnimation = gsap.to(state, {
      animation: 1.0,
      duration,
      ease: Linear.easeNone,
      onUpdate,
    });
  }

  function jumpTo(progress: number) {
    autoplayAnimation?.kill();

    // Only autoplay at the beginning
    state.isInAutoplay = progress === 0.0;
    if (state.isInAutoplay) {
      autoplay(progress);
      return;
    }

    state.animation = progress;
    onUpdate();
  }

  function isInAutoplay() {
    return state.isInAutoplay;
  }

  function pause() {
    if (state.interactionBreak) {
      state.isInAutoplay = false;
      const direction = Math.sign(state.interactionBreak.start - state.animation);
      autoplayAnimation?.kill();
      
      // Otherwise this can trigger when using jumpTo
      if (Math.abs(state.interactionBreak.start - state.animation) > 0.001)
        gsap.to(state, {
          animation:
            state.interactionBreak.start + state.interactionBreak.activationDelta * direction,
          duration: 5,
          onUpdate,
        });
    }
  }
  function resume() {
    if (state.animation > NO_AUTOPLAY_WINDOW.start && state.animation < NO_AUTOPLAY_WINDOW.end)
      return;
    autoplay(state.animation);
  }

  function setInteractionBreak(interactionBreak?: InteractionBreak) {
    state.interactionBreak = interactionBreak;
  }

  return {
    state,
    moveScroll,
    addAutoplayStatusCallback,
    removeAutoplayStatusCallback,
    addProgressCallback,
    removeProgressCallback,
    autoplay,
    jumpTo,
    isInAutoplay,
    setInteractionBreak,
  };
}

let scrollHandler: ReturnType<typeof createScrollHandler> | undefined;

export default function useScrollProgress(
  progressCallback?: (progress: number) => void,
  autoplayCallback?: (autoplayActive: boolean) => void
) {
  const latestProgressCallback = useLatest(progressCallback);
  const latestAutoplayCallback = useLatest(autoplayCallback);

  useLayoutEffect(() => {
    if (!scrollHandler) scrollHandler = createScrollHandler();

    function handleProgressCallback(progress: number) {
      if (latestProgressCallback.current) latestProgressCallback.current(progress);
    }
    function handleAutoplayCallback(autoplayActive: boolean) {
      if (latestAutoplayCallback.current) latestAutoplayCallback.current(autoplayActive);
    }

    scrollHandler.addProgressCallback(handleProgressCallback);
    scrollHandler.addAutoplayStatusCallback(handleAutoplayCallback);

    return () => {
      scrollHandler?.removeProgressCallback(handleProgressCallback);
      scrollHandler?.removeAutoplayStatusCallback(handleAutoplayCallback);
    };
  }, [latestProgressCallback, latestAutoplayCallback]);

  const startAutoplay = useCallback(() => {
    if (!scrollHandler) return undefined;

    return scrollHandler.autoplay(0, true);
  }, []);

  const jumpTo = useCallback((progress: number) => {
    if (!scrollHandler) return undefined;

    return scrollHandler.jumpTo(progress);
  }, []);

  const setInteractionBreak = useCallback((interactionBreak: InteractionBreak | undefined) => {
    if (!scrollHandler) return undefined;

    return scrollHandler.setInteractionBreak(interactionBreak);
  }, []);

  const isInAutoplay = useCallback(() => {
    if (!scrollHandler) return true;

    return scrollHandler.isInAutoplay();
  }, []);

  const moveScroll = useCallback(() => {
    if (!scrollHandler) return undefined;
    return scrollHandler.moveScroll();
  }, []);

  const getAnimationProgress = useCallback(() => {
    if (!scrollHandler) return 0;

    return scrollHandler.state.animation;
  }, []);

  return {
    startAutoplay,
    jumpTo,
    isInAutoplay,
    moveScroll,
    setInteractionBreak,
    getAnimationProgress,
  };
}

import { useCallback, useEffect } from 'react';
import { EventDispatcher } from 'three';
import { GlobalEvent, GlobalEventType } from 'utils/types';

import useLatest from './useLatest';

export const dispatcher = new EventDispatcher();

export function dispatchEvent(event: GlobalEvent) {
  dispatcher.dispatchEvent(event);
}

export function useListenToEvent<T extends GlobalEvent>(
  eventId: GlobalEventType,
  listener: (event: T) => void
) {
  const currentListener = useLatest(listener);

  useEffect(() => {
    function callCurrentListener(event: T) {
      currentListener.current(event);
    }

    // @ts-ignore
    dispatcher.addEventListener(eventId, callCurrentListener);
    // @ts-ignore
    return () => dispatcher.removeEventListener(eventId, callCurrentListener);
  }, [eventId, currentListener]);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDispatchEvent<T>(callback: (...args: any[]) => GlobalEvent) {
  const latestCallback = useLatest(callback);

  return useCallback(
    (args?: T) => dispatcher.dispatchEvent(latestCallback.current(args)),
    [latestCallback]
  );
}

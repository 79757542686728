import { gsap } from 'gsap';
import { useDispatchEvent, useListenToEvent } from 'hooks/eventDispatcher';
import { ReactComponent as ZoomOutIcon } from 'main/assets/images/scene-ui/minus-zoom.svg';
import { ReactComponent as ZoomInIcon } from 'main/assets/images/scene-ui/plus-zoom.svg';
import React from 'react';
import { DispatchZoomChanged, GlobalEventType } from 'utils/types';

interface SceneZoomProps extends React.HTMLAttributes<HTMLDivElement> {}

export default function SceneZoom(props: SceneZoomProps) {
  const { className } = props;

  const dispatchZoomIn = useDispatchEvent(() => ({
    type: GlobalEventType.zoomIn,
  }));

  const dispatchZoomOut = useDispatchEvent(() => ({
    type: GlobalEventType.zoomOut,
  }));

  function onZoomIn() {
    dispatchZoomIn();
  }

  function onZoomOut() {
    dispatchZoomOut();
  }

  useListenToEvent(GlobalEventType.zoomChanged, (event: DispatchZoomChanged) => {
    gsap.to('#scroll-indicator', {
      scaleY: 1.0 - event.zoom,
      duration: 0.25,
    });
  });

  return (
    <div
      className={`relative h-[100px] flex flex-col justify-center items-center px-8 ${
        className || ''
      }`}
    >
      <button
        type="button"
        className="flex-0 cursor-pointer select-none opacity-75"
        onClick={onZoomOut}
      >
        <ZoomOutIcon />
      </button>
      <div className="flex-auto min-h-[100px] w-[5px] rounded-lg my-4 bg-white opacity-75 overflow-hidden">
        <div
          id="scroll-indicator"
          className="w-full h-full bg-purple rounded-lg transform origin-bottom"
        />
      </div>
      <button type="button" className="flex-0 cursor-pointer select-none" onClick={onZoomIn}>
        <ZoomInIcon />
      </button>
    </div>
  );
}

import { useFrame } from '@react-three/fiber';
import useDisposableMemo from 'hooks/useDisposableMemo';
import useScrollProgress from 'hooks/useScrollProgress';
import useUniforms from 'hooks/useUniforms';
import React, { useMemo, useState } from 'react';
import fragmentShader from 'rendering/shaders/orbiting-line.fragment.glsl';
import vertexShader from 'rendering/shaders/orbiting-line.vertex.glsl';
import {
  Color,
  DoubleSide,
  InstancedBufferAttribute,
  InstancedBufferGeometry,
  Mesh,
  PlaneGeometry,
  ShaderMaterial,
} from 'three';
import { getOpacityForActiveWindow } from 'utils/three.utils';

interface OrbitingLineProps {
  count: number;
  speed: number;
  width: number;
  length: number;
  color?: string;
  window?: {
    start: number;
    end: number;
    startTransitionDuration: number;
    endTransitionDuration: number;
  };
}

export default function OrbitingLines(props: OrbitingLineProps) {
  const { speed, width, length, count, color = '#FFFFFF', window } = props;

  const lineColor = useMemo(() => new Color(color), [color]);
  const [orbitingLines, setOrbitingLines] = useState<Mesh | null>(null);

  const uniforms = useUniforms({
    uTime: 0,
    uSpeed: speed,
    uWidth: width,
    uLength: length,
    uColor: lineColor,
  });

  useFrame((state) => {
    uniforms.uTime.value = state.clock.elapsedTime;
  });

  const shaderMaterial = useDisposableMemo(
    () =>
      new ShaderMaterial({
        vertexShader,
        fragmentShader,
        uniforms,
        side: DoubleSide,
      }),
    [uniforms]
  );

  useScrollProgress((progress) => {
    if (!window || !orbitingLines) return;

    const { start, end, startTransitionDuration, endTransitionDuration } = window;

    if (progress > end + endTransitionDuration) return;
    const value = getOpacityForActiveWindow(
      progress,
      start - startTransitionDuration,
      start,
      end,
      end + endTransitionDuration
    );
    orbitingLines.scale.set(5.0 * value, 5.0 * value, 5.0 * value);
  });

  const instancedGeometry = useDisposableMemo(() => {
    const geometry = new InstancedBufferGeometry();
    const planeGeometry = new PlaneGeometry(width, length, 1, 10);
    geometry.index = planeGeometry.index;
    geometry.attributes = { ...planeGeometry.attributes };

    const offsetArray = new Float32Array(count * 2);

    for (let i = 0; i < count; i += 1) {
      offsetArray[i * 2] = Math.random();
      offsetArray[i * 2 + 1] = Math.random();
    }

    geometry.setAttribute('offset', new InstancedBufferAttribute(offsetArray, 2));

    return geometry;
  }, [count, width, length]);

  return (
    <mesh frustumCulled={false} ref={setOrbitingLines} rotation={[-Math.PI / 2, 0, 0]}>
      <primitive object={instancedGeometry} attach="geometry" />
      <primitive object={shaderMaterial} attach="material" />
    </mesh>
  );
}

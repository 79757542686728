import SplitText from 'main/components/SplitText';
import React from 'react';

interface TitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  titleType?: string;
  color?: string;
  noDivided?: boolean;
  children: React.ReactNode;
}

export default function Title(props: TitleProps) {
  const { children, className, titleType, noDivided, color = '#CBC3FF' } = props;

  return (
    <TitleWrapper
      color={color}
      className={`${className || ''} uppercase ${color && `text-[${color}]`}`}
      titleType={titleType}
    >
      {children && !noDivided && <SplitText textToSplit={children.toString()} />}
      {children && noDivided && children}
    </TitleWrapper>
  );
}

function TitleWrapper(props: TitleProps) {
  const { children, className, titleType, color } = props;

  const args = { className, style: { color } };

  if (titleType === 'p') return <p {...args}>{children}</p>;
  if (titleType === 'h1') return <h1 {...args}>{children}</h1>;
  if (titleType === 'h2') return <h2 {...args}>{children}</h2>;
  if (titleType === 'h3') return <h3 {...args}>{children}</h3>;
  return <h4 {...args}>{children}</h4>;
}

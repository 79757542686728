import { useEffect, useMemo } from 'react';
import { Uniform } from 'three';
/**
 * Creates the uniforms objects and makes sure that the object is never recreated. All values will
 * automatically be updated. Functions WILL ONLY BE EXECUTED ON CREATION AND NOT DURING THE UPDATE!
 * @param args
 * @returns
 */

export default function useUniforms(
  args: Record<string, unknown>,
  target?: Record<string, Uniform>
) {
  const values = Object.values(args).filter((value) => typeof value !== 'function');
  const uniforms = useMemo(() => {
    const uni: Record<string, Uniform> = target ?? {};
    Object.entries(args).forEach(([key, value]) => {
      uni[key] = new Uniform(typeof value === 'function' ? value() : value);
    });
    return uni;
    // Uniform object should never be recreated, updated the changing values in the useEffect bellow
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target]);

  useEffect(() => {
    Object.entries(args)
      .filter(([_, value]) => typeof value !== 'function')
      .forEach(([key, value]) => {
        uniforms[key].value = value;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, values);

  return uniforms;
}

import SocialIcon from 'components/SocialIcon/SocialIcon';
import React from 'react';
import { Link } from 'react-router-dom';

export default function FinalOverlay() {
  return (
    <div className="h-full flex flex-col items-center justify-end p-8 pb-4 md:pb-8 pointer-events-auto">
      <div className="text-center max-w-[983px]">
        <h4 className="bg-gradient-to-b from-violet to-light-purple text-[transparent] bg-clip-text text-p md:text-h4 leading-[80px]">
          join her quest
        </h4>
        <div className="flex flex-row justify-center items-center gap-2 md:gap-10 mb-4 md:mb-6 md:mt-8">
          <SocialIcon type="twitter" />
        </div>
        <p className="text-footer text-purple">
          © 2023 Mittaria Origin Ltd. Backed by Transclucia. All rights reserved.
        </p>
        <p className="text-footer text-purple">
          View{' '}
          <Link to="/terms-nft" className="underline">
            Terms
          </Link>{' '}
          &{' '}
          <Link to="/privacy" className="underline">
            Privacy
          </Link>
          .
        </p>
      </div>
    </div>
  );
}

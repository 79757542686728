import React from 'react';

interface WalletCheckerInputFormProps {
  walletAddress: string;
  onAddressChange: (value: string) => void;
  errorMessage?: string;
  isLoading?: boolean;
}

export default function WalletCheckerInputForm(props: WalletCheckerInputFormProps) {
  const { walletAddress, onAddressChange, errorMessage, isLoading } = props;

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    onAddressChange(event.target.value);
  }

  return (
    <div className="flex flex-1 flex-col justify-center md:justify-end items-center">
      <p className="font-monsterOfFantasy text-[24px] text-center">
        {isLoading ? (
          <>
            CHECKING YOUR WALLET ADDRESS.
            <br />
            PLEASE WAIT...
          </>
        ) : (
          <>
            ENTER YOUR WALLET ADDRESS TO
            <br />
            CHECK YOUR ELIGIBILITY
          </>
        )}
      </p>
      <input
        className="w-[505px] max-w-[80vw] h-[58px] border border-brigth-purple bg-transparent font-monsterOfFantasy text-lily text-[18px] px-4 mt-[32px] outline-none"
        value={walletAddress}
        onChange={onInputChange}
      />
      <p className="text-center font-altform text-[#FF004D] text-[18px] h-[27px] mt-2 mb-[45px]">
        {errorMessage}
      </p>
    </div>
  );
}

import { ReactComponent as FlyCircle } from 'assets/images/UI/fly-button.svg';
import useScrollProgress from 'hooks/useScrollProgress';
import React, { useEffect, useState } from 'react';

export default function FlyButton() {
  const { moveScroll } = useScrollProgress();
  const [isFlying, setIsFlying] = useState(false);

  function onHold() {
    setIsFlying(true);
  }

  function onRelease() {
    setIsFlying(false);
  }

  useEffect(() => {
    if (!isFlying) return undefined;

    const interval = setInterval(moveScroll, 1000 / 30);

    return () => clearInterval(interval);
  }, [moveScroll, isFlying]);

  return (
    <>
      <div className="uppercase text-[14px] md:text-[28px] text-violet my-[12px]">Press & hold</div>
      <button
        type="button"
        className="cursor-pointer z-50 pointer-events-auto active:scale-95 transition-opacity "
        onMouseDown={onHold}
        onMouseLeave={onRelease}
        onMouseUp={onRelease}
        onTouchStart={onHold}
        onTouchEnd={onRelease}
      >
        <FlyCircle className="md:w-[196px] md:h-[196px]" />
      </button>
    </>
  );
}

import { useTexture } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { editable as e } from '@theatre/r3f';
import useDisposableMemo from 'hooks/useDisposableMemo';
import useUniforms from 'hooks/useUniforms';
import CLOUDS_ATLAS from 'main/assets/textures/clouds.png';
import fragmentShader from 'main/rendering/shaders/clouds.fragment.glsl';
import vertexShader from 'main/rendering/shaders/clouds.vertex.glsl';
import React, { memo, useMemo } from 'react';
import {
  FrontSide,
  InstancedBufferAttribute,
  InstancedBufferGeometry,
  InstancedMesh,
  Matrix4,
  PlaneGeometry,
  ShaderMaterial,
  Vector2,
} from 'three';

import { offsets, positions, uvs, scales } from './clouds-constants';

const PARTICLE_COUNT = 500;

function Clouds() {
  const map = useTexture(CLOUDS_ATLAS);

  const uvSize = useMemo(() => new Vector2(0.5, 0.5), []);

  // const possibleUvs = useMemo(
  //   () => [new Vector2(0.0, 0.0), new Vector2(0.5, 0.5), new Vector2(0.0, 0.5)],
  //   []
  // );

  const uniforms = useUniforms({
    map,
    uTime: 0.0,
    uvSize,
    uSpeed: 0.01,
    uWidth: 10.0,
    uAlphaTest: 0.6,
  });

  const material = useDisposableMemo(
    () =>
      new ShaderMaterial({
        vertexShader,
        fragmentShader,
        uniforms,
        transparent: true,
        toneMapped: false,
        side: FrontSide,
      }),
    [uniforms]
  );

  const particleGeometry = useDisposableMemo(() => new PlaneGeometry(1, 1), []);

  const instancedGeometry = useDisposableMemo(() => {
    const geometry = new InstancedBufferGeometry();

    geometry.index = particleGeometry.index;

    geometry.setAttribute('position', particleGeometry.getAttribute('position'));
    geometry.setAttribute('uv', particleGeometry.getAttribute('uv'));

    // const vertices: number[][] = new Array(PARTICLE_COUNT);
    // const uvOrigins: number[] = [];
    // const offset: number[] = [];

    // for (let i = 0; i < PARTICLE_COUNT; i += 1) {
    //   const x = MathUtils.randFloatSpread(5);
    //   const y = MathUtils.randFloatSpread(0.5);
    //   const z = MathUtils.randFloatSpread(3);

    //   const uvOrigin = possibleUvs[MathUtils.randInt(0, 2)];

    //   vertices[i] = [x, y, z];
    //   uvOrigins.push(uvOrigin.x, uvOrigin.y);
    //   offset.push(MathUtils.randFloat(0, 1.0));
    // }

    // // This insures there's no transparency artifacts
    // vertices.sort((a, b) => (a[2] < b[2] ? -1 : 1));

    // console.log(vertices, uvOrigins, offset);

    // geometry.setAttribute(
    //   'pos',
    //   new InstancedBufferAttribute(new Float32Array(vertices.flat(1)), 3)
    // );
    // geometry.setAttribute('uvOrigin', new InstancedBufferAttribute(new Float32Array(uvOrigins), 2));
    // geometry.setAttribute('offset', new InstancedBufferAttribute(new Float32Array(offset), 1));

    geometry.setAttribute(
      'pos',
      new InstancedBufferAttribute(new Float32Array(positions.flat(1)), 3)
    );
    geometry.setAttribute('uvOrigin', new InstancedBufferAttribute(new Float32Array(uvs), 2));
    geometry.setAttribute('offset', new InstancedBufferAttribute(new Float32Array(offsets), 1));

    return geometry;
  }, []);

  const instancedMesh = useMemo(() => {
    const mesh = new InstancedMesh(instancedGeometry, material, PARTICLE_COUNT);
    const matrix = new Matrix4();

    for (let i = 0; i < PARTICLE_COUNT; i++) {
      const scale = scales[i];
      matrix.makeScale(scale, scale, 1.0);
      mesh.setMatrixAt(i, matrix);
    }
    mesh.instanceMatrix.needsUpdate = true;

    return mesh;
  }, [instancedGeometry, material]);

  useFrame((state) => {
    uniforms.uTime.value = state.clock.elapsedTime;
  });

  return (
    <e.group theatreKey="clouds">
      <primitive object={instancedMesh} />
    </e.group>
  );
}

export default memo(Clouds);

import { ReactComponent as LegalsDecoration } from 'assets/images/UI/legals-decoration.svg';
import React from 'react'

export default function GenesisTermsAndCons() {
  return (
    <div className='text-white mt-5'>
        <h2 className="text-center text-[20px] leading-0">
        Supplementary Mittaria Genesis NFT Artwork Intellectual Property License Terms and
        Conditions
      </h2>
      <LegalsDecoration className="mb-10 max-w-full mx-auto" />
      <p className='text-[16px]'>
        The Mittaria Genesis NFTs which is a collection of digital collectable tokens, which is more
        commonly known as non-fungible tokens (“Mittaria Genesis NFT”). Mittaria Genesis’s smart
        contract is deployed on the Ethereum blockchain at address
        0x8ff2e72f8faf05384aeb501ba9644c9759d2fd5f (the “Smart Contract”). Mittaria Genesis NFTs
        contain metadata which are associated with the underlying Mittaria Genesis digital images or
        art (“Artwork”). For the avoidance of doubt, Artwork shall have the meaning as given to it
        under the Mittaria NFT Terms and Conditions.
      </p>
      <p className='text-[16px]'>
        This Supplementary Mittaria Genesis Artwork Intellectual Property License Terms and
        Conditions is an agreement (this “Agreement”) which sets out the terms relating to the grant
        of a license in certain intellectual property in the Artwork. (“Licensed NFT Artwork”) For
        the purposes of this Agreement, the “Licensed NFT Artwork” refers to the Artwork (in its
        entirety) specifically, directly, and identifiably associated with the Mittaria Genesis NFT
        owned by you in totality and not any individual component of the Artwork.
      </p>
      <p className='text-[16px]'>
        This Agreement, which is a supplemental agreement to the Mittaria NFT Terms and Conditions
        (which can be accessed here <a href="https://mittaria.io/terms-nft">https://mittaria.io/terms-nft</a>, contains
        legally binding provisions, is initially entered into between:
      </p>
      <p className='text-[16px]'>
        T&amp;B Media Global (Thailand) Co. Ltd, a company incorporated under the laws of Thailand,
        company registration number 0105559126836, with registered address at No. 101 True Digital
        Park Building, 4th Floor, Room 408, Sukhumvit Road, Bang Chak, Prakanong, Bangkok 10260 (“IP
        Owner”); and
      </p>
      <p className='text-[16px]'>
        The individual or entity who is the verified/recorded owner of the Mittaria Genesis NFT and
        Token ID submitted in accordance with and who has expressly accepted the terms of this
        Agreement by completing all of the following steps:
      </p>
      <p className='text-[16px]'>
        (i) transferring licensing fee to the IP Owner, (ii) submitting the IP Owner with the
        prerequisite information, and verifying NFT ownership and, (iii) electronically agreeing to
        this Agreement by clicking agree or as otherwise specified on the website (“you”).
      </p>
      <p className='text-[16px]'>
        Purchasing a Mittaria Genesis NFT, does not automatically grant you any intellectual
        property rights or licenses to any Artwork depicted by the Mittaria Genesis NFTs.
        Accordingly, you agree to enter into this Agreement in order to be granted the right to use
        the Licensed NFT Artwork in accordance with the terms of this Agreement.
      </p>
      <p className='text-[16px]'>
        Individuals and entities who subsequently become owners of Mittaria Genesis NFTs using a
        secondary marketplace that supports creator fees may enter into this Agreement by
        electronically agreeing to this Agreement by clicking agree or as otherwise specified on the
        website, subject to the verification of ownership of one or more Mittaria Genesis NFTs
        through a process specified by the IP Owner in its sole discretion, and transferring the
        licensing fee to the IP Owner.
      </p>

      <h3>WHEREAS:</h3>
      <p className='text-[16px]'>
        A The Smart Contract maintains a record of which digital wallet is the owner of a specific
        Mittaria NFT. Accordingly, when you purchase a Mittaria Genesis NFT on a secondary
        marketplace or receive a Mittaria Genesis NFT by way of a transfer, your ownership of that
        specific Mittaria Genesis NFT is recorded by the Smart Contract. Holding a Mittaria Genesis
        NFT grants you the non-exclusive right to hold, sell, transfer and carry out blockchain
        transactions involving that Mittaria Genesis NFT (“Your Mittaria Genesis NFT”).
      </p>
      <p className='text-[16px]'>
        B. The IP Owner is not the issuer of Mittaria Genesis NFTs. The IP Owner retains absolute
        ownership of all intellectual property rights, title and interest in the Artwork, including
        but not limited to copyrights, trade marks, goodwill and other intellectual property rights.
      </p>
      <p className='text-[16px]'>
        C. You are granted a license on the terms set out in this Agreement to use the Licensed NFT
        Artwork for as long as you own the relevant Mittaria Genesis NFT. As between you and the IP
        Owner, you own all rights, title and interest in and to any modifications, revisions,
        adaptations or transformations based upon Your Mittaria Genesis NFT during the Term (as
        defined below) (“Derivative Works”), provided that (i) the IP Owner retains the ownership of
        all intellectual property rights in the Licensed NFT Artwork; or (ii) your use of any
        Derivative Works during and after the Term is subject to the terms of the License (as
        defined below)
      </p>
      <p className='text-[16px]'>You and the IP Owner agree as follows:</p>

      <h3>1. Becoming a Party to this Agreement:</h3>
      <ol type="1">
        <li>
          <p className='text-[16px]'>
            1. Connect your wallet holding the Mittaria NFT(s) to the website or other means as
            provided on the website for the purpose of verification of NFT ownership;
          </p>
        </li>
        <li>
          <p className='text-[16px]'>
            2. Pay the licensing fee by transferring 0.001 Ethereum (ETH) to the following wallet
            address of the IP Owner <span className='font-bold'>0xE4eC08c1B4Beb0080bcbE6254dA35A5DfF8108D5</span>
          </p>
        </li>
        <li>
          <p className='text-[16px]'>
            3. Provide the information as required on the website which may include name, address,
            email, wallet address, NFT Token ID, transaction hash of the licensing fee transfer; and
          </p>
        </li>
        <li>
          <p className='text-[16px]'>
            4. Click or submit acceptance to this Agreement.
          </p>
        </li>
      </ol>

      <h3>2. License</h3>

      <p className='text-[16px]'>
        2.1 Subject to the terms of this Agreement, and provided that you have acquired Your
        Mittaria Genesis NFT lawfully in accordance with the Mittaria NFT Terms and Conditions, the
        IP Owner hereby grants to you during the Term (as defined below) a copyright license, which
        is:
      </p>

      <ol type="a">
        <li>
          <p className='text-[16px]'>
            (a) non-exclusive – the IP Owner retains ownership of the copyright and/or may license
            the same right to others’
          </p>
        </li>
        <li>
          <p className='text-[16px]'>(b) non-transferrable – you cannot transfer this license to anyone;</p>
        </li>
        <li>
          <p className='text-[16px]'>(c) non-sublicensable – you cannot sub-license this license to anyone;</p>
        </li>
        <li>
          <p className='text-[16px]'>
            (d) worldwide – allowing you to use the Mittaria Genesis NFT without restrictions as to
            the territory;
          </p>
        </li>
        <li>
          <p className='text-[16px]'>
            (e) irrevocable – meaning that the IP owner is not entitled to terminate the license;
            and
          </p>
        </li>
        <li>
          <p className='text-[16px]'>(f) for as long as you hold Your Mittaria Genesis NFT;</p>
        </li>
      </ol>

      <p className='text-[16px]'>
        to use the Licensed NFT Artwork in any and all formats and media, by any and all
        technologies and means of delivery, for any and all purposes whatsoever, including the
        commercialization provided that such commercial use does not result in you earning more than
        US$50,000 (fifty thousand United States Dollars) or its equivalent in gross revenue from any
        source throughout the period of your acquisition and holding of Mittaria Genesis NFT. This
        revenue cap remains in effect even if you transfer the NFT to another wallet for which you
        hold or own the private key. Permitted uses include reproduction, distribution, preparation
        of the Derivative Works, public display, and otherwise use and exploit the Licensed NFT
        Artwork (&quot;Commercialization”) excluding Commercialization of the Licensed NFT Artwork
        in the animation, music, games, and metaverse businesses (the “License”). For the avoidance
        of doubt, the License shall grant you limited commercial use of the Licensed NFT Artwork.
      </p>
      <p className='text-[16px]'>
        2.2 If your Commercialization of the License would result in you, or any third party acting
        on your behalf, earning more than more than US$50,000 or its equivalent in gross revenue
        from any source throughout the period of your acquisition and holding of Mittaria Genesis
        NFT, you must:
      </p>

      <ol type="i">
        <li>
          <p className='text-[16px]'>
            (i) notify the IP Owner whenever you are presented with any other opportunities to
            exploit the Licensed NFT Artwork; and
          </p>
        </li>
        <li>
          <p className='text-[16px]'>(ii) obtain written consent from the IP Owner to pursue such opportunities.</p>
        </li>
      </ol>

      <p className='text-[16px]'>
        In any event, if you expect that any Commercialization of the Licensed NFT Artwork will
        exceed such threshold, you will as soon as practicable contact the IP Owner at
        <a className="underline font-bold" href="mailto:info@tandbmediaglobal.com">
          {' '}info@tandbmediaglobal.com
        </a> or other channels that the IP Owner may designate to request a
        discussion regarding the entry into a license agreement for broader commercial use.
      </p>
      <p className='text-[16px]'>
        Your continued entitlement to the License is conditional upon your compliance with this
        clause 2 and any exploitation of the Licensed NFT Artwork in breach of this clause 2 shall
        constitute willful infringement of the IP Owner’s intellectual property rights.
      </p>
      <p className='text-[16px]'>
        2.3 You agree to allow the IP Owner to, upon request with reasonable notice, audit your
        commercial use of the IP Owner IP Rights associated with the Licensed NFT Artwork. In
        connection with such audit, you agree to carry out all acts and produce all documents as
        reasonably requested by the IP Owner. Such audit shall be at the IP Owner’s own cost and
        expense.
      </p>
      <p className='text-[16px]'>
        2.4 The License is subject to, and conditional upon your continued compliance with, the
        following restrictions and qualifications:
      </p>
      <p className='text-[16px]'>
        (a) The License extends only to the Licensed NFT Artwork. Therefore, while the License
        allows you to create and exploit the Derivative Works, the License does not grant you rights
        in any individual element (such as the features, attributes, properties, or traits) of Your
        Mittaria Genesis NFT or the Licensed NFT Artwork or a license to exploit any individual
        element separate and apart from Your Mittaria Genesis NFT (such as the features, attributes,
        properties, traits and other elements of other Mittaria Genesis NFTs).
      </p>
      <p className='text-[16px]'>
        (b) The License does not grant you any rights in or to any trade names, logos, brands or
        trade marks of the IP Owner or any of its affiliates (“IP Owner Brand IP Rights”), all of
        which are expressly reserved to the IP Owner and its respective affiliates. This means that
        to the extent that the Licensed NFT Artwork contains any IP Owner Brand IP Rights, you will
        need to blur or edit out such IP Owner Brand IP Rights prior to any use of the Licensed NFT
        Artwork unless you receive additional written permission from the IP Owner . You hereby
        agree that any IP Owner Brand IP Rights you purport to acquire, together with any associated
        goodwill, shall automatically, immediately, and at your expense be assigned to the IP Owner
        or an affiliate designated by the IP Owner .
      </p>
      <p className='text-[16px]'>
        (c) The License does not grant you any rights to use the business name, tradename,
        trademark, branding of “Mittaria Genesis”, “Mittaria”, “T&amp;B Media Global” or any other
        business name of the IP Owner, business partners, and its affiliates, all of which are
        exclusively reserved to the IP Owner, business partners, and its affiliates. The License
        does not grant you any right to represent yourself as being associated with the IP Owner,
        business partners, or any of its affiliates in any capacity. Specifically, nothing contained
        in this Agreement will be construed as creating any agency, partnership, joint venture, or
        other form of joint enterprise, employment, or fiduciary relationship between you and the IP
        Owner, and neither you nor the IP Owner will have authority to contract for or bind the
        other in any manner whatsoever.
      </p>
      <p className='text-[16px]'>(d) You may not, and shall not use the Licensed NFT Artwork:</p>

      <ol type="1">
        <li>
          <p className='text-[16px]'>1) on any physical or digital tobacco, pharmaceutical or any regulated product;</p>
        </li>
        <li>
          <p className='text-[16px]'>(2) in connection with any illegal activities;</p>
        </li>
        <li>
          <p className='text-[16px]'>
            (3) to create any security, capital market products, financial instrument or derivative
            product
          </p>
        </li>
        <li>
          <p className='text-[16px]'>
            (4) on any physical or digital weapons or other items which may represent or are
            associated with war
          </p>
        </li>
        <li>
          <p className='text-[16px]'>
            (5) in a manner that expresses hate or prejudice or encourages violence towards a person
            or group based on membership in a protected class, such as race, religion, gender,
            orientation, or disability
          </p>
        </li>
        <li>
          <p className='text-[16px]'>
            (6) in connection with any images, videos, or any other forms of media or content that
            depict hatred, intolerance, involving the monarchy that is likely to violate
            lèse-majestée, politically-charged messages, or anything else that could reasonably be
            found to constitute hate speech, infringe upon the rights of others, violating laws,
            public order, or good morals; or
          </p>
        </li>
        <li>
          <p className='text-[16px]'>
            (7) in any manner which would bring the IP Owner into disrepute, or in a manner that
            violates any applicable law.
          </p>
        </li>
        <li>
          <p className='text-[16px]'>
            (8) to mint another non-fungible token using the Licensed NFT Artwork in a manner which
            competes with the IP Owner
          </p>
        </li>
        <li>
          <p className='text-[16px]'>
            (9) trademark, patent or otherwise acquire additional intellectual property rights in or
            to the Licensed NFT Artwork
          </p>
        </li>
        <li>
          <p className='text-[16px]'>(10) in any manner that breaches the Mittaria NFT Terms and Conditions</p>
        </li>
      </ol>

      <p className='text-[16px]'>2.5 All rights not expressly granted in this Agreement are reserved by the IP Owner.</p>

      <h3>3. Representations and Warranties</h3>
      <p className='text-[16px]'>
        3.1 Each party represents and warrants to the other that it has the capacity, full right,
        power, and authority to enter into, perform, and grant the rights and licenses it grants and
        is required to grant under this Agreement.
      </p>

      <p className='text-[16px]'>3.2 You hereby represent and warrant to the IP Owner that:</p>

      <ol type="a">
        <li>
          <p className='text-[16px]'>(a) you lawfully acquired Your Mittaria Genesis NFT.</p>
        </li>
        <li>
          <p className='text-[16px]'>(b) you are at least 18 years of age.</p>
        </li>
        <li>
          <p className='text-[16px]'>(c) you paid the creator fees when acquiring Your Mittaria Genesis NFT.</p>
        </li>
        <li>
          <p className='text-[16px]'>
            (d) you are the sole and absolute owner of the private keys to the wallet address set
            forth in your signature block below and, to the extent that you co-own such wallet
            address with any other person(s), and that you have the authority to enter into this
            Agreement on the behalf of such other person(s).
          </p>
        </li>
        <li>
          <p className='text-[16px]'>
            (e) you will not use the Licensed NFT Artwork in any manner that is detrimental to the
            IP Owner, and the IP Owner’s business partners, or its affiliates.
          </p>
        </li>
        <li>
          <p className='text-[16px]'>
            (f) you acknowledge and agree that becoming a contracting party to the Mittaria NFT
            Terms and Conditions and any other applicable terms relating to it is a condition of
            becoming a party to this Agreement.
          </p>
        </li>
        <li>
          <p className='text-[16px]'>
            (g) you have read and understood the Mittaria NFT Terms and Conditions and any other
            applicable terms in its entirety and agree to it.
          </p>
        </li>
        <li>
          <p className='text-[16px]'>
            (h) you are not, and will not, knowingly execute a transaction involving a Mittaria
            Genesis NFT or the Licensed NFT Artwork with any person who is (i) located in a country
            that is subject to regulatory requirements, embargo, or that has been designated by as a
            terrorist-supporting country; or (ii) listed on any list of prohibited or restricted
            parties, whether on a list maintained by the authorities of the British Virgin Islands,
            Thailand, the United Nations, the European Union or other relevant sanctions authority.
          </p>
        </li>
        <li>
          <p className='text-[16px]'>
            (i) you have sufficient knowledge and understanding of how to transact using digital
            crypto wallets.
          </p>
        </li>
        <li>
          <p className='text-[16px]'>
            (j) You have consulted your legal, financial, tax or other professional advisor(s) when
            in doubt about any of the actions on this website.
          </p>
        </li>
        <li>
          <p className='text-[16px]'>
            (k) all the information provided by you to us is true and accurate and you will keep it
            updated at all times by connecting your digital crypto wallet and updating your account
            details.
          </p>
        </li>
      </ol>

      <h3>4. Indemnification, Disclaimers and Limitations of Liability</h3>

      <p className='text-[16px]'>
        4.1 You will have an obligation to cover all of the IP Owner’s costs arising from any
        material breach of this Agreement by you.
      </p>

      <p className='text-[16px]'>
        4.2 As a separate obligation to that in Clause 4.1, you will defend, indemnify, and hold the
        IP Owner, its licensors, affiliates, representatives, and service providers, and each of
        them, and all of their respective officers, directors, employees, and agents (the
        &quot;Indemnified Parties”) harmless from and against any and all claims, damages, losses,
        investigations, liabilities, judgments, fines, penalties, settlements, interest, and other
        similar results or occurrences (including attorneys’ fees) that directly or indirectly arise
        from, or are related to or in connection with, any claim, suit, action, demand, or
        proceeding or other similar occurrence, process, or activity that is initiated, made,
        brought, or financed by a third party (including any person who accesses or transacts using
        any Mittaria Genesis NFTs or Artwork, whether or not such person personally purchased a
        Mittaria Genesis NFT) against the Indemnified Parties, or on account of the investigation,
        defence, or settlement thereof, arising out of related to, or in connection with: (a) your
        access to or use of any NFT marketplace or third-party services or products; (b) your breach
        or alleged breach of this Agreement; (c) your exercise or attempted exercise of the License;
        or (d) your actual or alleged violation of applicable law. Counsel to be used in the defence
        of such claim must be approved by the IP Owner in writing prior to retention of such counsel
        and, upon our request, you will allow us to participate in the defence of any such claims.
        You will not enter into any settlement or compromise of any claim or litigation or that
        includes an admission of liability without the IP Owner’s prior written consent.
      </p>

      <p className='text-[16px]'>
        4.3 Each Mittaria Genesis NFT is an intangible digital asset that exists only by virtue of
        the ownership record maintained on the Ethereum blockchain. Any transfer of ownership that
        might occur in any unique digital asset occurs on the decentralized ledger within the
        Ethereum blockchain, which the IP Owner does not control.
      </p>

      <p className='text-[16px]'>
        4.4 To the extent permitted by applicable law, The IP Owner will not be responsible or
        liable to you for any loss in connection with any Mittaria Genesis NFT or the Licensed NFT
        Artwork and takes no responsibility for, and will not be liable to you for, any use of or
        inability to use any Mittaria Genesis NFT or the Licensed NFT Artwork for any reason.
      </p>

      <p className='text-[16px]'>
        4.5 There are numerous risks associated with blockchain technology and the Mittaria Genesis
        NFTs. You should familiarize yourself with these risks and agree to assume these risks by
        owning a Mittaria Genesis NFT.
      </p>

      <p className='text-[16px]'>
        4.6 To the maximum extent permitted by law, no Indemnified Party will be liable for any
        incidental, special, exemplary, or consequential damages, or damages for lost profits, lost
        revenues, lost savings, lost business opportunity, loss of data or goodwill, service
        interruption, computer damage, or system failure, or the cost of substitute services of any
        kind arising out of or in connection with this Agreement or from the use of or inability to
        use or interact with any Mittaria Genesis NFT or the Licensed NFT Artwork, whether based on
        warranty, contract, tort (including negligence), product liability, or any other legal
        theory, and whether or not the IP Owner or its service providers have been informed of the
        possibility of such damage, even if a limited remedy set forth herein is found to have
        failed of its essential purpose. Notwithstanding the foregoing, in no event will any
        Indemnified Party’s cumulative liability hereunder from all causes of action and all
        theories of liability exceed the price paid for the licensing fee for the Licensed Artwork.
      </p>

      <h3>5. Term and Termination.</h3>

      <p className='text-[16px]'>
        5.1 This Agreement is effective commencing from the date that you become a party to this
        Agreement in accordance with clause 1 of this Agreement and shall automatically expire
        immediately after your disposal (including by way of sale or transfer) of Your Mittaria
        Genesis NFT (as recorded by the Smart Contract) (“Term”).
      </p>

      <p className='text-[16px]'>
        5.2 Upon the termination or expiration of this Agreement, you may retain any tangible
        property using the Licensed NFT Artwork for personal use only; If, during the Term, you
        create and make available to the public a Derivative Work, you may continue to use and
        exploit that Derivative Work in accordance with this Agreement after the Term; provided
        however that: (i) you will be responsible for any obligations or liabilities arising from
        your continued use of the Derivative Work after the Term; and (ii) this privilege does not
        allow you to use the Licensed NFT Artwork to create any new works or materials after the
        Term.
      </p>

      <h3>6. Non-Compliance with this Agreement.</h3>

      <p className='text-[16px]'>
        6.1 if you have not complied with the conditions of the License, all rights licensed under
        this Agreement will revert to the IP Owner and you will be prohibited from any further use
        of the Licensed NFT Artwork.
      </p>

      <p className='text-[16px]'>
        6.2 If you breach any term of this Agreement, or if the IP Owner discovers that you acquired
        Your Mittaria Genesis NFT(s) unlawfully or you acquired Your Mittaria Genesis NFT(s), the IP
        Owner may seek appropriate measures and remedies, including but not limited to any one or
        more of the following:
      </p>

      <ol type="a">
        <li>
          <p className='text-[16px]'>
            (a) terminating this Agreement with immediate effect, provided that the IP Owner may
            allow you to cure such breach within a specified timeframe;
          </p>
        </li>
        <li>
          <p className='text-[16px]'>
            (b) restricting you from enjoying or participating in any future benefit from Your
            Mittaria Genesis NFTs (including, without limitation, airdrops and events), although,
            for the avoidance of doubt, the IP Owner has no obligation to provide any future benefit
            to you;
          </p>
        </li>
        <li>
          <p className='text-[16px]'>
            (c) requiring a marketplace to remove or disable access to the infringing uses of the
            Licensed NFT Artwork displayed on a marketplace, platform or other Internet service
            provider by way of a legal notice or a takedown notice in accordance with applicable
            copyright, intellectual property, or other laws.
          </p>
        </li>
      </ol>

      <h3>7. Miscellaneous.</h3>

      <p className='text-[16px]'>
        7.1 Recognised Blockchain. The License applies only to the Mittaria Genesis NFTs on the
        blockchain that the IP Owner, in its sole discretion, may designate, which designation shall
        apply retroactively. Thus, for example, if a fork or other event purports to result in
        duplicate Mittaria Genesis NFTs, only the Mittaria Genesis NFTs recorded on the blockchain
        designated by the IP Owner will be eligible to receive the benefit of the License. Any
        license purportedly granted hereunder to the owner of a non-fungible token recorded on a
        blockchain not designated by the IP Owner is null from the beginning.
      </p>

      <p className='text-[16px]'>
        7.2 Assignment. This Agreement is personal to you. Unless otherwise provided in this
        Agreement, you may not assign or otherwise transfer any of its rights, delegate, or
        otherwise transfer any of its obligations or performance, under this Agreement. Any
        purported assignment, delegation, or transfer in violation of this clause is void. IP Owner
        may freely assign or otherwise transfer all or any of its rights, or delegate or otherwise
        transfer all or any of its obligations or performance, under this Agreement. This Agreement
        is binding on and inures to the benefit of the parties hereto and their respective permitted
        successors and assigns.
      </p>

      <p className='text-[16px]'>
        7.3 Waiver. No waiver by any party of any of the provisions hereof will be effective unless
        explicitly set forth in writing and signed by the party so waiving. No waiver by any party
        will operate or be construed as a waiver of any failure, breach, or default not expressly
        identified by such written waiver, whether of a similar or different character, and whether
        occurring before or after that waiver. No failure to exercise, or delay in exercising, any
        right, remedy, power, or privilege arising from this Agreement will operate or be construed
        as a waiver thereof; nor will any single or partial exercise of any right, remedy, power, or
        privilege hereunder preclude any other or further exercise thereof or the exercise of any
        other right, remedy, power, or privilege.
      </p>

      <h3>8. Governing Law:</h3>

      <p className='text-[16px]'>
        This Agreement shall be governed by and construed and enforced in accordance with the laws
        of the British Virgin Islands, without regard to conflict of law rules or principles
        (whether of the British Virgin Islands or any other jurisdiction) that would cause the
        application of the laws of any other jurisdiction. Any dispute between the Parties arising
        out of or relating to this Agreement that is not subject to arbitration or cannot be heard
        in small claims court will be resolved and filed only in the courts of the British Virgin
        Islands. In such case, you hereby irrevocably and unconditionally consent and submit to the
        exclusive jurisdiction of such courts over any suit, action or proceeding arising out of
        this Agreement.
      </p>

      <h3>9.Dispute Resolution and Arbitration</h3>

      <p className='text-[16px]'>
        (a) All disputes between you and us shall be resolved by binding arbitration. In particular,
        arbitration is a form of private dispute resolution and replaces the right to go to court.
        In the absence of this arbitration agreement, you may otherwise have a right or opportunity
        to bring claims in a court, before a judge or jury, and/or to participate in or be
        represented in a case filed in court by others (including, but not limited to, class
        actions). Your acceptance of this Agreement constitutes a waiver of your right to litigate
        claims and all opportunity to be heard by a judge or jury. There is no judge or jury in
        arbitration, and court review of an arbitration award is limited. The arbitrator must follow
        these Terms and can award the same damages and relief as a court (including attorney’s
        fees).
      </p>

      <p className='text-[16px]'>
        (b) Any dispute, controversy, or claim arising out of, relating to, or in connection with
        this Agreement, including any question regarding its existence, validity or termination, or
        any dispute regarding non- contractual obligations arising out of or relating to it, shall
        be referred to and finally resolved by arbitration administered by the BVI International
        Arbitration Centre (“BVI IAC”) in accordance with the BVI IAC Arbitration Rules. (“BVI IAC
        Rules”) for the time being in force, which rules are deemed to be incorporated by reference
        in this clause. The law of this arbitration clause shall be the laws of the British Virgin
        Islands. The place of the arbitration shall be in Singapore. The number of arbitrators shall
        be three (3) arbitrators. The language to be used in the arbitral proceedings shall be
        English.
      </p>

      <h3>10. Class Action Waiver</h3>

      <p className='text-[16px]'>
        Except as otherwise provided in this clause, to the fullest extent permitted by applicable
        law, the arbitrators may not consolidate more than one person’s claims, and may not
        otherwise preside over any form of a class or representative proceeding or claims (such as a
        class action, consolidated action, representative action, or private attorney general
        action) unless both you and us specifically agree to do so in writing following initiation
        of the arbitration. Neither you, nor any other holder of the NFTs can be a class
        representative, class member, or otherwise participate in a class, consolidated, or
        representative proceeding.
      </p>

      <h3>11.Entire Agreement, Severance.</h3>

      <p className='text-[16px]'>
        This Agreement, including and together with any related attachments, is the sole and entire
        agreement of the parties with respect to the subject matter herein, and supersedes all prior
        and contemporaneous understandings, agreements, representations, and warranties, whether
        written or oral, regarding such subject matter. If any term or provision of this Agreement
        is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or
        unenforceability will not affect any other term or provision of this Agreement or invalidate
        or render unenforceable such term or provision in any other jurisdiction.
      </p>
    </div>
  )
}
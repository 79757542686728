import {
  PerformanceMonitor,
  PerformanceMonitorApi,
  useFBX,
  useGLTF,
  useTexture,
} from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { editable as e, PerspectiveCamera as EditableCamera } from '@theatre/r3f';
import IMG_GLOWING_PARTICLE from 'assets/images/particles/star_particle_1.png';
import MODEL_MITTARIA_REVEAL from 'assets/models/fbx/sister.fbx';
import FBX_SPEED_LINES from 'assets/models/fbx/SpeedLines_v1WholeSeq.fbx';
import MODEL_BIRD from 'assets/models/glb/Bird_v4.glb';
import MODEL_MITTRIA from 'assets/models/glb/Mittria_SeperatedAnimations_v5.glb';
import MODEL_CAMERA from 'assets/models/glb/shotcam_v7.glb';
import { BACKGROUND_SWITCH_POINT, REVEAL_WINDOW } from 'common/constants';
import useMainSceneAnimations from 'hooks/useMainSceneAnimations';
import useScrollProgress from 'hooks/useScrollProgress';
import useUniforms from 'hooks/useUniforms';
import MitarriaParticles from 'pages/MittariaParticles';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { isSafari } from 'react-device-detect';
import LegendaryItemCarousel from 'rendering/components/LegendaryItemCarousel/LegendaryItemCarousel';
import SceneTransitionsManager from 'rendering/components/SceneTransitionsManager/SceneTransitionsManager';
import Skybox from 'rendering/components/Skybox/Skybox';
import fragmentShader from 'rendering/shaders/dissolving-mesh.fragment.glsl';
import vertexShader from 'rendering/shaders/dissolving-mesh.vertex.glsl';
import {
  Box3,
  Color,
  DoubleSide,
  Group,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  MeshToonMaterialParameters,
  Object3D,
  PerspectiveCamera,
  sRGBEncoding,
  Texture,
  Uniform,
  Vector2,
  Vector3,
  Vector4,
} from 'three';
import { smoothstep } from 'three/src/math/MathUtils';

import FlowersManager from './components/FlowersManager/FlowersManager';
import ParticleMesh from './components/ParticleMesh';
import Sisters from './components/Sisters';
import Text3D from './components/Text3D/Text3D';
import VoidReflections from './components/VoidReflections';

function MainScene() {
  const shotCam = useGLTF(MODEL_CAMERA);
  const speedLines = useFBX(FBX_SPEED_LINES);
  const mittria = useGLTF(MODEL_MITTRIA);
  const mittariaReveal = useFBX(MODEL_MITTARIA_REVEAL);
  const bird = useGLTF(MODEL_BIRD);
  const particleTexture = useTexture(IMG_GLOWING_PARTICLE);
  const setDpr = useThree((s) => s.setDpr);

  const [cameraContainer, setCameraContainer] = useState<Group | null>(null);

  function onPerformanceChange({ factor }: PerformanceMonitorApi) {
    setDpr(0.5 + 1.5 * factor);
  }

  useMainSceneAnimations({ shotCam, speedLines, mittria, bird });

  useEffect(() => {
    if (!cameraContainer) return;

    const animatedCamera = shotCam.scene.children.find(
      (c) => c.name === 'shotcam'
    ) as PerspectiveCamera;
    animatedCamera.add(cameraContainer);
  }, [cameraContainer, shotCam]);

  // TODO: Currently, the frustum culling is disabled, because the animations cause the objects to get clipped when they should be visible.
  // As a performance improvement, manually enable and disable objects based on the scroll progress.
  useEffect(() => {
    function disableFrustumCulling(object: Object3D) {
      object.traverse((obj) => {
        const mesh = obj as Mesh;
        if (!mesh.isMesh) return;

        mesh.frustumCulled = false;
      });
    }

    disableFrustumCulling(speedLines);
    disableFrustumCulling(mittria.scene);
    disableFrustumCulling(bird.scene);
  }, [mittria, speedLines, bird]);

  const bounds = useMemo(() => {
    if (!mittria.scene) return new Vector2(0.0, 1.0);
    const bb = new Box3().setFromObject(mittria.scene);
    const size = bb.getSize(new Vector3());
    const center = bb.getCenter(new Vector3());
    const b = new Vector4(
      -size.x / 2 + center.x,
      size.x / 2 + center.x,
      -size.y / 2 + center.y,
      size.y / 2 + center.y - 1.1
    );
    return b;
  }, [mittria]);

  const color = useMemo(() => new Color('#b9f8ff'), []);
  const uniforms = useUniforms({
    uProgress: 0.0,
    uTransitionWidth: 0.0125,
    uOpacityBlur: 0.9,
    uDissolveBlur: 0.9,
    uDissolveSize: 1.0,
    uTransitionNoiseFactor: 5.0,
    uDissolveNoiseFactor: 20.0,
    uDissolveColor: color,
    uBounds: bounds,
  });

  useEffect(() => {
    function switchToBasicMaterial(
      root: Object3D,
      args?: MeshToonMaterialParameters,
      isMittaria = false
    ) {
      function createNewMaterial(map: Texture | null) {
        const material = new MeshBasicMaterial(args);
        material.toneMapped = false;
        if (map) {
          map.encoding = sRGBEncoding;
          material.map = map;
          material.needsUpdate = true;
        }
        if (isMittaria) {
          material.transparent = true;
          material.alphaTest = 0.5;
          material.onBeforeCompile = (shader) => {
            Object.entries(shader.uniforms).forEach(([key, value]) => {
              uniforms[key] = value as Uniform;
            });
            shader.vertexShader = vertexShader;
            shader.fragmentShader = fragmentShader;
            shader.uniforms = uniforms;
          };
        }
        return material;
      }
      root.traverse((obj) => {
        const mesh = obj as Mesh;
        if (!mesh.isMesh) return;

        const material = mesh.material as MeshStandardMaterial | MeshStandardMaterial[];
        mesh.material = Array.isArray(material)
          ? material.map((m) => createNewMaterial(m.map))
          : createNewMaterial(material.map);
      });
    }

    switchToBasicMaterial(mittria.scene, { side: DoubleSide }, true);
    switchToBasicMaterial(bird.scene);
  }, [mittria, bird, uniforms]);

  const [skyboxType, setSkyboxType] = useState<'galaxy' | 'dreamy'>('galaxy');

  useScrollProgress((progress) => {
    const newSkyboxType = progress > BACKGROUND_SWITCH_POINT ? 'dreamy' : 'galaxy';
    if (newSkyboxType !== skyboxType) setSkyboxType(newSkyboxType);
    uniforms.uProgress.value = 1.0 - smoothstep(progress, REVEAL_WINDOW.start, REVEAL_WINDOW.end);
    uniforms.uProgress.value = 0.1 + 0.06 * uniforms.uProgress.value;
    mittria.scene.visible = progress > 0.14;
  });

  return (
    <>
      <PerformanceMonitor onChange={onPerformanceChange} />
      <e.group ref={setCameraContainer} theatreKey="camera container">
        <EditableCamera
          theatreKey="Camera"
          makeDefault
          near={1}
          far={1000}
          fov={75}
          attachArray={undefined}
          attachObject={undefined}
          attachFns={undefined}
        />
      </e.group>
      <e.group theatreKey="legendary">
        <LegendaryItemCarousel />
      </e.group>
      <Skybox type={skyboxType} />
      <e.ambientLight theatreKey="ambient_light" />
      <primitive object={shotCam.scene} scale={[100, 100, 100]} />
      <primitive object={speedLines} scale={[1, 1, 1]} />
      <Text3D />
      <group>
        <primitive object={mittria.scene} scale={[100, 100, 100]} />
      </group>
      <primitive object={bird.scene} scale={[100, 100, 100]} />
      {!isSafari && (
        <e.group theatreKey="void_reflections">
          <VoidReflections />
        </e.group>
      )}
      <e.group theatreKey="sisters">
        <Sisters radius={20.0} mesh={mittariaReveal.children[0] as Mesh} map={particleTexture} />
      </e.group>
      <e.group theatreKey="particle_mesh">
        <ParticleMesh
          particleSize={0.021}
          flowHeight={15.0}
          intensity={1.5}
          flowSpeed={0.2}
          particleCount={4000}
          mesh={mittariaReveal.children[0] as Mesh}
          map={particleTexture}
        />
      </e.group>
      <SceneTransitionsManager />

      <FlowersManager />
      <group>
        <MitarriaParticles
          parent={mittria.scene.children[1].children[0].children[0]}
          map={particleTexture}
        />
      </group>
    </>
  );
}
export default memo(MainScene);

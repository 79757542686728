import background from 'assets/images/UI/genesis-background.png';
import mitria from 'assets/images/UI/mitria-illustration.png';
import logoMobile from 'assets/images/UI/mittaria-logo-mobile.png';
import logo from 'assets/images/UI/mittaria-logo.png';
import { GaTrackingAction, GaTrackingCategory } from 'common/constants';
import Button from 'components/Button/Button';
import { gsap } from 'gsap';
import React, { useLayoutEffect, useRef } from 'react';
import ReactGA from 'react-ga4';

interface WelcomeInterface {
  onStart: () => void;
  onStartWithoutSound: () => void;
}

export default function Welcome({ onStart, onStartWithoutSound }: WelcomeInterface) {
  useLayoutEffect(() => {
    const g = gsap.to('#mitria', {
      y: 12,
      ease: 'power1.inOut',
      yoyo: true,
      repeat: -1,
      duration: 1.8,
    });

    return () => {
      g.kill();
    };
  }, []);

  const containerDiv = useRef<HTMLDivElement>(null);

  function animate() {
    gsap.to(containerDiv.current, {
      opacity: 0,
      duration: 2.0,
      display: 'none',
      ease: 'power4.inOut',
    });
  }

  function start() {
    ReactGA.event({
      action: GaTrackingAction.enterExperienceButton,
      category: GaTrackingCategory.default,
    });
    animate();
    onStart();
  }

  function startWithoutSound() {
    ReactGA.event({
      action: GaTrackingAction.enterExperienceNoSoundButton,
      category: GaTrackingCategory.default,
    });
    animate();
    onStartWithoutSound();
  }

  return (
    <div ref={containerDiv}>
      <div className="flex flex-col items-center justify-end md:justify-center w-full h-full overflow-hidden fixed">
        <div className="relative flex flex-1 flex-col items-center justify-center sm:scale-125 md:scale-50 lg:scale-[60%] xl:scale-70 2xl:scale-100 pt-8">
          <img
            id="mitria"
            src={mitria}
            className="md:absolute h-[250px] md:h-[514px] left-[35%]"
            alt="mitria"
          />
          <img
            src={logo}
            className="hidden md:block ml-[-60px] w-[1618px] min-w-[1618px]"
            alt="Mittaria logo"
          />
          <img src={logoMobile} className="md:hidden w-[235px] -mt-4 z-10" alt="Mittaria logo" />
        </div>
        <img
          src={background}
          className="absolute w-full h-full object-cover -z-10"
          alt="background"
        />
        <div className="relative md:absolute md:bottom-10 flex flex-col gap-6 md:gap-10 mb-8 md:mb-0">
          <Button onClick={start}>enter experience</Button>
          <Button borderless onClick={startWithoutSound}>
            <span className="text-[14px] md:text-[16px] uppercase text-lily group-hover:text-[white]">
              enter without audio
            </span>
          </Button>
        </div>
      </div>
      <div className="fixed">
        <div id="stars-group-1" />
        <div id="stars-group-2" />
        <div id="stars-group-3" />
        <div id="stars-group-4" />
        <div id="stars-group-5" />
        <div id="stars-group-6" />
      </div>
    </div>
  );
}

import { OVERLAYS } from 'common/constants';
import useScrollProgress from 'hooks/useScrollProgress';
import React, { useRef, useState } from 'react';
import { getOpacityForActiveWindow } from 'utils/three.utils';
import { ArrElement } from 'utils/types';

const FADE_WINDOW = 0.008;

type Overlay = ArrElement<typeof OVERLAYS>;

function getOverlayActiveWindow(overlay: Overlay) {
  const { start } = overlay;

  const end = overlay.end ?? overlay.start + overlay.duration;
  const fadeStart = start - FADE_WINDOW;
  const fadeEnd = end + FADE_WINDOW;

  return { fadeStart, fadeEnd, start, end };
}

export default function OverlaysManager() {
  const containerRef = useRef<HTMLDivElement>(null);

  const [activeOverlay, setActiveOverlay] = useState<undefined | Overlay>();

  useScrollProgress((progress) => {
    const currentOverlay = OVERLAYS.find((overlay) => {
      const { fadeStart, fadeEnd } = getOverlayActiveWindow(overlay);
      return fadeStart <= progress && progress <= fadeEnd;
    });
    if (currentOverlay !== activeOverlay) setActiveOverlay(currentOverlay);

    if (!containerRef.current) return;

    containerRef.current.style.opacity = String(getOpacity(progress, currentOverlay));
  });

  function getOpacity(progress: number, overlay?: Overlay) {
    if (!overlay) return 0;

    const { fadeStart, fadeEnd, start, end } = getOverlayActiveWindow(overlay);
    return getOpacityForActiveWindow(progress, fadeStart, start, end, fadeEnd);
  }

  return (
    <div className="fixed w-full h-full pointer-events-none" ref={containerRef}>
      {activeOverlay?.render()}
    </div>
  );
}

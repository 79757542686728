import { MeshReflectorMaterial } from '@react-three/drei';
import { MeshReflectorMaterial as MeshReflectorMaterialType } from '@react-three/drei/materials/MeshReflectorMaterial';
import { VOID_ACTIVE_WINDOW } from 'common/constants';
import useScrollProgress from 'hooks/useScrollProgress';
import React, { memo, useState } from 'react';
import { clamp, inverseLerp } from 'three/src/math/MathUtils';

const DEFAULT_REFLECTION_VALUES = {
  mixStrength: 60,
  mixBlur: 0.78,
  roughness: 1,
  metalness: 0.2,
  blurFrom: 101,
  blurTo: 482,
  mirror: 1,
  resolutionScale: 2.0,
  color: '#191919',
};

function VoidReflections() {
  const [reflectionMaterial, setReflectionMaterial] = useState<MeshReflectorMaterialType | null>(
    null
  );
  useScrollProgress((p) => {
    const progress = p;
    const { start, end, transition } = VOID_ACTIVE_WINDOW;
    const startTransition = start - transition;
    const endTransition = end + transition;

    let opacity = 1;
    if (progress < start) opacity = clamp(inverseLerp(startTransition, start, progress), 0, 1);
    if (progress > end) opacity = 1 - clamp(inverseLerp(end, endTransition, progress), 0, 1);

    if (reflectionMaterial) reflectionMaterial.opacity = opacity;
  });

  return (
    <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]} visible>
      <planeGeometry args={[50, 50]} />
      <MeshReflectorMaterial
        ref={setReflectionMaterial}
        mirror={Math.round(DEFAULT_REFLECTION_VALUES.mirror)}
        blur={[DEFAULT_REFLECTION_VALUES.blurFrom, DEFAULT_REFLECTION_VALUES.blurTo]}
        resolution={1024 * DEFAULT_REFLECTION_VALUES.resolutionScale}
        mixBlur={DEFAULT_REFLECTION_VALUES.mixBlur}
        mixStrength={DEFAULT_REFLECTION_VALUES.mixStrength}
        roughness={DEFAULT_REFLECTION_VALUES.roughness}
        depthScale={1.2}
        minDepthThreshold={0.4}
        maxDepthThreshold={1.4}
        color={DEFAULT_REFLECTION_VALUES.color}
        metalness={DEFAULT_REFLECTION_VALUES.metalness}
        transparent
      />
    </mesh>
  );
}

export default memo(VoidReflections);

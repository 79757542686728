import { ReactComponent as ArrowsCircle } from 'assets/images/UI/circle-carousel.svg';
import { ReactComponent as DoubleArrows } from 'assets/images/UI/double-arrows.svg';
import BouncyButton from 'components/BouncyButton/BouncyButton';
import React from 'react';
import { AudioSource, play } from 'utils/sound';

interface CarouselButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  direction?: 'left' | 'right';
}

export default function CarouselButton(props: CarouselButtonProps) {
  const { onClick, direction = 'left', ...rest } = props;

  function privateOnClick() {
    if (onClick) {
      onClick();
    }
    play(AudioSource.sfxCarousel);
  }

  return (
    <BouncyButton type="button" onClick={privateOnClick} {...rest}>
      <ArrowsCircle />
      <DoubleArrows
        className={`absolute inset-0 m-auto ${direction === 'left' ? 'rotate-0' : 'rotate-180'}`}
      />
    </BouncyButton>
  );
}

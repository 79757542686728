import useResetScrollPosition from 'hooks/useResetScrollPosition';
import MEMBER1 from 'main/assets/images/about-page/members/member1.png';
import MEMBER10 from 'main/assets/images/about-page/members/member10.png';
import MEMBER11 from 'main/assets/images/about-page/members/member11.png';
import MEMBER12 from 'main/assets/images/about-page/members/member12.png';
import MEMBER2 from 'main/assets/images/about-page/members/member2.png';
import MEMBER3 from 'main/assets/images/about-page/members/member3.png';
import MEMBER4 from 'main/assets/images/about-page/members/member4.png';
import MEMBER5 from 'main/assets/images/about-page/members/member5.png';
import MEMBER6 from 'main/assets/images/about-page/members/member6.png';
import MEMBER7 from 'main/assets/images/about-page/members/member7.png';
import MEMBER8 from 'main/assets/images/about-page/members/member8.png';
import MEMBER9 from 'main/assets/images/about-page/members/member9.png';
import PARTNERS1 from 'main/assets/images/about-page/partners-1.png';
import PARTNERS2 from 'main/assets/images/about-page/partners-2.png';
import PARTNERS3 from 'main/assets/images/about-page/partners-3.png';
import PARTNERS4 from 'main/assets/images/about-page/partners-4.png';
import PARTNERS5 from 'main/assets/images/about-page/partners-5.png';
import PARTNERS6 from 'main/assets/images/about-page/partners-6.png';
import PARTNERS7 from 'main/assets/images/about-page/partners-7.png';
import PARTNERS8 from 'main/assets/images/about-page/partners-8.png';
import PORTLOGO1 from 'main/assets/images/about-page/portfolio-logo-1.png';
import PORTLOGO3 from 'main/assets/images/about-page/portfolio-logo-10.png';
import PORTLOGO9 from 'main/assets/images/about-page/portfolio-logo-11.png';
import PORTLOGO4 from 'main/assets/images/about-page/portfolio-logo-4.png';
import PORTLOGO5 from 'main/assets/images/about-page/portfolio-logo-5.png';
import PORTLOGO6 from 'main/assets/images/about-page/portfolio-logo-6.png';
import PORTLOGO7 from 'main/assets/images/about-page/portfolio-logo-7.png';
import PORTLOGO8 from 'main/assets/images/about-page/portfolio-logo-8.png';
import PORTLOGO2 from 'main/assets/images/about-page/portfolio-logo-9.png';
import energy from 'main/assets/images/about-page/square-energy.png';
import logo from 'main/assets/images/about-page/square-icon.png';
import { ReactComponent as SquareIcon } from 'main/assets/images/about-page/SquareIcon.svg';
import Divisor from 'main/components/Divisor/Divisor';
import IntroductionText from 'main/components/IntroductionText/IntroductionText';
import PageTitle from 'main/components/PageTitle/PageTitle';
import SkyBg from 'main/components/SkyBg/SkyBg';
import TeamMember from 'main/components/TeamMember/TeamMember';
import Title from 'main/components/Title/Title';
import Layout from 'main/layouts/Layout';
import React from 'react';

/* 
 This page needs to be finished
*/

export default function About() {
  useResetScrollPosition();

  return (
    <Layout smallerFooter>
      <div className="max-w-4xl mx-auto pt-[150px] px-5">
        <PageTitle pageTitle="About us" />
      </div>
      <div className="relative flex justify-center items-center pt-[50px] pb-[25px]">
        <IntroductionText
          introductionHeading=""
          className="z-10 max-w-4xl px-5"
          notOnlyChars
          smallText
        >
          Mittaria is a community-driven ecosystem that unites through the love of animation,
          movies, music, fashion, and games. We are a center for creative talent from across the
          globe to connect, co-own, co-create, and co-author new experiences, bringing imaginations
          to life and nurturing a community of animation enthusiasts.
        </IntroductionText>
        <SkyBg />
      </div>
      <div id="partners" className="max-w-4xl m-auto px-8 leading-10 py-[100px] ">
        <Title titleType="h2" className="text-center relative z-10">
          Mittaria Partners
        </Title>
        <div className="flex flex-wrap gap-20 items-center justify-center pt-[75px]">
          <img src={PARTNERS1} alt="partner" className="mix-blend-luminosity max-w-[140px]" />
          <img src={PARTNERS6} alt="partner" className="mix-blend-luminosity max-w-[140px]" />
          <img src={PARTNERS2} alt="partner" className="mix-blend-luminosity max-w-[140px]" />
          <img src={PARTNERS3} alt="partner" className="mix-blend-luminosity max-w-[140px]" />
          <img src={PARTNERS8} alt="partner" className="mix-blend-luminosity max-w-[140px]" />
          <img src={PARTNERS4} alt="partner" className="mix-blend-luminosity max-w-[140px]" />
          <img src={PARTNERS5} alt="partner" className="mix-blend-luminosit max-w-[140px]" />
          <img src={PARTNERS7} alt="partner" className="mix-blend-luminosity max-w-[140px]" />
        </div>
      </div>
      <div
        id="logo-section"
        className="relative flex justify-center items-center max-w-md mx-auto min-h-[35vh] pt-[50px] pb-[25px] px-8"
      >
        <img
          src={energy}
          alt="logo energy"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />
        <img
          src={logo}
          alt="logo"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />
        <SquareIcon id="square-logo" />
      </div>
      <div className="max-w-6xl m-auto pt-[75px] pb-[75px] lg:pb-0 text-center flex flex-col items-center justify-center px-8">
        <Title titleType="h2" className="pb-5 px-5">
          T&B MEDIA GLOBAL
        </Title>
        <div className="w-fit flex flex-col items-center justify-center pb-12">
          <IntroductionText
            introductionHeading=""
            notOnlyChars
            smallText
            scrollTrigger
            animate={false}
          >
            The home of happy
          </IntroductionText>
          <Divisor className="py-3 w-[80%]" />
        </div>
        <div className="capitals text-smp text-left max-w-3xl">
          Mittaria is the first Web3-backed project in T&B Media Global’s portfolio of 5,000+ IP’s.
          T&B Media Global is a world-renowned entertainment company aligned with the wider MQDC and
          C.P. Group ecosystems, making Mittaria a part of one of the world’s largest multinational
          corporate alliances.
        </div>
        <div className="flex flex-wrap gap-20 items-center justify-center pt-[75px] max-w-4xl">
          <img src={PORTLOGO1} alt="project" className="mix-blend-luminosity max-w-[120px]" />
          <img src={PORTLOGO4} alt="project" className="mix-blend-luminosity max-w-[70px]" />
          <img src={PORTLOGO5} alt="project" className="mix-blend-luminosity max-w-[80px]" />
          <img src={PORTLOGO6} alt="project" className="mix-blend-luminosity max-w-[110px]" />
          <img src={PORTLOGO7} alt="project" className="mix-blend-luminosity max-w-[110px]" />
          <img src={PORTLOGO8} alt="project" className="mix-blend-luminosity max-w-[80px]" />
          <img src={PORTLOGO9} alt="project" className="mix-blend-luminosity max-w-[100px]" />
          <img src={PORTLOGO2} alt="project" className="mix-blend-luminosity max-w-[100px]" />
          <img src={PORTLOGO3} alt="project" className="mix-blend-luminosity max-w-[100px]" />
        </div>
      </div>
      <div className="max-w-6xl m-auto pb-[50px] md:pt-0 pt-[75px] px-8">
        <Title titleType="h2" className="text-center pb-10 md:translate-y-[150%]">
          The team
        </Title>
        <TeamMember
          memberName="Bhak Tanta-Nanta"
          memberRole="CEO"
          memberImg={MEMBER5}
          className="md:translate-y-full mx-auto mb-[58px]"
        />
        <div className="flex lg:flex-nowrap flex-wrap md:flex-row flex-col items-center justify-center gap-8">
          <div className="flex-1 flex flex-col items-center justify-center gap-8 lg:gap-y-[216px] gap-y-[108px] min-w-[300px] my-8">
            <TeamMember
              memberName="Roy Hui"
              memberRole="CTO"
              memberImg={MEMBER1}
              className="md:mt-[50%]"
            />
            <TeamMember
              memberName="Pornviroon Kaewthong"
              memberRole="Project Director"
              memberImg={MEMBER2}
            />
            <TeamMember
              memberName="Nattaya Rujiradamrongchai"
              memberRole="Business Development"
              memberImg={MEMBER3}
            />
            <TeamMember
              memberName="Prommintr Prachitromrun"
              memberRole="Product Developer"
              memberImg={MEMBER4}
            />
          </div>
          <div className="flex-1 flex flex-col items-center justify-center gap-8 lg:gap-y-[216px] gap-y-[108px] min-w-[300px] my-8">
            <TeamMember
              memberName="Watcharavit Rungsimavisrut"
              memberRole="COO"
              memberImg={MEMBER6}
            />
            <TeamMember memberName="Xiao Su" memberRole="Production Director" memberImg={MEMBER7} />
            <TeamMember
              memberName="Kantapong Tapanyo"
              memberRole="Art Director"
              memberImg={MEMBER8}
            />
          </div>
          <div className="flex-1 flex flex-col items-center justify-center gap-8 lg:gap-y-[216px] gap-y-[108px] min-w-[300px] my-8">
            <TeamMember
              memberName="Peerapol Treelertkul"
              memberRole="CFO"
              memberImg={MEMBER9}
              className="lg:mt-[50%]"
            />
            <TeamMember memberName="Tasanee Ua-Aksorn" memberRole="CMO" memberImg={MEMBER10} />
            <TeamMember
              memberName="Kamontip Kamonprapa"
              memberRole="Project Manager"
              memberImg={MEMBER11}
            />
            <TeamMember
              memberName="Nirawit Kumarpandey"
              memberRole="Business Development & Community Lead"
              memberImg={MEMBER12}
            />
          </div>
        </div>
      </div>
      <div className="max-w-5xl m-auto pt-[100px] pb-[120px] px-8 flex flex-col items-center justify-center gap-12">
        <IntroductionText
          introductionHeading=""
          className="z-10 max-w-6xl px-5"
          notOnlyChars
          smallText
          scrollTrigger
          animate={false}
        >
          Bringing ideas to life, we are weaving dreams into reality by transforming creative
          passion into unforgettable experiences.
        </IntroductionText>
        <div className="capitals text-smp max-w-4xl">
          We have spent the last two years building the magical world of Mittaria, but this is just
          the beginning. Our ecosystem will continue to grow season-by-season as we develop into a
          globally-recognized brand and IP, striking a balance between Web2 and Web3.
        </div>
        <Divisor className="max-w-md w-full" />
        <IntroductionText
          animate={false}
          introductionHeading=""
          className="z-10 max-w-6xl px-5"
          notOnlyChars
          smallText
          scrollTrigger
        >
          Where magic becomes reality
        </IntroductionText>
      </div>
    </Layout>
  );
}

import { ReactComponent as Circle } from 'assets/images/UI/circle-team-member.svg';
import { ReactComponent as HoverBottom } from 'assets/images/UI/team-hover-bottom.svg';
import { ReactComponent as HoverTop } from 'assets/images/UI/team-hover-top.svg';
import React from 'react';

interface CarouselItemProps {
  name: string;
  surname: string;
  role: string;
  image?: string;
  linkedin?: string;
  twitter?: string;
  portrait: string;
}

function CarouselItem({ name, surname, role, portrait, twitter, linkedin }: CarouselItemProps) {
  return (
    <div className="transition-opacity duration-[750md] md:duration-500 ease-in-out group opacity-100">
      <div className="relative scale-75 origin-bottom md:scale-100 -z-10">
        <img
          src={portrait}
          alt={`${name} portrait`}
          className="absolute inset-0 m-auto rounded-full -z-10 group-hover:shadow-[0_0_150px_80px_#fff] max-h-[290px]"
        />
        <Circle className="z-20" />
        <HoverTop className="transition-all duration-200 ease-in-out absolute top-[-16px] inset-x-0 mx-auto opacity-0 group-hover:opacity-100 translate-y-2 group-hover:translate-y-0" />
        <HoverBottom className="transition-all duration-200 ease-in-out absolute bottom-[-12px] inset-x-0 mx-auto opacity-0 group-hover:opacity-100 -translate-y-2 group-hover:translate-y-0" />
      </div>
      <div className="relative flex flex-col items-center gap-0.5 mt-4">
        <p className="text-ch font-monsterOfFantasy bg-gradient-to-b from-violet to-light-blue text-[transparent] bg-clip-text text-center">
          {name}
        </p>
        <p className="text-ch font-monsterOfFantasy bg-gradient-to-b from-violet to-light-blue text-[transparent] bg-clip-text text-center">
          {surname}
        </p>
        <p className="text-[20px] text-violet text-center">{role}</p>
        <div className="flex flex-row opacity-100 md:opacity-0 group-hover:opacity-100 text-violet uppercase tracking-widest md:tracking-normal">
          {!!linkedin && (
            <a
              className="text-smch md:text-[10px] underline"
              href={linkedin}
              target="_blank"
              rel="noreferrer"
            >
              linkedin
            </a>
          )}
          {!!(linkedin && twitter) && <p className="text-smch md:text-[10px]">&nbsp;-&nbsp;</p>}
          {!!twitter && (
            <a
              className="text-smch md:text-[10px] underline"
              href={twitter}
              target="_blank"
              rel="noreferrer"
            >
              twitter
            </a>
          )}
        </div>
        <div className="absolute bottom-0 w-[90%] h-[90%] bg-white -z-10 bg-opacity-[0.45] rounded-full shadow-[0px_0px_40px_40px_rgba(255,255,255,0.45)]" />
      </div>
    </div>
  );
}

export default React.memo(CarouselItem);

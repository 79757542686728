export const positions = [
  [0.8146117334991648, -0.14523383475441143, -1.4934148143785013],
  [0.052383277455718136, 0.2237124749739985, -1.493216923749836],
  [0.0748692126230599, 0.16136085154780566, -1.4885498896715923],
  [0.21908549807753974, -0.20779079246739485, -1.483971120760906],
  [-0.7381958946563455, 0.14702407898883962, -1.4595661664048585],
  [-1.352271134700902, 0.12779933784832748, -1.430036423766163],
  [-0.8553761616515598, -0.04748467371202342, -1.4292240026166567],
  [0.37753748897623396, -0.06796931300674919, -1.4284405459256178],
  [0.6548478030780541, 0.23466587655009363, -1.413595600014626],
  [1.5512114158293333, 0.13421120786883628, -1.4127139853841104],
  [0.3874704924428629, 0.10643416515855308, -1.4068268109326452],
  [1.4095110354937739, 0.09780958550095442, -1.4049270831340324],
  [-1.7053127116766338, -0.16192978745847725, -1.399325160371724],
  [2.0289487756295532, -0.1837574188608312, -1.3938898647159434],
  [1.0668628226227002, -0.030283266717923973, -1.3896454403801344],
  [0.7718413852244865, 0.1810833779137786, -1.387811184953599],
  [1.3712962607162265, 0.09246080650539795, -1.3801166214169347],
  [2.393196240297794, -0.24517560036029346, -1.3739053287676504],
  [1.3219510965962984, -0.048648289435072156, -1.3692286496226136],
  [-0.9851953670454972, 0.19653566137986783, -1.367211528895489],
  [1.605296462363679, -0.05866502625760278, -1.356011103176267],
  [-2.2713783370653102, 0.047176923622189004, -1.3425833999571606],
  [1.0528600417808043, 0.17952712158697387, -1.3408412440892863],
  [1.565098575035484, 0.024619203383060284, -1.339326273585485],
  [-0.5142609554481159, -0.13463267209280277, -1.3347276245627349],
  [0.41750593643185785, -0.04440205048308593, -1.3328829892655933],
  [2.0198389296043517, -0.09818467023956023, -1.3233220539139992],
  [-2.3815010414731894, 0.07896726310190266, -1.322683623831907],
  [1.9206572121212506, 0.23580814739383837, -1.306187620533338],
  [1.937076735986734, -0.03236028780402861, -1.2986272895694104],
  [-0.8486884682750845, -0.03640075452335573, -1.2870130752481197],
  [1.0665864824917999, 0.23746093547655978, -1.2744116486041297],
  [0.201649622189235, 0.0797974415766417, -1.2683802899159975],
  [-1.2922776669177516, 0.11152775863943404, -1.2671953502739568],
  [-0.7552121401962469, 0.11678748008650403, -1.257392438650737],
  [0.6255385640948907, -0.11320683598534015, -1.251523044624682],
  [1.555513387115527, 0.15806322635219539, -1.2430393141816465],
  [-1.4845538842588462, 0.0796825796051896, -1.237192584997333],
  [1.984407327199753, 0.22712147165824254, -1.2349508898769286],
  [0.6954551270238851, 0.1576946398338896, -1.23424163473611],
  [-1.8963678740948313, 0.18213463195919988, -1.2327399179406178],
  [1.3421873500571664, 0.021496146022095597, -1.2173327793287134],
  [-1.3566470528637793, 0.12467609731939805, -1.2158483515101328],
  [1.3832895148142414, -0.15165761024231433, -1.204547513601522],
  [-1.28830040949935, -0.24455056230196998, -1.197035399993859],
  [-2.221634476012518, -0.24663378915750866, -1.1966754877978176],
  [1.144963537901544, 0.07114640652727344, -1.1966268143069905],
  [2.2272643929782143, 0.1994770849760052, -1.1916029553449061],
  [2.0585712787481243, 0.013090885136850194, -1.183599371495595],
  [-0.627852612374673, -0.04375919459961608, -1.1800291030041756],
  [0.2525974389966207, 0.052923007278444956, -1.1773343175903075],
  [0.27590206112282467, 0.0003507101396290069, -1.165515493676406],
  [-0.9347222022987084, -0.22885763803252768, -1.164199163682224],
  [-2.493647494050146, -0.05592932910977905, -1.1527651550495592],
  [1.3435522599678356, -0.008195588394951325, -1.1481695164299952],
  [-0.43864739766183325, 0.11804322010146756, -1.1473595973330268],
  [-0.8269018419863333, -0.18234362730502818, -1.1277095642277946],
  [0.1264537068562932, -0.10925092384776247, -1.1247850981831609],
  [-2.474716895911189, -0.18366945118591538, -1.1220514717596286],
  [-1.1780095440023974, -0.04784846565272499, -1.1194946768431975],
  [-2.4090639006903274, 0.05779028873498926, -1.1108530725176],
  [-2.2781232024330684, 0.0884948868624289, -1.1038753397020287],
  [0.8663664523559239, 0.02318883761492807, -1.103249111552236],
  [-0.5375401514847966, -0.09481685588333344, -1.096355834298814],
  [0.2841009219520524, 0.22670279720232406, -1.0785610185496548],
  [-0.6191051880138432, 0.07991284798142784, -1.0783400972923713],
  [-0.4317860070073326, -0.08432829150873045, -1.0740873378523972],
  [-2.247629691676477, -0.11566174930224787, -1.0666072172691015],
  [-1.2256122825362308, 0.04132419227161499, -1.0646346770200892],
  [-1.7579550701777609, -0.2002679559864114, -1.0587295063826856],
  [-2.4724291605452695, -0.06747345534901128, -1.0524412607880276],
  [2.2220295424438046, -0.15594339854670897, -1.0452240099772905],
  [1.8070594480981994, -0.05368962439681968, -1.0399083444323358],
  [1.479179589811136, 0.0005565098667464907, -1.0365781771596665],
  [-2.13589531630472, 0.002982638086478362, -1.0316096829822643],
  [-0.9309257040584301, -0.02543902052987207, -1.0226302819148385],
  [-0.6589412737636546, 0.01710625547796779, -1.0197564649490949],
  [-1.6941714332414053, -0.08748400810748402, -0.9982919816493998],
  [0.7698941539182624, 0.06316299791549207, -0.993016426564318],
  [-1.0438342733835915, 0.0771998867267572, -0.9927416541248616],
  [1.9988567742148877, 0.12343973178754203, -0.9927200983647637],
  [-1.8431417734767142, 0.004497596334259002, -0.9913699015057391],
  [-0.33987320269548427, 0.24655798187028244, -0.9698688293388239],
  [0.3827592630484078, -0.14669984372760547, -0.9689999569467559],
  [1.1707218399557613, -0.08335216278163404, -0.9635153616698996],
  [1.930326967963592, 0.15809667697313873, -0.9381627451241876],
  [-0.24545087491176343, -0.03887889144482998, -0.9381193573457249],
  [1.914574635276744, 0.15647938643102643, -0.9154420942724525],
  [0.5068829249522705, -0.11821952957170295, -0.9146027344113495],
  [-0.38358821668231013, 0.1937382209449196, -0.9020996452697104],
  [-1.057550638105963, -0.2192237050156225, -0.9009004889070272],
  [2.361604578004506, -0.1602885929551362, -0.8941325948072254],
  [0.030431170604622304, 0.08327299079327216, -0.8894907987794218],
  [-1.7554947682676008, 0.18840240435791156, -0.8861206882056669],
  [-0.17521565890306734, 0.09951760294375944, -0.8829318207518435],
  [1.48514184425742, -0.02647526890719848, -0.8820590779876011],
  [0.7360448108769219, -0.18004296575442136, -0.8697876857522953],
  [-0.1298942604087483, 0.041838422896874894, -0.8668385298662556],
  [-0.47436848827605405, 0.19255170388840026, -0.8656253985023459],
  [1.1320057421199907, 0.026727625476998806, -0.8622375803533978],
  [1.908237309919486, 0.2181483630222938, -0.8508613734000323],
  [-2.179482908221863, -0.11658933007190181, -0.8355982656873104],
  [1.5801905643612497, -0.1818522481702055, -0.8251812306068843],
  [0.72562464703256, -0.24555381846305335, -0.8238336445212981],
  [-2.1249728151577374, -0.009611996487405072, -0.8207491383582519],
  [2.2278811866844084, 0.03217432814866761, -0.8060999866418692],
  [-0.6753294226442819, 0.18165612664797404, -0.8056401960825124],
  [-2.042615471950328, 0.11924099651658027, -0.801591162491881],
  [1.8197274455264205, -0.19436882776689446, -0.7997877816748042],
  [-0.940947130800901, -0.06822316818369334, -0.796641546235246],
  [-1.1959733188259043, -0.14955949119155948, -0.7913151096242366],
  [0.23132429530199616, 0.07238913779201545, -0.782414523265855],
  [-2.3411084276812644, 0.229276306380362, -0.7803664646995591],
  [-1.620394697809509, 0.10637641424033928, -0.779093076268963],
  [-1.884639710281839, 0.05388785711344585, -0.7745493630970073],
  [0.8372641266576741, -0.23173815926410746, -0.7576859430145265],
  [-1.1898284348967891, -0.06935178736778291, -0.7436197117570803],
  [0.09031246030195839, -0.21390947613948952, -0.7416632985732359],
  [2.3985083665372615, -0.01249094176178539, -0.7412838822081924],
  [0.3546911896270305, -0.2453197196118112, -0.7365019156837075],
  [-0.8487267028033102, -0.014327884945801117, -0.7357761429815798],
  [-1.0233736479703643, -0.00287690963496523, -0.716360557889186],
  [-1.2886071912832526, 0.06881149189029034, -0.7138440354479914],
  [1.93755312415449, 0.05492212667470636, -0.706792642641503],
  [-0.4789495116504183, 0.11428181804444926, -0.7047970676934201],
  [2.2499682365114126, 0.19415631996272753, -0.7047098553298359],
  [-0.32122050646191336, -0.060317593528544544, -0.6945284815525276],
  [0.04205202230429861, 0.0900588447132794, -0.6802851430244822],
  [1.0053565097343065, 0.09004350043648135, -0.679428859575242],
  [-2.3896266530922383, -0.22670805466156946, -0.6608271586818233],
  [2.3173752770962492, 0.215933194841757, -0.6599264543356487],
  [-1.4354698436944773, 0.1340324245617694, -0.6462365815790184],
  [-1.4015545306062716, 0.1589455187933313, -0.6053689108065613],
  [0.7852430185420634, -0.18976661310229737, -0.6006481296434242],
  [0.45318695322549174, -0.03256524517764087, -0.5879876466018634],
  [-0.7949180422726049, -0.026111977944107856, -0.5843871297158508],
  [2.1482017735038825, 0.12633183692319727, -0.5837072914224178],
  [1.539226705648053, 0.2405494433593483, -0.5795009775638816],
  [-1.356126925042196, -0.19753509753745457, -0.5792270136226619],
  [1.3461265732617633, -0.027243142064610815, -0.5774738290335567],
  [-2.463475720082549, 0.16904090680554346, -0.5707106573763965],
  [2.1913412331824627, 0.11164549557526315, -0.5598154741783826],
  [0.7321656303369733, -0.15924048116439338, -0.5595991264833899],
  [0.3806090913809712, 0.24181993006757901, -0.556193456620206],
  [0.7130779511753604, 0.1534508913846871, -0.5535406732399291],
  [2.0253934477138653, 0.03203081054192769, -0.5528885432932737],
  [-1.2884979994276042, 0.05350105361027968, -0.551004763854255],
  [-0.9506312874960282, -0.22853948839306043, -0.5456354311396391],
  [-2.3622498999239134, -0.23185001807453348, -0.5442405290070165],
  [0.3684087232699007, -0.17289737317726733, -0.5340522601718045],
  [1.3091885253875608, 0.23955905697540147, -0.5267995154597267],
  [0.2870911777220958, 0.15171068091513212, -0.5262697419037008],
  [-1.8324304317955664, 0.17557826113465447, -0.5239766947769415],
  [2.363933767278307, -0.2289426711170388, -0.516251376576413],
  [2.4268099862566244, 0.14117512362492168, -0.5146643707014961],
  [-0.2592229645056193, 0.09668922175190353, -0.5040448279032659],
  [2.2428310906520315, 0.06810444495410611, -0.5026393560360933],
  [-0.35142037938586224, 0.10607868005261223, -0.49966538186266585],
  [-1.1388930544288782, -0.1864704469462557, -0.4775634670700165],
  [-0.024987872328194327, -0.23097145791006424, -0.47662927110925557],
  [1.5653436537780037, 0.2396809775813693, -0.47620788636234634],
  [-2.252294875731378, 0.06325009056489139, -0.4755158921400139],
  [1.1673742302033374, -0.22750957678216088, -0.4743973077249062],
  [1.0818437626574835, 0.08632546341587655, -0.47026889898580815],
  [0.4506573113075413, -0.029970591107789435, -0.4612335713341411],
  [0.13662981346276581, 0.04933400148815109, -0.4596054420104859],
  [0.8435284878883376, 0.20239176253945979, -0.44132626379257045],
  [0.02136718758243117, -0.22645267936392693, -0.4357009711319998],
  [2.012150493248537, 0.2103416185797825, -0.4228293265338241],
  [1.2648783772784529, -0.054951194522109725, -0.4193834666560552],
  [-0.7827133752672166, 0.15674972785667368, -0.41314557752864633],
  [-0.3798986120509801, 0.21744834884299075, -0.4115453197350627],
  [-1.9340377130941833, 0.16741693641141353, -0.39452119954082265],
  [1.80274010415019, -0.12156974549121052, -0.39115335586123645],
  [-2.128376197895366, -0.1660942012722163, -0.38290471789466907],
  [-1.423961030083491, -0.027641998160074843, -0.37981011416098354],
  [-0.8826748779211396, -0.04208316154291225, -0.37460303725384714],
  [1.9545689821061134, 0.08269985648534778, -0.3740774827606377],
  [1.8861450606914727, -0.024509700812087365, -0.36983029198887984],
  [0.7536617789723438, -0.08537819279469555, -0.3668670474099389],
  [-1.685288082362943, 0.09950395631975234, -0.3621381195208193],
  [1.0743267078811218, 0.1030536097733199, -0.3605562975214486],
  [1.1253843527534961, 0.11767716388485666, -0.35402027914290324],
  [0.7364152752485531, 0.045417256090798774, -0.3387404542644933],
  [1.2772944901946792, 0.052148461272301994, -0.3266346321583614],
  [-1.124450182690242, 0.13697129191061375, -0.3261451742017817],
  [1.577904617812809, -0.10719277249582593, -0.32421141484773164],
  [-2.32804385607587, 0.2039475590859524, -0.3097533114652895],
  [1.8996458074234908, -0.08457107139327008, -0.3031969455744561],
  [1.7181871234531472, 0.06728817909941259, -0.3011282430751967],
  [1.7608745708545, -0.031090454752494, -0.29333088883462743],
  [1.5084684008394373, -0.1541727914890348, -0.2924841092947861],
  [0.8299756940423164, 0.19905435702231883, -0.2859966169808683],
  [0.6348058053811023, -0.03340734091880021, -0.27156788754255123],
  [0.8189430676815895, 0.028759284407186614, -0.2713288672147277],
  [-0.5441068001958255, -0.21584567484119221, -0.2691636578014541],
  [1.4105015920489246, -0.2177048180795922, -0.2534829826173174],
  [-0.6373350522823845, 0.24791644129442186, -0.2456717421606558],
  [0.5476132102047288, -0.015536324103438304, -0.2364047949096203],
  [1.7464476333244894, -0.1336321717038298, -0.2355867283140628],
  [-1.9311273751154916, 0.010970759780401629, -0.23520298856101174],
  [2.2287694581704227, -0.20270004828414218, -0.23305119727607626],
  [1.957668318877161, 0.1345708316900031, -0.23059346017240345],
  [-2.3331818001322526, 0.2284251661415272, -0.22546413242783647],
  [1.9049260476011742, 0.054579751730705284, -0.22457602932628362],
  [1.4118184058133125, -0.008693716429951481, -0.22440302956276026],
  [0.906619941946149, 0.10811029542978268, -0.21356377909269364],
  [-2.131883846743279, -0.22025130074616162, -0.19831352401707103],
  [-2.38271361911437, 0.03974520273791782, -0.19584164189723985],
  [-1.941580559592354, -0.17055831478808464, -0.19552500275573392],
  [-0.2439709671856416, -0.14692349249971143, -0.19205590977302944],
  [1.108629947723787, 0.0940891721660379, -0.18987704305261566],
  [-1.416376627945228, 0.24767098612109, -0.18777639158635095],
  [-2.463294167495838, -0.05232854040392315, -0.18459825988384948],
  [-2.0778416210881074, -0.10314444000963191, -0.1695055319217197],
  [0.12558343074199785, -0.21536118799179665, -0.16876018345067012],
  [-1.4130859547501207, 0.05706364777222667, -0.16024542284129029],
  [-2.372557420083204, -0.050645718314649535, -0.15606898888284693],
  [1.6156761462665992, -0.24495036981370844, -0.15261458573037268],
  [-1.0719478044391328, 0.10505780112354934, -0.1524141265919624],
  [2.263930205597048, 0.14341829887739532, -0.14950050605965304],
  [1.2794070101581396, 0.24548576233338515, -0.14736122873501523],
  [-1.860925377450665, 0.14776650308071254, -0.14586442509594433],
  [-1.715567118416275, 0.049566671448238675, -0.1368795674487553],
  [2.264094989008239, -0.013795772226918968, -0.1362464810139704],
  [-0.9776104204030511, -0.044202589508342494, -0.11998947976279706],
  [-1.3851436936398813, -0.15542048541864134, -0.1148486099271172],
  [-2.4824376687880627, 0.10163487460695392, -0.10744875093851658],
  [2.479970196452225, -0.2339709166324771, -0.08656226915941023],
  [-1.920762908821751, 0.19386707795178004, -0.07154919175302543],
  [0.8874683160144747, 0.19592752271186015, -0.0704008352995471],
  [-2.4755707676309795, -0.17537632075639797, -0.06996329350258568],
  [0.24222105682769168, 0.01793851925662232, -0.06625225314611805],
  [-0.9987725222413651, 0.07297806770313431, -0.0637547090481757],
  [0.24347571654007527, -0.002670989862436135, -0.06277732738490471],
  [1.7234671543751783, -0.13903919096230066, -0.0557903575191554],
  [1.0097099441748036, -0.05396272879941577, -0.02738927274064651],
  [0.9313959318959608, -0.13480804022437165, -0.022065530408089784],
  [1.6932015773315516, -0.10379328128102322, -0.006536308894382392],
  [-0.7057300157263047, -0.06869483347106398, -0.00018613861804872567],
  [-2.386584608064875, -0.23273011008617256, 0.002267850000143401],
  [0.2212547969230011, -0.20607964089190017, 0.003234749602709641],
  [-1.1348155685610672, 0.18120129782076727, 0.004076983345230101],
  [0.3484212485298882, -0.20210168686457003, 0.012344567391800676],
  [1.8208958487049514, -0.1590748392150922, 0.04243585877435685],
  [-0.2643221238093074, -0.23652531037459634, 0.044579776631923584],
  [0.2682255605890377, -0.14153549994970333, 0.04688266024045229],
  [1.2798995912267603, 0.04221183335634887, 0.05840770862784428],
  [1.1330822187431677, -0.16246196575921812, 0.06090005548146604],
  [0.9178326246208318, 0.03845977638441894, 0.0641565135294907],
  [2.121716208597734, 0.030757158797734663, 0.0653359780095677],
  [-1.1511596177881116, -0.03123546654451642, 0.06733056857883879],
  [0.9136574911802858, -0.09912116308255281, 0.07361932162867757],
  [1.845908556535098, 0.09232701428114498, 0.09167576581600967],
  [1.0488886820426098, 0.023563368156648612, 0.0941196157227987],
  [2.32878283675273, -0.1325259519024068, 0.10773827919247958],
  [1.1683821066686968, -0.033809800245807264, 0.13344460338595798],
  [0.7308189734518178, -0.2283464577896061, 0.1508505598120201],
  [1.8436231343116334, -0.09637992122700739, 0.15253887912531772],
  [-0.36832865660222536, -0.22636465409079676, 0.15285077147848958],
  [-0.8011340302291448, 0.12961394283666872, 0.16007765658031925],
  [2.436681139426786, 0.17035848141863358, 0.16079199512419706],
  [1.8050238962861165, 0.23197962167126557, 0.16391800736418238],
  [0.6976721330151586, 0.2098187319614051, 0.18119868544112328],
  [1.8396179418406784, 0.08166290567318413, 0.19476014757837545],
  [-0.35960735459129367, -0.23902078382974312, 0.1978934916768028],
  [-1.5810674597763075, 0.1402961778272404, 0.2070352418884217],
  [-0.3065351092553309, -0.08571435856246468, 0.2109940891945703],
  [1.0498748135710412, 0.056993733637558, 0.21523950833283623],
  [-0.11471821370547253, 0.13720413524024194, 0.21723429096616065],
  [-2.070343422618213, 0.1395497013425237, 0.22352353174284934],
  [0.5894206687463566, -0.23321210276416526, 0.23119401113350047],
  [-1.5894293343405037, 0.043430423521039374, 0.2339243481213663],
  [-2.1005437460253598, -0.2367703845931453, 0.24328820952765506],
  [-1.928995356802003, 0.1964726350288275, 0.24498753888713576],
  [-2.2044254157574756, -0.0005634306025325553, 0.24567086409091388],
  [0.5409007418249662, -0.21754402620563906, 0.25691838543997847],
  [2.2244536078870114, 0.07412119295162445, 0.2650870909296478],
  [-1.4844390519789314, -0.11095291333289597, 0.2793090563803592],
  [-2.4926820915136187, 0.24348269423217467, 0.2823577494499413],
  [0.775777096935511, -0.11987920015398085, 0.2869417642287847],
  [-2.1055223862864643, -0.21481451720743133, 0.2976044375307174],
  [-0.29832604326099965, -0.23920527556505827, 0.29993038097983715],
  [-1.0916108704328291, 0.19709470521757555, 0.3030329658672609],
  [-0.10687378887265564, 0.02634875044675633, 0.3095278372906888],
  [0.27394208256436403, 0.0546912257836345, 0.3150850292948454],
  [0.03492869510949792, -0.13100144454774149, 0.32089890525725884],
  [2.1353599163489467, -0.24960581762004352, 0.33100102694607936],
  [1.0198782998664868, -0.15298489813700084, 0.3417368511434078],
  [1.7563457056504528, 0.17696980856754752, 0.34197379784221915],
  [-2.084569029894273, -0.14943471106366235, 0.3446241311880871],
  [-1.891466860681581, 0.02195477013876357, 0.350187127562715],
  [-2.0076136142629704, 0.24455604030618955, 0.35157224021054545],
  [-1.1802760392690166, -0.13911138034621567, 0.3627003337359279],
  [0.48182020653061375, -0.19508343250151228, 0.36954010259520054],
  [-2.2172302536626094, 0.1407409527747765, 0.37171771308215806],
  [1.3433878203462368, -0.17852136510161226, 0.37467449773764216],
  [-0.30791807504249147, -0.13543410571065784, 0.38298410777183534],
  [2.4547753275731217, -0.17153304456655527, 0.39509728886355877],
  [-1.5629418849722292, -0.1442186339972799, 0.3961122098916914],
  [2.1387396012704887, -0.025920719178279594, 0.40563104138979855],
  [2.433881406943572, -0.23978779357574087, 0.41644034425756216],
  [-0.30928258756787064, 0.24373049889656817, 0.41927994573976135],
  [-2.3145665705533247, -0.13986787330208256, 0.4262137957598565],
  [-0.02015304204039725, -0.09084248274714651, 0.43131850425489715],
  [-2.4664117116121935, -0.009535062260168037, 0.4359223166558236],
  [1.4838124310530683, 0.18254383417267817, 0.4422907097229083],
  [0.769785566223537, -0.05031805730840894, 0.44416102342402464],
  [-2.2301078935261707, 0.015021464859558409, 0.45130345431458796],
  [-2.4645282534988286, -0.15890279762933684, 0.4571744174764061],
  [-0.24351106442771608, 0.1406101279806038, 0.4615070839719768],
  [2.2824607753713666, -0.07060148263793425, 0.4619986049922977],
  [1.3315401810033793, 0.07584627862052462, 0.4672475437962621],
  [1.4768318697751592, -0.2299418679686524, 0.46955128408048885],
  [-1.7787468921184157, 0.20372186398635572, 0.4752907140602042],
  [2.1854371166191777, -0.23733540352570415, 0.4837995651682181],
  [-2.187028594517599, 0.1734042039901974, 0.48874731315956566],
  [-0.8094196584341867, 0.18019654009037622, 0.5051223748695068],
  [-0.08262456922926098, -0.16656877360380284, 0.5186838434752594],
  [0.4708606155111311, -0.1428136279859023, 0.5241482588709546],
  [-0.894538084125705, -0.06399682848999699, 0.5362777536982745],
  [0.41909026178452213, 0.21152490822351, 0.5370917635730487],
  [-0.33040369737687536, 0.01913503099005376, 0.5501248820764646],
  [-1.023502357831334, -0.23464039648634116, 0.5543177643364511],
  [-1.046467461531907, -0.22593980789399803, 0.5544551393162018],
  [-1.4697042611904898, -0.030546463229368603, 0.5631250689770505],
  [0.4398434996254391, -0.19997258086355113, 0.5675499895838095],
  [0.9832487108535687, 0.14589352114132642, 0.5712255161088315],
  [2.3223286879081866, 0.07581356908828507, 0.5810200482296987],
  [-0.8991470030634602, 0.17678816708553113, 0.5817769723412697],
  [-0.24061681829100068, 0.18385782942933798, 0.5825503868694719],
  [2.4366959788208646, -0.1830753252211345, 0.5897103132352408],
  [-1.198001195356756, -0.011018682604008512, 0.6014214267341866],
  [2.057207462605386, 0.24598477698313342, 0.6022152828970898],
  [-0.64253245053561, 0.005363622970281368, 0.6063030631206336],
  [1.4668647349145165, -0.06726223587808894, 0.6072865609330245],
  [-0.5670075084587312, 0.17221521410315505, 0.6149061958818811],
  [-2.2141212007791484, 0.09189443620370219, 0.6217578547760344],
  [-1.9053369672008003, 0.141403014544572, 0.6273641142968828],
  [-0.3651822269599647, -0.20203703076851393, 0.62775780862265],
  [0.20224273982039187, 0.04526867735176948, 0.6290611505681705],
  [-0.8201339550502984, 0.05175832193117813, 0.6526674175316484],
  [1.8963392201551827, 0.19524397009318983, 0.6590060972552401],
  [0.8808391648832464, -0.12131806776343801, 0.6635451217785604],
  [1.556455616893258, 0.10378313097611841, 0.6752975351748006],
  [1.170960956904255, 0.23781035570449882, 0.6773324385445019],
  [-1.5741916180553157, -0.14943814193761856, 0.6930004322363383],
  [2.2509694163696015, 0.0936246976906212, 0.6941743190043691],
  [-0.652105348144954, -0.06516077907913143, 0.6996732458229787],
  [-2.2816521707852684, -0.04689845491948874, 0.7002223677855256],
  [1.16778075742898, -0.01635014030738935, 0.7008835415550099],
  [-0.8527869629926915, -0.16738826165149412, 0.704844467195576],
  [-0.7197025279119018, 0.06662831263830632, 0.7117086362608627],
  [0.6364702747338663, -0.03567133998247074, 0.7124974664284116],
  [-0.746574743022822, 0.006453012363991262, 0.715845671960926],
  [-0.6313671012345773, -0.2142716686718379, 0.7282992515945984],
  [1.3334659284555568, 0.18815898950183313, 0.730872336965473],
  [-0.359195587345012, 0.24779782153120916, 0.7352713701509808],
  [-0.8624036229514881, -0.1820276027163904, 0.7389175948047361],
  [1.18157943158602, -0.1782928676860313, 0.7395623802811887],
  [-1.5438398019844808, 0.03797905364447546, 0.7484192556448095],
  [-1.2989415834785394, 0.07789865522705697, 0.7642397159070675],
  [-2.1599454058620164, 0.13112011046984234, 0.7874720546370871],
  [-1.8150522826431692, 0.03807078829488442, 0.7929437357208136],
  [0.8045128310149768, 0.20137080301906335, 0.7929949228076814],
  [-0.21476974111818703, 0.0664638292873389, 0.7937003188777191],
  [-0.26551741951293784, 0.19774166017938, 0.7967496321048958],
  [-1.8765792980750247, -0.24388580442574476, 0.7980741219042541],
  [0.9723678016284532, -0.21929668595808566, 0.8117132338593089],
  [2.1035040760027988, 0.20798574787393898, 0.8200994840259461],
  [0.18731023790558188, -0.12943400573743996, 0.828858786974087],
  [-0.254543236029397, 0.019955622471507506, 0.8326729993822439],
  [-0.9278997024884283, -0.14328755829002593, 0.8347942318013063],
  [1.085158163727367, -0.1428362429047803, 0.843115643232558],
  [0.5621912306942312, -0.11325250484550442, 0.8521485467782068],
  [-1.7988596182402965, 0.04900942877982878, 0.8536746116654609],
  [2.4632334869945605, 0.158393508939696, 0.8626900201468614],
  [-2.0001334847549894, -0.10131605447778208, 0.8722027151417819],
  [0.8675128351788908, -0.002546144472937051, 0.8726531673002159],
  [1.0756640133891648, 0.12608375545298867, 0.8741003420444068],
  [-0.8933259876843119, 0.2076190769512043, 0.8824572928999683],
  [-0.5307468989475472, -0.014400364506134244, 0.8825282807011536],
  [0.8272937475218051, -0.23047776738913456, 0.8897174772815006],
  [1.4442055878607518, 0.022811214282263237, 0.8921939035347434],
  [-2.1938769093188633, 0.20992331063649217, 0.8948896685262535],
  [0.04336598387765844, -0.10844270140070628, 0.8967323740253057],
  [-1.9020659944297673, 0.12039320712685991, 0.8996113324745729],
  [-1.241170790817467, -0.24783207848187294, 0.9018197503992463],
  [-0.6541372863062445, -0.03793488280051288, 0.9042183138922121],
  [0.4585591227123176, 0.21945832134051374, 0.9059086443102464],
  [-0.7596384969424502, -0.13243392980672136, 0.905909078398272],
  [1.779311914142316, 0.09774277937041359, 0.9147082076747084],
  [-0.23194034865500257, 0.05061961106087004, 0.9171264518763036],
  [0.31072327108423115, -0.023236522419195216, 0.9205808602529792],
  [-2.4411158032428952, 0.18277314552017987, 0.937320578286293],
  [1.3455246516224262, 0.1757507889390434, 0.9416604620009341],
  [-1.090751275719506, -0.21250150192055317, 0.9420814054594558],
  [1.7753115752842064, -0.13448291587588784, 0.9441154889876551],
  [1.7811883594113054, 0.054902495109259764, 0.9535386823503847],
  [-0.7044702900949928, -0.15303390406385853, 0.9654029529075091],
  [-2.2660894238888125, 0.025002957801498082, 0.9658286663465268],
  [1.2156901400350584, -0.16199664566059135, 0.9719479363059429],
  [1.540774903150333, -0.22592415697040513, 0.9818326860761455],
  [2.493594998840737, 0.19908636925751377, 0.9844961450931038],
  [-2.2658552319122687, -0.18292047735790357, 0.9891781623465817],
  [0.18828511050903773, -0.027536354053652623, 0.989919195978513],
  [-0.20357007304202202, -0.021203782689136585, 0.9950716915429745],
  [1.1295405673787828, 0.15401007074664946, 1.0201227963092971],
  [0.3930458153925953, 0.18394571440660373, 1.024019312516133],
  [-2.3783133803706646, -0.20566432034429355, 1.0296237871524596],
  [-1.56244317583639, 0.056277301594810314, 1.0349416961776685],
  [-1.9496296917761424, 0.14925961494909235, 1.0351663366723174],
  [-0.14735238632955616, 0.17499340022005155, 1.038982265004955],
  [-1.922230173998899, 0.19170982032826678, 1.0397274877250844],
  [1.068975177589921, 0.1583138501037375, 1.0436474547268957],
  [-0.721050695716462, 0.02183214458300975, 1.046680409238032],
  [-1.2654971702957873, -0.10889910546414172, 1.0494205171245876],
  [-1.4883982784553789, 0.1889467245428783, 1.0640546774155557],
  [1.3336973495790079, 0.007949644554597513, 1.0703639825336673],
  [2.245559562761218, 0.012830828609074807, 1.07565903216909],
  [-1.6446768150799373, -0.14080896055131953, 1.0804829075699414],
  [0.12777473989871946, -0.15188980591803986, 1.0841783725727612],
  [-0.6823690217727141, 0.23895071830955444, 1.0843187950544153],
  [0.49822298866395665, 0.22013080634899929, 1.0921466773299415],
  [-0.07720256486159427, -0.21690822012704908, 1.0941374504577221],
  [-1.96598013380207, -0.0610377245070855, 1.0979729747837228],
  [2.4372176325808805, -0.016925653972491628, 1.1032746858264517],
  [1.086711238408085, 0.005107920997986293, 1.1059853461127505],
  [-1.2199692966581015, -0.1412746578303733, 1.1093201572058162],
  [1.1386847013040768, -0.02038496410613755, 1.1113971046596174],
  [1.6801341811352732, -0.0664315482757335, 1.1127102989045312],
  [1.8518296606469753, -0.19502156981666507, 1.1133282092512573],
  [0.656744527558919, -0.24357703521888374, 1.1152946502495318],
  [0.9723451088299268, 0.21595472236322033, 1.1226816929722712],
  [2.4218950132036374, 0.02833293020097899, 1.1227193137563585],
  [0.08076921917231639, -0.20086419120316606, 1.1384866131147953],
  [-2.1616951618437024, 0.11087372891066205, 1.1413983488832196],
  [-0.2458444294819745, 0.21826211350446006, 1.1518927305176077],
  [-0.7364077851340661, -0.24966699203265358, 1.162884839173767],
  [0.014196214379364469, -0.13723774460427696, 1.163937998941568],
  [-0.34539272709280644, -0.009032683264545982, 1.1704067587459908],
  [-1.0579082345437536, 0.0909124890888699, 1.172582748725208],
  [1.2254269206012465, -0.072430288588747, 1.1734296025670397],
  [0.19634330297669478, 0.15019569317097997, 1.1754275748994403],
  [-0.8215053219597707, -0.02121375926979907, 1.1781361926119591],
  [1.3002727737046404, 0.18893784156815635, 1.196062688764387],
  [1.0354320368820358, 0.20492883306866477, 1.2073113684406012],
  [2.1088907742457916, -0.15912706713607477, 1.2098503133557412],
  [-0.49600311040604306, -0.02467359179879436, 1.2126351221318608],
  [-1.9017922681322552, -0.011382940879290704, 1.2134450348993313],
  [-1.4796273935566955, 0.10986883057095437, 1.2142596432196315],
  [-0.049137023155512716, 0.10723565900075588, 1.214443094359232],
  [-0.4676952366686926, 0.11217214416043714, 1.2176386357744517],
  [0.43205665002123805, 0.1323269201356076, 1.2275436655478211],
  [1.922990288264771, 0.10692703457445557, 1.2403939166696913],
  [0.4841059951890564, 0.012744576927949447, 1.242064773850474],
  [-0.9949724793119202, -0.1014022841318254, 1.249888917227096],
  [0.5104650539798844, -0.11543775467366918, 1.255039412801249],
  [-2.1687759062927396, 0.08043091696304067, 1.2559500673426909],
  [-1.3778531824329499, -0.2026338997563939, 1.2597295873234498],
  [1.79181885926613, 0.22547721115328168, 1.2631385738410552],
  [1.1093559894660387, -0.17836807882915984, 1.2654709864601106],
  [-2.217576770817287, -0.04750945302116244, 1.276218180692184],
  [-0.9651884973934749, 0.19260414748068588, 1.2768735818274437],
  [2.235014854370037, 0.07353126646271557, 1.2956447231920276],
  [-0.3599950761825149, 0.1855105477540696, 1.2973211328757914],
  [1.8305357686669799, -0.032454270670268515, 1.297716378061648],
  [2.0347144002654387, -0.18266104440591674, 1.3057677722386059],
  [2.410256910833521, 0.2483055441598252, 1.3070233960061453],
  [-1.0139536642719134, -0.13915444603340849, 1.3070452795881742],
  [1.649654120263115, 0.21280809857398408, 1.3102230759997373],
  [-0.9236372454299457, -0.04665341791106681, 1.3116946974544033],
  [1.5137569469047558, 0.13201531030115465, 1.3260074307856047],
  [-0.20506671494590456, 0.00785213289901221, 1.331882472204168],
  [-2.051236172187659, -0.11843854818388644, 1.3388793741239788],
  [1.8522123234289034, -0.15431400086513808, 1.338926430864219],
  [-0.7141863203978827, 0.06825290238817894, 1.3421797037927794],
  [-1.5227516286406506, 0.21307005009839597, 1.3448352429189776],
  [0.2933488725840999, 0.1214322955373246, 1.3455401009829457],
  [1.570041831082215, -0.16009098508382735, 1.3490210601950388],
  [1.6051389052318106, 0.037019430567012124, 1.357506436921116],
  [-0.9633082312681729, -0.10619827931737802, 1.3587364827510542],
  [2.1803427406080567, 0.2499880508210816, 1.3614104031042364],
  [-1.119265456751165, 0.02844116334304697, 1.3792774195324924],
  [-1.8125754338982665, 0.18970029203547983, 1.3814014723781889],
  [1.5972939344354304, -0.13427706026237668, 1.3854309794981594],
  [-0.1959147771559283, 0.188005570425697, 1.3871867721578328],
  [-1.861635495468013, -0.2248269317980361, 1.3894853291292977],
  [-1.4486686919657106, -0.014534228723350862, 1.396101904286569],
  [1.1643014595304941, -0.058612981496303895, 1.421499724334068],
  [0.9879006226361448, 0.038583733732763525, 1.4377802004895086],
  [2.2161797917982877, 0.06286131482960006, 1.451309357473348],
  [1.015925442383202, 0.08275941176224516, 1.4517213434859293],
  [-2.396607190734909, 0.04927274494978712, 1.4781824068204203],
  [-1.559329793627246, 0.20566569253572453, 1.4790068385942718],
  [0.8781196775517269, -0.20513265994998203, 1.4813817068981467],
  [-1.4517845814820456, -0.2038547621229329, 1.4823556424494768],
  [1.6464346893991844, -0.1847481034632215, 1.4867947554638756],
  [-2.3495939011517555, -0.08251184303817449, 1.4871198698363843],
  [-0.30106828760627646, -0.15082109312446756, 1.4939408492582649],
];
export const uvs = [
  0, 0.5, 0.5, 0.5, 0, 0, 0.5, 0.5, 0.5, 0.5, 0, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 0, 0.5, 0, 0.5, 0,
  0.5, 0, 0.5, 0, 0, 0, 0.5, 0.5, 0.5, 0.5, 0.5, 0, 0, 0, 0, 0, 0.5, 0, 0, 0.5, 0.5, 0.5, 0.5, 0.5,
  0.5, 0.5, 0.5, 0, 0.5, 0, 0, 0, 0, 0.5, 0.5, 0, 0, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5,
  0.5, 0, 0, 0, 0, 0.5, 0.5, 0.5, 0.5, 0, 0, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0, 0, 0, 0, 0, 0.5, 0,
  0.5, 0, 0.5, 0, 0.5, 0, 0, 0, 0.5, 0, 0.5, 0, 0, 0, 0, 0, 0, 0, 0, 0.5, 0.5, 0, 0, 0.5, 0.5, 0, 0,
  0, 0.5, 0.5, 0.5, 0, 0, 0, 0.5, 0, 0, 0, 0, 0, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0, 0, 0, 0.5, 0,
  0, 0.5, 0.5, 0, 0.5, 0.5, 0.5, 0, 0, 0, 0, 0.5, 0.5, 0, 0.5, 0, 0.5, 0, 0.5, 0, 0, 0, 0, 0, 0.5,
  0, 0, 0, 0.5, 0, 0.5, 0.5, 0.5, 0, 0, 0, 0, 0, 0, 0, 0.5, 0.5, 0.5, 0, 0, 0, 0.5, 0.5, 0.5, 0, 0,
  0, 0, 0, 0.5, 0, 0.5, 0, 0, 0, 0, 0, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0, 0, 0, 0, 0, 0.5, 0, 0,
  0.5, 0.5, 0, 0.5, 0, 0.5, 0, 0.5, 0.5, 0.5, 0, 0, 0, 0.5, 0, 0, 0.5, 0.5, 0.5, 0.5, 0, 0.5, 0.5,
  0.5, 0, 0, 0, 0, 0.5, 0.5, 0, 0.5, 0.5, 0.5, 0, 0, 0, 0, 0.5, 0.5, 0, 0.5, 0, 0, 0, 0.5, 0, 0.5,
  0, 0.5, 0.5, 0.5, 0, 0, 0.5, 0.5, 0, 0.5, 0.5, 0.5, 0.5, 0.5, 0, 0, 0.5, 0.5, 0.5, 0.5, 0, 0, 0.5,
  0.5, 0, 0.5, 0, 0.5, 0, 0, 0, 0.5, 0, 0.5, 0, 0.5, 0, 0.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.5, 0, 0,
  0.5, 0.5, 0, 0.5, 0, 0.5, 0, 0, 0.5, 0.5, 0, 0, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0, 0.5, 0, 0, 0.5,
  0.5, 0, 0.5, 0.5, 0.5, 0, 0, 0, 0.5, 0, 0.5, 0, 0, 0, 0.5, 0, 0.5, 0, 0.5, 0.5, 0.5, 0.5, 0.5,
  0.5, 0.5, 0, 0.5, 0, 0, 0, 0, 0, 0.5, 0, 0.5, 0, 0.5, 0.5, 0.5, 0, 0.5, 0, 0, 0, 0, 0, 0.5, 0,
  0.5, 0, 0.5, 0, 0.5, 0, 0.5, 0, 0.5, 0, 0.5, 0.5, 0.5, 0, 0, 0, 0, 0, 0, 0, 0.5, 0, 0.5, 0, 0, 0,
  0.5, 0.5, 0.5, 0, 0.5, 0.5, 0.5, 0.5, 0.5, 0, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 0, 0.5, 0, 0.5, 0, 0.5,
  0, 0.5, 0.5, 0.5, 0, 0.5, 0.5, 0.5, 0, 0.5, 0, 0.5, 0, 0.5, 0.5, 0.5, 0, 0, 0, 0.5, 0.5, 0.5, 0,
  0.5, 0.5, 0.5, 0.5, 0.5, 0, 0.5, 0, 0.5, 0, 0, 0, 0, 0, 0, 0.5, 0.5, 0, 0.5, 0, 0, 0, 0.5, 0, 0,
  0.5, 0.5, 0, 0.5, 0, 0.5, 0, 0, 0.5, 0.5, 0, 0, 0, 0.5, 0, 0.5, 0, 0.5, 0.5, 0.5, 0, 0.5, 0.5,
  0.5, 0, 0, 0, 0, 0.5, 0.5, 0, 0, 0, 0.5, 0.5, 0.5, 0.5, 0.5, 0, 0, 0.5, 0.5, 0, 0, 0.5, 0.5, 0.5,
  0.5, 0.5, 0.5, 0.5, 0.5, 0, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0, 0, 0, 0.5, 0, 0.5, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0.5, 0.5, 0.5, 0, 0.5, 0, 0, 0.5, 0.5, 0, 0, 0.5, 0.5, 0.5, 0.5, 0, 0, 0.5, 0.5, 0,
  0.5, 0, 0, 0, 0.5, 0, 0.5, 0, 0, 0, 0, 0, 0, 0, 0.5, 0, 0, 0.5, 0.5, 0, 0.5, 0, 0.5, 0, 0, 0.5,
  0.5, 0, 0.5, 0, 0.5, 0, 0, 0, 0.5, 0, 0, 0.5, 0.5, 0.5, 0.5, 0, 0, 0, 0.5, 0.5, 0.5, 0, 0.5, 0.5,
  0.5, 0, 0.5, 0, 0, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0, 0, 0, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 0, 0, 0.5,
  0.5, 0, 0, 0.5, 0.5, 0, 0.5, 0, 0, 0.5, 0.5, 0.5, 0.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.5,
  0, 0, 0, 0, 0, 0.5, 0.5, 0.5, 0.5, 0.5, 0, 0, 0, 0, 0, 0, 0, 0.5, 0, 0.5, 0, 0.5, 0, 0.5, 0, 0, 0,
  0, 0.5, 0.5, 0, 0.5, 0.5, 0.5, 0, 0, 0, 0.5, 0, 0, 0.5, 0.5, 0, 0, 0.5, 0.5, 0.5, 0.5, 0, 0.5,
  0.5, 0.5, 0, 0.5, 0, 0.5, 0, 0, 0, 0, 0, 0.5, 0, 0.5, 0, 0.5, 0, 0, 0.5, 0.5, 0, 0, 0, 0.5, 0.5,
  0.5, 0, 0.5, 0.5, 0.5, 0, 0, 0, 0.5, 0, 0, 0.5, 0.5, 0, 0, 0, 0, 0.5, 0.5, 0, 0, 0, 0.5, 0, 0,
  0.5, 0.5, 0, 0.5, 0.5, 0.5, 0, 0, 0, 0.5, 0, 0, 0, 0, 0, 0.5, 0.5, 0.5, 0, 0, 0, 0, 0, 0.5, 0,
  0.5, 0.5, 0.5, 0, 0, 0.5, 0.5, 0, 0.5, 0, 0, 0, 0, 0, 0.5, 0, 0.5, 0, 0, 0.5, 0.5, 0, 0.5, 0, 0.5,
  0.5, 0.5, 0, 0.5, 0.5, 0.5, 0, 0.5, 0, 0, 0.5, 0.5, 0, 0, 0, 0.5, 0, 0, 0.5, 0.5, 0, 0, 0, 0, 0,
  0, 0, 0.5, 0, 0.5, 0, 0.5, 0, 0, 0, 0.5, 0.5, 0.5, 0.5, 0.5, 0, 0.5, 0, 0, 0.5, 0.5, 0.5, 0.5, 0,
  0.5, 0, 0, 0, 0, 0.5, 0.5, 0, 0.5, 0, 0, 0, 0.5, 0, 0.5, 0, 0.5, 0.5, 0.5, 0, 0.5, 0, 0.5, 0, 0,
  0, 0, 0, 0, 0.5, 0.5, 0, 0.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.5, 0.5, 0, 0.5, 0, 0.5, 0.5,
  0.5, 0, 0.5, 0, 0, 0, 0.5, 0, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0, 0, 0.5,
  0.5, 0, 0.5, 0, 0, 0.5, 0.5, 0, 0.5, 0, 0, 0.5, 0.5, 0, 0.5, 0.5, 0.5, 0, 0.5, 0, 0.5, 0.5, 0.5,
  0, 0, 0, 0, 0, 0.5, 0, 0.5, 0, 0, 0, 0.5, 0, 0.5,
];
export const offsets = [
  0.9795987089761699, 0.1907743968377551, 0.13258044752330278, 0.4565039670975919,
  0.5473406199326916, 0.829979517176107, 0.5308580917524746, 0.9825360838237485, 0.9918066111432927,
  0.19241663617019722, 0.14472059158625472, 0.2514462572437802, 0.47748521499066054,
  0.8434692891098694, 0.6941342603311242, 0.4401924155653656, 0.8822233037350247,
  0.6640591423868069, 0.08916705844857442, 0.22468964012099413, 0.34197089705357564,
  0.6861467917960228, 0.5305190183942653, 0.11157638529994374, 0.34388314207849546,
  0.11890268805421089, 0.16441596215503373, 0.1761317158453105, 0.9788973135759089,
  0.8135792849185186, 0.211672686255152, 0.28117680916634713, 0.21588736637944683,
  0.41440739272956084, 0.8907988187869259, 0.0353475049358043, 0.7119786556783582,
  0.3113031720357389, 0.3661664280530367, 0.8875338435379213, 0.02285601149036709,
  0.7955857804330697, 0.5375019566184123, 0.6671143243958764, 0.522687475994001, 0.3067058877750328,
  0.7362894373414027, 0.5764573937133748, 0.4253011164538336, 0.2234541796414118,
  0.9746633917462985, 0.020715810929291978, 0.6456916794879987, 0.012967557596075041,
  0.9934096894146666, 0.8674544608976312, 0.7334502493374481, 0.5636009245798368,
  0.8816809674111977, 0.15665285474105906, 0.3317456307422664, 0.5707915802690079,
  0.8936294147325343, 0.6400785275368718, 0.7766360196704184, 0.7375429294328838,
  0.9345138639237051, 0.9505354379962422, 0.7030708834506915, 0.870351142765012, 0.3913135301452316,
  0.897398413985733, 0.45331308720403485, 0.31398261297278307, 0.6215926937654589,
  0.5990802360645137, 0.8833542715987843, 0.6154654313409559, 0.9766641064324121,
  0.4785243452771417, 0.6627835881296078, 0.8715329099989411, 0.2436260192368822,
  0.5588574557057806, 0.21005595958375978, 0.7159268092102125, 0.12782776447672517,
  0.6019840680393327, 0.6750061932624564, 0.6146312703563594, 0.4429013396401831,
  0.7532515293988296, 0.5386599431475401, 0.07893633101909725, 0.05364961476750729,
  0.11300522025728998, 0.7781151300090615, 0.8854671290754275, 0.5818651903656618,
  0.31651293099911615, 0.4986627825414366, 0.06951462359681271, 0.08546574911910143,
  0.11411187370157183, 0.23044310904426357, 0.1400344433782953, 0.19855082844676308,
  0.006356203133833116, 0.293589436494596, 0.2572829232694819, 0.10975197089310973,
  0.5377396521850308, 0.28129437135526736, 0.21447840446881694, 0.8991723983081799,
  0.32738850358564253, 0.6230221824359051, 0.853295712787224, 0.8047375871917337,
  0.7355801727740249, 0.007610892126822422, 0.648407475229875, 0.14011891319459258,
  0.7498906663103577, 0.05994594375924611, 0.4570501904607369, 0.615959397025557,
  0.048670673376144724, 0.3188608422704816, 0.5375887067925875, 0.38810114322687617,
  0.9136423987903523, 0.062148427482431434, 0.29620117696607795, 0.7163610138961846,
  0.6821077481210691, 0.49230119280553153, 0.047101671100359344, 0.902856927041631,
  0.7564753778137201, 0.1739546553920822, 0.2814304264625007, 0.9680187464618322,
  0.6150635299514217, 0.3982813514957253, 0.920492120465802, 0.21852715424565494,
  0.4981707826386642, 0.27095182739855694, 0.8689712758570164, 0.7509030110782421,
  0.8782261692737732, 0.038464369625725414, 0.717432740214642, 0.41727086436573, 0.7264064383238333,
  0.7635106287303358, 0.33959802123771354, 0.5813119796123094, 0.17531981685960918,
  0.010787678808048984, 0.7095342513708888, 0.3634405745674847, 0.3650224597780063,
  0.9601162337371312, 0.9920131258667455, 0.5830240972111601, 0.23911673989401938,
  0.7362558180510457, 0.7655466137714072, 0.035986476582147486, 0.934066282505976,
  0.5730623438473371, 0.2293851347123128, 0.5195048136066954, 0.046130118733962444,
  0.4729629415616319, 0.21433986680863526, 0.6260398700542059, 0.7168598568535769,
  0.15981656418981582, 0.821103303876924, 0.6310336699406731, 0.27536333696218784,
  0.46284273434815637, 0.24296315759583575, 0.06120736628834078, 0.30004460511215336,
  0.8030058369721937, 0.5941062157910042, 0.39434970358658916, 0.9891303891422782,
  0.792538982020907, 0.18277593725159502, 0.554174782017926, 0.6884527531963387,
  0.48989346452873817, 0.7160220774712961, 0.8031915509072277, 0.331673651157094,
  0.7804163772864715, 0.25911401559806757, 0.4644420146921622, 0.1594427765148828,
  0.7285562126970757, 0.7376457007659872, 0.08241532454065792, 0.49929685478862795,
  0.6310091175763474, 0.492686626752433, 0.5964428620712441, 0.5244643594697684, 0.7040682906256499,
  0.8008588689456305, 0.1864758209178481, 0.8883361542797269, 0.8562859697417087,
  0.6266806608583513, 0.6746782835800055, 0.3736208317331946, 0.07603574193013818,
  0.2633674707167122, 0.4159049242157393, 0.15475781748422046, 0.10361421654752068,
  0.9002035039118158, 0.7358792400003809, 0.3698412573817331, 0.5192861518235219,
  0.056243841687942164, 0.407845461919769, 0.29196313231001736, 0.8760715003592436,
  0.5190898485247709, 0.4611432786472779, 0.90888452994684, 0.755501975213409, 0.9712510017765233,
  0.5183579163761902, 0.4042398190665636, 0.451255846005741, 0.8279605309386031, 0.7990248954144503,
  0.020982335168749477, 0.500075021257535, 0.37375237984742316, 0.0696835509139846,
  0.9939756511645429, 0.33664128258399384, 0.05203334210975896, 0.6620535022642641,
  0.16218554982086797, 0.9551815757278082, 0.9608841475539595, 0.7902647711283448,
  0.2643044503256815, 0.6784952951408292, 0.0401989627130791, 0.356176404358318, 0.5056581441642367,
  0.7714125395980722, 0.4179364432946644, 0.5354202410349811, 0.7016554231266302,
  0.8559606005414766, 0.5574151841939944, 0.9097615027759731, 0.5715008750718373,
  0.31077367709128745, 0.2852582929958848, 0.018030924241039137, 0.9300259748995873,
  0.5803827084607354, 0.3206190464849594, 0.6306680982109761, 0.30656301013683085,
  0.10219675319514054, 0.8139985300937966, 0.4042887453986381, 0.7967059922362082,
  0.650492356483264, 0.5216437053458305, 0.38993470857150814, 0.2838526467595023,
  0.9458225457266448, 0.3550335774797575, 0.17999752903613164, 0.1477126192474445,
  0.6062803734501864, 0.914421830148089, 0.07277054384627746, 0.08044524332121972,
  0.7457256844683525, 0.17648721259993894, 0.37706980843293036, 0.38062031680405606,
  0.6315387376322094, 0.1400101461500295, 0.1466303687542857, 0.1712233389976855,
  0.5543784005099843, 0.014577509789310383, 0.028884045627435073, 0.0007581458395023999,
  0.19382514154906116, 0.4605638053766308, 0.9323730575531413, 0.44515562571375955,
  0.5037839280511932, 0.6957576249395563, 0.16915830475694826, 0.9268164448933677,
  0.41065336527948104, 0.9711841806839996, 0.5791615709655582, 0.9588984315317588,
  0.7435724829175633, 0.48879758521348204, 0.5192461134689579, 0.35567251025661784,
  0.6122745729356125, 0.24166311815773434, 0.9525027652380966, 0.7326878050888576,
  0.6195555080626698, 0.007521470277487841, 0.9049100387583248, 0.04941959263411633,
  0.7183105635202796, 0.19307641439409984, 0.0572843180143745, 0.07117431950473085,
  0.7892447167209801, 0.1671360993716412, 0.31604818701201254, 0.7878415030665069,
  0.07801667644923971, 0.7696916622039358, 0.4786701037424841, 0.8557468280996461,
  0.46330219920191595, 0.4690639423119285, 0.1513236069021694, 0.8420609023149433,
  0.35614628719758123, 0.9690872152184763, 0.4656438791013232, 0.557626775523734,
  0.1996554554853741, 0.20557460657049986, 0.03371494046872325, 0.6531443461390005,
  0.16989810836862407, 0.29494714445336356, 0.010643862183987052, 0.669458979471499,
  0.5018389443091483, 0.3752295874622642, 0.4390458623320562, 0.9252677945928648,
  0.2984729591522969, 0.9282844114323627, 0.9597096579333042, 0.27826632872168466,
  0.2144170635472591, 0.7861864461266916, 0.39087515387420224, 0.05419972260597761,
  0.5384527947887146, 0.1518865228630628, 0.07398838668067342, 0.2727538096851543,
  0.05393863762888529, 0.3285957429842543, 0.4864372608843056, 0.9254944943557198,
  0.8289272385556657, 0.4965381614924803, 0.6898888519217339, 0.02842116955343743,
  0.26972080593489933, 0.09123747362613, 0.5854181492685095, 0.12360174245893896,
  0.25954248619517717, 0.3097365182482321, 0.08334214544674956, 0.18839258022661753,
  0.4291506268375439, 0.8837206793404844, 0.7068935493802821, 0.2962159450141947,
  0.32738392797690297, 0.877490202133582, 0.4288348902707355, 0.16026329882828783,
  0.7920223728635799, 0.7415445418690089, 0.7132820126557884, 0.629309978258081, 0.7815261562670452,
  0.9153264057016199, 0.7621933654482765, 0.6341474070915715, 0.8553957996454453,
  0.7396270657710512, 0.13656751149492874, 0.7953074780356618, 0.33994595508321457,
  0.12580898928726358, 0.37181304561705864, 0.44348746578876985, 0.02751304724698611,
  0.17921390092407818, 0.6513604140335594, 0.4452323626582273, 0.8018203579164132,
  0.1888432525219551, 0.0645925523593246, 0.7449076710609104, 0.5754895539989437,
  0.8842895640014687, 0.7176908726720628, 0.16948731448107934, 0.2569100900759641,
  0.24072793574677043, 0.3059586653568147, 0.6281454037725969, 0.06982071208689933,
  0.19039185191892727, 0.47853060655772106, 0.9055449404289797, 0.31907265626686965,
  0.027211228288066547, 0.31966683998387646, 0.1369015343972203, 0.10313605722079988,
  0.541431760360049, 0.8582726924994423, 0.5235224452397808, 0.981072381453655, 0.8983815160549175,
  0.7767153227635177, 0.11586588824193567, 0.8677543786179616, 0.11439845920876168,
  0.2757372801722847, 0.03330244216787415, 0.5811744573502114, 0.03994391343107284,
  0.4819998878400573, 0.7843131676017637, 0.3432299111438707, 0.8184480653115795,
  0.6449805843097991, 0.741278986135876, 0.7457994380138846, 0.028492072087922482,
  0.46086893117816763, 0.9747555613596319, 0.1707657756454738, 0.12111091721758649,
  0.8150815975224359, 0.47496286888916217, 0.6985383062636739, 0.6476553810725207,
  0.7273767934088188, 0.4775760548710657, 0.46702828927485995, 0.2689170287470174,
  0.7225958815597648, 0.5661905002916322, 0.1760606736743785, 0.6311332742441861, 0.648369645008083,
  0.7170101096619839, 0.957002147249218, 0.25580365103998726, 0.7037973746293058,
  0.41807011872940014, 0.028901311817335573, 0.7553839949156573, 0.7031179614830292,
  0.12951268969715135, 0.6039089043611559, 0.2812108039915039, 0.909313525537194,
  0.19336502351526974, 0.81350105421747, 0.8849550589203872, 0.7549254742889518, 0.8176205523180764,
  0.9344252019228413, 0.42378050374489906, 0.33213222559032896, 0.025916701393068897,
  0.5412220652499535, 0.6327101698116833, 0.9987896019127446, 0.3988726159299647,
];

export const scales = [
  0.1699451254842339, 0.715394306945175, 0.927970709467093, 0.9155461117466007, 0.43205769357350343,
  0.32986168558602125, 0.8574602568560369, 0.678974422101142, 0.18572385438141148,
  0.6942985249590584, 0.33914273379874593, 0.40259712004321246, 0.5447195462801944,
  0.6167093706141075, 0.48072188260009685, 0.8228592354218929, 0.39408549041460617,
  0.7191423041525452, 0.8180539122996292, 0.6672832402318662, 0.39424552229218557,
  0.6998482522822318, 0.6993383928687954, 0.9537152558226717, 0.5199520397887003,
  0.4960783733162336, 0.3369057313470518, 0.7977247696005627, 0.9786952283053415, 0.984845793992817,
  0.8510644640519176, 0.46487938661664563, 0.21440635371127217, 0.1990387587233498,
  0.5328074500672552, 0.24000379017752424, 0.36608382914280224, 0.1508373884471088,
  0.8248817446412428, 0.6102035758076295, 0.3944527504113109, 0.7852280774393606,
  0.8788385420107122, 0.7784563663807565, 0.5469905667957238, 0.16225106404248946,
  0.6847895682799738, 0.8769102506341993, 0.9357816956215395, 0.7274276242362039,
  0.7770285118121679, 0.3215051153087052, 0.9179284816200325, 0.18616118601946613,
  0.2689409079043734, 0.6865210929444756, 0.14014740084418167, 0.6291815582451591,
  0.7202521236962881, 0.7690836168868718, 0.7772930689055705, 0.2697545838791777,
  0.4127247272966309, 0.558854676491852, 0.8861048255228143, 0.8143708741479292, 0.839485649207213,
  0.2506129465745956, 0.4679322727617018, 0.7180666328999641, 0.37201307198169975,
  0.1617680309995495, 0.2978753458909835, 0.8853276104570491, 0.555386617319071, 0.7736954099396195,
  0.3764294802416087, 0.7420500505762694, 0.914313838250767, 0.12202568509241252,
  0.18702956849099667, 0.8218242085243949, 0.39670411343833434, 0.8159157830172772,
  0.27027357379351, 0.13188280751158904, 0.5941938819029724, 0.8451840190768106, 0.2104810154856984,
  0.8423101936752164, 0.5176501494878973, 0.24155102919140728, 0.457127414357824,
  0.5744654590119208, 0.4480457651363835, 0.5631178187848161, 0.5683135260745352,
  0.24277867482580567, 0.16444517795928226, 0.3930738022798864, 0.8118715375879462,
  0.36011813138985493, 0.9002305774195467, 0.8855246837148499, 0.21803853211459412,
  0.9833177725061658, 0.9611703048473154, 0.6321109167049763, 0.6599567563148665,
  0.3788637715979192, 0.9409605397122579, 0.3426922055942494, 0.7096271790935244,
  0.7447567439136399, 0.23779236045457133, 0.8471277916411455, 0.6107454431612052,
  0.5415536720276728, 0.4351451134548302, 0.44007263962418475, 0.9071959020203098,
  0.3331625111114477, 0.7882180233444438, 0.8889247593930868, 0.9560185355685931,
  0.5000973159114473, 0.49634708201152755, 0.7564018158453051, 0.8056949394028815,
  0.21269353149557482, 0.10602848342550836, 0.9839194639953542, 0.49707668818727924,
  0.9276023046836936, 0.4987518321220752, 0.8589313221488836, 0.8904830194969449,
  0.6962260370178478, 0.1221559328958931, 0.16334327064599324, 0.8047449284887538, 0.67588471293097,
  0.8341849312938213, 0.5386730729525185, 0.29048600376113903, 0.42711660447869293,
  0.45893906436740917, 0.8158892286526265, 0.2808032833371331, 0.766423508353019, 0.378978683877487,
  0.7953000050470118, 0.3252167810711109, 0.28925263798188655, 0.5386681192746255,
  0.8468361918280614, 0.4840797061723261, 0.8962246651043678, 0.18738813515906322,
  0.10719769510859048, 0.510689031834236, 0.8455925751044168, 0.6876398740681742,
  0.39499803786695376, 0.35951155916428634, 0.9655213559668151, 0.7520517659437529,
  0.5841312458979868, 0.8279890673348932, 0.5759302805535726, 0.6642718760425945,
  0.8471952462743755, 0.20642012395997622, 0.15850258514356647, 0.868535998834911,
  0.4718939379785684, 0.34187554673505527, 0.6778616200430814, 0.8882822323818089,
  0.3675558943855898, 0.4253257761360838, 0.21867566057445764, 0.7776828997212379,
  0.8193984821746055, 0.5357277909088007, 0.8643383522661613, 0.7183533300110968,
  0.9174191674616058, 0.5475988148505272, 0.7615840685403645, 0.49116594180079287,
  0.43240352789714454, 0.19582388034383968, 0.1451966648502142, 0.31545594084847567,
  0.3300760300526325, 0.6315512337602882, 0.4367536361922759, 0.9501352819474942,
  0.14163786555242783, 0.8858743098186782, 0.15014286284557046, 0.7425282974169383,
  0.11235546835343994, 0.5263951111876938, 0.23228350408700477, 0.21131600543254986,
  0.6379572487065862, 0.8605348689739301, 0.2863787658758934, 0.21758793827012016,
  0.9327042671575136, 0.8295237796150722, 0.2788897582811479, 0.8779613300638471,
  0.32873540958035863, 0.7522490122670887, 0.5599074087983401, 0.4570405870811982,
  0.21268637414567806, 0.11479875653931802, 0.12097574312274446, 0.4623165285718075,
  0.2976529991919429, 0.5548128494041793, 0.83778106382022, 0.39500068137862443, 0.4926179726958432,
  0.5127940951996693, 0.3705030710188608, 0.47104264223612247, 0.9948892298345893,
  0.5523590026803187, 0.40156600237229956, 0.3925891142169734, 0.8794428682043874,
  0.17679250804897167, 0.3323204257247313, 0.2977731215712105, 0.3471528694961601,
  0.5100591702909311, 0.6770598671310811, 0.5301351017457311, 0.22590964033945438,
  0.5148687268710586, 0.24836991941173672, 0.5901030677620668, 0.5907170293416717,
  0.5071527797801118, 0.15768603578027476, 0.7778881436404314, 0.573043057586446,
  0.49816655999472403, 0.7997071331404768, 0.955993837877092, 0.18219353183759077,
  0.4202976850760213, 0.5721966586199931, 0.41522163622902986, 0.9109056154440481,
  0.3260479008785144, 0.7831174141804669, 0.896627170526519, 0.9119775999261468,
  0.44423650658068903, 0.2922756032045034, 0.6670251525149141, 0.9195024613920266,
  0.7668840720901544, 0.3468636533396153, 0.4127592639548101, 0.15030922357654833,
  0.40863108735900755, 0.34501015322717715, 0.2484530907741857, 0.6086760482053397,
  0.4879585978701143, 0.7384513145621736, 0.6567072700736617, 0.17718374113409574,
  0.6037055360806349, 0.881420265832852, 0.26488054762483015, 0.11948340710183429,
  0.9329935498068308, 0.42114307115959015, 0.14652873907898328, 0.38517656747779216,
  0.5324129789434828, 0.9295984068475125, 0.35462747630634606, 0.9646773102505148,
  0.5621705087596193, 0.7615655190030122, 0.40055333703181206, 0.24709260299885366,
  0.9493507819938503, 0.30367730181773045, 0.29766636876660313, 0.9194403212872995,
  0.8528774735425989, 0.13546411830300412, 0.539333275839845, 0.9133652183749352, 0.414190196953082,
  0.8840564108663059, 0.44862752382812854, 0.8328943077230424, 0.5714565847734098,
  0.6832624141653488, 0.10244259083688344, 0.6604484575625448, 0.796519801406683,
  0.4748879459615135, 0.6492370661765532, 0.198371294029151, 0.9975296660656016, 0.9709555019086453,
  0.27804513314019685, 0.1672414351757575, 0.7570450652559545, 0.21810645608367107,
  0.34542452049510985, 0.21373960398986613, 0.3449761478875797, 0.2898183045134186,
  0.21283829167200946, 0.8578453162849261, 0.42340794774265433, 0.8435033581028232,
  0.6212529971509746, 0.38923282752436905, 0.3773518198337522, 0.4507205861317094,
  0.8000826497416693, 0.255150429691914, 0.21262773933311768, 0.223731908672622, 0.6574132868857714,
  0.33842115201052125, 0.7472508558107671, 0.8350000858031222, 0.4238367932988154,
  0.6707500058437601, 0.17923702881607223, 0.1709034742993059, 0.6649272075362875,
  0.7805957364631817, 0.9182247480625747, 0.2716370598731107, 0.8893646000163864,
  0.8583030477321746, 0.24512475772718362, 0.34359355033359573, 0.8472957640617547,
  0.10317024201620227, 0.8236879975480447, 0.662009620691884, 0.8123183199219942, 0.770973867877141,
  0.10552708864095811, 0.5694655643960564, 0.3595658962430919, 0.10118961517097436,
  0.2957483088569008, 0.2908939062162966, 0.5140354718049869, 0.23665546824939393,
  0.9655201438007581, 0.635820249947917, 0.43277201044991476, 0.3179223614984882,
  0.8477169837920722, 0.9748242328551358, 0.8184385828148119, 0.19536986819819652,
  0.20645279334333216, 0.7007673196313497, 0.644136234117973, 0.7320184409878744, 0.956629821972041,
  0.1717735637857229, 0.5657344903871212, 0.4222966235272575, 0.93294839662223, 0.8374074012788021,
  0.6845259052204618, 0.9794903335200116, 0.1086488855748065, 0.979264594416191, 0.4970612002095167,
  0.5095724367454956, 0.4589724044843364, 0.33050039874072523, 0.12988681868824833,
  0.40817577316507314, 0.24003453022313814, 0.29901618468977703, 0.5465803007036072,
  0.988410927347866, 0.4538090349777022, 0.3655196344297301, 0.7210291069921269, 0.5855557579285726,
  0.139547073405829, 0.8568005994369756, 0.3200154537410467, 0.22797540029362812,
  0.5655763114184418, 0.2752621159382906, 0.9409393496619494, 0.15736582224161422,
  0.3620736348270813, 0.5080811749001487, 0.991682206766645, 0.4597020357395639, 0.9038550388744152,
  0.5736106629638609, 0.674425906718019, 0.5029605977090736, 0.36575903357730133,
  0.8599567247538868, 0.38812534304085744, 0.5852963318970978, 0.5857027316752618,
  0.2390961832084445, 0.5203386733569108, 0.8660604336141147, 0.6874033899754497,
  0.5899008215435518, 0.32616288420462475, 0.1892388849914686, 0.1461256698518968,
  0.6767942585633333, 0.4144498215941086, 0.8180824815415315, 0.3394272388559932,
  0.5867296331990555, 0.6727826776138339, 0.186438268737723, 0.9680256560058569, 0.9174705299887045,
  0.44024422070467606, 0.6627056993425251, 0.3381945246064225, 0.9032892374546615,
  0.5533466029597733, 0.3035091563440569, 0.6586765465851744, 0.6106286008050963,
  0.2847528133094309, 0.2689183978333019, 0.9564616450218026, 0.35523954217476794,
  0.2929283407798433, 0.920480897953325, 0.28107860865447765, 0.5908436744079314,
  0.48014568833663307, 0.5575918090665893, 0.5206225698246375, 0.31290768462283386,
  0.34384149615841486, 0.5372641365131132, 0.7766987024374522, 0.661207286491381, 0.842913943359662,
  0.3156406351420751, 0.5979877746868193, 0.9127784651578884, 0.852041396194943, 0.3854145443236012,
  0.3256780246463949, 0.5995460888126941, 0.569396772164459, 0.8850010884494892, 0.6122430817450276,
  0.19684622758403708, 0.7545879620229338, 0.3905672389123265, 0.8771404544187063,
  0.6022999370134504, 0.8050470219878614, 0.9893801546627284, 0.7869577244495752,
  0.47897091035569594, 0.7471222548256546, 0.40288085352173564, 0.297837357856552,
  0.22435348299386154, 0.7684373818877536, 0.2250694343547041, 0.4846160663090442,
  0.9121514204453067, 0.13519142670439976, 0.3484346933630912, 0.4202717741492993,
  0.3263849840579935, 0.17829299675897978, 0.8622392508075523,
];

import Heading from 'main/components/Heading';
import React from 'react';

interface TeamMemberProps extends React.HTMLAttributes<HTMLDivElement> {
  memberName: string;
  memberRole: string;
  memberImg: string;
}

export default function TeamMember(props: TeamMemberProps) {
  const { className, memberName, memberRole, memberImg } = props;

  return (
    <div
      className={`team-member flex flex-col items-center justify-center max-w-[350px] ${className}`}
    >
      <div className="w-fit max-w-[150px] rounded-full overflow-hidden border border-purple p-1">
        <img src={memberImg} alt="Member" className="rounded-full w-full h-full" />
      </div>
      <div className="flex flex-0 items-center gap-x-5 pt-5">
        <div className="flex-1">
          <svg width="100%" viewBox="0 0 171 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
              width="161.994"
              height="1"
              transform="matrix(-1 0 0 1 161.993 0.00195312)"
              fill="#5948C8"
              className="svg-elem-1"
            />
            <rect
              width="13.3811"
              height="1.19075"
              transform="matrix(-0.668951 -0.743307 -0.668951 0.743307 170.935 9.95312)"
              fill="#5948C8"
              className="svg-elem-2"
            />
            <rect
              width="153.894"
              height="1"
              transform="matrix(-1 0 0 1 164.694 3.00195)"
              fill="url(#paint0_linear_146_1383)"
              className="svg-elem-3"
            />
            <defs>
              <linearGradient
                id="paint0_linear_146_1383"
                x1="0.899967"
                y1="0.999771"
                x2="77.8476"
                y2="1.40481"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#5948C8" />
                <stop offset="1" stopColor="#5948C8" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="flex-0 flex flex-col h-fit max-w-[150px]">
          <Heading noSplit className="flex-0 text-center text-[14px] leading-5">
            {memberName}
          </Heading>
          <p className="font-altform uppercase flex-0 text-center text-[14px] text-[#AC9FFF] leading-5">
            {memberRole}
          </p>
        </div>
        <div className="flex-1 transform -scale-x-100">
          <svg width="100%" viewBox="0 0 171 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
              width="161.994"
              height="1"
              transform="matrix(-1 0 0 1 161.993 0.00195312)"
              fill="#5948C8"
              className="svg-elem-1"
            />
            <rect
              width="13.3811"
              height="1.19075"
              transform="matrix(-0.668951 -0.743307 -0.668951 0.743307 170.935 9.95312)"
              fill="#5948C8"
              className="svg-elem-2"
            />
            <rect
              width="153.894"
              height="1"
              transform="matrix(-1 0 0 1 164.694 3.00195)"
              fill="url(#paint0_linear_146_1383)"
              className="svg-elem-3"
            />
            <defs>
              <linearGradient
                id="paint0_linear_146_1383"
                x1="0.899967"
                y1="0.999771"
                x2="77.8476"
                y2="1.40481"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#5948C8" />
                <stop offset="1" stopColor="#5948C8" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
}

import { defaultConfig } from "@web3modal/ethers5/react";

export const projectId = process.env.REACT_APP_WALLET_CONNECT_ID;

export const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com'
}

export const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mywebsite.com', // origin must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
}

export const ethersConfig = defaultConfig({
  metadata,
})

export const modalTheme = {
    '--w3m-accent': '#fff',
    '--w3m-font-family': 'font-monsterOFFantasy',
    '--w3m-font-size-master': '10px',
  }
import { gsap } from 'gsap';
import { ReactComponent as TitleDecoration } from 'main/assets/images/page-title/titleDecoration.svg';
import React, { useEffect, useRef } from 'react';

const animationDuration = 0.5;

interface DivisorProps extends React.HTMLAttributes<HTMLDivElement> {}

export default function Divisor(props: DivisorProps) {
  const { className, ...rest } = props;
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap
        .timeline({
          defaults: {
            duration: animationDuration,
            ease: 'expo.inOut',
          },
          scrollTrigger: {
            trigger: container.current,
            start: 'top 100%',
            once: true,
          },
        })
        // Decoration
        .fromTo(
          [
            'svg .svg-elem-1',
            'svg .svg-elem-2',
            'svg .svg-elem-3',
            'svg .svg-elem-4',
            'svg .svg-elem-5',
            'svg .svg-elem-6',
            'svg .svg-elem-7',
            'svg .svg-elem-8',
          ],
          {
            scaleX: 0,
          },
          {
            scaleX: 1,
          },
          0.5
        )
        .fromTo(
          'svg .svg-elem-9',
          {
            strokeDashoffset: 34,
            strokeDasharray: 34,
            fill: 'transparent',
          },
          {
            strokeDashoffset: 0,
            fill: 'rgb(172, 159, 255)',
          },
          0.75
        )
        .fromTo(
          'svg .svg-elem-10',
          {
            strokeDashoffset: 62,
            strokeDasharray: 62,
          },
          {
            strokeDashoffset: 0,
          },
          0.75
        );
    }, container);

    return () => ctx.revert();
  }, [container]);

  return (
    <div
      ref={container}
      className={`flex justify-center items-center ${className || ''}`}
      {...rest}
    >
      <TitleDecoration />
    </div>
  );
}

import { useThree, useFrame } from '@react-three/fiber';
import { useDispatchEvent } from 'hooks/eventDispatcher';
import useDisposableMemo from 'hooks/useDisposableMemo';
import { useEffect, useRef } from 'react';
import { GlobalEventType } from 'utils/types';

import { CustomOrbitControls } from './CustomOrbitControls';

interface OrbitControlsProps {
  enabled?: boolean;
}

const DEFAULT_DISTANCE = 9.0;
const MAX_DISTANCE = 11.0;

export default function useOrbitControls(props: OrbitControlsProps) {
  const camera = useThree((s) => s.camera);
  const gl = useThree((s) => s.gl);
  const currentZoom = useRef(0.7);

  const { enabled = true } = props;

  const orbitControls = useDisposableMemo(() => {
    gl.setClearColor('#000000');
    const controls = new CustomOrbitControls(camera, gl.domElement);
    controls.enabled = false;
    controls.dampingFactor = 0.1;
    controls.maxDistance = MAX_DISTANCE;
    controls.minDistance = 0.7;
    controls.enableDamping = true;
    controls.rotateSpeed = 0.5;
    controls.setDistance(DEFAULT_DISTANCE);
    controls.setPolarAngle(Math.PI * 0.5);

    return controls;
  }, [camera, gl]);

  const dispatchZoomChanged = useDispatchEvent((zoom) => ({
    type: GlobalEventType.zoomChanged,
    zoom,
  }));

  useEffect(() => {
    orbitControls.enabled = enabled;
  }, [enabled, orbitControls]);

  useFrame(() => {
    if (!enabled) return;
    orbitControls?.update();
    const zoom = orbitControls.getCurrentZoom();

    if (currentZoom.current !== zoom) {
      currentZoom.current = zoom;
      dispatchZoomChanged(zoom);
    }
  });

  return { orbitControls };
}

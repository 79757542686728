import React, { useEffect, useState } from 'react';

import { ASSET_MAP } from './Chapter.constants';

interface ChapterProps {
  chapter: number;
  lightVariant?: boolean;
}

export default function Chapter(props: ChapterProps) {
  const { chapter, lightVariant = false } = props;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const { Title, Circle } = ASSET_MAP[chapter];

  return (
    <div className="bg-transparent h-screen flex flex-col items-center justify-center">
      <div className="flex flex-col items-center mb-4 md:mb-12">
        <p
          className={`transition-all transform ease-in-out delay-200 duration-[800ms] text-[10px] md:text-ch uppercase text-lily ${
            visible ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'
          } ${lightVariant ? 'text-violet' : 'text-lily'}`}
        >
          chapter {chapter}
        </p>
        <Title
          id="chapter-title"
          className={`transition-all ease-in-out delay-200 duration-[800ms] w-[277px] h-[99px] md:mt-2 md:w-[620px] md:h-[210px] z-20 ${
            visible ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'
          }`}
        />
      </div>
      <Circle
        className={`${lightVariant ? 'light-variant' : ''} ${
          chapter === 1 ? 'the-beginning' : ''
        } stroke-animation absolute w-[240px] md:w-[465px]`}
      />
    </div>
  );
}

import { useFrame } from '@react-three/fiber';
import { MITTARIA_PARTICLES_WINDOW } from 'common/constants';
import React, { useState } from 'react';
import OrbitingLines from 'rendering/components/OrbitingLines/OrbitingLines';
import SparkParticles from 'rendering/components/SparkParticles/SparkParticles';
import { Group, Object3D, Texture } from 'three';

const DEFAULT_PARTICLE_PROPS = {
  particleCount: 3000,

  rotationFrequency: 0.48,
  rotationSpeed: 0.08,

  rotationNoiseFrequency: 0.31,
  rotationNoiseSpeed: 0.09,
  rotationNoiseStrength: 0.27,

  flowHeight: 0.074,
  flowSpeed: 0.14,

  alphaFrequency: 0.45,
  alphaSpeed: 0.14,

  particleSize: 0.01,
  intensity: 2.6,
  color: '#c6f5fc',
};

export default function MitarriaParticles({ parent, map }: { parent: Object3D; map: Texture }) {
  const [groupRef, setGroupRef] = useState<Group | null>(null);

  useFrame(() => {
    if (!parent || !groupRef) return;
    groupRef.position.setFromMatrixPosition(parent.matrixWorld);
  });

  return (
    <group ref={setGroupRef} scale={[0.1, 0.1, 0.1]}>
      <OrbitingLines
        count={10}
        width={0.5}
        length={20.0}
        speed={0.9}
        window={MITTARIA_PARTICLES_WINDOW}
        color="#FFFFFF"
      />
      <group rotation={[0.0, -Math.PI * 0.8, 0.0]} position={[0, -0.13, 0]}>
        <SparkParticles
          map={map}
          particleCount={DEFAULT_PARTICLE_PROPS.particleCount}
          rotationFrequency={DEFAULT_PARTICLE_PROPS.rotationFrequency}
          rotationSpeed={DEFAULT_PARTICLE_PROPS.rotationSpeed}
          rotationNoiseFrequency={DEFAULT_PARTICLE_PROPS.rotationNoiseFrequency}
          rotationNoiseSpeed={DEFAULT_PARTICLE_PROPS.rotationNoiseSpeed}
          rotationNoiseStrength={DEFAULT_PARTICLE_PROPS.rotationNoiseStrength}
          flowHeight={DEFAULT_PARTICLE_PROPS.flowHeight}
          flowSpeed={DEFAULT_PARTICLE_PROPS.flowSpeed}
          alphaFrequency={DEFAULT_PARTICLE_PROPS.alphaFrequency}
          alphaSpeed={DEFAULT_PARTICLE_PROPS.alphaSpeed}
          particleSize={DEFAULT_PARTICLE_PROPS.particleSize}
          color={DEFAULT_PARTICLE_PROPS.color}
        />
      </group>
    </group>
  );
}

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import SliderButton from 'main/components/SliderButton/SliderButton';
import React from 'react';
import { createPortal } from 'react-dom';

interface ModalProps {
  children: React.ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
}
export default function Modal(props: ModalProps) {
  const { isOpen, onClose, children } = props;

  if (!isOpen) return null;
  return createPortal(
    <div className="flex justify-center items-center absolute h-screen w-screen top-0 left-0 bg-[#160D4D] bg-opacity-50 z-50">
      <div className="h-full w-full md:max-w-[1190px] md:max-h-[580px] bg-[#160D4D] p-2">
        <div className="relative h-full w-full bg-transparent border border-[#5948C8]">
          <div className="absolute top-0 right-0 mt-2 mr-2">
            <SliderButton
              onClick={onClose}
              action="close"
              className="min-h-[40px]"
              id="station-close"
            />
          </div>
          {children}
        </div>
      </div>
    </div>,
    document.body
  );
}

import SplitText from 'main/components/SplitText';
import React from 'react';

interface HeadingProps extends React.HTMLAttributes<HTMLTitleElement> {
  headingType?: string;
  onlyChars?: boolean;
  noSplit?: boolean;
  children: React.ReactNode;
}

export default function Heading(props: HeadingProps) {
  const { children, className, headingType, onlyChars, noSplit, ...rest } = props;

  return (
    <HeadingWrapper
      className={`${className || ''} 
      z-10 uppercase font-altform font-normal text-transparent bg-clip-text bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-indigo-100 via-gray-400 to-stone-700`}
      headingType={headingType}
      {...rest}
    >
      {(children && !noSplit && (
        <SplitText onlyChars={onlyChars} textToSplit={children.toString()} />
      )) ||
        children}
    </HeadingWrapper>
  );
}

function HeadingWrapper(props: HeadingProps) {
  const { children, className, headingType } = props;

  if (headingType === 'p') return <p className={className}>{children}</p>;
  if (headingType === 'h1') return <h1 className={className}>{children}</h1>;
  if (headingType === 'h2') return <h2 className={className}>{children}</h2>;
  if (headingType === 'h3') return <h3 className={className}>{children}</h3>;
  return <h4 className={`${className}`}>{children}</h4>;
}

import { useTexture } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import GLOW_TEXTURE from 'assets/images/GLOW-CIRCLE-0.png';
import GLOW_TEXTURE2 from 'assets/images/GLOW-CIRCLE-1.png';
import ATLAS_LEGENDARY_CHARMS from 'assets/images/legendary-items/legendary_charms.png';
import { LEGENDARY_ITEMS, LEGENDARY_ITEMS_ACTIVE_WINDOW } from 'common/constants';
import { gsap, Linear } from 'gsap';
import { useListenToEvent } from 'hooks/eventDispatcher';
import useDisposableMemo from 'hooks/useDisposableMemo';
import useScrollProgress from 'hooks/useScrollProgress';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import OrbitingLines from 'rendering/components/OrbitingLines';
import TextureAtlasBasicMaterial from 'rendering/materials/TextureAtlasBasicMaterial';
import {
  AdditiveBlending,
  Group,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  ShaderMaterial,
  Texture,
  Vector2,
  Vector3,
  sRGBEncoding,
} from 'three';
import { clamp, inverseLerp } from 'three/src/math/MathUtils';
import { DispatchItemInCarousel, GlobalEventType } from 'utils/types';

interface LegendaryItemProps {
  glowMaterial: MeshBasicMaterial;
  additiveGlowMaterial: MeshBasicMaterial;
  itemMaterial: ShaderMaterial;
  link?: string;
}

const worldPosTarget = new Vector3();

function LegendaryItemSphere(props: LegendaryItemProps) {
  const { glowMaterial, additiveGlowMaterial, itemMaterial, link } = props;
  const camera = useThree((s) => s.camera);
  const [ref, setRef] = useState<Group | null>(null);
  const [glowRef, setGlowRef] = useState<Mesh | null>(null);
  const [glow2Ref, setGlow2Ref] = useState<Mesh | null>(null);

  useFrame(() => {
    if (!ref) return;
    camera.getWorldPosition(worldPosTarget);
    ref.lookAt(worldPosTarget);
  });

  function onLinkClick() {
    if (!link) return;
    window.open(link, '_blank');
  }

  useEffect(() => {
    if (!glowRef) return;
    const offset = Math.PI * 4.0 * Math.random();
    gsap.fromTo(
      glowRef.rotation,
      { z: offset },
      {
        z: Math.PI * 2.0 + offset,
        repeat: -1,
        duration: 3.0,
        ease: Linear.easeIn,
      }
    );
  }, [glowRef]);

  useEffect(() => {
    if (!glow2Ref) return;
    const offset = Math.PI * 4.0 * Math.random();
    gsap.fromTo(
      glow2Ref.rotation,
      { z: offset },
      {
        z: Math.PI * 2.0 + offset,
        repeat: -1,
        duration: 3.0,
        ease: Linear.easeIn,
      }
    );
  }, [glow2Ref]);
  const [hovered, setHovered] = useState(false);

  function onPointerEnter() {
    if (!link) return;
    if (hovered !== true) setHovered(true);
  }

  function onPointerLeave() {
    if (!link) return;
    if (hovered !== false) setHovered(false);
  }

  function onClick() {
    if (!link) return;
    window.open(link, '_blank');
  }

  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto';
    return () => {
      document.body.style.cursor = 'auto';
    };
  }, [hovered]);

  return (
    <group ref={setRef}>
      <mesh ref={setGlowRef} position={[0.0, 0.0, -0.01]}>
        <planeGeometry args={[3.3, 3.3, 2, 2]} />
        <primitive attach="material" object={additiveGlowMaterial} />
      </mesh>
      <mesh ref={setGlow2Ref} position={[0.0, 0.0, 0.0]}>
        <planeGeometry args={[3.3, 3.3, 2, 2]} />
        <primitive attach="material" object={glowMaterial} />
      </mesh>
      <mesh
        onPointerEnter={onPointerEnter}
        onPointerOut={onPointerLeave}
        onPointerLeave={onPointerLeave}
        onClick={onClick}
        position={[0.0, 0.0, 0.01]}
      >
        <planeGeometry args={[3.6, 3.6, 2, 2]} />
        <primitive attach="material" object={itemMaterial} />
      </mesh>
      <group scale={[1.5, 1.5, 1.5]}>
        <OrbitingLines count={10} width={0.75} length={15.0} speed={0.4} color="#FFFFFF" />
      </group>
    </group>
  );
}

interface LegendaryItemCarouselProps {
  radius?: number;
}

function scaleDownAnimation(object: Object3D) {
  gsap.to(object.scale, {
    x: 0.9,
    y: 0.9,
    z: 0.9,
    duration: 0.3,
  });
}

function scaleUpAnimation(object: Object3D) {
  gsap.to(object.scale, {
    x: 1.5,
    y: 1.5,
    z: 1.5,
    duration: 0.5,
  });
}

function LegendaryItemCarousel(props: LegendaryItemCarouselProps) {
  const { radius = 10.0 } = props;
  const [ref, setRef] = useState<Group | null>(null);
  const [moveRef, setMoveRef] = useState<Group | null>(null);
  const itemsAtlas = useTexture(ATLAS_LEGENDARY_CHARMS);
  const itemMaterials: TextureAtlasBasicMaterial[] = useMemo(
    () =>
      LEGENDARY_ITEMS.map(
        ({ atlasUvOrigin }) =>
          new TextureAtlasBasicMaterial({
            map: itemsAtlas,
            transparent: true,
            uvCoordinates: {
              origin: atlasUvOrigin,
              size: new Vector2(0.25, 0.25),
            },
          })
      ),
    [itemsAtlas]
  );
  const selectedItem = useRef(0);

  const len = itemMaterials.length;

  const rotate = () => {
    if (!ref) return;
    const currentRotation = ref.rotation.y;
    const targetRotation = (1.0 - selectedItem.current / len) * Math.PI * 2.0;
    const numberOfFullRotations = Math.floor(ref.rotation.y / Math.PI / 2);
    const possibleRotations = [
      numberOfFullRotations - 1,
      numberOfFullRotations,
      numberOfFullRotations + 1,
    ].map((r) => r * Math.PI * 2 + targetRotation);

    const smallestRotation = possibleRotations.sort(
      (a, b) => Math.abs(a - currentRotation) - Math.abs(b - currentRotation)
    )[0];

    gsap.to(ref.rotation, {
      y: `${smallestRotation}`,
      duration: 0.3,
      ease: 'none',
    });
  };

  const glowTextures = useTexture([GLOW_TEXTURE, GLOW_TEXTURE2], (textures) => {
    (textures as Texture[]).forEach((t) => {
      t.encoding = sRGBEncoding;
    });
  });

  const additiveGlowMaterial = useDisposableMemo(
    () =>
      new MeshBasicMaterial({
        transparent: true,
        map: glowTextures[0],
        opacity: 0.8,
        blending: AdditiveBlending,
      })
  );
  const glowMaterial = useDisposableMemo(
    () => new MeshBasicMaterial({ transparent: true, map: glowTextures[1] })
  );

  const animateCarousel = useCallback(() => {
    if (!ref) return;
    ref.children.forEach((obj, i) => {
      if (i === selectedItem.current) scaleUpAnimation(obj);
      else scaleDownAnimation(obj);
    });
  }, [ref]);

  useEffect(() => {
    animateCarousel();
  }, [ref, animateCarousel]);

  useListenToEvent(GlobalEventType.legendaryItemCarousel, (event: DispatchItemInCarousel) => {
    selectedItem.current = event.currentItem as number;
    rotate();
    animateCarousel();
  });

  const [visible, setVisible] = useState(true);

  useScrollProgress((p) => {
    if (!ref || !moveRef) return;
    const progress = p;
    const { start, end, transition } = LEGENDARY_ITEMS_ACTIVE_WINDOW;
    const startTransition = start - transition;
    const endTransition = end + transition;

    const active = startTransition <= progress && progress <= endTransition;
    if (visible !== active) setVisible(active);

    let scale = 1;
    if (progress < start) scale = clamp(inverseLerp(startTransition, start, progress), 0, 1);
    if (progress > end) scale = 1 - clamp(inverseLerp(end, endTransition, progress), 0, 1);

    moveRef.position.y = (1.0 - scale) * 10.0;

    ref.children.forEach((obj, i) => {
      if (i === selectedItem.current) obj.scale.set(scale * 1.5, scale * 1.5, scale * 1.5);
      else obj.scale.set(scale * 0.9, scale * 0.9, scale * 0.9);
    });
  });

  return (
    <group ref={setMoveRef}>
      <group rotation={[0, 0, Math.PI / 12.0]} visible={visible}>
        <group ref={setRef}>
          {itemMaterials.map((material, index) => (
            <group
              position={[
                Math.sin((index / len) * Math.PI * 2.0) * radius,
                0,
                Math.cos((index / len) * Math.PI * 2.0) * radius,
              ]}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <LegendaryItemSphere
                link={LEGENDARY_ITEMS[index].link}
                itemMaterial={material}
                glowMaterial={glowMaterial}
                additiveGlowMaterial={additiveGlowMaterial}
              />
            </group>
          ))}
        </group>
      </group>
    </group>
  );
}

export default memo(LegendaryItemCarousel);

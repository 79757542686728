import { INTERACTION_BREAKS, OVERLAYS } from 'common/constants';
import { SOUND_DATA } from 'pages/Home/Home.constants';
import { Object3D, Vector2 } from 'three';

export type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

export enum GlobalEventType {
  legendaryItemCarousel = 'LEGENDARY_ITEM_IN_CAROUSEL',
  setMute = 'SET_MUTE',
  selectStation = 'SELECT_STATION',
  animateToStation = 'ANIMATE_TO_STATION',
  finishedIntroAnimation = 'INTRO_ANIMATION',
  zoomIn = 'ZOOM_IN',
  zoomOut = 'ZOOM_OUT',
  zoomChanged = 'ZOOM_CHANGED',
}

type CustomEvent<T = undefined> = { type: GlobalEventType; message?: T };

export interface DispatchMute extends CustomEvent<boolean> {
  type: GlobalEventType.setMute;
  message: boolean;
}
export interface DispatchItemInCarousel extends CustomEvent<number> {
  type: GlobalEventType.legendaryItemCarousel;
  currentItem: number;
  direction: number;
}

export interface DispatchFinishedIntroAnimation extends CustomEvent<boolean> {
  type: GlobalEventType.finishedIntroAnimation;
}

export interface DispatchZoomIn extends CustomEvent {
  type: GlobalEventType.zoomIn;
}

export interface DispatchZoomOut extends CustomEvent {
  type: GlobalEventType.zoomOut;
}

export interface DispatchZoomChanged extends CustomEvent<number> {
  type: GlobalEventType.zoomChanged;
  zoom: number;
}

export interface DispatchSelectStation extends CustomEvent<Object3D> {
  type: GlobalEventType.selectStation;
  index: number;
}

export interface DispatchAnimateToStation extends CustomEvent<Object3D> {
  type: GlobalEventType.animateToStation;
  object?: Object3D;
  defaultRotationOffset?: number;
}

export type GlobalEvent =
  | DispatchItemInCarousel
  | DispatchMute
  | DispatchSelectStation
  | DispatchAnimateToStation
  | DispatchFinishedIntroAnimation
  | DispatchZoomIn
  | DispatchZoomOut
  | DispatchZoomChanged;

export type OverlayElement = ArrElement<typeof OVERLAYS>;

export interface AtlasUvCoordinates {
  origin: Vector2;
  size: Vector2;
  padding?: Vector2;
}

export type InteractionBreak = ArrElement<typeof INTERACTION_BREAKS>;

export type VoiceOverData = typeof SOUND_DATA.legendaryCharms;
export type VoiceOverTranscription = ArrElement<VoiceOverData['subtitleSections']>;

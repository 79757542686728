import { ReactComponent as ButtonBorder } from 'assets/images/UI/button-border.svg';
import { ReactComponent as WingLeft } from 'assets/images/UI/button-wing-left.svg';
import { ReactComponent as WingRight } from 'assets/images/UI/button-wing-right.svg';
import React from 'react';

interface ButtonProps {
  children: React.ReactNode;
  borderless?: boolean;
  onClick?: () => void;
}

export default function Button(props: ButtonProps) {
  const { children, borderless, onClick } = props;

  const content =
    typeof children === 'string' ? (
      <p className="text-[16px] md:text-[18px] uppercase text-lily group-hover:text-[white]">
        {children}
      </p>
    ) : (
      children
    );

  return (
    <button
      type="button"
      onClick={onClick}
      className="relative px-6 py-2.5 min-w-[240px] md:min-w-[300px] group"
    >
      {content}
      {!borderless && (
        <>
          <WingLeft className="transition-all duration-200 ease-out absolute -left-3.5 bottom-0 top-0 mt-auto mb-auto opacity-0 scale-0 group-hover:opacity-100 group-hover:scale-100" />
          <ButtonBorder className="z-10 absolute left-0 top-0 mt-auto mb-auto bottom-0 w-full" />
          <WingRight className="transition-all duration-200 ease-out absolute -right-3.5 bottom-0 top-0 mt-auto mb-auto opacity-0 scale-0 group-hover:opacity-100 group-hover:scale-100" />
        </>
      )}
    </button>
  );
}

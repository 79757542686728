import { gsap } from 'gsap';
import placeholder2 from 'main/assets/images/seasons-page/placeholder-2.png';
import placeholder3 from 'main/assets/images/seasons-page/placeholder-3.png';
import placeholder4 from 'main/assets/images/seasons-page/placeholder-4.jpg';
import placeholder from 'main/assets/images/seasons-page/placeholder.png';
import PageTitle from 'main/components/PageTitle/PageTitle';
import SeasonPoster from 'main/components/SeasonPoster/SeasonPoster';
import SkyBg from 'main/components/SkyBg/SkyBg';
import Slider from 'main/components/Slider/Slider';
import Layout from 'main/layouts/Layout';
import React, { useEffect, useState } from 'react';

export default function NewSeasons() {
  const [seasons, setSeasons] = useState<Season[]>([
    {
      id: 1,
      title: 'The Genesis',
      subTitle: 'Access',
      link: '/the-genesis',
      imagePosition: '20% 30%',
      imageUrl: placeholder,
    },
    {
      id: 2,
      title: 'Way to Gods',
      subTitle: 'Creator',
      link: '/season-2',
      imagePosition: '50% 50%',
      imageUrl: placeholder2,
      isDisabled: true,
    },
    {
      id: 3,
      title: '12 Zodiacs',
      subTitle: 'spirituality',
      link: '/season-tbd',
      imagePosition: '50% 50%',
      imageUrl: placeholder3,
      isDisabled: true,
    },
    {
      id: 4,
      title: 'Coming soon',
      link: '/season-tbd',
      imagePosition: '50% 50%',
      imageUrl: placeholder4,
      isDisabled: true,
    },
  ]);

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.from('.season-poster', {
        autoAlpha: 0,
        ease: 'power4',
        duration: 1.25,
        stagger: { amount: 0.5 },
      });
    });

    return () => ctx.revert();
  }, []);

  return (
    <Layout hideFooter>
      <div className="lg:hidden">
        <div className="pt-[150px] px-5">
          <PageTitle pageHeading="EXPLORE THEM ALL" pageTitle="Seasons" />
        </div>
        <div className="relative flex justify-center items-center min-h-[50vh] pt-[50px] pb-[25px]">
          <Slider seasons={seasons} className="z-10" />
          <SkyBg className="flex items-center" />
        </div>
      </div>
      <div className="hidden lg:flex flex-row items-stretch justify-evenly w-full h-screen gap-1">
        {seasons.map((season) => (
          <SeasonPoster season={season} />
        ))}
      </div>
    </Layout>
  );
}

import Title from 'main/components/Title';
import React from 'react';

interface IconParagraphProps {
  iconSrc: string;
  title: string;
  children: string;
}

export default function IconParagraph(props: IconParagraphProps) {
  const { title, children, iconSrc } = props;
  return (
    <div className="icon flex 2xl:w-[450px] w-[375px] gap-4">
      <div className="h-[45px] aspect-square flex items-center justify-center rounded-full p-1 border border-brigth-purple">
        <div className="h-full aspect-square flex items-center justify-center rounded-full p-2 bg-dark-purple">
          <img src={iconSrc} alt="Icon" className="h-full w-full object-contain" />
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <Title titleType="h3" className="text-[18px]" noDivided>
          {title}
        </Title>
        <p className="font-altform text-[14px]">{children}</p>
      </div>
    </div>
  );
}

import { ReactComponent as Discord } from 'assets/images/UI/ic-discord.svg';
import { ReactComponent as Instagram } from 'assets/images/UI/ic-instagram.svg';
import { ReactComponent as Twitter } from 'assets/images/UI/ic-twitter.svg';
import { ReactComponent as Circle } from 'assets/images/UI/social-circle.svg';
import { ReactComponent as Ornament } from 'assets/images/UI/social-ornament.svg';
import { ReactComponent as WingLeft } from 'assets/images/UI/social-wing-left.svg';
import { ReactComponent as WingRight } from 'assets/images/UI/social-wing-right.svg';
import { GaTrackingCategory, GaTrackingAction } from 'common/constants';
import React from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';

const SOCIALS = {
  // TODO: replace with actual links
  instagram: { link: 'https://www.instagram.com/', Icon: Instagram },
  twitter: { link: 'https://twitter.com/Mittaria_Origin', Icon: Twitter },
  discord: { link: 'https://discord.com/', Icon: Discord },
};

interface SocialIconsProps {
  type: keyof typeof SOCIALS;
}

export default function SocialIcon(props: SocialIconsProps) {
  const { type } = props;
  const { link, Icon } = SOCIALS[type];

  const animationConfig = 'transition-all ease-in-out duration-500';

  function onClick() {
    if (type === 'twitter')
      ReactGA.event({
        category: GaTrackingCategory.default,
        action: GaTrackingAction.twitterButton,
      });
  }

  return (
    <Link
      onClick={onClick}
      to={link}
      target="_blank"
      rel="noreferrer"
      className={`relative ${animationConfig} group hover:-translate-y-6 scale-75 md:scale-100`}
    >
      <WingLeft
        className={`${animationConfig} absolute top-3 -left-3.5 opacity-0 group-hover:opacity-100 scale-70 group-hover:scale-100`}
      />
      <Ornament
        className={`${animationConfig} absolute bottom-0 inset-x-0 opacity-0 group-hover:opacity-100`}
      />
      <Icon className="absolute inset-0 m-auto" />
      <WingRight
        className={`${animationConfig}  absolute -right-3.5 top-3 opacity-0 group-hover:opacity-100 scale-70 group-hover:scale-100`}
      />
      <Circle className="relative" />
    </Link>
  );
}

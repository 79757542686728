import quickMenuCharms from 'assets/images/UI/quick-menu-charms.png';
import quickMenuQuest from 'assets/images/UI/quick-menu-quest.png';
import quickMenuStory from 'assets/images/UI/quick-menu-story.png';
import quickMenuTeam from 'assets/images/UI/quick-menu-team.png';
import Chapter from 'overlays/Chapter/Chapter';
import FinalOverlay from 'overlays/FinalOverlay/FinalOverlay';
import LegendaryItemCarouselOverlay from 'overlays/LegendaryItemCarouselOverlay/LegendaryItemCarouselOverlay';
import Team from 'overlays/Team/Team';
import { SOUND_DATA } from 'pages/Home/Home.constants';
import React from 'react';
import ReactGA from 'react-ga4';
import { Vector2 } from 'three';
import { AudioSource, play } from 'utils/sound';

export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
export const GA_ID = process.env.REACT_APP_GA_ID as string;

export const SCROLL_CONTAINER_ID = 'scroll_container';

export const ENTER_TRANSITION = {
  start: 0.025,
  end: 0.04,
  reverse: true,
};

export const BLACKNESS_IN_TRANSITION = {
  start: 0.265,
  end: 0.279,
  reverse: false,
};
export const BLACKNESS_OUT_TRANSITION = {
  start: 0.467,
  end: 0.458,
  reverse: false,
};

export const REVEAL_WINDOW = { start: 0.15, end: 0.165 };
export const REVEAL_CLOUD_WINDOW = { start: 0.07, end: 0.15 };

export const MITTARIA_PARTICLES_WINDOW = {
  start: REVEAL_WINDOW.start + 0.005,
  end: 0.274,
  startTransitionDuration: 0.002,
  endTransitionDuration: 0.01,
};

export const BACKGROUND_SWITCH_POINT =
  BLACKNESS_IN_TRANSITION.start +
  (BLACKNESS_OUT_TRANSITION.end - BLACKNESS_IN_TRANSITION.start) / 2;

const CHAPTER_DURATION = 0.008;

export const ANIMATION_SKIP = { start: 0.525, end: 0.645 };
export const AUTOPLAY_STOP = ANIMATION_SKIP.start;

export const LEGENDARY_ITEMS_ACTIVE_WINDOW = { start: 0.503, end: 0.655, transition: 0.005 };
export const VOID_ACTIVE_WINDOW = { start: 0.37, end: 0.454, transition: 0.003 };
export const NO_AUTOPLAY_WINDOW = { start: 0.69, end: 0.82 };

export const DEFAULT_INTERACTION_BREAK_ACTIVATION_DELTA = 0.005;
export const INTERACTION_BREAKS = [
  {
    start: 0.1465,
    activationDelta: DEFAULT_INTERACTION_BREAK_ACTIVATION_DELTA,
    vignette: true,
    pressToText: 'reveal Mitria',
    sideEffect: () => {
      ReactGA.event({
        category: GaTrackingCategory.default,
        action: GaTrackingAction.mitriaRevealButton,
      });
      play(AudioSource.sfxMitriaReveal);
    },
    voiceOverData: SOUND_DATA.mittriaReveal,
    soundEffect: AudioSource.sfxMitriaReveal,
  },
  {
    start: 0.35,
    activationDelta: DEFAULT_INTERACTION_BREAK_ACTIVATION_DELTA,
    vignette: true,
    isVoid: true,
    scrollClear: true,
    voiceOverData: SOUND_DATA.theVoid,
  },
  {
    start: 0.42,
    activationDelta: DEFAULT_INTERACTION_BREAK_ACTIVATION_DELTA,
    vignette: true,
    isVoid: true,
    pressToText: 'see the vision',
    voiceOverData: SOUND_DATA.legendaryCharms,
    sideEffect: () => {
      ReactGA.event({
        category: GaTrackingCategory.default,
        action: GaTrackingAction.visionButton,
      });
    },
  },
  {
    start: 0.525,
    scrollClear: true,
    activationDelta: DEFAULT_INTERACTION_BREAK_ACTIVATION_DELTA,
  },
  {
    start: NO_AUTOPLAY_WINDOW.start,
    isFlying: true,
    activationDelta: DEFAULT_INTERACTION_BREAK_ACTIVATION_DELTA,
  },
  {
    start: 0.87,
    scrollClear: true,
    voiceOverData: SOUND_DATA.jointTheQuest,
    activationDelta: DEFAULT_INTERACTION_BREAK_ACTIVATION_DELTA,
  },
];

export const OVERLAYS = [
  {
    start: 0,
    duration: CHAPTER_DURATION,
    render: () => <Chapter chapter={1} />,
    end: 0.038,
    title: 'Beginning',
    jumpTo: 0.0,
    quickMenuImage: quickMenuStory,
    voiceOver: SOUND_DATA.theBeginning,
  },
  {
    start: 0.477,
    duration: CHAPTER_DURATION,
    render: () => <Chapter chapter={2} />,
  },
  {
    start: LEGENDARY_ITEMS_ACTIVE_WINDOW.start,
    end: LEGENDARY_ITEMS_ACTIVE_WINDOW.end,
    render: () => <LegendaryItemCarouselOverlay />,
    title: 'Legendary Charms',
    jumpTo: 0.525,
    quickMenuImage: quickMenuCharms,
  },
  {
    start: 0.82,
    end: 0.84,
    duration: CHAPTER_DURATION,
    render: () => <Chapter chapter={3} />,
  },
  {
    start: 0.87,
    end: 0.9,
    render: () => <Team />,
    title: 'Team',
    quickMenuImage: quickMenuTeam,
    jumpTo: 0.87,
  },
  {
    start: 0.93,
    duration: CHAPTER_DURATION,
    render: () => <Chapter chapter={4} />,
  },
  {
    start: 0.97,
    end: 1,
    render: () => <FinalOverlay />,
    title: 'Join the Quest',
    quickMenuImage: quickMenuQuest,
    jumpTo: 1.0,
    voiceOver: SOUND_DATA.jointTheQuest,
  },
];

export const LEGENDARY_ITEMS = [
  {
    atlasUvOrigin: new Vector2(0, 0.75),
    name: 'Hourglass',
    description:
      'The Hourglass embodies wisdom, reminding us that time is precious and must be used wisely.',
  },
  {
    atlasUvOrigin: new Vector2(0.25, 0.75),
    name: 'Heart',
    description:
      "The Heart symbolizes love, encompassing this powerful emotion's complexities and depths.",
  },
  {
    atlasUvOrigin: new Vector2(0.5, 0.75),
    name: 'Star',
    description:
      'The Star represents hope and optimism, inspiring us to reach the heavens and believe in a brighter future.',
  },
  {
    atlasUvOrigin: new Vector2(0.75, 0.75),
    name: 'Flame',
    description:
      'The Flame embodies passion in all forms, from the fiery intensity of desire to the gentle warmth of affection.',
  },
  {
    atlasUvOrigin: new Vector2(0, 0.5),
    name: 'Coloring Wheel',
    description:
      'The Color Wheel is the root of creativity, offering infinite possibilities for self-expression and artistic exploration.',
  },
  {
    atlasUvOrigin: new Vector2(0.25, 0.5),
    name: 'Moon Lamp',
    description:
      'The Moon Lamp has the power to create and grant wishes, reflecting the dreams and aspirations of those who gaze upon it.',
  },
  {
    atlasUvOrigin: new Vector2(0.5, 0.5),
    name: 'Clover',
    description:
      'The Clover symbolizes good fortune and luck, reminding us to cherish the blessings that come our way.',
  },
  {
    atlasUvOrigin: new Vector2(0.75, 0.5),
    name: 'Lotus',
    description: 'The Lotus represents kindness, inspiring compassion, and empathy towards others.',
  },
    {
    atlasUvOrigin: new Vector2(0, 0.25),
    name: 'Immortal Key',
    description: 'Unlock the secrets of the Mortal Realm',
    link: 'https://zealy.io/c/mittariaorigin/questboard'
  },
  {
    atlasUvOrigin: new Vector2(0.25, 0.25),
    name: 'Tesseract',
    description:
      'The Tesseract unlocks the secrets of science, discovery, and intelligence, revealing the mysteries of the universe.',
  },
  {
    atlasUvOrigin: new Vector2(0.5, 0.25),
    name: 'Shard',
    description:
      'The Shard represents diversity, reminding us that every individual is unique and valuable in their way.',
  },
];

export enum GaTrackingAction {
  enterExperienceButton = 'Enter Experience Button',
  enterExperienceNoSoundButton = 'Enter Experience No Sound Button',
  mitriaRevealButton = 'Mitria Reveal Button',
  visionButton = 'Vision Button',
  twitterButton = 'Twitter Button',
  endReached = 'End Reached',
}

export enum GaTrackingCategory {
  default = 'User Actions',
}

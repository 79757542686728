import { gsap } from 'gsap';
import leftDecoration from 'main/assets/images/button/button-decoration-left.svg';
import iconSrc from 'main/assets/images/seasons-page/icon-2.png';
import Divisor from 'main/components/Divisor';
import Title from 'main/components/Title';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

interface SeasonPosterProps extends React.HTMLAttributes<HTMLDivElement> {
  season: Season;
}

export default function SeasonPoster(props: SeasonPosterProps) {
  const { className, season } = props;
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      if (container.current) {
        const tl = gsap
          .timeline({ paused: true, defaults: { ease: 'power4.inOut', duration: 0.75 } })
          .to('.veil', { autoAlpha: 0 });

        container.current.addEventListener('mouseenter', () => tl.play());
        container.current.addEventListener('mouseleave', () => tl.reverse());
      }
    }, container);

    return () => ctx.revert();
  }, [container]);

  return (
    <div
      className={`season-poster w-full h-full relative opacity-90 overflow-hidden border-r border-l border-purple ${
        className || ''
      } ${season.isDisabled && 'pointer-events-none select-none'}`}
      ref={container}
    >
      <Link
        to={season.link}
        className={`w-full h-full relativ ${className || ''} ${
          season.isDisabled && 'pointer-events-none select-none'
        }`}
      >
        <div className="veil absolute w-full h-full z-10 inset-0 bg-opacity-80 bg-dark-purple" />

        <div className="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full p-4 text-center flex flex-col items-center justify-center">
          <div className="flex-initial">
            {(season.isDisabled && (
              <div className="h-[40px] aspect-square flex items-center justify-center rounded-full p-1 border border-brigth-purple mx-auto">
                <div className="h-full aspect-square flex items-center justify-center rounded-full p-2 bg-dark-purple">
                  <img src={iconSrc} alt="Icon" className="h-full w-full object-contain" />
                </div>
              </div>
            )) || <div className="h-[40px]" />}
          </div>
          <Title titleType="h2" color="white" className="flex-1 xl:text-h2 text-[24px]">
            {season.title}
          </Title>
          <Divisor className="max-w-xs mx-auto my-3" />
          <Title titleType="h3" color="white" className="flex-1 xl:text-h3 text-[18px]">
            {season.subTitle}
          </Title>
          <span className={`${season.subTitle ? 'hidden' : 'text-h3 text-[transparent]'}`}>
            null
          </span>
        </div>
        <div className="absolute z-10 bottom-0 left-0 w-full min-h-[20%] flex items-end justify-end uppercase gap-6 bg-gradient-to-t from-dark-purple">
          <div className="flex items-center justify-end uppercase gap-6 px-6 pb-2">
            <div>
              <img src={leftDecoration} alt="introduction bg" className="w-[50px] h-full " />
            </div>
            <Title className="xl:text-h3 text-h5 pr-1">{`Season ${season.id}`}</Title>
          </div>
        </div>
        <img
          src={season.imageUrl}
          alt="introduction bg"
          style={{ objectPosition: season.imagePosition }}
          className={`relative z-0 w-full h-full object-cover `}
        />
      </Link>
    </div>
  );
}

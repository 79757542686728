import React, { useMemo } from 'react';

interface SplitTextProps {
  onlyChars?: boolean;
  noChars?: boolean;
  textToSplit?: string;
  children?: React.ReactNode;
}

export default function SplitText(props: SplitTextProps) {
  const { children, textToSplit, noChars, onlyChars } = props;

  const words = useMemo(() => {
    if (onlyChars) {
      if (textToSplit) return textToSplit.split('');
      if (children) return children.toString().split('');
    } else {
      if (textToSplit) return textToSplit.split(' ');
      if (children) return children.toString().split(' ');
    }

    return null;
  }, [textToSplit, children, onlyChars]);

  if (words) {
    return (
      <>
        {words.map((word, i) => (
          <span
            key={word + Math.random()}
            className="inline-flex overflow-hidden whitespace-pre-wrap p-0"
          >
            {(!noChars &&
              !onlyChars &&
              word.split('').map((char) => (
                <span key={char + Math.random()} className="inline-flex whitespace-pre-wrap p-0">
                  {char}
                </span>
              ))) ||
              word}
            {!onlyChars && i < words.length - 1 ? '\xA0' : ''}
          </span>
        ))}
      </>
    );
  }

  return null;
}

import { ReactComponent as DragIcon } from 'main/assets/images/scene-ui/360.svg';
import Header from 'main/components/Header';
import SceneMap from 'main/components/SceneMap';
import SceneZoom from 'main/components/SceneZoom';
import Socials from 'main/components/Socials';
import StationSection from 'main/components/StationSection';
import VolumeButton from 'main/components/VolumeButton';
import { StationContext } from 'main/store/StationProvider';
import React, { useContext } from 'react';

export default function SceneUI() {
  const { activeStation } = useContext(StationContext);

  return (
    <div className="fixed inset-0 z-30 text-white pointer-events-none">
      <Header className="pointer-events-auto" />
      <div className="fixed top-0 right-0 h-full w-fit md:flex hidden items-center justify-end p-5">
        <SceneZoom className="pointer-events-auto" />
      </div>
      <div className="relative h-full w-full flex items-end justify-center py-5 px-8 bg-no-repeat bg-fill bg-bottom">
        <div className="flex-1 pointer-events-auto lg:block hidden">
          <SceneMap />
        </div>
        {activeStation < 0 && (
          <div className="flex-auto flex items-center lg:justify-center justify-end relative p-5 pointer-events-auto">
            <div className="text-center">
              <p className="font-altform text-[13px] uppercase pb-1 m-0">Drag to rotate</p>
              <p className="font-monsterOfFantasy text-[16px] text-white pb-1 m-0">360°</p>
            </div>
            <DragIcon className="absolute bottom-0 lg:left-1/2 left-auto lg:right-auto right-0 lg:transform lg:-translate-x-1/2 max-w-[150px] w-full h-auto" />
          </div>
        )}
        <div className="flex-1 flex items-center justify-end pointer-events-auto">
          <Socials className="lg:flex hidden" />
          <VolumeButton className="xl:hidden ml-2" />
        </div>
        <div className="absolute bottom-0 pointer-events-auto">
          {activeStation > -1 && <StationSection />}
        </div>
      </div>
    </div>
  );
}

import Button from 'components/Button/Button';
import React from 'react';
import { Link } from 'react-router-dom';

export default function TermsGenesis() {
  return (
    <div className="container">
      <h3>1. About these Terms of Sale and the contract between the Buyer and the Seller</h3>
      <p>
        1.1 These Terms of Sale apply to the sale and purchase of Mittaria Genesis Collection. They
        also specify the restrictions to the Artwork (as defined in the Mittaria Genesis Collection
        Terms and Conditions). A legally binding agreement incorporating these Terms of Sale will
        come into existence between the creator (in the primary sale/mint) or seller of the Mittaria
        Genesis Collection (in the secondary sale), collectively known as (“Seller”) and the
        minter/buyer of the Mittaria Genesis Collection (Buyer) when the Buyer’s offer is accepted
        by the Seller, as determined or confirmed by the website (Website) where the sale is
        concluded (Contract for Sale). No other person will be a party to the Contract for Sale
        between the Seller and the Buyer.
      </p>

      <h3>2. No right to cancel</h3>

      <p>
        Due to the irreversible nature of all blockchain transactions and the fact that the
        purchase and delivery of a Mittaria Genesis Collection takes place immediately and is
        irreversible, the Buyer consents to immediate performance of the Contract for Sale and
        acknowledges that the Buyer will lose their right of withdrawal from the Contract for Sale
        when they click the button to mint and purchase a Mittaria Genesis Collection, at which
        point the Artwork will be embedded in the Mittaria Genesis Collection and recorded on a
        blockchain. This means that the Buyer will not have the right to cancel their purchase and
        receive a refund. This does not affect any rights the Buyer may have under any applicable
        laws, where such rights cannot be legally excluded.
      </p>

      <h3>3. Payment and the delivery of the Mittaria Genesis Collection</h3>

      <p>
        3.1 As a pre-condition to the purchase of the Mittaria Genesis Collection, the Buyer must:
      </p>

      <ol className="ml-5" type="a">
        <li>
          <p>
            (a) make a payment in full in any nominated digital currency or via any other nominated
            payment method (as specified on the Website) to the wallet address nominated by the
            Seller, and in the amount stated on the Website at the time of the acceptance of the
            Buyer’s offer by the Seller (Purchase Price); and
          </p>
        </li>
        <li>
          <p>
            (b) provide the Seller with its digital wallet address by linking its wallet with the
            Website or by any other means specified by the Website. The Buyer shall be solely
            responsible for ensuring that the wallet address provided is correct and the Buyer
            agrees that the Seller will not be liable for any loss or damage caused as a result of
            an incorrect wallet address being provided by the Buyer.
          </p>
        </li>
      </ol>

      <p>
        3.2 The Seller will transfer the Mittaria Genesis Collection to the wallet address provided
        by the Buyer immediately upon acceptance of the Buyer’s offer to purchase the Mittaria
        Genesis Collection and receipt of the Purchase Price from the Seller.
      </p>

      <p>
        3.3 In the event the payment of the Purchase Price is reversed or becomes invalid (for any
        reason), the Buyer shall immediately return the Mittaria Genesis Collection to the Seller by
        transferring it back to the wallet address of the Seller.
      </p>

      <h3>4. Right to use the NFT</h3>

      <p>
        4.1 All rights of the Buyer to the Mittaria Genesis Collection s will terminate immediately
        upon the transfer of the Mittaria Genesis Collection by the Buyer to a third party, subject
        to the contract for sale and any other applicable terms.
      </p>

      <p>
        4.2.The Buyer agrees that in the case of any onward sale of the Mittaria Genesis Collection,
        the grant of rights to the Mittaria Genesis Collection s must be on the same terms as set
        out in these Terms of Sale and including the restrictions specified in clause 5 of this NFT
        Terms of Sale. Any sale of the Mittaria Genesis Collection which purports to modify the
        terms of the grant of rights or the restrictions on use of Mittaria Genesis Collection s
        will result in the automatic termination of the grant of rights to the Mittaria Genesis
        Collection s.
      </p>
      <h3>5. Restrictions on the use of the Asset</h3>

      <p>
        5.1 The Buyer shall not, and shall not permit any other person to do or attempt to do any of
        the following:
      </p>
      <ol className='ml-5' type="a">
        <li>
          <p>
            (a) trademark, copyright, patent or otherwise acquire intellectual property rights in or
            to the Mittaria Genesis Collection or Artwork unless obtained lawfully from the owner of
            such intellectual property rights;
          </p>
        </li>
        <li>
          <p>
            (b) take any action which would or might invalidate, challenge, oppose or otherwise put
            in dispute our intellectual property rights, the owner’s intellectual property rights in
            the Artwork, or contravene the moral rights of the creator of the Artwork;
          </p>
        </li>
        <li>
          <p>
            (c) use the Mittaria Genesis Collection s or the Artwork in connection with any illegal
            activities;
          </p>
        </li>
        <li>
          <p>
            (d) create any security, capital market products, financial instrument or derivative
            product based on the Mittaria Genesis Collection; or the Artwork
          </p>
        </li>
        <li>
          <p>
            (e) use the Mittaria Genesis Collection s or Artwork in connection with any images,
            videos, or any other forms of media or content that depict hatred, intolerance,
            involving the monarchy that is likely to violate lèse-majestée, politically-charged
            messages, violence, cruelty or anything else that could reasonably be found to
            constitute hate speech, infringe upon the rights of others, violating laws, public
            order, or good morals or otherwise bring the Seller into disrepute.
          </p>
        </li>
      </ol>
      <p>
        5.2 Any breach of the restrictions set out in clause 5.1 will result in the automatic
        termination of the right to use the Mittaria Genesis Collection s under clause 4.1.
      </p>

      <p>
        5.3 Nothing in these Terms of Sale grants any licence or right to use the Seller’s,
        intellectual property rights, including but not limited to trademarks or branding.
      </p>

      <h3>6. No partnership or agency.</h3>
      <p>
        6.1 Nothing in these terms is intended to, or shall be deemed to, establish any partnership
        or joint venture between you and us, constitute any party the agent of another party, or
        authorise any party to make or enter into any commitments for or on behalf of any other
        party.
      </p>

      <h3>7. Assignment.</h3>

      <p>
        We may assign our rights and obligations under these terms (without your prior express
        consent), provided that your rights under these terms remain unaffected. You must not assign
        your rights or obligations under these terms.
      </p>

      <h3>8. Events outside control.</h3>

      <p>
        Neither party will have any liability under or be deemed to be in breach of these terms
        for any delays or failures in performance of these terms which result from circumstances
        beyond its reasonable control.
      </p>

      <h3>
        9. Severance, Governing law, Dispute Resolution and Arbitration, and Class Action Waiver
      </h3>

      <p>
        Clause 11.7, Severance, Clause 11.8 Governing Law, Clause 11.9 Dispute Resolution and
        Arbitration, and Clause 11.10 Class Action Waiver of the Mittaria Genesis Collection Terms
        and Conditions shall apply, with the necessary changes being made to these NFTs Terms of
        Sale.
      </p>

      <div className="mt-20 flex justify-center">
        <Link to="/creator-form" target="_blank">
          <Button>
            <span className="font-monsterOfFantasy font-normal">Creator Registration</span>
          </Button>
        </Link>
      </div>
    </div>
  );
}

import { gsap } from 'gsap';
import icon1 from 'main/assets/images/seasons-page/icon-1.png';
import icon2 from 'main/assets/images/seasons-page/icon-2.png';
import icon3 from 'main/assets/images/seasons-page/icon-3.png';
import icon4 from 'main/assets/images/seasons-page/icon-4.png';
import icon5 from 'main/assets/images/seasons-page/icon-5.png';
import icon6 from 'main/assets/images/seasons-page/icon-6.png';
import icon7 from 'main/assets/images/seasons-page/icon-7.png';
import icon8 from 'main/assets/images/seasons-page/icon-8.png';
import placeholder from 'main/assets/images/seasons-page/placeholder.png';
import IconParagraph from 'main/components/IconParagraph/IconParagraph';
import PageIntroduction from 'main/components/PageTitle/PageTitle';
import Title from 'main/components/Title/Title';
import Layout from 'main/layouts/Layout';
import React, { useEffect } from 'react';

export default function TheGenesis() {
  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.timeline().from(['h3', '.icon'], {
        yPercent: 100,
        autoAlpha: 0,
        duration: 1.25,
        stagger: {
          amount: 1,
        },
        ease: 'power4',
        delay: 1,
      });
    });

    return () => ctx.revert();
  }, []);

  return (
    <Layout smallerFooter absoluteFooter>
      <div className="h-full min-h-[100vh] relative flex flex-col items-center justify-center">
        <div className="absolute h-full w-full z-10 bg-dark-purple bg-opacity-50" />
        <div
          className="absolute h-full w-full z-10"
          style={{
            background: 'linear-gradient(180deg, #160D4D 0%, rgba(22, 13, 77, 0.00) 100%)',
          }}
        />
        <img src={placeholder} alt="bg" className="absolute h-full w-full object-cover" />
        <div className="relative h-full w-full max-w-9xl flex flex-col items-center justify-center z-10 mt-[100px] md:mb-[25px] mb-[75px] pb-0">
          <PageIntroduction pageTitle="The Genesis" />
          <Title titleType="h3" className="pt-5" color="white">
            Season 1: Access
          </Title>
        </div>
        <div className="relative w-full max-w-10xl m-auto flex flex-wrap gap-x-[5%] 2xl:gap-y-10 gap-y-10 z-10 px-10 pt-0 md:pb-24 pb-40">
          <IconParagraph iconSrc={icon7} title="Access">
            Step into our evolving ecosystem to mint all upcoming collections and enroll in all
            future seasons for free.
          </IconParagraph>
          <IconParagraph iconSrc={icon5} title="Rewards">
            Harness the power of our thriving ecosystem: From valuable rewards to immense
            opportunities, they’re all here for the taking.
          </IconParagraph>
          <IconParagraph iconSrc={icon3} title="Experience">
            Journey through our captivating metaverses: Translucia, Master of the Metaverse;
            Forestias, the beacon of sustainable, compassionate living; and Idyllias, a harmonious
            fusion of virtual and real worlds.
          </IconParagraph>
          <IconParagraph iconSrc={icon6} title="IP">
            At T&B, we&apos;ve mastered the art of creating successful Intellectual Properties. With
            our incredible community, we will build Mittaria into a globally recognized brand.
          </IconParagraph>
          <IconParagraph iconSrc={icon4} title="Partnership">
            Indulge in the benefits of our global relationships in both Web2 and Web3 and gain value
            from our prestigious network of partners.
          </IconParagraph>
          <IconParagraph iconSrc={icon8} title="Web2">
            Benefit from our Web2 accomplishments past, present, and future. Every season features a
            traditional business model that generates value for our community.
          </IconParagraph>
          <IconParagraph iconSrc={icon1} title="Community">
            Immerse yourself in the captivating world of Mittaria - the home of happiness. Join our
            vibrant community and be a part of redefining Web3.
          </IconParagraph>
          <IconParagraph iconSrc={icon2} title="Locked">
            This utility is yet to be revealed…
          </IconParagraph>
        </div>
      </div>
    </Layout>
  );
}

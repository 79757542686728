/* eslint-disable no-nested-ternary */
import { useFrame } from '@react-three/fiber';
import * as React from 'react';
import { Object3D, AnimationMixer, AnimationClip, AnimationAction } from 'three';

/**
 * Function is based on the three/drei useAnimations hook
 * https://github.com/pmndrs/drei#useanimations
 */
function useAnimations(clips: AnimationClip[], root: Object3D, autoUpdate = true) {
  const ref = React.useRef();
  const [actualRef] = React.useState(() =>
    root
      ? root instanceof Object3D
        ? {
            current: root,
          }
        : root
      : ref
  ); // eslint-disable-next-line prettier/prettier

  // @ts-ignore
  const [mixer] = React.useState(() => new AnimationMixer(undefined));
  const lazyActions = React.useRef<Record<string, AnimationAction>>({});
  const [api] = React.useState(() => {
    const actions = {};
    clips.forEach((clip) =>
      Object.defineProperty(actions, clip.name, {
        enumerable: true,

        get() {
          if (actualRef.current) {
            if (!lazyActions.current[clip.name])
              lazyActions.current[clip.name] = mixer.clipAction(clip, actualRef.current);
            return lazyActions.current[clip.name];
          }
          return undefined;
        },
      })
    );
    return {
      ref: actualRef,
      clips,
      actions,
      names: clips.map((c) => c.name),
      mixer,
    };
  });

  useFrame((_, delta) => {
    if (autoUpdate) mixer.update(delta);
  });

  React.useEffect(() => {
    const currentRoot = actualRef.current;
    return () => {
      // Clean up only when clips change, wipe out lazy actions and uncache clips
      lazyActions.current = {};
      Object.values(api.actions).forEach((action) => {
        if (currentRoot) {
          mixer.uncacheAction(action as AnimationClip, currentRoot);
        }
      });
    };
  }, [clips, actualRef, api.actions, mixer]);

  React.useEffect(
    () => () => {
      mixer.stopAllAction();
    },
    [mixer]
  );
  return api;
}

export { useAnimations };

import introductionImg from 'main/assets/images/info-page/introduction-bg.png';
import React from 'react';

interface SkyBgProps extends React.HTMLAttributes<HTMLDivElement> {}

export default function SkyBg(props: SkyBgProps) {
  const { className } = props;

  return (
    <div className={`absolute z-0 top-0 left-0 w-full h-full ${className || ''}`}>
      <img
        src={introductionImg}
        alt="introduction bg"
        className="lg:w-full lg:h-auto h-[65vh] object-cover"
      />
    </div>
  );
}

import { gsap } from 'gsap';
import videoIcon from 'main/assets/images/video/video-icon.png';
import React, { useEffect, useRef } from 'react';
import ReactPlayer from 'react-player/youtube';

interface VideoProps extends React.HTMLAttributes<HTMLDivElement> {
  videoUrl: string;
  videoBg?: string;
}

export default function Video(props: VideoProps) {
  const { className, videoUrl, videoBg, ...rest } = props;
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap
        .timeline()
        .set(container.current, {
          visibility: 'visible',
        })
        .from(container.current, {
          autoAlpha: 0,
          yPercent: 50,
          duration: 1.5,
          delay: 1.5,
          ease: 'power4',
        });
    });

    return () => ctx.revert();
  }, [container]);

  return (
    <div
      ref={container}
      className={`border border-violet rounded relative pt-[56.25%] invisible ${className || ''}`}
      {...rest}
    >
      <ReactPlayer
        className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
        url={videoUrl}
        light={videoBg}
        width="99%"
        height="98%"
        playIcon={
          <button type="button">
            <img src={videoIcon} alt="video icon" />
          </button>
        }
      />
    </div>
  );
}

import { gsap } from 'gsap';
import { StationInformation } from 'main/common/constants';
import { StationContext } from 'main/store/StationProvider';
import React, { useContext, useEffect, useRef } from 'react';

interface MapPointProps {
  className?: string;
  station: StationInformation;
  index: number;
}

export default function MapPoint(props: MapPointProps) {
  const { className, station, index } = props;
  const { setActiveStation } = useContext(StationContext);
  const container = useRef<HTMLDivElement>(null);

  function selectStation() {
    setActiveStation(index);
  }

  useEffect(() => {
    const ctx = gsap.context(() => {
      const tl = gsap
        .timeline({
          paused: true,
          defaults: {
            duration: 0.75,
            ease: 'expo',
          },
        })
        .to(
          'button',
          {
            borderColor: '#AC9FFF',
          },
          0
        )
        .to(
          'button > div',
          {
            backgroundColor: '#AC9FFF',
          },
          0
        )
        .set(
          '.point-title',
          {
            display: 'block',
          },
          0
        )
        .from(
          '.point-title',
          {
            scale: 0.75,
            autoAlpha: 0,
          },
          0
        );

      const handleMouseEnter = () => {
        tl.play();
      };
      const handleMouseLeave = () => {
        tl.reverse();
      };

      container.current?.addEventListener('mouseenter', handleMouseEnter);
      container.current?.addEventListener('mouseleave', handleMouseLeave);
    }, container);

    return () => ctx.revert();
  }, [container]);

  return (
    <div
      ref={container}
      className={`absolute w-[10%] aspect-square bg-[#26235F] border border-purple rounded-full flex items-center justify-center ${
        className || ''
      }`}
    >
      <button
        onClick={selectStation}
        type="button"
        className="w-full h-full aspect-square z-10 bg-[#26235F] border border-purple rounded-full flex items-center justify-center "
      >
        <div className="w-[25%] h-[25%] bg-white rounded-full" />
      </button>
      <div className="point-title absolute z-40 left-[130%] bg-[#5948C8] rounded-3xl py-1 px-3 uppercase text-[10px] font-altform ">
        {station.name}
      </div>
    </div>
  );
}

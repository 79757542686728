import { gsap } from 'gsap';
import { ReactComponent as StationDecoration } from 'main/assets/images/seasons-slider/titleDecoration.svg';
import { STATIONS } from 'main/common/constants';
import Title from 'main/components//Title';
import SliderButton from 'main/components/SliderButton';
import { StationContext } from 'main/store/StationProvider';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { MathUtils } from 'three';

const FADE_DURATION = 0.3;

export default function StationCard(props: React.HTMLAttributes<HTMLDivElement>) {
  const { className, ...rest } = props;
  const container = useRef<HTMLDivElement>(null);

  const { activeStation, showStationOverlay, setShowStationOverlay, setActiveStation } =
    useContext(StationContext);

  const sideStations: { prev: string; next: string } | undefined = useMemo(() => {
    const nextIndex = MathUtils.euclideanModulo(activeStation + 1, STATIONS.length);
    const prevIndex = MathUtils.euclideanModulo(activeStation - 1, STATIONS.length);
    return { prev: STATIONS[prevIndex].name, next: STATIONS[nextIndex].name };
  }, [activeStation]);

  function closeOverlay() {
    setShowStationOverlay(false);
  }
  const changeActiveStation = useCallback(
    (value: number) => {
      const newStation = MathUtils.euclideanModulo(activeStation! + value, STATIONS.length);
      setActiveStation(newStation);
    },
    [activeStation, setActiveStation]
  );

  function handleClickPrev() {
    changeActiveStation(-1);
  }

  function handleClickNext() {
    changeActiveStation(1);
  }

  useEffect(() => {
    gsap.set(container.current, {
      yPercent: showStationOverlay ? 0 : 100,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    gsap.to(container.current, {
      yPercent: showStationOverlay ? 0 : 100,
      duration: 1.25,
      ease: 'power4',
    });
  }, [showStationOverlay]);

  return (
    <div
      {...rest}
      ref={container}
      className={`fixed z-30 top-0 pointer-events-auto bottom-0 right-0 w-full max-w-[400px] overflow-hidden flex flex-col items-center justify-center font-altform font-light bg-cover bg-top bg-no-repeat pt-5 bg-[#160D4D] ${
        className || ''
      }`}
    >
      <SliderButton
        onClick={closeOverlay}
        action="close"
        className="min-h-[40px] ml-auto mr-8"
        id="station-close"
      />

      <div
        id="station-info"
        className="flex-auto h-[85%] w-full pt-30 overflow-hidden py-8 md:px-6 px-3"
      >
        <div className="relative z-30 h-full flex flex-col gap-5 md:px-6 px-3 overflow-y-scroll">
          <div className="">
            <Title titleType="h2" color="white" className="text-center pb-10">
              {STATIONS[activeStation].name}
            </Title>
          </div>
          <div className="relative mb-20">
            {/* 
            Station model view
          */}
            <div
              id="station-viewer"
              className="absolute top-0 left-0 w-full h-full flex items-center justify-center uppercase"
            >
              <img src={STATIONS[activeStation].img} alt={STATIONS[activeStation].name} />
            </div>
            <StationDecoration className="w-full h-auto" />
          </div>
          <div className="capitals whitespace-pre-wrap">{STATIONS[activeStation].description}</div>
        </div>
      </div>

      <div
        id="station-selectors"
        className="relative flex-0 flex gap-x-3 w-full px-8 pt-2 pb-5 uppercase"
      >
        <div className="flex items-center flex-auto gap-x-5">
          <SliderButton
            onClick={handleClickPrev}
            action="prev"
            id="prev-station"
            className="min-h-[40px] max-h-[40px]"
          />
          <p className="font-monsterOfFantasy text-[12px] ">{sideStations?.prev || ''}</p>
        </div>
        <div className="flex items-center justify-end flex-auto gap-x-5">
          <p className="font-monsterOfFantasy text-[12px] text-right">{sideStations?.next || ''}</p>
          <SliderButton
            onClick={handleClickNext}
            action="next"
            id="next-station"
            className="min-h-[40px] max-h-[40px]"
          />
        </div>
      </div>
    </div>
  );
}

import { ReactComponent as SlideDecoration } from 'main/assets/images/seasons-slider/slideDecoration.svg';
import React from 'react';

interface HeadingAlternativeProps extends React.HTMLAttributes<HTMLDivElement> {}

export default function HeadingAlternative(props: HeadingAlternativeProps) {
  const { children, className } = props;

  return (
    <div className={`flex gap-x-2 items-center justify-center ${className || ''}`}>
      <div className="flex-1 opacity-50">
        <SlideDecoration className="w-full h-auto" />
      </div>
      <div className="flex-0 relative z-10 text-center uppercase px-2">{children}</div>
      <div className="flex-1 transform -scale-x-100 opacity-50">
        <SlideDecoration className="w-full h-auto" />
      </div>
    </div>
  );
}

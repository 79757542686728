import * as THREE from 'three';
import { clamp, inverseLerp } from 'three/src/math/MathUtils';

function encodeInt(array: Uint8Array, index: number, num: number) {
  const int = Math.round(num) >>> 0;

  const r = (int >> 24) & 0xff;
  const g = (int >> 16) & 0xff;
  const b = (int >> 8) & 0xff;
  const a = int & 0xff;

  array[index] = r;
  array[index + 1] = g;
  array[index + 2] = b;
  array[index + 3] = a;
}

export function encodePointsInTexture(points: THREE.Vector3[]) {
  const size = Math.sqrt(2 ** Math.ceil(Math.log2(points.length * 3)));
  const uint8Array = new Uint8Array(size * size * 4);

  points.forEach((point, i) => {
    const xIndex = i * 12;
    const yIndex = xIndex + 4;
    const zIndex = xIndex + 8;

    encodeInt(uint8Array, xIndex, point.x * 10000);
    encodeInt(uint8Array, yIndex, point.y * 10000);
    encodeInt(uint8Array, zIndex, point.z * 10000);
  });

  const dataTexture = new THREE.DataTexture(
    uint8Array,
    size,
    size,
    THREE.RGBAFormat,
    THREE.UnsignedByteType
  );

  dataTexture.wrapS = THREE.RepeatWrapping;
  dataTexture.wrapT = THREE.RepeatWrapping;
  dataTexture.minFilter = THREE.NearestFilter;
  dataTexture.magFilter = THREE.NearestFilter;
  dataTexture.needsUpdate = true;
  dataTexture.offset = new THREE.Vector2(0, 0);

  return { dataTexture, textureSize: size };
}

export function randomPointInSphere(
  radius: number,
  arc: number,
  target: THREE.Vector3 = new THREE.Vector3(0.0, 0.0, 0.0)
): THREE.Vector3 {
  const u = Math.random();
  const v = Math.random();
  const theta = 2.0 * Math.PI * u;
  const phi = Math.acos(2 * v - 1) * arc;
  target.x = radius * Math.sin(phi) * Math.cos(theta);
  target.y = radius * Math.sin(phi) * Math.sin(theta);
  target.z = radius * Math.cos(phi);
  return target;
}

export function getOpacityForActiveWindow(
  progress: number,
  fadeStart: number,
  windowStart: number,
  windowEnd: number,
  fadeEnd: number
) {
  if (progress < windowStart) return clamp(inverseLerp(fadeStart, windowStart, progress), 0, 1);
  if (progress > windowEnd) return clamp(1 - inverseLerp(windowEnd, fadeEnd, progress), 0, 1);
  return 1;
}

/**
 * Finds the equivalent rotation to targetRotation that requires to move for the least amount of radians
 * @param currentRotation
 * @param targetRotation
 * @returns
 */
export function findClosestRotation(currentRotation: number, targetRotation: number) {
  if (targetRotation < 0) targetRotation = 2 * Math.PI + targetRotation;
  const numberOfFullRotations = Math.floor(currentRotation / Math.PI / 2);
  const possibleRotations = [
    numberOfFullRotations - 1,
    numberOfFullRotations,
    numberOfFullRotations + 1,
  ].map((r) => r * Math.PI * 2 + targetRotation);
  return possibleRotations.sort(
    (a, b) => Math.abs(a - currentRotation) - Math.abs(b - currentRotation)
  )[0];
}

import { ReactComponent as CircleOne } from 'assets/images/UI/chapter-circle-one.svg';
import { ReactComponent as CircleThree } from 'assets/images/UI/chapter-circle-three.svg';
import { ReactComponent as CircleTwo } from 'assets/images/UI/chapter-circle-two.svg';
import { ReactComponent as ChapterFiveTitle } from 'assets/images/UI/title-chapter-five.svg';
import { ReactComponent as ChapterFourTitle } from 'assets/images/UI/title-chapter-four.svg';
import { ReactComponent as ChapterOneTitle } from 'assets/images/UI/title-chapter-one.svg';
import { ReactComponent as ChapterSixTitle } from 'assets/images/UI/title-chapter-six.svg';

export const ASSET_MAP: {
  [key: number]: {
    Title: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    Circle: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  };
} = {
  1: {
    Title: ChapterOneTitle,
    Circle: CircleThree,
  },
  2: {
    Title: ChapterFourTitle,
    Circle: CircleOne,
  },
  3: {
    Title: ChapterFiveTitle,
    Circle: CircleTwo,
  },
  4: {
    Title: ChapterSixTitle,
    Circle: CircleThree,
  },
};

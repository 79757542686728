import Button from 'components/Button/Button';
import PageTitle from 'main/components/PageTitle/PageTitle';
import React from 'react';

interface WalletCheckerLayoutProps {
  buttonText: string;
  onButtonClick?: () => void;
  children?: React.ReactNode;
  disableButton?: boolean;
}
export default function WalletCheckerLayout(props: WalletCheckerLayoutProps) {
  const { disableButton, buttonText, onButtonClick, children } = props;

  return (
    <div className="h-full flex flex-col flex-1 justify-between items-center text-white p-12 pt-4 pb-20">
      <PageTitle className="pb-4" pageTitle="WALLET CHECKER" />
      <div className="flex flex-col flex-1 pb-8">{children}</div>
      <div className={disableButton ? 'opacity-30 pointer-events-none' : ''}>
        <Button onClick={onButtonClick}>{buttonText}</Button>
      </div>
    </div>
  );
}
